import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import Headeradminhotel from "./headeradminhotel";
import CryptoJs from "crypto-js";
import { jwtDecode } from "jwt-decode";
function Registrationhotel({showAlert,setAlertProps}) {
  var [name, setName] = useState("");
  // var [pass,setPass]=useState('')
  var [email, setEmail] = useState("");
  var [mobile, setMobile] = useState("");
  var [message, setMessage] = useState("");
  var [messages, setMessages] = useState("");
  var [flag, setFlag] = useState();
  var [flags, setFlags] = useState();
  var [flagForUser, setflagForUser] = useState();
  var [userUniqueMessage, setUserUniqueMessage] = useState();
  const [error, setError] = useState("");
  const [mobileError, setMobileError] = useState("");
  var [mobileFlag, setMobileFlag] = useState();
  var [userFlag, setUserFlag] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [data, setdata] = useState(4);
  const feed = useRef();
  console.log(data);
  console.log(name);

  var user;
  const token = localStorage.getItem("adminToken");
  //const project=cookie.get("project")
  const [admin, setAdmin] = useState([]);
  const [project, setProject] = useState("");

  useEffect(() => {
    try {
      const decodedToken = jwtDecode(token);
      setProject(decodedToken.project);
    } catch (error) {
      navigate("/v1/jim_admin_user_login");
    }
  }, []);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/adminfindall/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAdmin(data.data);
        if (data.statusCode === 400) {     
 showAlert({
  title:  'Logged Out',
  message: 'You are logged out due to session timeout.',
  okText: 'OK',
  callback: () => {
      console.log('Alert closed');
      setAlertProps(null)
  }
});
          navigate("/v1/jim_admin_user_login");
        }
      })
      .catch((err) => console.log(err));
  }, [project]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/findAll/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUsers(data.data);
        if (data.statusCode === 400) {
          //alert("User Logged Out")
          navigate("/v1/jim_admin_user_login");
        }
      })
      .catch((err) => console.log(err));
  }, [project]);

  useEffect(() => {
    console.log(token);
    if (localStorage.getItem("tokenauth") === "false") {
      //if(cookie.get("tokenauth")==="false")
      window.location.href = "/v1/jim_admin_user_login";
    }
  }, []);
  const button = () => {
    try {
      navigate(-1); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };

  const clicks = async (y) => {
    setIsLoading(false);
    const token = localStorage.getItem("adminToken");
    console.log(flag);
    console.log(flags);
    y.preventDefault();
    // if(flag===0 && flags===0 && mobileFlag===0 && userFlag===0 && flagForUser===0)
    //  {
    if (mobileFlag === 0) {
      /*if(document.getElementById('User').checked) {
        user="user"
      } 
      else if(document.getElementById('Support').checked) {
        user="support"
      } 
      else {
        user=""
      }*/
   

    
    var user=document.getElementById("type").value;
    console.log(user)
    var status=""
    const randomBytes = CryptoJs.lib.WordArray.random(8);
    const pass= randomBytes.toString(CryptoJs.enc.Hex).slice(0, 8);
    const encryptedPassword=CryptoJs.AES.encrypt(pass,process.env.REACT_APP_API_KEY)
    const obj={name,pass:encryptedPassword.toString(),email,mobile,user}
    console.log(obj)
    const res={
      method: 'POST',
      withCredentials: 'true',
      headers: {'Authorization':`Bearer ${token}`,'Content-Type': 'application/json' },
      body: JSON.stringify(obj)
  };
  if(user==='user')
  {
  await fetch(`${process.env.REACT_APP_API_URL}/useradd/${project}`, res)
  .then(response => response.json())
        .then(async (data)=>
        {
          setIsLoading(true)
 if(data.status===400)
 {
  //alert("user Logged out")
   navigate('/v1/jim_admin_user_login')
 }
 else if(data.statusCode===400)
 {  
 showAlert({
  title:  'Error',
  message: data.message,
  okText: 'OK',
  callback: () => {
      console.log('Alert closed');
      setAlertProps(null)
  }
}); 
  navigate("/v1/jim/admin_adduser_support");
 }
 else
 {
  
 showAlert({
  title:  'Registration Successful',
  message: 'You have successfully registered.',
  okText: 'OK',
  callback: () => {
      console.log('Alert closed');
      setAlertProps(null)
  }
}); 
  const encryptedPassword=CryptoJs.AES.encrypt(pass,process.env.REACT_APP_API_KEY).toString()
  console.log(encryptedPassword)
  console.log(`${process.env.REACT_APP_API_URL}/newpassword/${email}/${name}`)
   await fetch(`${process.env.REACT_APP_API_URL}/newpassword/${email}/${name}/${project}`,
  {
    method: 'POST',
    headers: {'Content-Type': 'application/json','Authorization':`Bearer ${token}` },
    body: JSON.stringify({encryptedPassword})
  })
    .then(
      console.log("email send"),
    navigate("/v1/jim/admin_proj_memberlist"))
      .catch(err=>console.log(err))
  // try {     
  //   navigate("/v1/jim/admin_proj_memberlist"); // Omit optional second argument
  // } catch (error) {
  //   navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
  // }
 }
            console.log("added")
        })
       
  }
      
      else{
        await fetch(`${process.env.REACT_APP_API_URL}/adminuseradd/${project}`, res)
        .then(response => response.json())
        .then(async (data)=>
        {
          setIsLoading(true)
          if(data.status===400)
          {
           // alert("user logged out")
            navigate('/v1/jim_admin_user_login')
          }
          else if(data.statusCode===400)
          {
          
 showAlert({
  title:  'Error',
  message: data.message,
  okText: 'OK',
  callback: () => {
      console.log('Alert closed');
      setAlertProps(null)
  }
}); 
          navigate("/v1/jim/admin_adduser_support");
          }
          else
          {
            showAlert({
              title:  'Registration Successful',
              message: 'You have successfully registered.',
              okText: 'OK',
              callback: () => {
                  console.log('Alert closed');
                  setAlertProps(null)
              }
            }); 
           const encryptedPassword=CryptoJs.AES.encrypt(pass,process.env.REACT_APP_API_KEY).toString()
           await  fetch(`${process.env.REACT_APP_API_URL}/adminnewpassword/${email}/${name}/${project}`,
           {
             method: 'POST',
             headers: {'Content-Type': 'application/json','Authorization':`Bearer ${token}` },
             body: JSON.stringify({encryptedPassword})
           })
             .then(console.log("email send"),
             navigate("/v1/jim/admin_proj_memberlist"))
               .catch(err=>console.log(err))
          //  try {
         
          //   navigate("/v1/jim/admin_proj_memberlist"); // Omit optional second argument
          // } catch (error) {
          //   navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
          // }
          }
            console.log("added")
        })
       
      }

      //window.location.reload(false);
      /* emailjs.sendForm('service_hhccauz', 'template_z0l397w', feed.current, 'O2NrWMLvbyPo5hJ-v')
        .then((result) => {
          
        }, (error) => {
            console.log(error.text);
        });
        */

      // try {

      //   navigate("/v1/jim/admin_proj_memberlist"); // Omit optional second argument
      // } catch (error) {
      //   navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
      // }

      // }
      // else if(flag===1 && flags===0 && mobileFlag===0 && userFlag===0 && flagForUser===0){
      // alert("email is already registered")
      // try {
      //   navigate("/v1/jim/admin_adduser_support"); // Omit optional second argument
      // } catch (error) {
      //   navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
      // }
      // }
      // else if(flag===0 && flags===0 && mobileFlag===0 && userFlag===0 && flagForUser===1){
      // alert("username is already registered")
      // try {
      //   navigate("/v1/jim/admin_adduser_support"); // Omit optional second argument
      // } catch (error) {
      //   navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
      // }
    } else {
      showAlert({
        title:  'Invalid Details',
        message: 'Please check the information you entered and try again.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    }); 
      try {
        navigate("/v1/jim/admin_adduser_support"); // Omit optional second argument
      } catch (error) {
        navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
      }
    }
  };
  return (
    <div>
      <div style={{ height: "100vh" }}>
        <Headeradminhotel
          raiseTicket={0}
          registrationRequest={0}
          adminRequests={0}
          backButton={1}
          showAlert={showAlert} 
          setAlertProps={setAlertProps}
        />

        <h2
          style={{ color: "#ffffff", textAlign: "center", fontFamily: "auto" }}
        >
          Registration
        </h2>
        <br />
        <div
          className="login"
          style={{
            paddingTop: "50px",
            marginBottom: "30px",
            width: "475px",
            height: "550px",
          }}
        >
          {/*<span class="sc-AnqlK jNMwMZ">
    <button type="button" class="css-1undbr0" onClick={button}>
      <span class="css-j8fq0c">
        <span class="css-8xpfx5">
          <span role="img" aria-label="Back" class="css-snhnyn" /*style="--icon-primary-color: currentColor; --icon-secondary-color: var(--ds-surface, #FFFFFF);">
            <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
              <path fill="#fff" fill-opacity=".01" d="M0 0h24v24H0z"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.207 5.793a1 1 0 010 1.414L8.414 11H18a1 1 0 110 2H8.414l3.793 3.793a1 1 0 11-1.415 1.414l-5.499-5.5A.997.997 0 015 12.028v-.057a.996.996 0 01.293-.678l5.499-5.5a1 1 0 011.415 0z" fill="currentColor"></path></svg></span></span>
              <span class="css-mu6jxl">Back</span></span></button></span>
              <br/>
              <br/>*/}
          <form onSubmit={clicks} ref={feed}>
            <div align="left">
              <label for="type">
                <b>Select Option</b>
              </label>
              <select name="type" id="type">
                <option value="user">User</option>
                <option value="support">Support</option>
                <option value="admin">Admin</option>
              </select>
              <br />
            </div>
            <br />

            <label>
              <b>User Name</b>
            </label>
            <input
              type="text"
              required
              name="username"
              id="Uname"
              placeholder="Username"
              autoComplete="off"
              onChange={(e) => {
                if (
                  document.getElementById("type").value === "admin" ||
                  document.getElementById("type").value === "support"
                ) {
                  if (admin.length !== 0) {
                    for (var key of admin) {
                      console.log(key);
                      if (key.name === e.target.value) {
                        setflagForUser(1);
                        setUserUniqueMessage("user name should be unique");
                        break;
                      } else {
                        setflagForUser(0);
                        setUserUniqueMessage("");
                        const newUsername = e.target.value;
                        if (newUsername.includes(" ")) {
                          setError("Username cannot contain spaces");
                          setUserFlag(1);
                        } else {
                          setError("");
                          setName(newUsername);
                          setUserFlag(0);
                        }
                      }
                    }
                  } else {
                    setflagForUser(0);
                  }

                  setName(e.target.value);
                } else if (document.getElementById("type").value === "user") {
                  if (users.length !== 0) {
                    for (var key of users) {
                      console.log(key);
                      if (key.name === e.target.value) {
                        setflagForUser(1);
                        setUserUniqueMessage("user name should be unique");
                        console.log(key.email);
                        //fl=1
                        console.log(flag);
                        break;
                      } else {
                        setflagForUser(0);
                        setUserUniqueMessage("");
                        console.log(e.target.value);
                        const newUsername = e.target.value;
                        if (newUsername.includes(" ")) {
                          setError("Username cannot contain spaces");
                          setUserFlag(1);
                        } else {
                          setError("");
                          setName(newUsername);
                          setUserFlag(0);
                        }
                      }
                    }
                  } else {
                    setflagForUser(0);
                  }

                  setName(e.target.value);
                }
              }}
              //    onChange={(e)=>{
              //     const newUsername = e.target.value;
              // if (newUsername.includes(' ')) {
              //   setError('Username cannot contain spaces');
              //   setUserFlag(1)
              // } else {
              //   setError('');
              //   setName(newUsername);
              //   setUserFlag(0)
              // }} }
            />
            <br />
            {userUniqueMessage && (
              <p style={{ color: "red" }}>{userUniqueMessage}</p>
            )}
            {error && <p style={{ color: "red" }}>{error}</p>}
            <br />
            <label>
              <b>Email</b>
            </label>
            <p style={{ color: "red" }}>{message}</p>
            <input
              style={{ marginBottom: "00px" }}
              type="email"
              required
              name="email"
              id="Email"
              placeholder="Email"
              onInvalid={(F) =>
                F.target.setCustomValidity("Email should be contain @ and .")
              }
              onInput={(F) => F.target.setCustomValidity("")}
              pattern=".+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
              onChange={(e) => {
                {
                  console.log(document.getElementById("type").value);
                }
                if (
                  document.getElementById("type").value === "admin" ||
                  document.getElementById("type").value === "support"
                ) {
                  if (admin.length !== 0) {
                    for (var key of admin) {
                      console.log(key);
                      if (
                        key.email.toLowerCase() === e.target.value.toLowerCase()
                      ) {
                        setFlag(1);
                        setMessage("! email is already registered");
                        console.log(key.email);
                        //fl=1
                        console.log(flag);
                        break;
                      } else {
                        setFlag(0);
                        setMessage("");
                        console.log(flag);
                      }
                    }
                  } else {
                    setFlag(0);
                  }

                  setEmail(e.target.value);
                } else if (document.getElementById("type").value === "user") {
                  if (users.length !== 0) {
                    for (var key of users) {
                      console.log(key);
                      if (
                        key.email.toLowerCase() === e.target.value.toLowerCase()
                      ) {
                        setFlag(1);
                        setMessage("! email is already registered");
                        console.log(key.email);
                        //fl=1
                        console.log(flag);
                        break;
                      } else {
                        setFlag(0);
                        setMessage("");
                        console.log(flag);
                      }
                    }
                  } else {
                    setFlag(0);
                  }

                  setEmail(e.target.value);
                }
              }}
            />
            <br />
            <br />
            <label>
              <b>Mobile Number</b>
            </label>
            <p style={{ color: "red" }}>{messages}</p>
            <input
              style={{ marginBottom: "00px" }}
              type="tel"
              required
              name="mobile"
              id="Mobile"
              pattern="[0-9]{10}"
              value={mobile}
              placeholder="Mobile"
              onChange={(e) => {
                const inputValue = e.target.value;
                const numericValue = inputValue.replace(/[^0-9]/g, "");
                const limitedNumericValue = numericValue.slice(0, 10);
                if (limitedNumericValue.length !== 10) {
                  setFlags(1);
                  setMessages("! Not a valid Number");
                  console.log("flags" + flags);
                } else {
                  setFlags(0);
                  setMessages("");
                }
                const newMobilenumber = e.target.value;
                if (newMobilenumber.includes(" ")) {
                  setMobileError("Mobile number cannot contain spaces");
                  setMobileFlag(1);
                } else {
                  setMobileError("");

                  setMobile(limitedNumericValue);
                  setMobileFlag(0);
                }
              }}
            />
            <br />
            {mobileError && <p style={{ color: "red" }}>{mobileError}</p>}
            <br />
            {/*<label><b>Password
            </b>
          </label>
          <input type="password" name="pass" id="Pass" placeholder="Password" onChange={(e)=>setPass(e.target.value)}/>
        <br/><br/>*/}

            <br />
            <strong>
              <button
                type="submit"
                name="log"
                id="log"
                value="Register"
                style={{
                  background: "#3399FF",
                  border: "none",
                  borderRadius: "2px",
                  height: "42px",
                }}
              >
                {!isLoading ? (
                  <div class="spinner-border spinner-border-sm">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <span>Register</span>
                )}
              </button>
            </strong>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Registrationhotel;
