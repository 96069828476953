import React, { useState } from "react";
import "../css/login.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Adminmail, supportAdminmail } from "../feature/slice";
import CryptoJs from "crypto-js";
import Headeradminhotel from "./headeradminhotel";
import imge from './image/login1.jpg';
import Alert from '@mui/material/Alert';
import Footer from "./Footer";



export let nams;
function Loginshotel({ userAuth }) {
  //console.log(li)
const token=localStorage.getItem("adminToken")
  const [selected, setSelected] = useState("admin");
  var [name, setName] = useState("");
  const [isLoading,setIsLoading] = useState(true)
  var [pass, setPass] = useState("");
  var [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [data, setdata] = useState(4);
  const [close,setClose]=useState(0)
  console.log(data);
  console.log(name);
  console.log(pass);
  var lin;
  const dispatch = useDispatch();
  //const togglePassword = useRef("");
  const password = useRef("");
  const [filteredProjectList, setFilteredProjectList] = useState([]);
  const [selectedProject,setSelectedProject] = useState('')
  const [isBlocked,setIsBlocked] = useState("")
  const [active,setActive] = useState('')
  const [icon,setIcon] = useState("bi bi-eye-slash")
  const [type,setType] = useState("password")
  const [attemptLeft,setAttemptLeft]=useState(4)
  const [height,setHeight]=useState('100vh')
  let [countDown,setCountDown]=useState("")
  let [intervalArray,setIntervalArray]=useState([])
 const intervalFunction=(blockedAt)=>
   {
 const countDownDate =  new Date(parseInt(blockedAt, 10)).getTime()+15 * 60 * 1000;
 let interval1 = setInterval(()=>
   {
     const now = new Date().getTime();
     console.log(now)
     const distance = countDownDate - now;
     const  minutes= Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
     const seconds = Math.floor((distance % (1000 * 60)) / 1000);
     console.log(minutes + "m " + seconds + "s ")
     setCountDown(minutes + "m " + seconds + "s ")
     if (distance< 0) {
       clearInterval(interval1);
       setIntervalArray([])
       setCountDown("")
       setAttemptLeft("")
       setIsBlocked(false)
       setClose(0)
       setActive("Account is Active please login")
       setHeight('120vh')
       }
   },1000);  
   intervalArray.push(interval1)
   setIntervalArray(intervalArray)
 }

  
  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_API_URL}/findprojects`,
  //   {
  //     method: 'GET',
  //     withCredentials: 'true',
  //     headers:
  //     {
  //       'Authorization':`Bearer ${token}`,
  //     },
  //   }
  //   )
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log("data of admins", data[0]);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  // const handleFilter = () => {
  //   const result = projectList.map((el) => {
  //     return el.filter((el) => {
  //       return el.email == name ? temp.push(el.project) : null;
  //     });
  //   });
  //   setFilteredProjectList(temp);
  //   console.log("hello world+++++++++++++++++>",temp[0])
  //   setSelectedProject(temp[0])
  // };
  const handleFilter = async() => {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/findProjectsByAdminId/${name}/${selected}`,
        {
          method: 'GET',
          withCredentials: 'true',
          headers:
          {
            'Authorization':`Bearer ${token}`,
          },
        }
        )
  
        .then((res) => res.json())
        .then((data) => {
          console.log("data of admins", data[0]);
          setFilteredProjectList(data.data)
          if(data.data.length==1){
          setSelectedProject(data.data[0]) 
        }
        else if(data.data.length>1)
          {
            setHeight('120vh')
          }
        return data.data
        })
        .catch((err) => console.log(err));
   return result
  }
  useEffect(()=>{
    localStorage.removeItem("adminToken")
    localStorage.removeItem("supportToken")
    localStorage.removeItem("mail")
    localStorage.removeItem("tokenauth")
    //cookie.remove("tokenauth")

     },[])
  useEffect(() => {
    // Fetch the value of the cookie when the component mounts
    const userCookie = document.cookie.replace(
      /(?:(?:^|.;\s)user\s*=\s*([^;]).$)|^.*$/,
      "$1"
    );
  }, []);

  // const Toggle = () => {
  //   // toggle the type attribute
  //   const type = password.current.type === "password" ? "text" : "password";
  //   password.current.type = type;
  //   // toggle the eye slash icon
  //   if (e === 0) {
  //     s(1);
  //     togglePassword.current.className = "bi-eye";
  //   } else {
  //     s(0);
  //     togglePassword.current.className = "bi bi-eye-slash";
  //   }
  // };

  const handleToggle = () =>{  
    if(icon == "bi bi-eye-slash") {
      setIcon("bi-eye")
      setType("text")
    }else{
      setIcon("bi bi-eye-slash")
      setType("password")
    } 
  }

  useEffect(()=>{
  // const result = handleFilter()
  // setSelectedProject(result)
  },[])

 
  const clicks = async (y) => {
  
    // setIsLoading(false)
    // setIsBlocked("")
    setIsBlocked("")
    setClose(0)
    const result = await handleFilter()
    console.log("here is exact result",result)
    console.log("here is selectedProject *******",selectedProject)
    // if(selectedProject === "" || selectedProject === undefined){
      
    //   setIsLoading(true)
    //   setFlag(true)
    //   setClose(0)
    // }
    // else{
    
    const encryptedPassword = CryptoJs.AES.encrypt(
      pass,
      process.env.REACT_APP_API_KEY
    );
    if (selected === "admin") {
      lin = "admin";
    } else if (selected === "support") {
      lin = "support";
    } else {
      lin = "";
    }
    if (
      (name === "" && pass === "" && lin === "") ||
      (name === "" && pass === "") || name === "" || pass === ""
    ) {
      setMessage("Please Enter Email & Password");
      setClose(1)
      setIsBlocked("")
      setIsLoading(true)
    } 
    else if(filteredProjectList.length>1 && selectedProject===""){
      setMessage("Please Select Project")
      setClose(1) 
    }
    else {
    //  if(!result[0])
    //   { 
        // setMessage("!Incorrect Email Or Password");
        // setHeight('120vh')
        // setClose(1)
        // setIsBlocked("")
        // setIsLoading(true)
       
       
      // }
      // else{
        if(selectedProject == '' || selectedProject == undefined){
          //result.length == 1? console.log("na") :alert("Please Select Project")
        }
      const af = `${process.env.REACT_APP_API_URL}/adminlogin`;
      const response = await fetch(af, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: name,
          pass: encryptedPassword.toString(),
          user: lin,
          project: result.length == 1 ? result[0] : selectedProject
        }),
      });
      if (response.status === 401) {
        const data = await response.json();
        setIsLoading(true)
        setClose(1)
        setAttemptLeft(data.remainingLogAttempt)
        setIsBlocked("")
        setMessage("! Incorrect Email Or Password");
        setHeight('120vh')
      }
      else if(response.status === 404){
        //alert("Invalid Email Id OR Password")
        const data = await response.json();
        setMessage(data.error)
        setClose(1)
        setIsBlocked("")
       
      }
      else if(response.status===400){
        intervalArray.map(e=>clearInterval(e))
        setIntervalArray([])
        const data=await response.json()
        console.log(data)
        setMessage("")
        setIsBlocked(true)
        setClose(0)
        setActive("Account is Blocked for 15 Minutes")
        setHeight('120vh')
        setIsLoading(true)
        intervalFunction(data.timeOut)
      }
   else if (response.status === 200) {
    setIsLoading(true)
        const data = await response.json();
        console.log(af);
        //const result=await fetch(`${process.env.REACT_APP_API_URL}/findbyName/${name}`)
        //const datas=await result.json();
        setdata(data);
        console.log(data);
        //sessionStorage.setItem("mail", name);
        if (selected === "admin") {
          dispatch(Adminmail(name));
          localStorage.setItem("adminToken", data.token);
        } else if(selected==="support"){
          dispatch(supportAdminmail(name));
          localStorage.setItem("supportToken", data.token);
        }
       
        //cookie.set("token",data.token)
        //cookie.set("project", data.project);

        try {
          const token = localStorage.getItem("adminToken") || localStorage.getItem("supportToken")
          //const token=cookie.get("token")
          if (token) {
            // Make an asynchronous request to validate the token

            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/protected-route`,
              {
                method: "GET",
                withCredentials: "true",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (response.status === 200) {
              // Token is valid
              
              console.log("log");
              userAuth(true);
              //localStorage.setItem("tokenauth","true")
              localStorage.setItem("tokenauth", "true");
            } else {
              // Token is not valid
              userAuth(false);
            }
          } else {
            // No token found
            userAuth(false);
          }
          
        } 
        catch (error) {
          // Handle errors, e.g., network issues
          console.error("Token validation failed:", error);
        }
        try {
          if (lin === "admin") {
            navigate("/v1/jim/admin_home");
          } // Omit optional second argument
          else {
            navigate("/v1/jim/support");
          }
        } catch (error) {
          navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
        }
      } else if (response.status === 404) {
        console.log(lin);
        console.log(name);
        console.log(pass);
        setIsLoading(true)
        setClose(1)
        setIsBlocked("")
        setMessage("! Incorrect Email Or Password");
        setHeight('120vh')
      }
    // }
  // }
}
  };

  const handlKeyPress = (event) => {
    if (event.key === "Enter") {
      clicks(event);
    }
  };


  
  console.log("here is selectedProject###", selectedProject);
  // const handleSelectionChange = () =>{
  //   setSelectedProject(e.target.value)
  // }
  return (
    <>
    <div
      className="body"
      style={{
        height: height,
        backgroundImage: `url(${imge})`,
      }}
    >
      <Headeradminhotel disp="none" />
      <h1 style={{ color: "#ffffff", textAlign: "center", fontFamily: "auto" }}>
        Jio Service Support
      </h1>
      <br /> 
      <div className="login" style={{background:"#ffffff63",paddingTop:"30px",paddingBottom:"10px",width:"460px"}}>
      <div className='SignIn-header'>
      <h4 style={{color:"#000080",textAlign:"cemter",fontFamily:"auto"}}>Log In</h4>
    </div>
        <form>
          <div align="center" style={{ display: "flex",marginLeft:"50px"}}>
            <input
             style={{width:"30px"}} type="radio"
              id="Admin"
              checked={selected === "admin"}
              onChange={(e) => {
                setSelected(e.target.value);
              }}
              name="fav_language"
              value="admin"
              onBlur={handleFilter}
            />
              <label for="admin">Admin</label>
             &nbsp; &nbsp;
            <input
              style={{width:"30px"}} type="radio"
              id="Support"
              checked={selected === "support"}
              onChange={(e) => {
                setSelected(e.target.value);
              }}
              name="fav_language"
              value="support"
              onBlur={handleFilter}
            />
              <label for="support">Support</label>
            <br />
          </div>
          <label>
            <b>Email</b>
          </label>
          <input
            type="email"
            name="username"
            id="Uname"
            placeholder="Enter Email"
            onChange={(e) => {
              setName(e.target.value);
            }}
            onBlur={handleFilter}
            value={name}
          />
          <br />
          <br />
          <label>
            <b>Password</b>
          </label>

          <input
            type={type}
            name="password"
            id="Pass"
            onKeyDown={handlKeyPress}
            ref={password}
            placeholder="Enter Password"
            onChange={(e) => setPass(e.target.value)}
            
          />
          <i
            className={icon}
            //ref={togglePassword}
            onClick={handleToggle}
            id="togglePassword"
            style={{ marginLeft: "-30px", cursor: "pointer" }}
          ></i>
          <br />

        
          <div align="left">
            {filteredProjectList.length > 1 ? (
              <>
                <br />
                <label for="type">
                  <b>Select Project</b>
                </label>
                <select name="type" id="type" value={selectedProject} onChange={(e)=>{
                  e.preventDefault()
                  setSelectedProject(e.target.value)
                  }}>
                    <option>Select Project</option>
                  {filteredProjectList.map((el) => (
                    <option key={el} value={el} >
                      {el}
                    </option>
                  ))}
                </select>
              </>
            ) : null}
          </div>
          <br />
        </form>
        <strong>
        <button
          type="submit"
          name="log" 
          id="log"
          value="Log In"
          onClick={clicks}
          style={{background:"#3399FF",border:"none",borderRadius:"2px",height:"44px"}}
        >
          {
            !isLoading ? <div class="spinner-border spinner-border-sm">
            <span class="sr-only">Loading...</span>
            </div> :<span>Log In</span>
          }   
          </button>
        </strong>
        <br />
        {/* <p style={{ color: "red" }}>{message}</p> */}
        {/* { isBlocked ? <span style={{color:"red"}}>{active}</span> : <span style={{color:"green"}}>{active}</span> } */}
        <br />
        {/*<a style={{float: "left"}} href="/registers">Register for new User</a>*/}
        <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
        <a
          className="a"
          style={{justifyContent:'center',color: "white", textAlign:'center' }}
          href="/v1/jim/admin_home/forget"
        >
          Forgot Password ? 
        </a>
        </div>
        {(message=="Please Enter Email & Password" || message==="Please Select Project" ||  message=="Please Select Project" || message== "Invalid Email Id Or Password")?close?<Alert severity="warning" onClose={() => {setClose(0)}}>
        {message}
        </Alert>:""
        :close?<Alert severity="warning" onClose={() => {setClose(0)}}>
        {message}
          <p>{attemptLeft} attempts left</p>
        </Alert>:""}
        {isBlocked===false?<Alert severity="success">
        {/* <AlertTitle>Success</AlertTitle> */}
        {active}
        </Alert>:""}
        {isBlocked===true?<Alert severity="info">
        {/* <AlertTitle>Info</AlertTitle> */}
        Too many wrong attempts, {active}
        <br/>
        {countDown?`TimeLeft: ${countDown}`:""}
        </Alert>:""}
        <br />
        <br />
      </div>
    </div>
    
<Footer margin={"auto"}/>   
</>
  );
}

export default Loginshotel;
