import React, { useRef } from 'react'
import '../css/drop.css';
import { useState,useEffect } from 'react';
import AllUserListhotel from './AllUserListhotel';
import UserListTablehotel from './UserListTablehotel';
import Headeradminhotel from './headeradminhotel';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'
  
function UserListhotel({Clic,showAlert,setAlertProps}) {
  //var [adm,setAdm]=useState(Clic);
  const [pass,setPass]=useState([])
  //let datas = sessionStorage.getItem("mail");
 
 // const project=cookie.get("project")
  const token=localStorage.getItem("adminToken")
  const navigate=useNavigate()

   
  const [project,setProject]=useState("")


  useEffect(() => {
   try{
 const decodedToken = jwtDecode(token);
 setProject(decodedToken.project)
   }
   catch(error)
   {
     navigate("/v1/jim_admin_user_login")
   }
 },[])
    useEffect(() => {

      console.log(token)
     if(localStorage.getItem("tokenauth")==="false")
     //if(cookie.get("tokenauth")==="false")
     {
      window.location.href="/v1/jim_admin_user_login"
     }
    }, [])
    //console.log(nam)
    //sessionStorage.setItem("key", nam);
    //dispatch(userName(nam))
  console.log(Clic)
  var [opti,setOption]=useState('');
  
  var [req,setReqOption]=useState('');
  let [searchFlag,setSearchFlag]=useState(0)
  let [optionFlag,setOptionFlag]=useState(0)


  
   const text=(e)=>
   {
    if(e.target.value==="")
    {
      setSearchFlag(0)
    }
    else
    {
      setSearchFlag(1)
    }
    setSearch(e.target.value)
    fetch(`${process.env.REACT_APP_API_URL}/filterusertype/${project}/name/${e.target.value}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
        console.log(data)
      setPass(data.data)
      if(data.statusCode===400)
        {
          showAlert({
            title:  'Logged Out',
            message: 'You are logged out due to session timeout.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        });        navigate("/v1/jim_admin_user_login")
        }
   })
      .catch(err=>console.log(err))
    setOption("")
    setReqOption("")
    setOptionFlag(0)
    
   }
   console.log(pass)
  
  const request=(f)=>
  {
    setOption("")
    if(optionFlag===0)
    {
      setOptionFlag(1)
    }
    else{
      setOptionFlag(0)
    }
    setReqOption(f)
    console.log(`${process.env.REACT_APP_API_URL}/filterusertype/${project}/${f}`)
    fetch(`${process.env.REACT_APP_API_URL}/filterusertype/${project}/${f}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
        console.log(data)
      setPass(data.data)
  })
      .catch(err=>console.log(err))
  }
 
  var [search,setSearch]=useState('');
  const searchClick=(e)=>
  {
    e.preventDefault()
    if(search==="")
    {
      setSearchFlag(0)
    }
    else
    {
      setSearchFlag(1)
    
    fetch(`${process.env.REACT_APP_API_URL}/filterusertype/${project}/name/${search}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      setPass(data.data))
      .catch(err=>console.log(err))
  
    console.log(search);
      }
  }

  console.log(opti)
  console.log(req)

 
      
      
         
     
const dropdownRef = useRef(null);

useEffect(() => {
  // Add a click event listener to the document to detect clicks outside the dropdown
  function handleClickOutside(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOptionFlag(0);
    }
  }

  // Attach the event listener when the component mounts
  document.addEventListener('click', handleClickOutside);

  // Clean up the event listener when the component unmounts
  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, []);






  return (
    
    <>
    <Headeradminhotel requests={0} backButton={1} raiseTicket={0} registrationRequest={0} userManagement={0} adminRequests={0} requestTrack={0} newAddition={1} showAlert={showAlert} setAlertProps={setAlertProps}/>
      <div className="sc-hORach dWMRNy">
      <div className="sc-eTuwsz iBEElS">
      </div>
      <main className="sc-hXRMBi bJStMD sc-ibxdXY fgfUVu">
      <div className="sc-bwCtUz ijKeUx">
      <section className="sc-bJTOcE bFtVSx">
      <header>
      <span className="sc-rBLzX fayCzS">
      {/*<a className="sc-CtfFt ewysoW sc-Rmtcm DgkTO" href="/servicedesk/customer/portals" target="_self">GL Services Portal Home</a>
      */}</span>
      <div className="sc-TFwJa dUxdnV"><div><h1 className="sc-bHwgHz bGtLRv">{project} members</h1></div>
      </div>
      </header>
      <div className="sc-PLyBE iGtjzS">
      <div>
      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
      <div>
      <div>
      <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
  
      </div>
      </div>
      <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
      <div style={{height: "100%", width: "100%"}}></div></div>
      <div className="styledContentContainer-p0j3f7-0 caSnlw"><div style={{position: "absolute", zIndex: "400", top: "0px", left: "0px",transform: "translate3d(-95px, 40px, 0px)"}}></div>
      </div></div></div></div></div>
      <div className="sc-jqIZGH bokdCL">
      </div>
      <div className="sc-imDfJI jpQXKX" data-test-id="request-list.wrapper">
      <div className="sc-bSbAYC bxpmxB"><div id="request-search-filter">
      <div data-ds--text-field--container="true" className="css-1o07z2d">
      <input aria-label="free text filter" placeholder="Type to search..." data-test-id="request-list.filters.textFilter" data-ds--text-field--input="true" className="css-mfjdp3"  onChange={text} defaultValue={search} style={{marginBottom:"4px"}}/>
      <button type="button" className="css-q68uj"  style={{backgroundColor: "#BDBDBD"}} onClick={searchClick}><span className="css-j8fq0c">
      <span className="css-noix33">
      <span role="img" aria-label="Search my requests" className="css-snhnyn" >
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path d="M14.823 15.883a5.5 5.5 0 111.06-1.06l2.647 2.647c.293.293.53.59 0 1.06-.53.47-.767.293-1.06 0l-2.647-2.647zM11.5 15.5a4 4 0 100-8 4 4 0 000 8z" fill="currentColor">
      </path></svg></span></span></span></button></div></div>
      <div className="sc-bOCYYb gxwVbe"  >
      <div className="sc-iHhHRJ jnXgrr" ref={dropdownRef} onClick={()=>request("")}>
      <button className="css-370xbg" data-testid="paginatedStatusFilter" type="button" tabIndex="0">
      <span className="css-178ag6o">
      <div className="sc-kqlzXE iitdmc">
      <div className="sc-cFlXAS hPkIUt"><strong>Role:</strong>
      <div className="sc-hcnlBt iHCcjr">{req.charAt(0).toUpperCase() + req.slice(1)}</div></div></div> 
      <div className="sc-OxbzP kJxPgr">
      <span aria-hidden="true" className="css-snhnyn" >
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z" fill="currentColor" fill-rule="evenodd">
      </path></svg></span></div></span></button></div>
      {optionFlag===1?
      <div className="scroll-menu" style={{width: "139px",marginTop:"inherit"}} >
        <div className="menu-item" value="Support" onChange={(e)=>setReqOption(e.target.value)} onClick={()=>request("support")}>
        <span className="css-qezc8k" style={{maxWidth: "100%"}}>
          <span className="css-18kwa17"   style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}} /*onChange={(e)=>setReqOption(e.target.value)} onClick={()=>request("opened")}*/>
          Support</span>
      </span>
          </div>
        <div className="menu-item" value="User" onChange={(e)=>setReqOption(e.target.value)} onClick={()=>request("user")}>
        <span className="css-qezc8k" style={{maxWidth: "100%"}}>
          <span className="css-18kwa17" style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}} >
           User</span>
          </span></div>
          <div className="menu-item" value="Admin" onChange={(e)=>setReqOption(e.target.value)} onClick={()=>request("admin")}>
        <span className="css-qezc8k" style={{maxWidth: "100%"}}>
          <span className="css-18kwa17"   style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}} /*onChange={(e)=>setReqOption(e.target.value)} onClick={()=>request("opened")}*/>
          Admin</span>
      </span>
          </div>
        </div>:""}
      </div>
      
      
  
  </div>
  </div>
   

    {(() => {
     
       if(req!=='') 
       {
        /*return (<Table   Option={opti} Tab={Clic}/>)*/
        return (<AllUserListhotel showAlert={showAlert} setAlertProps={setAlertProps} addr={pass}/>)
       }
       else if(searchFlag===1)
       {
        console.log("lo")
         /*return (<Search  Searched={search}/>*/
         //f=`http://localhost:5050/search/${search}`
         return (<AllUserListhotel showAlert={showAlert} setAlertProps={setAlertProps} addr={pass}/>
         )
       }

       else{
        return (<UserListTablehotel showAlert={showAlert} setAlertProps={setAlertProps} addr={`${process.env.REACT_APP_API_URL}/users/${project}`}/>)
       }
      
      })()}
    
    <div className="sc-jzgbtB gUQSWG">
    {/* <div className="col-xl-2">
    <div class="input-group">
    <div class="input-group-prepend">
    <button class="btn btn-outline-primary" type="button" onClick={decNum}>-</button>
    </div>
    <input type="text" class="form-control" value={num} onChange={handleChange}/>
    <div class="input-group-prepend">
    <button class="btn btn-outline-primary" type="button" onClick={incNum}>+</button>
    </div>
    </div>
    </div> */}
    {/* <div class="container justify-content-between" style={{display:"flex"}}>
    <div className=''style={{marginTop:"5px"}}>
	<button 
  data-decrease 
  className ="quantity-left-minus btn btn-number" 
  style={{
  height:"36px",
  backgroundColor:"#007bff", 
  borderRadius:"21px"
  }}
  onClick={decNum}
  >-</button>
	<input data-value
   type="text"
    //value="1"
    value={num} 
    onChange={handleChange}
    disabled
    style={{    
    textAlign: "center",
    margin: "10px",
    width: "59px",
    borderRadius: "63px",
    height: "45px"
    }}

     />
	<button
   className="quantity-right-plus btn  btn-number"
    data-increase
    style={{height:"36px",backgroundColor:"#007bff",
    borderRadius:"21px"}}
    onClick={incNum}
    >+</button>
    </div>
  <div style={{marginTop:"22px"}}>
    <nav aria-label="Page navigation example">
  <ul class="pagination">
    <li class="page-item">
      <a class="page-link" href="#" aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link" href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li>
    <li class="page-item"><a class="page-link" href="#">4</a></li>
    <li class="page-item"><a class="page-link" href="#">5</a></li>
    <li class="page-item"><a class="page-link" href="#">6</a></li>
    <li class="page-item"><a class="page-link" href="#">...</a></li>
    <li class="page-item">
      <a class="page-link" href="#" aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
</nav>
</div>
  </div>*/}

      </div> 
    <div className="sc-jqIZGH bokdCL"></div>
    
    </section></div>
   </main>
   </div>
    </>
   
  )
}

export default UserListhotel
