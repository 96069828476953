import React from 'react'
import '../css/drop.css';
import { useState,useEffect } from 'react';
import Alladmin from './Alladmin';
import AdminList from './AdminList';
import HeaderAdmin from './headersuperadmin';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';



  
function SuperAdmin({Clic,showAlert,setAlertProps}) {
  //var [adm,setAdm]=useState(Clic);
 
  let datas = sessionStorage.getItem("mail");
  const token=localStorage.getItem("superToken")
  const navigate=useNavigate()
  console.log(datas)

    console.log(datas)
    const [projects,setProjects]=useState([])
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/projects`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })
      .then(res=>res.json())
      .then(data=>
        {
        setProjects(data.data)
        if(data.statusCode===400)
          {
            showAlert({
              title:  'Logged Out',
              message: 'You are logged out due to session timeout.',
              okText: 'OK',
              callback: () => {
                  console.log('Alert closed');
                  setAlertProps(null)
              }
          });  
            navigate('/v1/jim_super_login')
          } 
    })
        .catch(err=>console.log(err))
    }, [])
 
    //console.log(nam)
    useEffect(() => {

      console.log(token)
     if(localStorage.getItem("tokenauth")==="false")
     //if(cookie.get("tokenauth")==="false")
     {
      window.location.href="/v1/jim_super_login"
     }
    }, [])
  console.log(Clic)
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Add a click event listener to the document to detect clicks outside the dropdown
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        t(0);
      }
    }

    // Attach the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  
  var [opti,setOption]=useState('');
  
  var [req,setReqOption]=useState('');
  let [s,k]=useState(0)
  let [r,t]=useState(0)
  
   const text=()=>
   {
    k(2)
    setOption("")
    setReqOption("")
    t(0)
    
   }

  
  const request=(f)=>
  {
    setOption("")
    if(r===0)
    {
    t(1)
    }
    else{
      t(0)
    }
    setReqOption(f)
  }
 
  var [search,setSearch]=useState('');
  const searchClick=(e)=>
  {
    e.preventDefault()
   if(search==="")
   {
    k(0)
   }
   else
   {
    k(1)
   }
  
    console.log(search);
      }
  console.log(s);

  console.log(opti)
  console.log(req)

           
  return (
    
    <>
    <HeaderAdmin l={1} k={1} h={1} j={1} f={1} i={1} t={0} adminmanage={1} admin={0} form={0} showAlert={showAlert} setAlertProps={setAlertProps}/>
      <div className="sc-hORach dWMRNy">
      <div className="sc-eTuwsz iBEElS">
      </div>
      <main className="sc-hXRMBi bJStMD sc-ibxdXY fgfUVu">
      <div className="sc-bwCtUz ijKeUx">
      <section className="sc-bJTOcE bFtVSx">
      <header>
      <span className="sc-rBLzX fayCzS">
     </span>
      <div className="sc-TFwJa dUxdnV"><div><h1 className="sc-bHwgHz bGtLRv">Project Details</h1></div>
      </div>
      </header>
      <div className="sc-PLyBE iGtjzS">
      <div>
      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
      <div>
      <div>
      <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
  
      </div>
      </div>
      <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
      <div style={{height: "100%", width: "100%"}}></div></div>
      <div className="styledContentContainer-p0j3f7-0 caSnlw"><div style={{position: "absolute", zIndex: "400", top: "0px", left: "0px",transform: "translate3d(-95px, 40px, 0px)"}}></div>
      </div></div></div></div></div>
      <div className="sc-jqIZGH bokdCL">
      </div>
      <div className="sc-imDfJI jpQXKX" data-test-id="request-list.wrapper">
      <div className="sc-bSbAYC bxpmxB"><div id="request-search-filter">
      <div data-ds--text-field--container="true" className="css-1o07z2d">
      <input aria-label="free text filter" onClick={text} placeholder="Type to search..." data-test-id="request-list.filters.textFilter" data-ds--text-field--input="true" className="css-mfjdp3"  onChange={(e)=>setSearch(e.target.value)} defaultValue={search} style={{marginBottom:"4px"}}/>
      <button type="button" className="css-q68uj"  style={{backgroundColor: "#BDBDBD"}} onClick={searchClick}><span className="css-j8fq0c">
      <span className="css-noix33">
      <span role="img" aria-label="Search my requests" className="css-snhnyn" >
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path d="M14.823 15.883a5.5 5.5 0 111.06-1.06l2.647 2.647c.293.293.53.59 0 1.06-.53.47-.767.293-1.06 0l-2.647-2.647zM11.5 15.5a4 4 0 100-8 4 4 0 000 8z" fill="currentColor">
      </path></svg></span></span></span></button></div></div>
      <div className="sc-bOCYYb gxwVbe"  >
      <div className="sc-iHhHRJ jnXgrr" ref={dropdownRef} onClick={()=>request("")}>
      <button className="css-370xbg" data-testid="paginatedStatusFilter" type="button" tabindex="0">
      <span className="css-178ag6o">
      <div className="sc-kqlzXE iitdmc">
      <div className="sc-cFlXAS hPkIUt"><strong>Project:</strong>
      <div className="sc-hcnlBt iHCcjr">{req ? req : "Select"}</div></div></div>
      <div className="sc-OxbzP kJxPgr">
      <span aria-hidden="true" className="css-snhnyn" >
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z" fill="currentColor" fill-rule="evenodd">
      </path></svg></span></div></span></button></div>
      {r===1?
      <div className="scroll-menu" style={{width: "200px"}} >
       {projects.length===0?"":projects.map((element)=>
        <div className="menu-item"  style={{borderBottom:"solid 1px lightgrey"}} value={element.ProjectName} onChange={(e)=>setReqOption(e.target.value)} onClick={()=>request(element.ProjectName)}>
        <span className="css-qezc8k" style={{maxWidth: "100%"}}>
          <span className="css-18kwa17"   style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}} /*onChange={(e)=>setReqOption(e.target.value)} onClick={()=>request("opened")}*/>
          {element.ProjectName}</span>
      </span>
          </div>)}
        
        </div>:""}
      </div>
      
      
  
  </div>
  </div>
   

    {(() => {
     
       if(req!=='') 
       {
        /*return (<Table   Option={opti} Tab={Clic}/>)*/
        return (<Alladmin showAlert={showAlert} setAlertProps={setAlertProps} addr={`${process.env.REACT_APP_API_URL}/filteradmins/${req}`}/>)
       }
       else if(s===1)
       {
        console.log("lo")
         /*return (<Search  Searched={search}/>*/
         //f=`http://localhost:5050/search/${search}`
         return (<Alladmin showAlert={showAlert} setAlertProps={setAlertProps} addr={`${process.env.REACT_APP_API_URL}/searched/admin/${search}`}/>
         )
       }

       else{
        return (<AdminList showAlert={showAlert} setAlertProps={setAlertProps}  addr={`${process.env.REACT_APP_API_URL}/superadmin`}/>)
       }
      
      })()}
    
    <div className="sc-jzgbtB gUQSWG">
    {/* <div class="spinner-border" style={{width: "3rem", height: "3rem", role:"status"}}>
  <span class="sr-only">Loading...</span>
</div> */}
    </div>
    <div className="sc-jqIZGH bokdCL"></div>
    
    </section></div>
   </main>
   </div>
   <Footer/>
    </>
   
  )
}

export default SuperAdmin

