import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "../css/table.css";
import "../css/admin.css";
import img from "./image/filenot.jpg";
import {jwtDecode} from 'jwt-decode'

import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux"; 
import { updateAdminData, user } from "../feature/slice";

export let d;
function Tablehotel({ addr,limit,showAlert,setAlertProps }) {
  //<Details d={deta}/> 
  //console.log(deta)
  const token=localStorage.getItem("adminToken")
  const [data,setdata]=useState([])
  const [datas,setdatas]=useState([])
  const [isLoading, setLoading] = useState(true);
  //const project=cookie.get("project")
  const [pageNumber,setPageNumber] = useState(1)
  const [initialCount,setInitialCount] = useState(0)
  const [lastCount,setLastCount] = useState(0)
  let [num, setNum]= useState(10);
  const [numOfTickets,setNumOfTickets] = useState()
  const [lastNumber,setLastNumber]=useState(0)
  const navigate=useNavigate()
  const [project,setProject]=useState("")
  


  const numb=JSON.parse(localStorage.getItem("admin")).number
  const pageNumbers=JSON.parse(localStorage.getItem("admin")).pagenumber
  console.log(numb)
  console.log(pageNumbers)

 useEffect(() => {
  try{
const decodedToken = jwtDecode(token);
setProject(decodedToken.project)
  }
  catch(error)
  {
    navigate("/v1/jim_admin_user_login")
  }
},[])
  useEffect(() => {
    fetch(addr+`/${numb}/${pageNumbers}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setdata(data.data);
        setdatas(data.data);
        setLoading(false)
        //initial(data.data[0])
        //last(data.data[data.data.length-1])
        console.log(data+"shwetadata")
        if (data.statusCode === 400) {
          showAlert({
            title:  'Logged Out',
            message: 'You are logged out due to session timeout.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        }); 
          navigate("/v1/jim_admin_user_login");
        }
      })
      .catch((err) => console.log(err));
  }, [numb,pageNumbers,project]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/ticketCount/${project}/${numb}/${pageNumbers}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
      setInitialCount(data.data.minimumCount)
      setLastCount(data.data.maximumCount)
      setNumOfTickets(data.data.totalCount)
      setPageNumber(pageNumbers)
      setNum(numb)
      if(data.data.totalCount<10)
      {
        console.log("losufar")
        setLastNumber(data.data.totalCount)
      }})
  
      .catch(err=>console.log(err))
  }, [numb,pageNumbers,project])



let incNum =()=>{
  if(num<numOfTickets)
  {
    if(Math.ceil(numOfTickets/(num+10))>=pageNumber)
    {
  setNum(Number(num)+10);
  localStorage.setItem("admin",JSON.stringify({"number":Number(num)+10,"pagenumber":JSON.parse(localStorage.getItem("admin")).pagenumber}))
  if(pageNumber*(num+10)>numOfTickets)
  {
    setLastNumber(numOfTickets)
  }
  } 
}
};




console.log("*************here is pageCountData",numOfTickets)

let decNum = () => {
  if(num<=10)
  {
   setNum(10);
   localStorage.setItem("admin",JSON.stringify({"number":10,"pagenumber":JSON.parse(localStorage.getItem("admin")).pagenumber}))
  }
  else{
    if(lastNumber!==0)
      {
        setLastNumber(0)
      }
   setNum(num-10)
   localStorage.setItem("admin",JSON.stringify({"number":num-10,"pagenumber":JSON.parse(localStorage.getItem("admin")).pagenumber}))
   setLastCount(num-10)
   if(pageNumber*(num-10)>numOfTickets)
   {
     setLastNumber(numOfTickets)
   }
  }
}
let handleChange = (e)=>{
 setNum(e.target.value);
 localStorage.setItem("admin",JSON.stringify({"number":e.target.value,"pagenumber":JSON.parse(localStorage.getItem("admin")).pagenumber}))
}
  /*const click=(e)=>
  {
    console.log(e);
  {<Detail deta={e}/>}
  }*/
  const dispatch = useDispatch();
  const clicks = (e) => {
    const parsedData = e.id;
    dispatch(updateAdminData(parsedData));
    //sessionStorage.setItem("id", e.id);
    //sessionStorage.setItem("user", e.user);
    dispatch(user(e.user));
  };
  var l = [];
  console.log(data);
  const [assigneeFlag, setAssigneeFlag] = useState(0);
  const sort = () => {
    if (assigneeFlag=== 0) {
      for (var i in data) {
        if (data[i].admin !== null) {
          console.log(i);
          l.push(data[i]);
        }
      }
      for (var j in data) {
        if (data[j].admin === null) {
          l.push(data[j]);
        }
      }
      setAssigneeFlag(1);
      setdata(l);
    } else if (assigneeFlag === 1) { 
      setAssigneeFlag(0);
      setdata(datas);
    }
  };
  const [sortFlag, setSortFlag] = useState(0);
  const descending = () => {
    if (sortFlag === 0) {
     data.reverse()
      setSortFlag(1);
      setdata(data);
      setdatas(data)
    } else if (sortFlag === 1) {
      data.reverse()
      setdata(data);
      setdatas(data)
      setSortFlag(0);
    }
  };
  console.log(l);

  return (
    <>
   {
    isLoading ? <div style={{display:"flex",height:"458px",width:"100%",justifyContent:"center",alignItems:"center"}}>  <div class="spinner-border" style={{width: "3rem", height: "3rem", role:"status"}}>
    <span class="sr-only">Loading...</span>
  </div> </div> :  <div>
      {data.length !== 0 ? (
        <><div
          className="sc-ghsgMZ gDXBCU"
          //style={{overflow:"hidden"}} 
          data-test-id="request-list.request-list-table-v2"
        >
          <div className="sc-dznXNo zdDVp">
            <div
              data-testid="request-list.request-list-table-v2--loading--container--advanced"
              className="css-h8zv0n"
            >
              <table
                data-testid="request-list.request-list-table-v2--table"
                className="css-fkqt3q"
                style={{
                  height: "150px",
                  LocalDynamicTableHoverBg:
                    "var(--ds-background-neutral-subtle-hovered, #FAFBFC)",
                  LocalDynamicTableHighlightedBg:
                    "var(--ds-background-selected, #DEEBFF)",
                  LocalDynamicTableHoverHighlightedBg:
                    "var(--ds-background-selected-hovered, #B3D4FF)",
                  LocalDynamicTableRowFocusOutline:
                    "var(--ds-border-focused, #4C9AFF)",
                }}
              >
                <thead
                  data-testid="request-list.request-list-table-v2--head"
                  className="css-1g54gxt"
                >
                  <tr>
                    <th
                      data-testid="request-list.request-list-table-v2--head--cell"
                      className="css-100m01t"
                      aria-sort="descending"
                      style={
                        {fontSize: "14.5px"
                          /*"--local-dynamic-table-width: 0.9%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/
                        }
                      }
                    >
                       <span>
                        Reference Id
                        <button
                          style={{
                            border: "aliceblue",
                            backgroundColor: "white",
                          }}
                          onClick={descending}
                        >
                          {sortFlag===0?<svg height="20" viewBox="0 0 48 48" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z"/><path d="M0-.75h48v48h-48z" fill="none"/></svg>
                          :<svg height="20" viewBox="0 0 48 48" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M14.83 30.83l9.17-9.17 9.17 9.17 2.83-2.83-12-12-12 12z"/><path d="M0 0h48v48h-48z" fill="none"/></svg>}
                        </button>
                      </span>
                    </th>

                    <th
                      data-testid="request-list.request-list-table-v2--head--cell"
                      className="css-1xabuij"
                      style={
                        {fontSize: "14.5px"
                          /*"--local-dynamic-table-width: 0.5%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/
                        }
                      }
                    >
                      <span>Date</span>
                    </th>
                    <th
                      data-testid="request-list.request-list-table-v2--head--cell"
                      className="css-1xabuij"
                      style={
                        {fontSize: "14.5px"
                          /*"--local-dynamic-table-width: 0.5%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/
                        }
                      }
                    >
                      <span>Type</span>
                    </th>

                    <th
                      data-testid="request-list.request-list-table-v2--head--cell"
                      className="css-1xabuij"
                      style={
                        {fontSize: "14.5px"
                          /*"--local-dynamic-table-width: 1.5%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/
                        }
                      }
                    >
                      <span>Summary</span>
                    </th>
                    <th
                      data-testid="request-list.request-list-table-v2--head--cell"
                      className="css-1xabuij"
                      style={
                        {fontSize: "14.5px"
                          /*"--local-dynamic-table-width: 1%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/
                        }
                      }
                    >
                      <span>Status</span>
                    </th>
                    <th
                      data-testid="request-list.request-list-table-v2--head--cell"
                      className="css-1xabuij"
                      style={
                        {fontSize: "14.5px"
                          /*"--local-dynamic-table-width: 1%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/
                        }
                      }
                    >
                      <span>Severity</span>
                    </th>
                    <th
                      data-testid="request-list.request-list-table-v2--head--cell"
                      className="css-11phftz"
                      style={
                        {fontSize: "14.5px"
                          /*"--local-dynamic-table-width: 0.9%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/
                        }
                      }
                    >
                      <button type="button" aria-roledescription="Sort button">
                        Requester
                      </button>
                    </th>
                    <th
                      data-testid="request-list.request-list-table-v2--head--cell"
                      className="css-1xabuij"
                      style={
                        {fontSize: "14.5px"
                          /*"--local-dynamic-table-width: 1%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/
                        }
                      }
                    >
                      <span>
                        Assignee
                        <button
                          style={{
                            border: "aliceblue",
                            backgroundColor: "white",
                          }}
                          onClick={sort}
                        >
                       {assigneeFlag===0?<svg height="20" viewBox="0 0 48 48" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z"/><path d="M0-.75h48v48h-48z" fill="none"/></svg>
                          :<svg height="20" viewBox="0 0 48 48" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M14.83 30.83l9.17-9.17 9.17 9.17 2.83-2.83-12-12-12 12z"/><path d="M0 0h48v48h-48z" fill="none"/></svg>}
                        </button>
                      </span>
                    </th>
                  </tr>
                </thead>
                {data.map((e) => {
                   let date = new Date(e.date).toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit'});
                   let parts = date.split('/');
                   let formattedDate = `${parts[0]}-${parts[1]}-${parts[2]}`;
                   let formattedSeverity = (e.severity).slice(-2);
                  
                  return (
                    <tbody
                      data-testid="request-list.request-list-table-v2--body"
                      style={{}}
                    >
                      <tr
                        data-testid="request-list.request-list-table-v2--row-row-HRIN-24438"
                        className="css-unmje5"
                      >
                        
                        <td
                          data-testid="request-list.request-list-table-v2--cell-1"
                          className="css-zu8jlf"
                          style={
                            {
                              /*"--local-dynamic-table-width: 0.9%;"*/
                            }
                          }
                        >
                          <Link
                            className="cv-exclude-from-customisation sc-ekulBa AlQuL"
                            onClick={() => clicks(e)}
                            to="/v1/jim/detail"
                            target="_self"
                            style={{color:"blue",textDecoration:"underline"}}
                            // onMouseOver={(e) => {
                            //   e.currentTarget.style.color = 'blue';
                            //   e.currentTarget.style.textDecoration = 'underline';
                            //     }}
                            // onMouseOut={(e) => {
                            //    e.currentTarget.style.color = 'grey';
                            //    e.currentTarget.style.textDecoration = 'none';
                            //   }}
                          >
                            {e.id}
                          </Link>
                        </td>
                        <td
                          data-testid="request-list.request-list-table-v2--cell-1"
                          className="css-zu8jlf"
                          style={
                            {
                              /*"--local-dynamic-table-width: 0.9%;"*/
                            }
                          }
                        >
                          <div role="presentation">
                            <p
                              className="cv-exclude-from-customisation sc-ekulBa AlQuL"
                              href="/servicedesk/customer/portal/10/HRIN-24438"
                              target="_self"
                            >
                             {formattedDate}
                            </p>
                          </div>
                        </td>
                        <td
                          data-testid="request-list.request-list-table-v2--cell-0"
                          className="css-zu8jlf"
                          style={
                            {
                              /*"--local-dynamic-table-width: 0.5%;"*/
                            }
                          }
                        >
                          <div role="presentation">
                            <p
                              className="cv-exclude-from-customisation sc-ekulBa AlQuL"
                              href="/servicedesk/customer/portal/10/HRIN-24438"
                              target="_self"
                            >
                              {e.opt}
                            </p>
                          </div>
                        </td>
                      
                        
                         <td
                          data-testid="request-list.request-list-table-v2--cell-2"
                          className="css-zu8jlf"
                          style={
                            {
                              /*"--local-dynamic-table-width: 1.5%;"*/
                            }
                          }
                        >
                          <div className="sc-jkCMRl gjGtwC">
                            <Link
                              className="cv-exclude-from-customisation sc-ekulBa AlQuL"
                              onClick={() => clicks(e)}
                              to="/v1/jim/detail"
                              target="_self"
                              onMouseOver={(e) => {
                                e.currentTarget.style.color = 'blue';
                                e.currentTarget.style.textDecoration = 'underline';
                                  }}
                              onMouseOut={(e) => {
                                 e.currentTarget.style.color = 'grey';
                                 e.currentTarget.style.textDecoration = 'none';
                                }}
                            >
                              {e.summary}
                            </Link>
                          </div>
                        </td>
                        
                        <td
                          data-testid="request-list.request-list-table-v2--cell-3"
                          className="css-zu8jlf"
                          style={
                            {
                              /*"--local-dynamic-table-width: 1%;"*/
                            }
                          }
                        >
                          <div role="presentation">
                            <div data-test-id="request-details.status-lozenge">
                            {e.status==="In Progress"?<span className={`css-cztx6i-InProgress`} style={{maxWidth: "100%"}}>
      <span className={`css-1et8jlg-InProgress`} style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}}>{e.status}</span></span>:
      <span className={`css-cztx6i-${e.status}`} style={{maxWidth: "100%"}}>
      <span className={`css-1et8jlg-${(e.status)}`} style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}}>{e.status}</span></span>}
                            </div>
                          </div>
                        </td>
                        <td
                          data-testid="request-list.request-list-table-v2--cell-4"
                          className="css-zu8jlf"
                          style={
                            {
                              /*"--local-dynamic-table-width: 0.9%;"*/
                            }
                          }
                        >
                          <div className="sc-jkCMRl gjGtwC" style={{paddingLeft: "20px"}}>
                            <div className={`label ${formattedSeverity}`}>
                              {formattedSeverity}
                            </div>
                          </div>
                        </td>
                        <td
                          data-testid="request-list.request-list-table-v2--cell-4"
                          className="css-zu8jlf"
                          style={
                            {
                              /*"--local-dynamic-table-width: 0.9%;"*/
                            }
                          }
                        >
                          <div className="sc-jkCMRl gjGtwC">{e.user}</div>
                        </td>
                        <td
                          data-testid="request-list.request-list-table-v2--cell-1"
                          className="css-zu8jlf"
                          style={
                            {
                              /*"--local-dynamic-table-width: 0.9%;"*/
                            }
                          }
                        >
                          {e.admin === null
                            ? "Not Assigned"
                            : `${e.admin}`}
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
               <div class="container justify-content-between" style={{display:"flex"}}>
               <div className=''style={{marginTop:"5px"}}>
             <button 
             data-decrease 
             className ="quantity-left-minus btn btn-number" 
             style={{
             height:"36px",
             backgroundColor:"#007bff", 
             borderRadius:"21px"
             }}
             onClick={decNum}
             >-</button>
             <input data-value
              type="text"
               //value="1"
               value={numb} 
               onChange={handleChange}
               disabled
               style={{    
               textAlign: "center",
               margin: "10px",
               width: "59px",
               borderRadius: "63px",
               height: "45px"
               }}
           
                />
             <button
              className="quantity-right-plus btn  btn-number"
               data-increase
               style={{height:"36px",backgroundColor:"#007bff",
               borderRadius:"21px"}}
               onClick={incNum}
               >+</button>
              <span style ={{marginLeft:"40px"}}> Showing {initialCount} to {lastNumber===0?lastCount:lastNumber} of {numOfTickets} Tickets.</span>
               </div>
             <div style={{marginTop:"22px"}}>
               <nav aria-label="Page navigation example">
             <ul class="pagination">
               <li class="page-item">
                 <a class="page-link" onClick={() => {
               //     if(pageNumber<=1){
               //       setPageNumber(1)
               //     }else{
               //       if(lastNumber!==0)
               //       {
               //         setLastNumber(0)
               //       }
                     
               //       setInitialCount(((pageNumber-1)*num)-num+1);
               // console.log(lastCount+1);  setLastCount((pageNumber-1)*num)
               //     setPageNumber(pageNumber-1)
               //     }
               if(pageNumber<=1){
                 setPageNumber(1)
                 localStorage.setItem("admin",JSON.stringify({"pagenumber":1,"number":JSON.parse(localStorage.getItem("admin")).number}))
               }else{
                 if(lastNumber!==0)
                     {
                       setLastNumber(0)
                     }
               setPageNumber(pageNumber-1)
               localStorage.setItem("admin",JSON.stringify({"pagenumber":pageNumber-1,"number":JSON.parse(localStorage.getItem("admin")).number}))
               }
                   }} aria-label="Previous">
                   <span aria-hidden="true">&laquo;</span>
                   <span class="sr-only">Previous</span>
                 </a> 
               </li>
               <li class="page-item">
                 <p class="page-link"  aria-label="Next">
                  {pageNumbers}
                 </p>
               </li>    <li class="page-item">
                 <a class="page-link"  aria-label="Next" onClick={() =>{
           //        if(pageNumber>=(numOfTickets/num)){
           //         setPageNumber(pageNumber)
           //       }
           //       else{ setInitialCount(lastCount+1);
                  
           //   console.log(lastCount+1); setLastCount((pageNumber+1)*num);
           //  setPageNumber(pageNumber+1)
           //  if(((pageNumber+1)*num)-(numOfTickets)>0)
           //  {
           //     setLastNumber(numOfTickets)
           //  }}
           setPageNumber(pageNumber+1)
           localStorage.setItem("admin",JSON.stringify({"pagenumber":pageNumber+1,"number":JSON.parse(localStorage.getItem("admin")).number}))
           if(pageNumber>=(numOfTickets/num)){
                     setPageNumber(pageNumber)
                     localStorage.setItem("admin",JSON.stringify({"pagenumber":pageNumber,"number":JSON.parse(localStorage.getItem("admin")).number}))
                   }
                   else{
           if(((pageNumber+1)*num)-(numOfTickets)>0)
            {
               setLastNumber(numOfTickets)
           }
                   }
                 }}>
                   <span aria-hidden="true">&raquo;</span>
                   <span class="sr-only"   >Next</span>
                 </a>
               </li>
             </ul>
           </nav>
           </div>
             </div>
           </>
      ) : (
        <div className="sc-imDfJI jpQXKX">
          <div className="sc-hAnkBK bETBem">
            <img src={img} alt="No requests were found." />
            <div className="sc-DNdyV RIerR">
              <h3>We couldn’t find any requests </h3>
            
            </div>
          </div>
        </div>
        
      )}
  

    </div>
   }
   </>
  );
}

export default Tablehotel;
