import React, { useState } from "react";
import "../css/cmd.css";
import img from "./image/jio.jpg";
import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import uploadImage from './image/upload.png'
import 'react-quill/dist/quill.snow.css';



import ReactQuill from "react-quill";
import Headerhotel from "./headerhotel";
import { useSelector } from "react-redux";
import {jwtDecode} from 'jwt-decode'
import CryptoJS from 'crypto-js';
import {fileTypeFromBuffer} from 'file-type'

function Edithotel({showAlert,setAlertProps}) {
  //const storedData = JSON.parse(localStorage.getItem('mydata'));
  //const storedDatas = JSON.parse(localStorage.getItem(storedData));
  //let d= sessionStorage.getItem("id");
  const token = localStorage.getItem("token");
  const d = useSelector((state) => state.yourData);

  //let nam= sessionStorage.getItem("key");
  const nam = useSelector((state) => state.userData);
  const [showInput,setShowInput] = useState(false)
  const [city,setCity] = useState("")
  var [opt, setOption] = useState("");
  var [descript, setDescript] = useState("");
  var [attach, setAttach] = useState("");
  var [mail, setMail] = useState("");
  const [files,setFile]=useState([])
  const [filename,setFilename]=useState('')

  const [options,setOptions]=useState([])
  const [calender,setCalender]=useState([])
  const [number,setNumber]=useState([])
  const [numberValue,setNumberValue]=useState([])
  const [radio,setradio]=useState([])
  const [dropdownFields,setDropdownFields]=useState([])
  const [radiooptions,setRadioOptions]=useState([])
  const maxDigits=32;
  const [isDisabled, setIsDisabled] = useState(false);
  const [count1,setCount1] = useState()


  var [mobile, setMobile] = useState("");
  var [location, setLocation] = useState("");
  const [fieldvalues, setFieldvalues] = useState([]);
  const [allFieldsData,setAllFieldsData]=useState([])
  const [textarea, setTextarea] = useState([]);
  const [value, setValue] = useState([]);
  const [uploadErr,setUploadErr] = useState(false)
  const [fileUploadErrMessage,setFileUploadErrMessage]=useState("")

 

  //const project = cookie.get("project");

  
  const [project,setProject]=useState("")


 useEffect(() => {
  try{
const decodedToken = jwtDecode(token);
setProject(decodedToken.project)
  }
  catch(error)
  {
    navigate("/v1/jim_user_login")
  }
},[])
function decryptMessage(encryptedHexWithKey) {
  console.log(encryptedHexWithKey)
  // Extract the key from the encrypted string
  const key = encryptedHexWithKey.substring(0,8);
  // Remove the key from the encrypted string
  const encryptedHex = encryptedHexWithKey.substring(8);

  // Convert the key to a WordArray
  const keyWordArray = CryptoJS.enc.Hex.parse(key);

  // Decrypt the message
  const decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Hex.parse(encryptedHex),
    },
    keyWordArray,
    {
      iv: CryptoJS.enc.Hex.parse('e5f6a7b8'), // Use the same IV for decryption
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
  return decryptedString;
}
useEffect(() => {
  fetch(`${process.env.REACT_APP_API_URL}/getAllFields/${project}`,
  {
    method: 'GET',
    withCredentials: 'true',
    headers:
    {
      'Authorization':`Bearer ${token}`,
    },
  })
  .then(res=>res.json())
  .then(data=>
    { console.log(data.data)
      setAllFieldsData(data.data)
})
},[project])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/textareas/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTextarea(data.data);
        if (data.statusCode === 400) {
          showAlert({
            title:  'Logged Out',
            message: 'You are logged out due to session timeout.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        });
          navigate("/v1/jim_user_login");
        }
      })
      .catch((err) => console.log(err));
  }, [project]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/calender/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    }
)
    .then(res=>res.json())
    .then(data=>
      {
      setCalender(data.data)
      if(data.statusCode===400)
      {
       
        showAlert({
          title:  'Logged Out',
          message: 'You are logged out due to session timeout.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });
        navigate('/v1/jim_user_login')
      }
  })
      .catch(err=>console.log(err))
  }, [project])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/number/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
        if(data.statusCode===400)
      {
       
      //alert("User Logged Out")
        navigate('/v1/jim_user_login')
      }
      setNumber(data.data)
    }
      )
      .catch(err=>console.log(err))
  }, [project])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/radio/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
      setradio(data.data)
      if(data.statusCode===400)
      {
       
      //alert("User Logged Out")
        navigate('/v1/jim_user_login')
      }
    })
      .catch(err=>console.log(err))
  }, [project])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/dropdown/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
      setDropdownFields(data.data)
      if(data.statusCode===400)
      {
       
      //alert("User Logged Out")
        navigate('/v1/jim_user_login')
      }
    })
      .catch(err=>console.log(err))
  }, [project])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/options/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
      setRadioOptions(data.data)
      if(data.statusCode===400)
      {
       
      //alert("User Logged Out")
        navigate('/v1/jim_user_login')
      }
  })
      .catch(err=>console.log(err))
  }, [project])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/getOptions/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
        console.log(data.data)
        setOptions(data.data)
      })
      .catch(err=>console.log(err))
  }, [project])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/getvalue/${project}/${d}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setFieldvalues(data.data);
        data.data.forEach((value) => {
          console.log(value);
          setValue(value);
        });
        if (data.statusCode === 400) {
          //alert("User Logged Out")
          navigate("/v1/jim_user_login");
        }
      })
      .catch((err) => console.log(err));
  }, [project]);
  useEffect(() => {
    console.log(token);
    if(localStorage.getItem("tokenauth")==="false")
    //if (cookie.get("tokenauth") === "false") 
    {
      window.location.href = "/v1/jim_user_login";
    }
  }, []);
  const dropdownRef = useRef(null);
  
  useEffect(() => {
    // Add a click event listener to the document to detect clicks outside the dropdown
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        m(0)
      }
    }
  
    // Attach the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);
  
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/userTicket/${project}/${d}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //setData(data.data)
        setOption(data.data.opt);
        setMobile(data.data.mobile);
        setMail(data.data.mail);
        setLocation(data.data.location);
        setDescript(data.data.description);
        setSummary(data.data.summary);
        // setAttach(data.data.file);
        const fileName=data.data.file
 const extension=fileName.substring(fileName.lastIndexOf("."))
 const fileWithoutExtensionId=fileName.substring(9,fileName.lastIndexOf("."))
 console.log(fileWithoutExtensionId)
 const fileId=fileName.substring(0,9)
 console.log(fileId)
 console.log(extension)
 const decryptedFile=decryptMessage(fileWithoutExtensionId)
 console.log(decryptedFile)
 setAttach(decryptedFile+extension)
 setFilename(decryptedFile+extension)
        if (data.statusCode === 400) {
          // alert("User Logged Out")
          navigate("/v1/jim_user_login");
        }
      })
      .catch((err) => console.log(err));
  }, [project]);
  //sessionStorage.setItem("opt",data.opt);
  //var opti=sessionStorage.getItem("opt")

  var [summary, setSummary] = useState("");

  console.log(opt);
  //console.log(opti)

  //setSummary(data.summary)
  const navigate = useNavigate();

  const initialState={};
  console.log(textarea)
textarea.forEach((item)=>
{
  initialState[item.fieldname]='';
})
calender.forEach((item)=>
{
  initialState[item.fieldname]='';
})
const [state,setState]=useState(initialState);
const handleChange=(item,value)=>
{
  console.log(item)
  console.log(value)
  setState((prevState)=>
  ({
    ...prevState,
    [item]:value,

  }))
  setValue((prevState)=>
    ({
      ...prevState,
      [item]:value,
  
    }))
 
}

console.log("***********",fieldvalues)
const getElement=(name)=>
  {
    var htmlElement=""
    if(name.fieldtype==="textarea")
      { 
        htmlElement=<><div data-test-id="request-field-summary">
        <div className="sc-gGCbJM foiuyx">
        <label htmlFor="mobnumber" className="css-1qzu3fk">{name.fieldname}</label>
        {name.required==="true"?<span className="sc-lcpuFF kHmppP" aria-hidden="true">*</span>:""}</div>
        <div data-ds--text-field--container="true" className="css-1o07z2d">
        {name.required==="true"?<input id="summary" required={true} 
              value={value[name.fieldname.split(" ").join("")]}
              onChange={(e) => handleChange(name.fieldname.split(" ").join(""), e.target.value)} name="mobnumber" type="text" data-ds--text-field--input="true" className="css-mfjdp3" />
        :<input id="summary" required={false} 
             value={value[name.fieldname.split(" ").join("")]}
              onChange={(e) => handleChange(name.fieldname.split(" ").join(""), e.target.value)} name="mobnumber" type="text" data-ds--text-field--input="true" className="css-mfjdp3" />}
        </div></div>
        <br/></>
      }
      else if(name.fieldtype==="number"){
        htmlElement=<><div data-test-id="request-field-summary">
        <div className="sc-gGCbJM foiuyx">
        <label htmlFor="mobnumber" className="css-1qzu3fk">{name.fieldname}</label>
        {name.required==="true"?<span className="sc-lcpuFF kHmppP" aria-hidden="true">*</span>:""}</div>
        <div data-ds--text-field--container="true" className="css-1o07z2d">
        {name.required==="true"?<input type="number" id="phoneNumber" name="phoneNumber"  placeholder="Number"  value={value[name.fieldname.split(" ").join("")]} 
          className="css-mfjdp3" required={true} onChange={(e)=> {const inputValue = e.target.value;
            // Use a regular expression to remove non-numeric characters
            const numericValue = inputValue.replace(/[^0-9]/g, '');
            const limitedNumericValue = numericValue.slice(0, maxDigits);
            setNumberValue(limitedNumericValue);
            handleChange(name.fieldname.split(" ").join(""),limitedNumericValue)}}/>:<input type="number" id="phoneNumber" name="phoneNumber"  placeholder="Number"  value={value[name.fieldname.split(" ").join("")]}
            className="css-mfjdp3"  required={false} onChange={(e)=> {const inputValue = e.target.value;
              // Use a regular expression to remove non-numeric characters
              const numericValue = inputValue.replace(/[^0-9]/g, '');
              const limitedNumericValue = numericValue.slice(0, maxDigits);
              setNumberValue(limitedNumericValue);
              handleChange(name.fieldname.split(" ").join(""),limitedNumericValue)}}/>}
        </div></div>
        <br/></>
      }
      else if(name.fieldtype==="calender")
        {
          htmlElement=<><div data-test-id="request-field-summary">
          <div className="sc-gGCbJM foiuyx">
          <label htmlFor="mobnumber" className="css-1qzu3fk">{name.fieldname}</label>
          {name.required==="true"?<span className="sc-lcpuFF kHmppP" aria-hidden="true">*</span>:""}</div>
          <div data-ds--text-field--container="true" className="css-1o07z2d">
          {name.required==="true"?<input type="date"  id="calender" required={true} 
                value={value[name.fieldname.split(" ").join("")]} className="css-mfjdp3"
                onChange={(e) => handleChange(name.fieldname.split(" ").join(""), e.target.value)}  name="calender"/>:
                <input type="date"   id="calender" required={false} 
                value={value[name.fieldname.split(" ").join("")]} className="css-mfjdp3"
                onChange={(e) => handleChange(name.fieldname.split(" ").join(""), e.target.value)}  name="calender"/>}
      &nbsp;&nbsp;
          </div></div>
    
          <br/></>
        }
        else if(name.fieldtype==="dropdown"){
          htmlElement=<><div data-test-id="request-field-summary">
          <div className="sc-gGCbJM foiuyx">
          <label htmlFor="mobnumber" className="css-1qzu3fk">{name.fieldname}</label>
          {name.required==="true"?<span className="sc-lcpuFF kHmppP" aria-hidden="true">*</span>:""}
          </div>
          <div data-ds--text-field--container="true" className="css-1o07z2d">
          {name.required==="true"?<select name="select" id="select" className="css-mfjdp3" value={value[name.fieldname.split(" ").join("")]} onChange={(e) => handleChange(name.fieldname.split(" ").join(""), e.target.value)} required={true}>
          <option value="">Select</option>
          {radiooptions.filter(j=>j.field===name.fieldname).map((element)=>
          <><option value={element.FieldName}>{element.FieldName}</option></>)}
          </select>:
          <select name="select" id="select" value={value[name.fieldname.split(" ").join("")]} className="css-mfjdp3" onChange={(e) => handleChange(name.fieldname.split(" ").join(""), e.target.value)} required={false}>
          <option value="">Select</option>
          {radiooptions.filter(j=>j.field===name.fieldname).map((element)=>
          <><option value={element.FieldName}>{element.FieldName}</option></>)}
          </select>}
          </div>
          </div>
          <br/></>
        }
        else if(name.fieldtype==="radio")
          {
            htmlElement=<><div data-test-id="request-field-summary">
      <div className="sc-gGCbJM foiuyx">
      <label htmlFor="mobnumber" className="css-1qzu3fk">{name.fieldname}</label>
      {name.required==="true"?<span className="sc-lcpuFF kHmppP" aria-hidden="true">*</span>:""}
      </div>
      {radiooptions.filter(j=>j.field===name.fieldname).map((element)=>name.required==="true"?<><input  type="radio"  id={element.FieldName} name={name.fieldname} checked={value[name.fieldname.split(" ").join("")]===element.FieldName}
      value={element.FieldName} required={true} onChange={(e) => handleChange(name.fieldname.split(" ").join(""), element.FieldName)}/>
  <label for={`${element.FieldName}`}>{element.FieldName}</label>&nbsp;</>:
<><input  type="radio"  id={element.FieldName} name={name.fieldname} checked={value[name.fieldname.split(" ").join("")]===element.FieldName}
      value={element.FieldName}   onChange={(e) => handleChange(name.fieldname.split(" ").join(""), element.FieldName)}/>
  <label for={`${element.FieldName}`}>{element.FieldName}</label>&nbsp;</>)}
      </div>
      <br/></>
          }
          else if(name.fieldtype==="statictext")
            {
              htmlElement= <><div data-test-id="request-field-summary">
              <div className="sc-gGCbJM foiuyx">
                <label htmlFor="summary" className="css-1qzu3fk">
                  {name.fieldname}
                </label>
                <span
                        className="sc-lcpuFF kHmppP"
                        aria-hidden="true"
                      >
                        *
                      </span>
              </div>
              <div
                data-ds--text-field--container="true"
                className="css-1o07z2d"
              >
                <input
                 style={{marginBottom:"auto"}}
                 disabled
                  id="nameuser"
                  required
                  defaultValue={mail}
                  onChange={(e) => setMail(e.target.value)}
                  name="nameuser"
                  type="text"
                  data-ds--text-field--input="true"
                  className="css-mfjdp3"
                />
              </div>
            </div>
            <br/>
            </>
            }
            else if(name.fieldtype==="staticnumber")
              {
                htmlElement= <><div data-test-id="request-field-summary">
                <div className="sc-gGCbJM foiuyx">
                  <label htmlFor="mobnumber" className="css-1qzu3fk">
                    {name.fieldname}
                  </label>
                  <span
                          className="sc-lcpuFF kHmppP"
                          aria-hidden="true"
                        >
                          *
                        </span>
                </div>
                <div
                  data-ds--text-field--container="true"
                  className="css-1o07z2d"
                >
                  <input
                  style={{marginBottom:"auto"}}
                  disabled
                    id="summary"
                    required
                    defaultValue={mobile}
                    value={mobile}
                    onChange={(e) =>{ 
                      const regex = /^[0-9]*$/
                      if(regex.test(e.target.value)){
                        setMobile(e.target.value)
                      }
                      else{
                       
                      }
                    }}
                    name="mobnumber"
                    type="text"
                    data-ds--text-field--input="true"
                    className="css-mfjdp3"
                    maxLength={10}
                  />
                </div>
              </div>
              <br />
              </>
              }
              else if(name.fieldtype==="staticlocation")
                {
                  htmlElement= <><div data-test-id="request-field-summary">
                  <label htmlFor="capitalCity" className="css-1qzu3fk">
                    {name.fieldname}
                  </label>
                  <span
                                className="sc-lcpuFF kHmppP"
                                aria-hidden="true"
                              >
                                *
                              </span>
                  <br />
                  <div
                    data-ds--text-field--container="true"
                    className="css-1o07z2d"
                  >
                    <select
                      class="form-select"
                      id="capitalCity"
                      name="capitalCity"
                      value={location}
                      onChange={handleCityChange}
                    >
                      <option value={location}>{location}</option>
                      {capitalCities.sort().map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}
                      <option value="Other" onClick={handleCity} onBlur={handleOnBlur}>
                         Other 
                       </option>
                    </select>
                  </div>
                  {
          showInput ? <input type ="text" value={city} required placeholder="enter city" onChange={handleInputCity}/> :null
        }
        
                </div>
                <br />
                </>
                }
                else if(name.fieldtype==="statictitle")
                  {
                    htmlElement= <>  <div data-test-id="request-field-summary">
                    <div className="sc-gGCbJM foiuyx">
                      <label htmlFor="summary" className="css-1qzu3fk">
                        {name.fieldname}
                        <span
                          className="sc-lcpuFF kHmppP"
                          aria-hidden="true"
                        >
                          *
                          Characters left: {150 - summary.length}
                        </span>
                      </label>
                    </div>
                    <div
                      data-ds--text-field--container="true"
                      className="css-1o07z2d"
                    >
                      <input
                      style={{marginBottom:"auto"}}
                        id="summary"
                        onChange={(e) =>{ 
                          const inputSummary = e.target.value;

                          // Limit the summary to 50 characters
                          if (inputSummary.length <= 150) {
                            setSummary(inputSummary);
                          }
                        }}
                        name="summary"
                        type="text"
                        className="css-mfjdp3"
                        value={summary}
                        maxLength="150"
                      />
                    </div>
                  </div>
                  <br />
                  </>
                  }
                  else if(name.fieldtype==="staticselect")
                    {
                      htmlElement= <>   <label htmlFor="request-group-select" className="css-1qzu3fk">
                      {name.fieldname}
                    </label>
                    <span
                                className="sc-lcpuFF kHmppP"
                                aria-hidden="true"
                              >
                                *
                              </span>
  
                    <div className=" css-5a7vsu-container">
                      <div
                        className=" css-bzqzs4-control"
                        ref={dropdownRef}
                        onClick={() => dropdown(opt)}
                      >
                        <div className=" css-s88hxx">
                          <div
                            className=" css-8atqhb"
                            onclick={() => dropdown(opt)}
                            style={{
                              opacity: "1",
                              transition: "opacity 1ms ease 0s",
                            }}
                          >
                            <div className="sc-jrIrqw gRnkOb">{opt}</div>
                          </div>
                          <input
                           style={{marginBottom:"auto"}}
                            id="request-group-select"
                            readOnly=""
                            tabIndex="0"
                            aria-autocomplete="list"
                            className="css-62g3xt-dummyInput"
                            value=""
                          />
                        </div>
                        <div className=" css-t5ibhw">
                          <div
                            aria-hidden="true"
                            className=" css-gg6ksl-indicatorContainer"
                          >
                            <span
                              role="img"
                              aria-label="open"
                              className="css-snhnyn"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                role="presentation"
                              >
                                <path
                                  d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z"
                                  fill="currentColor"
                                  fillRule="evenodd"
                                ></path>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                  c === 1 ? (
                    <div className="scroll-menu" style={{width:"200px"}}>
                     
                      {options.map(ele=>{return <div className="menu-item"   value={ele.reasons}  onClick={()=>{dropdown(ele.reasons);
    }}>{ele.reasons}</div>
    })}
                    </div>
                  ) : (
                    ""
                  )
                }
                    <br/>
                    </>
                    }
      return htmlElement
  }
  const click = async (y) => {
    let i=0
    const token = localStorage.getItem("token");
    y.preventDefault();

    const id = d;
    const user = nam;
    const description = descript;
    const status = "opened";
    const file = attach
    const obj = { user, opt, summary, description, location:location == "Other" ?city:location, status};
    console.log(obj);
    const res = {
      method: "PUT",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    const plainString = descript.replace(/<[^>]+>/g, '');
    //const noSpace=/^[^-\s][a-zA-Z0-9_\s-]+$/
    const noSpace = plainString.split('')
    console.log(plainString,"descript")
    y.preventDefault()
    if(noSpace[0]==" " && noSpace[0]!="")
    {
      showAlert({
        title:  'Invalid Input',
        message: 'Please enter text first.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    });
    }
    else if (obj.user && obj.opt && obj.summary && obj.location && plainString) {
      const dynamicres={
        method: 'POST',
        withCredentials: 'true',
        headers: { 'Authorization':`Bearer ${token}`,'Content-Type': 'application/json' },
        body: JSON.stringify(state)
    };
    await fetch(`${process.env.REACT_APP_API_URL}/insert/dynamicdata/${project}/${d}`, dynamicres)
    .then((data)=>
    {
      if(data.status===201)
        {
          console.log("added")
          i++;
        }  
    })
      await fetch(
        `${process.env.REACT_APP_API_URL}/comment/${project}/${d}`,
        res
      ).then((data) => {
        if (data.statusCode === 400) {
          //alert("User Logged Out")
          navigate("/v1/jim_user_login");
        } else if(i===1 && data.status===200){
          showAlert({
            title:  'Ticket updated',
            message: 'Your ticket has been updated.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
                navigate("/v1/jim/user_homepage");
            }
        });
          console.log("added");
        }
        else{
          showAlert({
            title:  'Failed To Raise',
            message: 'We encountered an issue while trying to raise your ticket. Please try again.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        });
        }
        
      });
      console.log(hiddenFileInput.current.files[0]);
      const formData = new FormData();
      //formData.append("file", hiddenFileInput.current.files[0]);
      formData.append("file",files);
      console.log(formData);
      for (var key of formData.entries()) {
        console.log(key[0] + ", " + key[1]);
      }
    const result =await fetch(`${process.env.REACT_APP_API_URL}/upload/${project}/${d}`,{
      method: 'Post',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
      //headers: { 'Content-Type': 'multipart/form-data' },
      body: formData,
  })

  const data  = await result.json()
  console.log(">>>>>>>>>>>>>>>>>>*****",data.statusCode)
  if(data.statusCode == 401){
    showAlert({
      title:  'Invalid File',
      message:'The file you upload is invalid, Please check the file format.' ,
      //`MIME type mismatch: Expected ${data.expectedMimeType}, but got ${data.detectedMimeType}`,
      okText: 'OK',
      callback: () => {
          console.log('Alert closed');
          setAlertProps(null)
          
      }
  });
  setFile([])
  
 setAttach(filename)
  }
    
      //window.location.reload(false);
      try {
        // Omit optional second argument
      } catch (error) {
        navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
      }
    } else {
      showAlert({
        title:  'Required',
        message: 'Please fill out all required field.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    });
    }
  };

  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  let [c, m] = useState(0);
  const dropdown = (e) => {
    if (c === 0) {
      m(1);
    } else {
      m(0);
    }
    setOption(e);
  };
  const [whic, setWhich] = useState("");
  const textclick = (e) => {
    setWhich(e);
  };
  console.log(whic);
  const capitalCities = [
    "Mumbai",
    "Delhi",
    "Bangalore",
    "Kolkata",
    "Chennai",
    "Hyderabad",
    "Pune",
    "Ahmedabad",
    "Jaipur",
    "Lucknow",
    "Chandigarh",
    "Bhopal",
    "Indore",
    "Patna",
    "Kanpur",
    "Nagpur",
    "Coimbatore",
    "Vadodara",
    "Ludhiana",
    "Agra",
    "Nashik",
    "Kochi",
    "Varanasi",
    "Madurai",
    "Visakhapatnam",
    // Add more cities or create additional arrays as needed
  ];



  // Function to handle changes in the selected city
  const handleCityChange = (event) => {
    setLocation(event.target.value);
    if(event.target.value=="Other"){
      setShowInput(true)
    }
    else{
      setShowInput(false)
    }
  };
  const handleDragOver=(event)=>
  {
    event.preventDefault()
    console.log(event.dataTransfer.files)

  }
  const handleDrop=(event)=>
  {
    event.preventDefault()
    console.log(event.dataTransfer.files[0].name)
    setFile(event.dataTransfer.files[0])
    let files=event.dataTransfer.files[0]
    console.log(files)
    setAttach(files.name)
  }
  const handleCity = () =>{
    alert("hello world")
    setShowInput(true)
  }
  const  handleInputCity = (e) =>{
    console.log(e.target.value)
    const regex = /^[A-Za-z]+$/;
    if(regex.test(e.target.value)){
      //const lowerCaseCitiesArray = capitalCities.map(city => city.toLowerCase());
      // if(lowerCaseCitiesArray.includes(e.target.value.toLowerCase())){
      //   alert("city is available in list")
      // }else{
    setCity(e.target.value)
    // }
    }
    else{
      setCity(e.target.value.slice(0,-1))
    }
    }
    
  const handleOnBlur = () =>{
    setShowInput(false)
  }
  return (
    <>
    {allFieldsData.length!==0?
      <>
      <Headerhotel
        requests={1}
        backButton={0}
        raiseTicket={0}
        registrationRequest={0}
        adminRequests={0}
        showAlert={showAlert} 
        setAlertProps={setAlertProps}
      />
      <form className="sc-cgHJcJ fLqQEk" onSubmit={click}>
      <div>
        <div className="sc-hORach dWMRNy">
          <div className="sc-eTuwsz jmFfsW"></div>
          <main className="sc-hXRMBi bJStMD sc-ibxdXY fpdUcT">
            <div className="sc-hrWEMg cesPGE">
              <header>
                <span className="sc-rBLzX fayCzS">
                  {/*<p className="sc-CtfFt ewysoW sc-Rmtcm DgkTO" href="/servicedesk/customer/portals" target="_self">Jio Services Portal Home</p>
      <span className="sc-hGoxap dNSoSr">/</span>
      <a className="sc-CtfFt ewysoW sc-Rmtcm DgkTO" href="/servicedesk/customer/portal/10" target="_self">Jio Services</a>
      <span className="sc-hGoxap dNSoSr">/</span>
  <a className="sc-CtfFt ewysoW sc-Rmtcm DgkTO" href="/servicedesk/customer/portal/10/group/-1" target="_self">Raise a request</a>*/}
                </span>
                <div className="sc-TFwJa dUxdnV">
                  <div className="sc-fjmCvl OGFXn">
                    <img className="sc-feJyhm fDacxp" src={img} />
                  </div>
                  <div>
                    <h1 className="sc-bHwgHz bGtLRv">Jio Services</h1>
                  </div>
                </div>
              </header>

              <div className="sc-jqIZGH bokdCL"></div>
              <div className="sc-gkFcWv eIvNgR">
                <div>
                  <h2 className="sc-kQsIoO bfEIYs sc-cpmKsF hyLwHu">
                    Id Number: {d}
                  </h2>
                 

                  
                  {/* {textarea.length === 0
                    ? ""
                    : textarea.map((name) => (
                        <div data-test-id="request-field-summary">
                          <div className="sc-gGCbJM foiuyx">
                            <label htmlFor="summary" className="css-1qzu3fk">
                              {`${name.fieldname}`}
                              {console.log(value[name.fieldname])}
                            </label>
                          </div>
                          <div
                            data-ds--text-field--container="true"
                            className="css-1o07z2d"
                          >
                            <input
                              id="summary"
                              required
                              defaultValue={value[name.fieldname]}
                              onChange={(e) => handleChange(name.fieldname.split(" ").join(""), e.target.value)}
                              name="summary"
                              type="text"
                              data-ds--text-field--input="true"
                              className="css-mfjdp3"
                            />
                            <br />
                          </div>
                        </div>
                      ))} 
                  <br />*/}
                  
                  



                 
                </div>
              
              </div>
              
              <div className="sc-krvtoX gLHWRW">
                
                  <div
                    className=""
                    style={{
                      height: "auto",
                      overflow: "visible",
                      transition: "height 0.25s ease 0",
                    }}
                  >
                    <div style={{ overflow: "visible" }}>
                    
                      {allFieldsData.length===0?"":allFieldsData.filter(j=>j.groupname===null).map((name)=>
        {return name.fieldtype==="HeadGroup"?
      <><div data-test-id="request-field-summary">
      <div className="sc-gGCbJM foiuyx">
      <label htmlFor="mobnumber" className="css-1qzu3fk">{name.fieldname}</label>
      {name.required==="true"?<span className="sc-lcpuFF kHmppP" aria-hidden="true">*</span>:""}
      </div>
      <div data-ds--text-field--container="true" className="css-1o07z2d">
      {name.required==="true"?<select name="select" id="select" className="css-mfjdp3" value={value[name.fieldname.split(" ").join("")]} onChange={(e) => handleChange(name.fieldname.split(" ").join(""), e.target.value)} required={true}>
      <option value="">Select</option>
      {radiooptions.filter(j=>j.field===name.fieldname).map((element)=>
      <><option value={element.FieldName}>{element.FieldName}</option></>)}
      </select>:
      <select name="select" id="select" value={value[name.fieldname.split(" ").join("")]} className="css-mfjdp3" onChange={(e) => handleChange(name.fieldname.split(" ").join(""), e.target.value)} required={false}>
      <option value="">Select</option>
      {radiooptions.filter(j=>j.field===name.fieldname).map((element)=>
      <><option value={element.FieldName}>{element.FieldName}</option></>)}
      </select>}
      </div>
      </div>
                    <br/>
      {allFieldsData.filter(j=>j.groupname===value[name.fieldname.split(" ").join("")] && j.groupname!==null).map(ele=>getElement(ele))}</>
        :getElement(name)}
      )} 
      {console.log(value)}
                      <div data-test-id="request-field-description">
                        <div className="sc-gGCbJM foiuyx">
                          <label htmlFor="description" className="css-1qzu3fk">
                            Description
                          </label>
                          <span
                              className="sc-lcpuFF kHmppP"
                              aria-hidden="true"
                            >
                              *
                              
        Characters left: {count1 || 10000-descript.replace(/(<([^>]+)>)/gi, '').length} 
      
                            </span>
                        </div>

                        <div
                          className="sc-chAAoq hcyXVm"
                          id="description"
                          data-test-id="rich-text-area-field"
                        >
                          <div className="sc-hAXbOi eotBHb">
                            <div className="css-1dr2qy6">
                              <div
                                style={{
                                  display: "block",
                                  width: "100%",
                                  position: "absolute",
                                }}
                              ></div>
                              <div className=" css-18w41as">
                                <div className="akEditor css-1yk94i7" onClick={()=>setIsDisabled(false)}>
                                  {/*<div data-testid="ak-editor-main-toolbar" className="css-1atvvg6">
      <div className="custom-key-handler-wrapper css-yd211k" role="toolbar" aria-label="Editor toolbar" aria-controls="ak-editor-textarea">
      <div className="css-1ky3qeu"><div style={{display: "block", width: "100%", position: "absolute"}}>
      </div>
      <div className="css-6gmvcx"><span className="css-2i865c">
      <div className="css-x571cj">
      <div>
      <div role="presentation">
      <div className="css-13cz8p3">
      <button aria-expanded="false" onClick={()=>textclick("text")} aria-haspopup="true" aria-label="Text styles" className="block-type-btn css-pg7w17" type="button" tabIndex="0" style={{alignItems: "center"}}>
      <span className="css-1gd7hga"><span className="css-nim8xe">Normal text</span></span>
      <span className="css-l2xrfb">
      <span data-testid="toolbar-block-type-text-styles-icon" className="css-1cm0fqf">
      <span className="css-il1drd">
      <span aria-hidden="true" className="css-snhnyn">
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z" fill="currentColor" fillRule="evenodd">
      </path></svg></span></span></span></span></button></div></div></div></div>
      <span className="css-g4dhky"></span>
      </span>
      <span class="css-ox6esc">
      <span class="css-ox6esc">
      <div role="presentation">
      <div class="css-13cz8p3" aria-describedby="84val-tooltip">
      <button id="editor-toolbar__strong"  onClick={()=>textclick("bold")} aria-label="Bold Ctrl+B" aria-pressed="false" aria-keyshortcuts="Control+b" class="css-1muxtt6" data-testid="editor-toolbar__Bold" tabindex="0" type="button" style={{alignItems: "center"}}>
      <span class="css-18kgcs9">
      <span aria-hidden="true" class="css-snhnyn" style="--icon-primary-color: currentColor; --icon-secondary-color: var(--ds-surface, #FFFFFF);">
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path d="M8 6h4.832C13.908 6 16 6.5 16 9c0 1.333-.333 2.167-1 2.5 1.333.333 2 1.333 2 3 0 .5 0 3.5-4 3.5H8a1 1 0 01-1-1V7a1 1 0 011-1zm1 10h3.5c1 0 2-.25 2-1.5s-1.104-1.5-2-1.5H9v3zm0-4.975h3c.504 0 2 0 2-1.525S12 8 12 8H9v3.025z" fill="currentColor" fill-rule="evenodd"></path></svg>
      </span></span></button></div></div>
      <div role="presentation">
      <div class="css-13cz8p3" aria-describedby="85val-tooltip">
      <button id="editor-toolbar__italic" onClick={()=>textclick("italic")} aria-label="Italic Ctrl+I" aria-pressed="false" aria-keyshortcuts="Control+i" class="css-1muxtt6" data-testid="editor-toolbar__Italic" tabindex="0" type="button" style={{alignItems: "center"}}>
      <span class="css-18kgcs9"><span aria-hidden="true" class="css-snhnyn" style="--icon-primary-color: currentColor; --icon-secondary-color: var(--ds-surface, #FFFFFF);"><svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path d="M10 6h6a1 1 0 010 2h-6a1 1 0 110-2zM8 16h6a1 1 0 010 2H8a1 1 0 010-2zm4-8h2l-2 8h-2l2-8z" fill="currentColor" fill-rule="evenodd"></path></svg></span></span></button></div></div></span>
      <span class="css-2i865c">
      <div class="css-x571cj">
      <div>
      <div role="presentation">
      <div class="css-13cz8p3" aria-describedby="86val-tooltip">
      <button aria-expanded="false" aria-haspopup="true" aria-label="More formatting" class="css-1muxtt6" tabindex="0" type="button" style={{alignItems: "center"}}><span class="css-18kgcs9"><div class="css-ov1ktg"><span aria-hidden="true" class="css-snhnyn" >
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path d="M12 14a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm-4.5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm9 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z" fill="currentColor" fill-rule="evenodd"></path></svg></span></div></span></button></div></div></div></div>
      <span class="css-g4dhky"></span></span></span>
      </div></div>
      <div className="css-16cy0zh"></div></div></div>*/}
                                  {/*<textarea data-testid="click-wrapper" contentEditable={true} value={description} onChange={(e)=>setDescription(e.target.value)} aria-multiline="true" role="textbox" id="ak-editor-textarea"  translate="no" data-gramm="false"className="css-1q3xxv2">*/}
                                  {/*<div className="ak-editor-content-area less-margin css-1ijlzqt">
      <div className="css-ov1ktg"></div>
<div className="ua-chrome ProseMirror pm-table-resizing-plugin" contentEditable={true}  value={description} onChange={(e)=>setDescription(e.target.value)} aria-label="Main content area, start typing to enter text." aria-multiline="true" role="textbox" id="ak-editor-textarea"  translate="no" data-gramm="false">*/}
                                  {/*<div id="comment2" contentEditable={true}  placeholder="" className="comment2 gwKMCV ">
      <p  id="para" value={description} onChange={(e)=>setDescription(e.target.value)}> 
      {(() => {
        if(whic==="bold")
       {
        console.log("b");
        document.getElementById("para").innerHTML +=<strong>{description}</strong>
       }
       else if(whic==="text") 
       {
        <strong><br className="ProseMirror-trailingBreak"/></strong>
       }
       else if(whic==="italic")
       {
        <p style={{fontStyle:"italic"}}><br className="ProseMirror-trailingBreak"/></p>
       }
      })()}
      </p>
    </div>*/}
                                  <ReactQuill
                                    theme="snow"
                                    value={descript}
                                    style={{ height: "105px" }}
                                    readOnly={isDisabled}
                                    onChange={(value)=>{
                                      console.log("$$$$$$$$$",value.replace(/(<([^>]+)>)/gi, '').length)  
                                      if(value.replace(/(<([^>]+)>)/gi, '').length <= 10000){
                                        setDescript(value)
                                        setCount1(10000-value.replace(/(<([^>]+)>)/gi, '').length)
                                        setIsDisabled(false);
                                      }
                                      else{
                                        setIsDisabled(true);
                                      }
                                      
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div data-test-id="request-field-attachment">
                        <div className="sc-gGCbJM foiuyx">
                          <label htmlFor="attachment" className="css-1qzu3fk">
                            Attachment
                          </label>
                        </div>
                        <div>
                          <div className="sc-hizQCF fkZtIa">
                            <div className="sc-eNPDpu gJJhIj">
                              <div className="sc-hARARD evXuov">
                              <p style={{align:"center"}}>Drag & Drop file here</p>
    {/* <span>Select .jpg or .mp4 or .zip</span> */}
    <div className="sc-TFwJa dUxdnV"  style = {{justifyContent: 'center', marginBottom: '00px',marginTop:"00px", marginLeft: '20px',paddingTop:"0px",paddingBottom:"0px"}}>
    <div className="sc-fjmCvl OGFXn" style={{width:"100px",height:"100px"}} ref={hiddenFileInput} onDragOver={handleDragOver} onDrop={handleDrop}>
      <img className="sc-feJyhm fDacxp" src={uploadImage}/></div></div>
    </div>
    <p style={{align:"center"}}>OR</p>
    <br/>
                              <button
                                type="button"
                                onClick={handleClick}
                                
                                className="request-create-form-submit css-68ar9j"
                                style={{backgroundColor:"#3399FF"}} 
                              >
                                <span className="css-j8fq0c">
                                  <span className="css-t5emrf">
                                    {attach === "" ? (
                                      <span>Browse File</span>
                                    ) : (
                                      <span>
                                        {attach.replace("C:\\fakepath\\", "")}
                                      </span>
                                    )}
                                  </span>
                                </span>
                              </button>
                              <input
                                type="file"
                                /*onChange={handleChange}*/

                                accept=".jpg,.jpeg,.pdf,.zip,.mp4,.png"
                                ref={hiddenFileInput}
                                onChange={async (e) => {
                                  setUploadErr(false)
                                  if(hiddenFileInput.current.files[0])
            {
            if(hiddenFileInput.current.files[0].size<=10485760/2)
            {
              const mimetypes= [('.jpg','image/jpeg'), ('.jpeg','image/jpeg'),
              ('.png','image/png'), 
              ('.pdf','application/pdf'), 
              ('.zip','application/x-zip-compressed'),
              ('.zip','application/zip'), 
              ('.mp4','video/mp4')];
            console.log(e.target.files[0].type)
            const buffer = e.target.files[0].arrayBuffer()
            const type=await fileTypeFromBuffer(await buffer)
            console.log(type?.mime)
              if(mimetypes.includes(e.target.files[0].type) && mimetypes.includes(type?.mime))
                {
                  setFile(e.target.files[0])
              setAttach(e.target.value)
                }
                else{
                  setFile([])
                  setUploadErr(true)
                  setFileUploadErrMessage("* Invalid File")
                }
            }
            else{
              setUploadErr(true)
              setFile([])
              setFileUploadErrMessage("* File size should less than 5MB")
              //alert("file size should less than 5MB")
            }
          }
          else{
            setAttach("")
          }
                                }}
                                style={{ display: "none" }}
                              />
                              {
        uploadErr==true ? <p style={{color:"red",fontSize:"12px"}}>{fileUploadErrMessage}</p> : null
      }
      <p style={{color:"blue",fontSize:"12px", marginTop:"4px"}}>Max file size: 5 MB</p>
      <p style={{fontSize:"12px", marginTop:"0px"}}>Supported File Formats: zip,jpg,jpeg,png,pdf,mp4.</p>

                            </div>
                          </div>
                        </div>

                        <input
                          data-testid="media-picker-file-input"
                          type="file"
                          multiple=""
                          style={{ display: "none" }}
                        />
                      </div>
                      <div className="sc-cbkKFq icDrbe" style={{justifyContent:'center',  marginBottom: '16px', marginLeft: '8px'}}>
                     
                        <button
                          type="submit"
                          className="request-create-form-submit css-68ar9j" /*onClick={click}*/
                        >
                          <span className="css-j8fq0c">
                            <span className="css-t5emrf">
                              <span>Submit</span>
                            </span>
                          </span>
                        </button>
                        
                        {/*<button type="button" className="css-q68uj">
    <span className="css-j8fq0c">
    <span className="css-t5emrf">
<span>Cancel</span></span></span></button>*/}
                      </div>
                    </div>
                    
                  </div>
                  
              </div>
              
              <div className="sc-jzgbtB gUQSWG"></div>
              <div className="sc-jqIZGH bokdCL"></div>  
               
            </div>
          </main>
        </div>
      </div>
      </form> </>:<div style={{display:"flex",height:"458px",width:"100%",justifyContent:"center",alignItems:"center"}}>  <div class="spinner-border" style={{width: "3rem", height: "3rem", role:"status"}}>
        <span class="sr-only">Loading...</span>
      </div> </div>}
    </>
  );
}

export default Edithotel;
