import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const VideoModal = ({ title, videoLink, okText,cancel }) => {
    console.log(title)
  

  
    return (
      <Modal size="lg" show={true} centered onHide={()=>cancel(false)}
      backdrop="static"
      keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title size="sm" style={{fontSize: "1.428572em",
      fontWeight: "bold"
  }}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <video autoPlay src={videoLink} controls width="750" height="350">
          </video>
         <br/>
         <br/>
      {/* <Button style={{float: "inline-end"}} onClick={()=>cancel(false)} size="sm"  >
           {okText}
          </Button> */}
      </Modal.Body>
     
      </Modal>
    );
};

export default VideoModal;