import React from "react";
import { Link } from "react-router-dom";
import PrivacyPolicyStyle from "../css/PrivacyPolicyStyle.css"
const TermsAndConditionsComponent = () => {
  return (
    <div>
      <header id="banner" role="banner" class="header">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <nav class="navbar navbar-expand-lg">
                {/* <a
                  class="col-4 col-sm-4 navbar-menu"
                  href="javascript:void(0);"
                >
                  <img
                    src="https://url.us.m.mimecastprotect.com/s/O6JIC2kNE1cpj0NQDUn0bda?domain=jiocentral.jioconnect.com"
                    alt=""
                  />
                </a> */}
                <a
                  class="logo custom-logo col-4 col-sm-4 brand"
                  href="#"
                  title="Go to jiocentral"
                >
                  <img
                    alt=""
                    src="https://jiocentral.jioconnect.com/o/jio-central-theme/images/img/logo.svg"
                    width=""
                    height=""
                  />
               
                </a>
                <ul class="nav-rt ml-auto">
                  <li class="nav-item">
                    {/* <a
                      class="nav-link btn notification m-md-0"
                      href="javascript:void(0);"
                    >
                      <img
                        src="https://url.us.m.mimecastprotect.com/s/MTQkC4x3JYiBoRpxwCBmP5D?domain=jiocentral.jioconnect.com"
                        class="pr-1"
                      />
                      <span
                        id="notification"
                        class="badge text-white p-1 w-auto h-auto"
                        style={{ display: "none" }}
                      >
                        {" "}
                        0{" "}
                      </span>
                    </a> */}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <dd>
        <p align="center">
          <strong>Terms &amp; Conditions for Jio Service Support</strong>
        </p>
        <ol>
  <li style={{ listStyle: 'none', marginLeft: '50px' }} value="2">
   <strong>1.</strong>&nbsp;<strong>SCOPE</strong>
  </li>
</ol>
<ol >
  <li style={{listStyle:'none',marginLeft:'50px',marginTop:"10px"}}>
    1.1 <strong>Jio Service Support</strong> is a web based portal (“Portal”), provided by Jio Platforms Limited and/or its affiliates (“Company”, “we,” “us” and “our”) located at Office - 101, Saffron Near Centre Point, Panchwati 5 Rasta, Ambavadi Ahmedabad GJ 380006 IN.
  </li>
</ol>

<p>&nbsp;</p>

<ol style={{ listStyle: 'none' }}>
  <li style={{ marginLeft: '50px' }}>1.2 The Portal is designed and made available by the Company and /or its affiliates to provide its users services such as (i) reporting issues and raising a support ticket (“Ticket”) related to the use and working of the Company’s products (“Product”); (ii) tracking and viewing the status of the Ticket; and (iii) such other services as may be provided by the Company from time to time (collectively known as “Services”) on any browser available on desktop monitors, mobile phones, smart tablets etc.</li>
</ol>

<p>&nbsp;</p>

<ol style={{ listStyle: 'none' }}>
  <li style={{ marginLeft: '50px' }}>1.3 Your (“you”, “your” or “user”) use of the Portal is subject to these Terms and Conditions (“Terms”).</li>
</ol>

<p>&nbsp;</p>

<ol style={{ listStyle: 'none' }}>
  <li style={{ marginLeft: '50px' }}>1.4 Your use of the Portal requires that you agree to these Terms and the privacy policy of the Portal. By registering or signing up to the Portal, or otherwise having access to, receiving, and/or using the Portal, you acknowledge to have read, understood and you consent to be governed and bound by these Terms and the privacy policy of the Portal. If you do not understand the Terms or the privacy policy of the Portal, or do not accept any part of them, then you should not use the Portal.</li>
</ol>

<p>&nbsp;</p>

<ol style={{ listStyle: 'none' }}>
  <li style={{ marginLeft: '50px' }}>1.5 The Terms are an electronic record in terms of the Information Technology Act, 2000 (as amended / re-enacted) (“IT Act") and rules thereunder, and is published in accordance with the provisions of Rule 3 (1) of the of the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021, which mandates for publishing of rules and regulations, privacy policy and terms of use for access or usage of Portal. This electronic record is generated by a computer system and does not require any physical or digital signatures.</li>
</ol>
        <p>&nbsp;</p>
      
<p>&nbsp;</p>


        <ol>
  <li style={{ listStyle: 'none', marginLeft: '50px' }} value="2">
   <strong>2.</strong>&nbsp;<strong>PRIVACY AND PROTECTION OF PERSONAL INFORMATION:</strong>
  </li>
</ol>

<p>&nbsp;</p>

<ol>
  <li style={{ listStyle: 'none', marginLeft: '50px' }} value="2.1">
    2.1 Please refer to the privacy policy of the Platform available here.
    <p>
      <Link to="/privacypolicy">Privacy Policy</Link>
    </p>
  </li>
</ol>

        <p>&nbsp;</p>
        <ol>
  <li style={{ listStyle: 'none', marginLeft: '50px'}} value="3">
    <strong>3.&nbsp;</strong><strong>MODIFICATIONS</strong>
    <ol>
      <li style={{ listStyle: 'none',marginTop:'10px' }} value="3.1">
        3.1&nbsp;Modifications of the Terms: The Company reserves the right to
        change the Terms under which the Services are offered, including
        but not limited to the charges, if any, associated with the use
        of the Services. The Terms may be further modified based on
        changes in the business, legal and regulatory requirements and
        will be updated online. We will notify you whenever any change
        to the Terms are made. You are encouraged to periodically visit
        this page to review these Terms and any changes to it.
      </li>
    </ol>
  </li>
</ol>

<p>&nbsp;</p>

<ol>
  <li style={{ listStyle: 'none', marginLeft: '50px',marginBottom:'20px' }} value="3.2">
    3.2 Modification of Services: The Company reserves the right to add,
    modify or delete any content or features available in the Services
    at any time at its sole discretion.
  </li>
</ol>
<ol>
  <li style={{ listStyle: 'none', marginLeft: '50px',marginBottom:'20px' }} value="4">
    <strong>4. </strong><strong>LINKS TO THIRD PARTY SITES AND APPLICATIONS:     &nbsp;
</strong>
    <ol>
      <li style={{ listStyle: 'none',marginTop:'10px' }} value="4.1">
       4.1 The Portal may contain links to other web sites/applications
        ("Linked Sites"). The Linked Sites are not under the control of
        the Company and the Company is not responsible for the contents
        of any Linked Site, including without limitation any link
        contained in a Linked Site, or any changes or updates to a
        Linked Site or any information transmitted on a Linked Site. The
        inclusion of any link does not imply endorsement by the Company
        of the site or any association with its operators.
      </li>
    </ol>
  </li>
</ol>


<ol>
  <li style={{ listStyle: 'none', marginLeft: '50px' }} value="4.2">
    4.2 Any dealings with third parties (including advertisers) included
    within or available via a link from the Portal or participation in
    promotions, including the delivery of and the payment for goods and
    services, and any other terms, conditions, warranties or
    representations associated with such dealings or promotions, are
    solely between you and the advertiser or other third party. The
    Company shall not be responsible or liable for any part of any such
    dealings or promotions.
  </li>
</ol>

<p>&nbsp;</p>

<ol>
  <li style={{ listStyle: 'none', marginLeft: '50px' }} value="4.3">
    4.3 You acknowledge and agree that the use of any Linked Sites is
    governed by such third party’s terms of use, license agreement,
    privacy policy, or other such agreement. THE COMPANY DISCLAIMS ANY
    AND ALL RESPONSIBILITY FOR ANY DISCLOSURE OF INFORMATION OR ANY
    OTHER PRACTICES OF ANY THIRD PARTY. THE COMPANY EXPRESSLY DISCLAIMS
    ANY WARRANTY WITH RESPECT TO YOUR PERSONAL OR OTHER INFORMATION THAT
    MAY BE COLLECTED, PROCESSED, SHARED OR RETAINED BY ANY THIRD PARTY
  </li>
</ol>
<p>&nbsp;</p>

<ol>
  <li style={{ listStyle: 'none', marginLeft: '50px'}} value="5">
    <strong>5.&nbsp;</strong><strong>INTELLECTUAL PROPERTY RIGHTS:</strong>
  </li>
</ol>
<ol>
  <li style={{ listStyle: 'none', marginLeft: '50px',marginTop:'10px' }} value="5">
    <ol>
      <li style={{ listStyle: 'none' }} value="5.1">
        5.1 The Company shall own title, interest and rights, including all
        related intellectual property rights, in/of the Portal including
        but not limited to object code of the Portal, features of
        services, which is/are protected by the applicable laws except
        the content owned by the user. You acknowledge that such
        ownership shall include all intellectual property rights arising
        from any suggestions, enhancement requests, recommendations or
        other information provided by the user, whether in the course of
        use of the Portal, your Device or otherwise.
      </li>
    </ol>
  </li>
</ol>
<p>&nbsp;</p>

<ol>
  <li style={{ listStyle: 'none', marginLeft: '50px' }} value="6">
  <strong>6. </strong>  <strong>USE LIMITATIONS:</strong>
    <ol>
      <li style={{ listStyle: 'none',marginTop:'10px' }} value="6.1">
        6.1 Services offered by the Portal are intended solely for the
        purpose of availing Services provided by the Portal. You may not
        distribute, transmit, display, perform, reproduce, publish,
        license, create derivative works from, transfer, or sell any
        information, software, products or Services offered by the
        Portal.
      </li>
    </ol>
  </li>
</ol>

<p>&nbsp;</p>

<ol>
  <li style={{ listStyle: 'none', marginLeft: '50px' }} value="6.2">
   6.2 As a condition of your use of the Portal, you warrant to the Company
    that you will not use the Portal for any purpose that is unlawful or
    prohibited by these Terms. You may not use the Portal in any manner
    which could damage, disable, overburden or impair the Services or
    interfere with any other party's use and enjoyment of the Portal.
  </li>
</ol>

<p>&nbsp;</p>

<ol>
  <li style={{ listStyle: 'none', marginLeft: '50px' }} value="6.3">
    6.3 You are not entitled to use any kind of measures, mechanisms or
    tools (software or hardware) that could interfere with the
    functioning of the Portal or any of its Services.
  </li>
</ol>

<p>&nbsp;</p>

<ol>
  <li style={{ listStyle: 'none', marginLeft: '50px' }} value="6.4">
    6.4 You may not use the Services and/or Portal if (i) you have been
    convicted of any offence under applicable law; and/or (ii) if you
    are under the age of 18 years;
  </li>
</ol>

        <p>&nbsp;</p>

        <ol>
  <li style={{ listStyle: 'none', marginLeft: '50px' }} value="7">
   <strong>7.</strong> <strong>USER NAMES AND PASSWORDS</strong>
  </li>
</ol>

<p style={{marginLeft:'50px',marginTop:'10px'}}>7.1  You may be required to register in order to access Portal using the means and methodology as provided by the Company from time to time.</p>
<p>&nbsp;</p>

<p  style={{marginLeft:'50px'}}>7.2 You are responsible for maintaining the confidentiality of any password you use to access the Portal and you agree not to transfer such passwords or user names or lend or otherwise transfer your use or access to the Portal to any third party. You agree to notify us of any unauthorized use of your passwords or user names or any other breach of security related to your account of which you become aware. You are fully responsible for all use of the Portal and any Services that occur in connection with your user name, except to the extent that any unauthorized use is not caused by any breach of your obligations under these Terms.</p>

<p>&nbsp;</p>

<ol>
  <li style={{ listStyle: 'none', marginLeft: '50px' }} value="8">
   <strong>8.</strong> <strong>GENERAL:</strong>
    <ol>
      
    <p>&nbsp;</p>

      <li style={{ listStyle: 'none' }} value="8.1">
       8.1 By using our Services you hereby agree to ensure that the explicit prohibitions mentioned in section 8.3 are at all times adhered to. The Company may use and/ or develop advanced technologies to detect any misuse of our Services. The Company reserves the right to use and/ or develop automated means and/ or systems to improve our ability to detect and/ or remove any prohibited conduct that may be in violation of the applicable law and/ or may harm the integrity of our Services.
      </li>
    </ol>
  </li>
</ol>

<p  style={{marginLeft:'50px',marginTop:'10px'}}>8.2 Use Restriction: You must not access the Portal if you are a person who is either barred or otherwise legally prohibited from receiving or using the Service or any Services under the laws of India.</p>

<p style={{marginLeft:'50px',marginTop:'10px'}}>8.3 You shall not host, display, upload, modify, publish, transmit, update or share any information which:</p>

<ol style={{ marginLeft: '90px',marginTop:'10px',marginTop:'10px' }}>
  <li>
    belongs to another person and to which the user does not have any right;
  </li>
  <li>
    is obscene, pornographic, paedophilic, invasive of another‘s privacy, including bodily privacy, insulting or harassing on the basis of gender, libellous, racially or ethnically objectionable, relating or encouraging money laundering or gambling, or an online game that causes user harm, or promoting enmity between different groups on the grounds of religion or caste with the intent to incite violence;
  </li>
  <li>
    is harmful to child;
  </li>
  <li>
    infringes any patent, trademark, copyright or other proprietary rights;
  </li>
  <li>
    deceives or misleads the addressee about the origin of the message or knowingly and intentionally communicates any misinformation or information which is patently false and untrue or misleading in nature or, in respect of any business of the Central Government, is identified as fake or false or misleading by such fact check unit of the Central Government as the Ministry may, by notification published in the Official Gazette, specify;
  </li>
  <li>
    impersonates another person;
  </li>
  <li>
    threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting other nation;
  </li>
  <li>
    contains software virus or any other computer code, file or program designed to interrupt, destroy or limit the functionality of any computer resource;
  </li>
  <li>
    is in the nature of an online game that is not verified as a permissible online game;
  </li>
  <li>
    is in the nature of advertisement or surrogate advertisement or promotion of an online game that is not a permissible online game, or of any online gaming intermediary offering such an online game;
  </li>
  <li>
    violates any law for the time being in force;
  </li>
  <li>
    Provides instructional information about illegal activities such as making and/ or buying illegal weapons, violating someone’s privacy, or providing or creating computer viruses;
  </li>
  <li>
    Violates any law for the time being in force;
  </li>
</ol>

<p style={{marginTop:'10px',marginLeft:'50px'}}>8.4 Services may be made available for free of cost or for a specified cost as may be decided by the Company from time to time.</p>

<p style={{marginTop:'10px',marginLeft:'50px'}}>8.5 Third-Party Fees: You may incur access or data fees from third parties (such as your internet provider or mobile carrier) in connection with your use of Services and of Portal. You are solely responsible for all such fees.</p>

<p style={{marginTop:'10px',marginLeft:'50px'}}>8.6 Updates: You may need to install updates to the Portal or related software that we introduce from time to time to use of the Portal. Services originating from the Portal may communicate with the servers from time to time to check for available updates to the Services and to the functionality of the Portal, such as bug fixes, patches, enhanced functions, missing plug-ins and new versions (collectively, "Updates"). Your use of the Services you have installed requires that you have agreed to receive such automatically requested Updates.</p>

<p style={{marginTop:'10px',marginLeft:'50px'}}>8.7 Accuracy of Information: You agree that the information entered by you while profile creation/ updation or using any of Services is true, current, complete and accurate in all respects. The Company is not responsible for any losses arising out of discrepancy in your data. You are solely responsible for maintaining confidentiality and security of your user account and for all activities that occur on or through your user account. The Company is not responsible for any losses arising out of the unauthorized use of your user account.</p>
<ol style={{ listStyle: 'none', marginLeft: '50px', paddingLeft: 0,marginTop:'20px' }}>
                <li value="9">
                    <strong>9.&nbsp;</strong><strong>LIABILITY DISCLAIMER</strong>
                    <ol style={{ listStyle: 'none'}}>
                        <li value="9.1" style={{ marginLeft: '10px',marginTop:'10px'}}>
                           9.1 TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE COMPANY BE LIABLE TO YOU FOR ANY SPECIAL, INCIDENTAL, INDIRECT, PUNITIVE OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF INFORMATION, OR ANY OTHER PECUNIARY LOSS) ARISING OUT OF THE USE OF, OR INABILITY TO USE OR ACCESS, THE PORTAL OR FOR ANY SECURITY BREACH OR ANY VIRUS, BUG, UNAUTHORIZED INTERVENTION, DEFECT, OR TECHNICAL MALFUNCTIONING OF THE PORTAL, WHETHER OR NOT FORESEEABLE OR WHETHER OR NOT THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR BASED ON ANY THEORY OF LIABILITY, INCLUDING BREACH OF CONTRACT OR WARRANTY, NEGLIGENCE OR OTHER TORTIOUS ACTION, OR ANY OTHER CLAIM ARISING OUT, OF OR IN CONNECTION WITH, YOUR USE OF, OR ACCESS TO, THE PORTAL OR THE CONTENT. FURTHER, THE COMPANY SHALL NOT BE LIABLE TO YOU FOR ANY TEMPORARY DISABLEMENT, PERMANENT DISCONTINUANCE OR MODIFICATION OF THE PORTAL BY THE COMPANY OR FOR ANY CONSEQUENCES RESULTING FROM SUCH ACTIONS.
                        </li>
                    </ol>
                </li>
            </ol>
            <p>&nbsp;</p>
            <ol style={{ listStyle: 'none', marginLeft: 0, paddingLeft: 0 }}>
                <li value="9.2" style={{ marginLeft: '50px', paddingLeft: 0 }}>
                    9.2 YOU SHALL BE SOLELY RESPONSIBLE FOR OBTAINING AND MAINTAINING ANY EQUIPMENT AND ANCILLARY SERVICES (INCLUDING THE PAYMENT OF ANY ADDITIONAL FEES THEREFORE) REQUIRED TO CONNECT TO, ACCESS OR OTHERWISE USE THE SERVICES. YOU WILL ALSO BE RESPONSIBLE FOR MAINTAINING THE SECURITY OF THE EQUIPMENT AND FOR ALL USES OF THE EQUIPMENT WITH OR WITHOUT YOUR KNOWLEDGE OR CONSENT
                </li>
            </ol>
            <p>&nbsp;</p>
            <ol style={{ listStyle: 'none', marginLeft: 0, paddingLeft: 0 }}>
                <li value="9.3" style={{ marginLeft: '50px', paddingLeft: 0 }}>
                    9.3 YOU ARE SOLELY RESPONSIBLE FOR THE CONTENT AND OTHER MATERIALS YOU TRANSMIT THROUGH THE SERVICE OR SHARE WITH OTHER USERS OR RECIPIENTS. YOU WILL NOT TRANSMIT ANY CONTENT THAT YOU DID NOT CREATE OR THAT YOU DO NOT OWN ALL RIGHT, TITLE AND INTEREST IN AND TO, INCLUDING, WITHOUT LIMITATION, ALL COPYRIGHT AND RIGHTS OF PUBLICITY CONTAINED THEREIN.
                </li>
            </ol>
            <p>&nbsp;</p>
            <ol style={{ listStyle: 'none', marginLeft: 0, paddingLeft: 0 }}>
                <li value="9.4" style={{ marginLeft: '50px', paddingLeft: 0 }}>
                    9.4 THE COMPANY AND/OR ITS RESPECTIVE AFFILIATES MAKES NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS AND ACCURACY OF THE INFORMATION, SOFTWARE AND SERVICES AND RELATED CONTENT CONTAINED ON THE PORTAL FOR ANY PURPOSE. ALL SUCH INFORMATION, SOFTWARE AND SERVICES AND RELATED CONTENT ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. THE COMPANY AND/OR ITS RESPECTIVE SUPPLIERS HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, AND SERVICES AND RELATED CONTENT, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT
                </li>
            </ol>
            <p>&nbsp;</p>
            <ol style={{ listStyle: 'none', marginLeft: 0, paddingLeft: 0 }}>
                <li value="9.5" style={{ marginLeft: '50px', paddingLeft: 0 }}>
                    9.5 YOU SPECIFICALLY AGREE THAT THE COMPANY SHALL NOT BE RESPONSIBLE FOR UNAUTHORISED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, ANY MATERIAL OR DATA SENT OR RECEIVED OR NOT SENT OR RECEIVED, OR ANY TRANSACTIONS ENTERED INTO THROUGH THE PORTAL. YOU SPECIFICALLY AGREE THAT THE COMPANY IS NOT RESPONSIBLE OR LIABLE FOR ANY THREATENING, DEFAMATORY, OBSCENE, OFFENSIVE OR ILLEGAL SERVICES OR CONDUCT OF ANY OTHER PARTY OR ANY INFRINGEMENT OF ANOTHER'S RIGHTS, INCLUDING INTELLECTUAL PROPERTY RIGHTS. YOU SPECIFICALLY AGREE THAT THE COMPANY IS NOT RESPONSIBLE FOR ANY SERVICES SENT USING AND/OR INCLUDED IN THE PORTAL BY ANY THIRD PARTY
                </li>
            </ol>
            <p>&nbsp;</p>
            <ol style={{ listStyle: 'none', marginLeft: 0, paddingLeft: 0 }}>
                <li value="9.6" style={{ marginLeft: '50px', paddingLeft: 0 }}>
                    9.6 IN NO EVENT SHALL THE COMPANY AND/OR ITS AFFILIATES BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR OR IN RELATION TO: (A) LOSS OF USE, DATA OR PROFITS ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF PORTAL; (B) ANY DELAY OR INABILITY TO USE THE PORTAL OR SERVICES; (C) THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION OR SERVICES OBTAINED THROUGH THE PORTAL; (D) OR OTHERWISE ARISING OUT OF THE USE OF THE PORTAL OR THE SERVICES OF THE COMPANY; WHETHER OR NOT ALL OF THE ABOVE LOSSES, CLAIMS OR DAMAGES ARE BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, EVEN IF THE COMPANY AND/OR ITS AFFILIATES AND/OR ITS ASSOCIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSSES, CLAIMS OR DAMAGES.
                </li>
            </ol>
        <p>&nbsp;</p>
        <ol style={{ listStyle: 'none', marginLeft: '50px', paddingLeft: 0 }}>
                <li value="10">
                  <strong>10. </strong>  <strong>TERMINATION (TEMPORARY AND PERMANENT DISABLEMENT):</strong>
                </li>
            </ol>

            <p style={{marginLeft:'50px',marginTop:"10px"}}>10.1 Unless otherwise provided in these Terms, the Services offered to you may be terminated by the Company without assigning any reasons at any time at the sole discretion of the Company. Upon termination / suspension, you shall not have the right to use or access the Portal/Services.</p>
            <p style={{marginLeft:'50px',marginTop:"10px"}}>10.2 The company reserves the right to suspend and/ or terminate your access to the Services in case of any non-adherence to these Terms and Conditions by you.</p>
            <p>&nbsp;</p>
        <ol style={{ listStyle: 'none', marginLeft: '50px', paddingLeft: 0 }}>
                <li value="11">
                    <strong>11.</strong><strong>GOVERNING LAW AND DISPUTE RESOLUTION:</strong>
                </li>
            </ol>
            <ol style={{ listStyle: 'none', marginLeft: '50px', paddingLeft: 0,marginTop:'10px' }}>
                <li value="11.1">
                    11.1 These terms and conditions are governed and construed in accordance with the laws of India. The courts in Mumbai shall have exclusive jurisdiction to hear disputes arising out of these terms.
                </li>
            </ol>

            <ol style={{ listStyle: 'none', marginLeft: '50px', paddingLeft: 0,marginTop:'10px' }}>
                <li value="12">
                  <strong>12. </strong><strong>FORCE MAJEURE:</strong>
                </li>
            </ol>
            <ol style={{ listStyle: 'none', marginLeft: '50px', paddingLeft: 0,marginTop:'10px' }}>
                <li value="12">
                    <ol style={{ listStyle: 'none', marginLeft:'10px'}}>
                        <li value="12.1" >
12.1 The Company shall be under no liability whatsoever in the event of non-availability of any portion of the Portal or subscribed content occasioned by act of God, war, disease, revolution, riot, civil commotion, strike, lockout, flood, fire, failure of any public utility, manmade disaster, infrastructure failure or any other cause whatsoever beyond the control of the Company.
                        </li>
                    </ol>
                </li>
            </ol>
            <ol style={{ listStyle: 'none', marginLeft: '50px', marginTop: '10px' }}>
                <li value="13">
                    <strong>13. </strong><strong>SEVERABILITY:</strong>
                </li>
            </ol>
            <ol style={{ listStyle: 'none', marginLeft: '20px', paddingLeft: 0 }}>
                <li value="13" style={{ marginLeft: '20px', paddingLeft: 0 }}>
                    <ol style={{ listStyle: 'none', marginLeft: '20px', marginTop: '10px' }}>
                        <li value="13.1">
                           13.1 If any of the provisions of these Terms are deemed invalid, void, or for any reason unenforceable, that part of these Terms will be deemed severable and will not affect the validity and enforceability of any remaining provisions of these Terms.
                        </li>
                    </ol>
                </li>
            </ol>
            <ol style={{ listStyle: 'none', marginLeft: 0, marginTop: '10px' ,marginLeft:"50px"}}>
                <li value="14">
                   <strong>14. </strong> <strong>CONTACT DETAILS:</strong>
                </li>
            </ol>
            <p>
                <ol style={{ listStyle: 'none', marginLeft: '50px', paddingLeft: 0 }}>
                    <li value="1" >
                        Mrs. Radha Nair, Reliance Corporate IT Park, TC-22, Thane Belapur Road, Ghansoli, Navi Mumbai 400701 
                    </li>
                </ol>
                <ol style={{marginTop:'0px',marginLeft:"45px",listStyle:"none"}}>
                <li>
                &nbsp;Email id: <strong>grievance.officer@jio.com</strong>

                </li>
                </ol>
               
            </p>
      </dd>
    </div>
  );
};
export default TermsAndConditionsComponent;
