import  React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import '../css/table.css';
import '../css/admin.css';
import img from './image/filenot.jpg';
import OtpModal from "./common/OtpModal";
import { Link, useNavigate } from 'react-router-dom';

export let da;
function Alladmin({addr,showAlert,setAlertProps}) {
  //<Details d={deta}/> 
  //console.log(deta)
  const token=localStorage.getItem("superToken")
  const [isLoading, setLoading] = useState(true);
  const navigate=useNavigate()
 console.log(addr)
  const [data,setdata]=useState([])
  const [show,setShow] = useState(false)
  const [emailId,setEmailId] = useState("")
  const [selectedProject,setSelectedProject] = useState('')
  const [otp,setOtp] = useState('')
  useEffect(() => { 
    fetch(addr,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })
    .then(res=>res.json())
    .then(data=>{
      setdata(data.data)
      setLoading(false)
      if(data.statusCode===400)
        {
          showAlert({
            title:  'Logged Out',
            message: 'You are logged out due to session timeout.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        });            navigate('/v1/jim_super_login')
        } 
    })
    .catch(err=>
      {
        setLoading(false)
      console.log(err)
    })
  
  }, [])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/projects`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
       if(data.statusCode===400)
        {
         // alert("User Logged Out")
          navigate('/v1/jim_super_login')
        } 
    })
    .catch(err=>console.log(err))
  }, [])

  /*const click=(e)=>
  {
    console.log(e);
  {<Detail deta={e}/>}
  }*/
  var a=1;
  const editForm=(e)=>
  {
    sessionStorage.setItem("project", e)
    navigate("/v1/jim/super_editform")
    
  }
  const clicks=(e)=>
  {
    
    sessionStorage.setItem("id", e.id);
    sessionStorage.setItem("user",e.user)
  }
  const deleted= async (email,project) => {
    // DELETE request using fetch inside useEffect React hook
    const token=localStorage.getItem("superToken")
    setShow(true)
    setEmailId(email)
    setSelectedProject(project)
    //const otp1 = Math.floor(100000 + Math.random() * 900000);
    //localStorage.setItem("deleteOtp", otp)

    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    const otp1 = array[0] % 900000 + 100000; 
    setOtp(otp1)

    // if(window.confirm("Confirm Once")===true)
    // {
    //   fetch(`${process.env.REACT_APP_API_URL}/admin/delete/${project}/${email}/${adminEmail}`, 
    //   { method: 'DELETE' ,
    //   withCredentials: "true",
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     }
    // })
    //   .then(() => console.log('admin deleted  successful'));

    // fetch(`${process.env.REACT_APP_API_URL}/jiouser/delete/${project}`, 
    // { method: 'DELETE',
    // withCredentials: "true",
    // headers: {
    //   Authorization: `Bearer ${token}`,
    // }, })
    // .then(() => console.log('Delete project successful'));   
    // fetch(`${process.env.REACT_APP_API_URL}/project/delete/${project}`, 
    // { method: 'DELETE',
    // withCredentials: "true",
    // headers: {
    //   Authorization: `Bearer ${token}`,
    // }, })
    // .then(() => console.log('Delete from jioprojects successful'));    
    // }
    // else{
    //   alert("Your Request Cancelled")
    // }
    // window.location.reload(false);

}

 console.log(data)
  return (
    <>
   {
    isLoading ? <div style={{display:"flex",height:"458px",width:"100%",justifyContent:"center",alignItems:"center"}}>  <div class="spinner-border" style={{width: "3rem", height: "3rem", role:"status"}}>
    <span class="sr-only">Loading...</span>
  </div> </div> :  <div>
      {data.length!==0?<div className="sc-ghsgMZ gDXBCU" data-test-id="request-list.request-list-table-v2">
      <div className="sc-dznXNo zdDVp">
      <div data-testid="request-list.request-list-table-v2--loading--container--advanced" className="css-h8zv0n">
      <table data-testid="request-list.request-list-table-v2--table" className="css-fkqt3q" style={{height: "150px",LocalDynamicTableHoverBg: "var(--ds-background-neutral-subtle-hovered, #FAFBFC)", LocalDynamicTableHighlightedBg: "var(--ds-background-selected, #DEEBFF)", LocalDynamicTableHoverHighlightedBg: "var(--ds-background-selected-hovered, #B3D4FF)", LocalDynamicTableRowFocusOutline: "var(--ds-border-focused, #4C9AFF)"}}>
      <thead data-testid="request-list.request-list-table-v2--head" className="css-1g54gxt">
      <tr>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-1xabuij" style={{fontSize: "initial"/*"--local-dynamic-table-width: 0.5%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <span>Project Name</span>
      </th>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-100m01t" aria-sort="descending" style={{fontSize: "initial"/*"--local-dynamic-table-width: 0.9%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}><button type="button" aria-roledescription="Sort button">Admin Name</button></th>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-1xabuij" style={{fontSize: "initial"/*"--local-dynamic-table-width: 1.5%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <span>Email</span>
      </th>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-1xabuij" style={{fontSize: "initial"/*"--local-dynamic-table-width: 1%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <span>Mobile</span>
      </th>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-11phftz" style={{fontSize: "initial"/*"--local-dynamic-table-width: 0.9%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <button type="button" aria-roledescription="Sort button">Role</button>
      </th>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-1xabuij" style={{fontSize: "initial"/*"--local-dynamic-table-width: 1%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <span>Action</span>
      </th></tr></thead>
      {data.filter(d=>d.mainAdmin===1).map((e)=>{ 
        return <> <tbody data-testid="request-list.request-list-table-v2--body" style={{}}>
      <tr data-testid="request-list.request-list-table-v2--row-row-HRIN-24438" className="css-unmje5">
      <td data-testid="request-list.request-list-table-v2--cell-0" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 0.5%;"*/}}>
      <div role="presentation">
      <p className="cv-exclude-from-customisation sc-ekulBa AlQuL"  href="/servicedesk/customer/portal/10/HRIN-24438" target="_self">{e===null?"null":e.project}</p></div></td>
     <td data-testid="request-list.request-list-table-v2--cell-1" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 0.9%;"*/}}>
      <p className="cv-exclude-from-customisation sc-ekulBa AlQuL"  onClick={()=>clicks(e)} to="/detail" target="_self">{e===null?"null":e.name}</p></td>
      <td data-testid="request-list.request-list-table-v2--cell-2" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 1.5%;"*/}}>
      <div className="sc-jkCMRl gjGtwC">
      <p className="cv-exclude-from-customisation sc-ekulBa AlQuL" onClick={()=>clicks(e)} to="/detail" target="_self">{e===null?"null":e.email}</p></div></td>
      <td data-testid="request-list.request-list-table-v2--cell-3" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 1%;"*/}}>
      <div role="presentation">
                            <p
                              className="cv-exclude-from-customisation sc-ekulBa AlQuL"
                              href="/servicedesk/customer/portal/10/HRIN-24438"
                              target="_self"
                            >{e===null?"null":e.mobile}</p></div></td>
      <td data-testid="request-list.request-list-table-v2--cell-4" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 0.9%;"*/}}>
      <div className="sc-jkCMRl gjGtwC" style={{color:"var(--ds-text-subtle, #6B778C)"}}>{e.user}</div></td>
      <td data-testid="request-list.request-list-table-v2--cell-4" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 0.9%;"*/}}>
      <button title="Edit Form" className="delete-button" style={{backgroundColor:"#ffffff"}} onClick={() =>editForm(e.project)}>
      <i class='far fa-edit' style={{fontSize:"24px"}}></i></button>&nbsp;
      <button class="delete-button" style={{backgroundColor:"#ffffff"}} onClick={()=>deleted(e.email,e.project)}>
  <i class="fas fa-trash-alt"></i>
</button>
      </td></tr>
      </tbody>
   
      </>
    })}
      </table></div></div></div>:
    <div className="sc-imDfJI jpQXKX"><div className="sc-hAnkBK bETBem">
      <img src={img} alt="No requests were found."/>
      <div className="sc-DNdyV RIerR">
      <h3>We couldn’t find any requests  </h3>
      <p>
    <span>To find a specific request, try searching using a different filter criteria or <a className="sc-Rmtcm DgkTO" href="/v1/jim/super_homepage" target="_self">view all requests</a>.
    </span>
    </p>
    </div>
    </div>
    </div>}
    </div>
   }
   {show?<OtpModal 
    showModal={show} 
    showAlert={showAlert} 
    setAlertProps={setAlertProps}
   // updateList={updateList} 
    email={emailId}
    selectedProject = {selectedProject}
    setotp = {otp}
     />:""}
    </>
  )

}

export default Alladmin
