import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useState,useEffect,useRef} from 'react'
import Headerhotel from './headerhotel';



function Registershotel({click}) {
    var [name,setName]=useState('')
    var [pass,setPass]=useState('')
    var [email,setEmail]=useState('')
    var [mobile,setMobile]=useState('')
    var [message,setMessage]=useState('')
    var [flag,setFlag]=useState()
    var [messages,setMessages]=useState('')
    var [flags,setFlags]=useState()
    //var fl=0
    const [error, setError] = useState('');
    const [mobileError, setMobileError] = useState('');
    var [mobileFlag,setMobileFlag]=useState()
    var [userFlag,setUserFlag]=useState()
    const navigate=useNavigate()
   const [admin,setAdmin]=useState([])
   const [project,setProject]=useState([])
  const [projects,setProjects]=useState([])
   console.log(name)
   console.log(pass)
   const token=localStorage.getItem("token")

   useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/project`)
    .then(res=>res.json())
    .then(data=>
      {setProject(data.data[0].ProjectName)
      setProjects(data.data)
      fetch(`${process.env.REACT_APP_API_URL}/findUsers/${data.data[0].ProjectName}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })
    .then(res=>res.json())
    .then(data=>
      setAdmin(data.data))
      .catch(err=>console.log(err))
   })
      .catch(err=>console.log(err))  
  }, [])
  console.log(project)


   const select=()=> {
    console.log(project)
    fetch(`${process.env.REACT_APP_API_URL}/findUsers/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      setAdmin(data.data))
      .catch(err=>console.log(err))  
  }
console.log(admin)
  
  
 
  console.log(project)
  const togglePassword = useRef("")
  const password = useRef("")
  const [e,s]=useState(0)
  const Toggle=()=>
  {
    // toggle the type attribute
    const type = password.current.type === 'password' ? 'text' : 'password';
    password.current.type=type;
    // toggle the eye slash icon
    if(e===0)
    {
      s(1)
    togglePassword.current.className='bi-eye';
    }
    else{
      s(0)
      togglePassword.current.className='bi bi-eye-slash';
    }
};
  const clicks= (y) => {
   //li.push({"name":name,"pass":pass,"email":email,"mobile":mobile,"user":user})
   //console.log(li)
   y.preventDefault()
  console.log(flag)
   if(flag===0 && flags===0 && mobileFlag===0 && userFlag===0)
   {
    var user="user"
    var status=""
    const obj={name,pass,email,mobile,user,status}
    console.log(obj)
    const res={
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(obj)
  };
  fetch(`${process.env.REACT_APP_API_URL}/users/${project}`, res)
        .then(()=>
        {
            console.log("added")
        })
       // alert("Registered Successfully")
        try {
          navigate("/v1/jim/messages"); // Omit optional second argument
        } catch (error) {
          navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
        }
      }
      else if(flag===1 && flags===0 && mobileFlag===0 && userFlag===0){
        alert("email is already registered")     
          try {
            navigate("/v1/jim/register"); // Omit optional second argument
          } catch (error) {
            navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
          }
      }
      else {
        alert("not a valid details")     
          try {
            navigate("/v1/jim/register"); // Omit optional second argument
          } catch (error) {
            navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
          }
      }

  }
  const button= () => {
    try {
      navigate(-1); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  }
  return (
    <div>
          <div /*style={{width: "100%",height: "100vh",backgroundSize: "cover",backgroundPosition: "center",backgroundImage:`url(${image})`}}/*className="body" style={{height: "700px"}}*/>
          
      <Headerhotel disp="none"/>
      
     <h1 style={{color: "#ffffff",textAlign: "center",fontFamily:"auto"}}>Registration</h1><br/>
  <div /*style={{marginLeft: "800px",
    marginTop: "-80px"}}*/ className="login">
  <span class="sc-AnqlK jNMwMZ">
    <button type="button" class="css-1undbr0" onClick={button}>
      <span class="css-j8fq0c">
        <span class="css-8xpfx5">
          <span role="img" aria-label="Back" class="css-snhnyn" /*style="--icon-primary-color: currentColor; --icon-secondary-color: var(--ds-surface, #FFFFFF);"*/>
            <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
              <path fill="#fff" fill-opacity=".01" d="M0 0h24v24H0z"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.207 5.793a1 1 0 010 1.414L8.414 11H18a1 1 0 110 2H8.414l3.793 3.793a1 1 0 11-1.415 1.414l-5.499-5.5A.997.997 0 015 12.028v-.057a.996.996 0 01.293-.678l5.499-5.5a1 1 0 011.415 0z" fill="currentColor"></path></svg></span></span>
              <span class="css-mu6jxl">Back</span></span></button></span>
              <br/>
              <br/>
      <form  onSubmit={clicks}>
      <div align="left">
 <label for="type" ><b>Select Project</b></label>&nbsp;
 <select name="type" id="type" value={project} onClick={select} onChange={(f)=>{console.log(f.target.value);setProject(f.target.value)}}>
 
 {projects.map((e,index)=>(<option  key={index} value={`${e.ProjectName}`}>{e.ProjectName}</option>))}

</select>  
<br/>
</div>
<br/>
      <label><b>User Name
      </b>
      </label>
      <input type="text" required name="username" id="Uname" placeholder="Username" onChange={(e)=>{const newUsername = e.target.value;
    if (newUsername.includes(' ')) {
      setError('Username cannot contain spaces');
      setUserFlag(1)
    } else {
      setError('');
      setName(newUsername);
      setUserFlag(0)
    }}} />
      <br/>{error && <p style={{ color: 'red' }}>{error}</p>}
       <br/>
      <label><b>Email
      </b>
      </label>
      <p style={{color:"red"}}>{message}</p>
      <input type="email" required name="email" id="Email" 
      onInvalid={F => F.target.setCustomValidity('Email should be contain @ and .')} 
      onInput={F => F.target.setCustomValidity('')}
       pattern=".+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"  placeholder="Email" onChange={(e)=>{
        console.log(admin.length)
        if(admin.length===0)
        {
          setFlag(0)
          setMessage("")
        }
        else{
        for (var key of admin) {
            if(key.email===e.target.value)
            {
              setFlag(1)
              setMessage("! email is already registered")
              console.log(key.email)
              //fl=1
              console.log(flag)
              break
            }
            else{
              setFlag(0)
              setMessage("")
            }
         }}
            /*if(flag===1)
            {
              //setMessage("! email is already registered")
            }
            else if(flag===0){
              setMessage("")
            }
            else
            {
              setMessage("")
            }*/
            setEmail(e.target.value)}}/>
      <br/>
      <br/>
      <label><b>Mobile Number
      </b>
      </label>
      <p style={{color:"red"}}>{messages}</p>
      <input type="text" required name="mobile" id="Mobile" placeholder="Mobile" onChange={(e)=>
        {
          if(e.target.value.length!==10)
          {
            setFlags(1)
            setMessages("! Not a valid Number")
           
          }
          else{
            setFlags(0)
            setMessages("")
          }
          const newMobilenumber = e.target.value;
    if (newMobilenumber.includes(' ')) {
      setMobileError('Mobile number cannot contain spaces');
      setMobileFlag(1)
    } else {
      setMobileError('');
      setMobile(newMobilenumber);
      setMobileFlag(0)
    }
          }}/>
      <br/>
      {mobileError && <p style={{ color: 'red' }}>{mobileError}</p>}<br/>
      <label><b>Password
      </b>
      </label>
      <input type="password" required name="pass" ref={password} id="Pass" placeholder="Password" onChange={(e)=>setPass(e.target.value)}/>
      <i className="bi-eye-slash"  ref={togglePassword} onClick={Toggle} id="togglePassword" style={{marginLeft: "-30px", cursor: "pointer"}}></i>
      <br/><br/>
      <input type="submit"  id="log" value="Register" /*onClick={()=>click(name,pass,email,mobile,"user")}*//> 
      </form>
  </div>
  </div>
    </div>
  )
}

export default Registershotel
