import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import HeaderAdmin from './headersuperadmin'
import CryptoJs from 'crypto-js'



function AdminManagement({showAlert,setAlertProps}) {
    var [name,setName]=useState('')
    var [pass,setPass]=useState('')
    var [email,setEmail]=useState('')
    var [mobile,setMobile]=useState('')
    var [message,setMessage]=useState('')
    var [messages,setMessages]=useState('')
    const [isLoading,setIsLoading] = useState(true)
    var [flag,setFlag]=useState()
    var [flags,setFlags]=useState()
    var [projectName,setProjectName]=useState()
    const [error, setError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [projectError, setProjectError] = useState('');
    var [mobileFlag,setMobileFlag]=useState()
    var [userFlag,setUserFlag]=useState()
    var [projectFlag,setProjectFlag]=useState()
    
    const token=localStorage.getItem("superToken")
    const adminEmail=sessionStorage.getItem("mail")
    const navigate=useNavigate()
  
   const [data,setdata]=useState(4)
   const feed=useRef()
   console.log(data)
   console.log(name)

   console.log(pass)
   var user
   const [admin,setAdmin]=useState([])
 
   useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/projects`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
      setAdmin(data.data)
      if(data.statusCode===400)
        {
         
 showAlert({
  title:  'Required',
  message: 'Please fill out this field.',
  okText: 'OK',
  callback: () => {
      console.log('Alert closed');
      setAlertProps(null)
  }
});
          navigate('/v1/jim_super_login')
        } 
      }
    )
      .catch(err=>console.log(err))  
  }, [])
  useEffect(() => {
    console.log(token)
   if(localStorage.getItem("tokenauth")==="false")
   //if(cookie.get("tokenauth")==="false")
   {
    window.location.href="/v1/jim_super_login"
   }
  }, [])
   const button= () => {
    try {
      navigate(-1); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  }
  
  const clicks= async (y) => {
    //setIsLoading(false)
    setIsLoading(false)
    console.log(isLoading)
    y.preventDefault()
    const token=localStorage.getItem("superToken")
    if(flag===0 && flags===0 && projectFlag===0 && userFlag===0 && mobileFlag===0)
    {
    /*if(document.getElementById('User').checked) {
        user="user"
      } 
      else if(document.getElementById('Support').checked) {
        user="support"
      } 
      else {
        user=""
      }*/
  
    //y.preventDefault()
    var user="admin"
    console.log(user)
    var status=""
    var mainAdmin=1
    const randomBytes = CryptoJs.lib.WordArray.random(8);
    var pass= randomBytes.toString(CryptoJs.enc.Hex).slice(0, 8);
    const encryptedPassword=CryptoJs.AES.encrypt(pass,process.env.REACT_APP_API_KEY)
    sessionStorage.setItem("projectname",projectName)
    const obj={projectName,name,pass:encryptedPassword.toString(),email,mobile,user,status,mainAdmin,adminEmail}
    const res={
      method: 'POST',
      withCredentials: 'true',
      headers: { 'Authorization':`Bearer ${token}`,'Content-Type': 'application/json'},
      body: JSON.stringify(obj)
  };
  await fetch(`${process.env.REACT_APP_API_URL}/NewTable`, res)
        .then(()=>
        {
            console.log("added")
            setIsLoading(true)
            showAlert({
              title:  'Project Registration',
              message: 'Your new project is registered successfully.',
              okText: 'OK',
              callback: () => {
                  console.log('Alert closed');
                  // Hide the alert
                  setAlertProps(null)
              }
          });
           
            navigate("/v1/jim/super_homepage"); 

        })
        //window.location.reload(false);
        /*emailjs.sendForm('service_hhccauz', 'template_z0l397w', feed.current, 'O2NrWMLvbyPo5hJ-v')
        .then((result) => {
          
        }, (error) => {
            console.log(error.text);
        });*/
        
         await fetch(`${process.env.REACT_APP_API_URL}/adminnewpassword/${email}/${name}/${projectName}`,
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json', 'Authorization':`Bearer ${token}`},
          body: JSON.stringify({encryptedPassword:encryptedPassword.toString()})
        })
          .then(console.log("email send"))
            .catch(err=>console.log(err))
        
        try {
         // Omit optional second argument
        } catch (error) {
          navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
        }
        
      }
      else if(flag===1 && flags===0 && projectFlag===0 && userFlag===0 && mobileFlag===0){
        setIsLoading(true)     
        showAlert({
          title: 'Project Registration',
          message: 'Project is already registered.',
          okText: 'OK',
          callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
      });
        try {
          navigate("/v1/jim/super_projectcreation"); // Omit optional second argument
        } catch (error) {
          navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
        }
      }
      else{
        alert("not a valid details")     
        try {
          navigate("/v1/jim/super_projectcreation"); // Omit optional second argument
        } catch (error) {
          navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
        }
      }

          console.log("++++++++++++++++++++",isLoading)


  }
  return (
    <div>
          <div  style={{height: "100vh"}}>
      
      <HeaderAdmin raiseTicket={0} registrationRequest={0} adminRequests={0} backButton={1} showAlert={showAlert} setAlertProps={setAlertProps}/>
      
     <h2 style={{color: "#ffffff",marginLeft:'10px',textAlign: "center",fontFamily:"auto"}}>Admin Registration</h2><br/>
  <div className="login" style={{paddingTop:"50px",paddingLeft:"100px"}}>
  {/*<span class="sc-AnqlK jNMwMZ">
    <button type="button" class="css-1undbr0" onClick={button}>
      <span class="css-j8fq0c">
        <span class="css-8xpfx5">
          <span role="img" aria-label="Back" class="css-snhnyn" /*style="--icon-primary-color: currentColor; --icon-secondary-color: var(--ds-surface, #FFFFFF);">
            <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
              <path fill="#fff" fill-opacity=".01" d="M0 0h24v24H0z"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.207 5.793a1 1 0 010 1.414L8.414 11H18a1 1 0 110 2H8.414l3.793 3.793a1 1 0 11-1.415 1.414l-5.499-5.5A.997.997 0 015 12.028v-.057a.996.996 0 01.293-.678l5.499-5.5a1 1 0 011.415 0z" fill="currentColor"></path></svg></span></span>
              <span class="css-mu6jxl">Back</span></span></button></span>
              <br/>
              <br/>*/}
      <form onSubmit={clicks}  ref={feed}>
      
      
  <label ><b>Project Name</b></label>
  {/*<select name="type" id="type">
  <option value="jiosignage">JioSignage</option>
  <option value="jiohotel">JioHotel</option>
  <option value="jiolight">JioLight</option>
            </select>*/}
            <p style={{color:"red"}}>{message}</p>
  <input style={{marginBottom:"00px"}} type="text" required name="projectname" id="Uname" placeholder="Project Name" 
   onChange={(e)=>{
    if(admin.length!==0)
    {
    for (var key of admin) {
        if(key.ProjectName.toLowerCase()===e.target.value.toLowerCase())
        {
          setFlag(1)
          setMessage("!project is already registered")
          console.log(key.email)
          //fl=1
          console.log(flag)
          break
        }
        else{
          setFlag(0)
          setMessage("")
        }
     }
    }
    else{
    setFlag(0)
    }
        
    const newProjectName = e.target.value;
      if (newProjectName.includes(' ')) {
        setProjectError('ProjectName cannot contain spaces');
        setProjectFlag(1)
      } else {
        setProjectError('');
        setProjectName(newProjectName);
        setProjectFlag(0)
      }}} />
<br/>{projectError && <p style={{ color: 'red' }}>{projectError}</p>}
      <br/>
      <label><b>Admin Name
      </b>
      </label>
      <input type="text"  style={{marginBottom:"00px"}} required name="username" id="Uname" autoComplete='off' placeholder="Admin Name" onChange={(e)=>{const newUsername = e.target.value;
    if (newUsername.includes(' ')) {
      setError('Username cannot contain spaces');
      setUserFlag(1)
    } else {
      setError('');
      setName(newUsername);
      setUserFlag(0)
    }}} />
      <br/>{error && <p style={{ color: 'red' }}>{error}</p>}
      <br/>
      <label><b>Email
      </b>
      </label>
      
      <input  //email validation
  style={{marginBottom:"00px"}} type="email" 
  required 
  name="email" 
  id="Email" 
  placeholder="Email"
  onKeyPress={(e) => {
    const email = e.target.value;
    const dotIndex = email.lastIndexOf('com');
    if (dotIndex !== -1 && dotIndex < email.length - 1 && /[^\w]/.test(e.key)) {
      e.preventDefault();
    }
  }}
  onChange={(e) => {
    const email = e.target.value;
    setEmail(email);
    const dotIndex = email.lastIndexOf('com');
    if (!email.includes('@') || !email.includes('.')) {
      e.target.setCustomValidity('Email should contain @ and .');
    } else if (dotIndex !== -1 && /[^\w]/.test(email.substring(dotIndex + 1))) {
      e.target.setCustomValidity('Special characters are not allowed after the dot');
    } else {
      e.target.setCustomValidity('');
    }
  }}
/>
      <br/><br/>
      <label><b>Mobile Number
      </b>
      </label>
      <p style={{color:"red"}}>{messages}</p>
    
      <input style={{marginBottom:"00px"}} type="tel" required name="mobile" id="Mobile" pattern="[0-9]{10}" value={mobile} placeholder="Mobile" onChange={(e)=>
        {
          const inputValue=e.target.value
            const numericValue = inputValue.replace(/[^0-9]/g, '');
            const limitedNumericValue = numericValue.slice(0, 10);
          if(limitedNumericValue.length!==10)
          {
            setFlags(1)
            setMessages("! Not a valid Number")
            console.log("flags"+flags)
           
          }
          else{
            setFlags(0)
            setMessages("")
            const inputValue = e.target.value;
          // Use a regular expression to remove non-numeric characters
          const numericValue = inputValue.replace(/[^0-9]/g, '');
          const limitedNumericValue = numericValue.slice(0, 10);
            
            setMobile(limitedNumericValue);
          }
          const newMobilenumber = e.target.value;
          if (newMobilenumber.includes(' ')) {
            setMobileError('Mobile number cannot contain spaces');
            setMobileFlag(1)
          } else {
            const inputValue = e.target.value;
          // Use a regular expression to remove non-numeric characters
          const numericValue = inputValue.replace(/[^0-9]/g, '');
          const limitedNumericValue = numericValue.slice(0, 10);
            setMobileError('');
            
            setMobile(limitedNumericValue);
            setMobileFlag(0)
          }
                }}/>
            <br/>
            {mobileError && <p style={{ color: 'red' }}>{mobileError}</p>}<br/>
      {/*<label><b>Password
      </b>
      </label>
      <input type="password" name="pass" id="Pass" placeholder="Password" onChange={(e)=>setPass(e.target.value)}/>
  <br/><br/>*/}
    

      <strong>
      <button style={{background:"#3399FF",border:"none",borderRadius:"2px",height:"42px"}} type="submit" name="log" id="log" value="Register"  >
        {
          isLoading===false ? <div class="spinner-border spinner-border-sm">
          <span class="sr-only">Loading...</span>
          </div> :  <span>Register</span>
        }
        </button> 
      </strong>

      </form>
    
  </div>
  </div>
  
    </div>
  )
}

export default AdminManagement
