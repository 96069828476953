import  React, { useState } from 'react'
import '../css/login.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect,useRef } from 'react';
import Headerhotel from './headerhotel';
import CryptoJS from 'crypto-js';

function Changeadminhotel({showAlert,setAlertProps}) {
    //let data = sessionStorage.getItem("change")
    const [message,setMessage]=useState([])
     const [selected,setSelected]=useState("admin")
     //const project=cookie.get("project")
  
    let {data} =useParams();
    let {project}=useParams()
    let {id} = useParams()
    console.log(id)
    
    const [pass,setPass]=useState("")
    const [password,setPassword]=useState("")
    const navigate=useNavigate()
    const togglePassword = useRef("")
    const Password = useRef("")
    const [e,s]=useState(0)
    const [passwordErr,setPasswordErr] = useState(false)
    const regex = /(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=.*[A-Z])[A-Za-z\d@$!%*#?&]{8,}$/

    useEffect(()=>{
      fetch(`${process.env.REACT_APP_API_URL}/adminfind/${project}/${data}/${id}`)
          .then((res) => 
          {
            if(res.status===500)
            {
              showAlert({
                title:  'Link Expired',
                message: 'The link you followed has expired.',
                okText: 'OK',
                callback: () => {
                    console.log('Alert closed');
                    setAlertProps(null)
                }
            });
              navigate('/v1/jim_admin_user_login')
             
            }
            return res.json()
          }
          )
          .then((data) => {
            if(data.data===0)
            { 
              showAlert({
                title:  'Link Expired',
                message: 'The link you followed has expired.',
                okText: 'OK',
                callback: () => {
                    console.log('Alert closed');
                    setAlertProps(null)
                }
              })
              navigate('/v1/jim_admin_user_login') 
            }
          })
          .catch((err) => console.log(err));
    },[])
    const handleFilter=() => {
      fetch(`${process.env.REACT_APP_API_URL}/findProjectsByAdminId/${data}/${selected}`)
          .then((res) => res.json())
          .then((data) => {
            console.log("data of admins", data[0]);
          })
          .catch((err) => console.log(err));
    }
    const Toggle=()=>
    {
      // toggle the type attribute
      const type = Password.current.type === 'password' ? 'text' : 'password';
      Password.current.type=type;
      // toggle the eye slash icon
      if(e===0)
      {
        s(1)
      togglePassword.current.className='bi-eye';
      }
      else{
        s(0)
        togglePassword.current.className='bi bi-eye-slash';
      }
  };
    const clicks=async (e)=>
    {
      e.preventDefault()
      if(!regex.test(pass)){
        setPasswordErr(true)
      }
      else{ 
     if(pass===password && pass!=="") 
     {   
      const encryptedPassword=CryptoJS.AES.encrypt(pass,process.env.REACT_APP_API_KEY)
      const obj={"pass":encryptedPassword.toString(),"project":project}
     
      const res={
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj)
    };
    const response=await fetch(`${process.env.REACT_APP_API_URL}/adminchange/${data}/${id}`, res)
          try {
           const data=await response.json()
           showAlert({
            title:  'Password changed',
            message: data.message,
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
          })
           navigate("/v1/jim_admin_user_login"); // Omit optional second argument
          } catch (error) {
            navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
          }
        }
        else{
          showAlert({
            title:  'Password Mismatch',
            message: 'The password you entered do not match. Please ensure both fields contain same password and try again.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
          })
        }
      }   
        }
        
  return (
   
      <>
    <Headerhotel disp="none"/>
    
   <h1 style={{color: "#ffffff",textAlign: "center",fontFamily:"auto"}}>Reset Password</h1><br/>
<div 
className='login'
style={{padding:'58px'}}
>

    <form onSubmit={clicks} style={{marginLeft: '37px'}}>
    {/* <div align="center" style={{ display: "flex", marginRight: "63px" }}>
            <input
              type="radio"
              id="Admin"
              checked={selected === "admin"}
              onChange={(e) => {
                setSelected(e.target.value);
              }}
              name="fav_language"
              value="admin"
              onBlur={handleFilter}
            />
              <label for="admin">Admin</label>
             {" "}
            <input
              type="radio"
              id="Support"
              checked={selected === "support"}
              onChange={(e) => {
                setSelected(e.target.value);
              }}
              name="fav_language"
              value="support"
              onBlur={handleFilter}
            />
              <label for="support">Support</label>
            <br />
          </div> */}
    <label><b>User Email
    </b>
    </label>
<input type="email"  name="username" id="Uname" placeholder="Usermail" disabled  value={data} />
    <br/><br/>
    <label><b>Password
    </b>
    </label>
    
    <input type="password" onClick={handleFilter} required ref={Password} name="password" id="Pass" placeholder="New password" onChange={(e)=>setPass(e.target.value)} onBlur={()=>{
     if(regex.test(pass)){
      setPasswordErr(false)
     }
     else{
      setPasswordErr(true)
     }
    }}/>
    <i className="bi-eye-slash"  ref={togglePassword} onClick={Toggle} id="togglePassword" style={{marginLeft: "-30px", cursor: "pointer"}}></i>
    <br />
    {
      passwordErr ? <span style={{color:"red", fontSize:"12px"}}>* Password must be at least 8 characters long, with an uppercase letter, lowercase letter, digit and special symbol</span> : null
    } 
    <br/><br/>
    <label style={{marginTop:"-12px"}}><b>Confirm Password
      </b>
      </label>
      <input type="text" required name="confirm" id="confirm" placeholder="Confirm new password" onChange={(e)=>{
            if(pass===e.target.value)
            {
              //setFlag(1)
              setMessage("Password Matched")
              console.log(pass)
              //fl=1
            }
            else{
              //setFlag(0)
              setMessage("Incorrect Password")
            }setPassword(e.target.value)}}/>
            <br/>
            {message==="Password Matched"?<p style={{color:"green"}}>{message}</p>:<p style={{color:"red"}}>{message}</p>}
            <br/>
          <br />
    
    {/* <input type="submit" name="log" id="log" value="Reset Password" /> */}
    <button
type="submit"
name="log"
id="log"
value="Reset Password"
style={{background:"#3399FF",border:"none",borderRadius:"2px",
height:"42px",marginTop:"5px"}}
/*onClick={clicks}*/>
   <span>Reset Password
  </span>
 
</button>
    </form>

    
  
</div>
</>
  )
}

export default Changeadminhotel
