import React, { useEffect } from 'react'
import '../css/login.css';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useRef } from 'react';
import Headerhotel from './headerhotel';
import cookie from 'js-cookie';

function Forgethotel({showAlert,setAlertProps}){
    var [name,setName]=useState('')
    const [selectedProject,setSelectedProject]=useState("")
    const [filteredProjectList,setFilteredProjectList]=useState([])
    const [isLoading,setIsLoading] = useState(true)

    const navigate=useNavigate()
    const [error,setError]=useState("")
    const [emailErr,setEmailErr] = useState(false)
   const [data,setdata]=useState(4)
   console.log(data)
   console.log(name)
   const feed=useRef()
  
  const button= () => {
    try {
      navigate('/v1/jim_user_login'); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  }
  const handleFilter = () => {
    fetch(`${process.env.REACT_APP_API_URL}/findProjectsById/${name}`,
        { 
          method: 'GET',
          withCredentials: 'true'
        }
        )
  
        .then((res) => res.json())
        .then((data) => {
          console.log("data of admins", data[0]);
          
          setFilteredProjectList(data.data)
          //setSelectedProject(data.data[0])
        })
        .catch((err) => console.log(err));
   
  }
  const clicks= (async (y) => {
    setIsLoading(false)

    y.preventDefault()
    if(!selectedProject){
      if(filteredProjectList.length===0)
        {
          showAlert({
            title:  'Email Not Registered',
            message: 'Looks like the email address you entered is not registered with us, Please check email and try again.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        }); 
        }
        else{
          
          showAlert({
            title:  'Select Project',
            message: 'Please select the project from list.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        }); 
        }
      setIsLoading(true)
    }else if(name==""){
      setEmailErr(true)
    }
    else{
    const result=await fetch(`${process.env.REACT_APP_API_URL}/userr/${selectedProject}/${name}`);
    const dat= await result.json();
    console.log(dat)
    const response=await fetch(`${process.env.REACT_APP_API_URL}/find/${selectedProject}/${name}/${"reset"}`);
    const data = await response.json();
    setdata(data.data);
    console.log(data.data)
    sessionStorage.setItem("change", name);
    //sessionStorage.setItem("list", JSON.stringify(list));
    
   
    if(data.data===1)
    {
      //setIsLoading(true)

      console.log(data+"1")
      /*emailjs.sendForm('service_hhccauz', 'template_z0l397w', feed.current, 'BcNE7hTZSQgnOCqn8')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });*/
          await fetch(`${process.env.REACT_APP_API_URL}/resetpassword`,
          {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mail: name,
            name: dat.data[0].name,
            project: selectedProject
          })})
          .then(res=>console.log(res))
          .catch(err=>console.log(err))
    try {
        
        navigate("/v1/jim/message");
        
      } catch (error) {
        navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
      }
    }
    else if(data.data===0)
    {
    try {
      setError("! Incorrect Email")
      setIsLoading(true)
        navigate("/v1/jim/forget");
         // Omit optional second argument
       //setError("User Not Found...!")

      } catch (error) {
        navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
      }
    }
  }
  });
console.log("selected Project is here",selectedProject)
  return (
  <>
  {/*<div className="body" style={{height: "630px"}}>
  <Header disp="none"/>
    
    <h2 style={{color: "#ffffff",textAlign: "center"}}>Forgot Password</h2><br/>
<div align="center">
<h6 className="login" >

<form>
<h5>Enter User Mail:</h5>
<br/><input  id="uname" name="uname" onChange={(e)=>setName(e.target.value)}/>
<br/><br/>
<input type="submit" name="log" id="log" value="Submit" onClick={clicks}/>
</form>
</h6>
</div>
  </div>*/}
  <div /*className="body" style={{height: "630px"}}*/>
      
      <Headerhotel disp="none"/>
      
     <h1 style={{color: "#ffffff",textAlign: "center",fontFamily:"auto"}}>Forgot Password</h1><br/>
     <div
className="login"
style={{width:"460px",height:"450px",paddingBottom:"60px",paddingTop:"5px",height:"auto"}}>
             
<br/>
             
<br/>
     
<form
onSubmit={clicks}
ref={feed}>
     
<p
class="b"
style={{paddingBottom:"10px",paddingTop:"00px",paddingBottom:"35px"}}>Enter
 your registered email address to get link to reset password.</p>
     
{<label><b
style={{textAlign:
"center",fontFamily:"inherit",paddingTop:"00px"}}>Email
 Address</b></label>}
     
{/* <h1 style={{textAlign: "center",fontFamily:"Arial"}}>Enter
 Email Address</h1><br/> */}
     
<input
type="email"
name="username"
  id="Uname"
placeholder="Enter Email"
onChange={(e)=>
{ setEmailErr(false);setName(e.target.value)}} required onBlur={handleFilter}/>
<br/>
{
        emailErr ? <span style={{color:"red"}}>Please Enter User Email</span> : null
      }
<div align="left">
            
            <>
              <br />
              <label for="type">
                <b>Select Project</b>
              </label>
              
              <select name="type" id="type" value={selectedProject} onChange={(e)=>{
                e.preventDefault()
                setSelectedProject(e.target.value)
                }}>
                  <option value="">Select Project</option>
                {filteredProjectList.map((el) => (
                  <option key={el} value={el} >
                    {el}
                  </option>
                ))}
              </select>
            </>
         
        </div>
        <br/>

<strong>
<p style={{color:"red"}}>
        {error}</p></strong>    
{/*<a style={{float: "left"}} href="/register">Register
 for new User</a>*/}  
 <br></br> 
     
<div>
     
     
<span
class="sc-AnqlK
 jNMwMZ">
   
<button
type="button"
class="css-1undbr0"
textAlign="center"
onClick={button}>
       
<span
class="css-8xpfx5">
         
<span
role="img"
aria-label="Back"
class="css-snhnyn"
/*style="--icon-primary-color: currentColor; --icon-secondary-color: var(--ds-surface, #FFFFFF);"*/>
           
<svg
width="00"
height="65"
viewBox="0
 0 24 24"
role="presentation">
             
<path
fill="#fff"
fill-opacity=".01"
d="M0
 0h24v24H0z"></path>
             
<path
fill-rule="evenodd"
clip-rule="evenodd"
d="M12.207
 5.793a1 1 0 010 1.414L8.414 11H18a1 1 0 110 2H8.414l3.793 3.793a1 1 0 11-1.415 1.414l-5.499-5.5A.997.997 0 015 12.028v-.057a.996.996 0 01.293-.678l5.499-5.5a1 1 0 011.415 0z"
fill="currentColor"></path></svg></span></span>
             
<span
class="css-mu6jxl"
style={{marginLeft:"100px"}}>Back
 to log in</span>
 </button></span></div>
     
     
<strong>
     
<button
type="submit"
name="log"
id="log"
value="Send"
style={{background:"#3399FF",border:"none",borderRadius:"2px",
height:"42px",marginTop:"20px"}}
/*onClick={clicks}*/>
  {
    !isLoading ? <div class="spinner-border spinner-border-sm">
    <span class="sr-only">Loading...</span>
    </div> : <span>Send Reset Link
  </span>
  }
</button>
    
</strong>
     
</form>
   
</div>
  </div>
</>
  )
}

export default Forgethotel
