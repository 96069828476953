import  React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import '../css/table.css';
import '../css/admin.css';
import img from './image/filenot.jpg';


import { useDispatch, useSelector } from 'react-redux';
import {  updateData } from '../feature/slice';
import { Link, useNavigate } from 'react-router-dom';


function Alluserhotel({addr}) {
  //<Details d={deta}/>
  //console.log(deta)
  //const storedData = JSON.parse(localStorage.getItem('myData'));
  //let nam = sessionStorage.getItem("key");
 console.log(addr)
  const [data,setdata]=useState([])
  console.log(data)
  useEffect(() => {
  setdata(addr)
  })
  /*const click=(e)=>
  {
    console.log(e);
  {<Detail deta={e}/>}
  }*/
  const [sortFlag, setSortFlag] = useState(0);
  const descending = () => {
    if (sortFlag === 0) {
     data.reverse()
      setSortFlag(1);
      setdata(data);
    } else if (sortFlag === 1) {
      data.reverse()
      setdata(data);
      setSortFlag(0);
    }
  };
  const dispatch=useDispatch()
  const clicks=(e)=>
  {
    const parsedData=e
    dispatch(updateData(parsedData))
    //sessionStorage.setItem("id",e);
  }
 console.log(data)
 console.log(addr)
  return (
   
    <div>
      {data.length!==0?<div className="sc-ghsgMZ gDXBCU" data-test-id="request-list.request-list-table-v2">
      <div className="sc-dznXNo zdDVp">
      <div data-testid="request-list.request-list-table-v2--loading--container--advanced" className="css-h8zv0n">
      <table data-testid="request-list.request-list-table-v2--table" className="css-fkqt3q" style={{height: "150px",LocalDynamicTableHoverBg: "var(--ds-background-neutral-subtle-hovered, #FAFBFC)", LocalDynamicTableHighlightedBg: "var(--ds-background-selected, #DEEBFF)", LocalDynamicTableHoverHighlightedBg: "var(--ds-background-selected-hovered, #B3D4FF)", LocalDynamicTableRowFocusOutline: "var(--ds-border-focused, #4C9AFF)"}}>
      <thead data-testid="request-list.request-list-table-v2--head" className="css-1g54gxt">
      <tr>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-100m01t" aria-sort="descending" style={{fontSize: "initial"/*"--local-dynamic-table-width: 0.9%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <span>
                        Reference Id
                        <button
                          style={{
                            border: "aliceblue",
                            backgroundColor: "white",
                          }}
                          onClick={descending}
                        >
                          {sortFlag===0?<svg height="20" viewBox="0 0 48 48" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z"/><path d="M0-.75h48v48h-48z" fill="none"/></svg>
                          :<svg height="20" viewBox="0 0 48 48" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M14.83 30.83l9.17-9.17 9.17 9.17 2.83-2.83-12-12-12 12z"/><path d="M0 0h48v48h-48z" fill="none"/></svg>}
                        </button>
                      </span>
      </th>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-1xabuij" style={{fontSize: "initial"/*"--local-dynamic-table-width: 0.5%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <span>Type</span>
      </th>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-1xabuij" style={{fontSize: "initial"/*"--local-dynamic-table-width: 1.5%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <span>Summary</span>
      </th>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-1xabuij" style={{fontSize: "initial"/*"--local-dynamic-table-width: 1%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <span>Status</span>
      </th>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-11phftz" style={{fontSize: "initial"/*"--local-dynamic-table-width: 0.9%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <button type="button" aria-roledescription="Sort button">Requester</button>
      </th>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-1xabuij" style={{fontSize: "initial"/*"--local-dynamic-table-width: 1%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <span>Action</span>
      </th>
      </tr></thead>
      {data.map((e)=>{ 
        return <> <tbody data-testid="request-list.request-list-table-v2--body" style={{}}>
      <tr data-testid="request-list.request-list-table-v2--row-row-HRIN-24438" className="css-unmje5">
      <td data-testid="request-list.request-list-table-v2--cell-1" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 0.9%;"*/}}>
      <Link className="cv-exclude-from-customisation sc-ekulBa AlQuL"  onClick={()=>clicks(e.id)} to="/v1/jim/userdetail" target="_self"
      style={{color:"blue",textDecoration:"underline"}}
      // onMouseOver={(e) => {
      //   e.currentTarget.style.color = 'blue';
      //   e.currentTarget.style.textDecoration = 'underline';
      //     }}
      // onMouseOut={(e) => {
      //    e.currentTarget.style.color = 'grey';
      //    e.currentTarget.style.textDecoration = 'none';
      //   }}
      >{e.id}</Link></td>
      <td data-testid="request-list.request-list-table-v2--cell-0" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 0.5%;"*/}}>
      <div role="presentation">
      <p className="cv-exclude-from-customisation sc-ekulBa AlQuL"  href="/servicedesk/customer/portal/10/HRIN-24438" target="_self">{e.opt}</p></div></td>
      <td data-testid="request-list.request-list-table-v2--cell-2" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 1.5%;"*/}}>
      <div className="sc-jkCMRl gjGtwC">
      <Link className="cv-exclude-from-customisation sc-ekulBa AlQuL" onClick={()=>clicks(e.id)} to="/v1/jim/userdetail" target="_self"
      onMouseOver={(e) => {
        e.currentTarget.style.color = 'blue';
        e.currentTarget.style.textDecoration = 'underline';
          }}
      onMouseOut={(e) => {
         e.currentTarget.style.color = 'grey';
         e.currentTarget.style.textDecoration = 'none';
        }}
      >{e.summary}</Link></div></td>
      <td data-testid="request-list.request-list-table-v2--cell-3" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 1%;"*/}}>
      <div role="presentation"><div data-test-id="request-details.status-lozenge">
      {e.status==="In Progress"?<span className={`css-cztx6i-InProgress`} style={{maxWidth: "100%"}}>
      <span className={`css-1et8jlg-InProgress`} style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}}>{e.status}</span></span>:
      <span className={`css-cztx6i-${e.status}`} style={{maxWidth: "100%"}}>
      <span className={`css-1et8jlg-${(e.status)}`} style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}}>{e.status}</span></span>}
    </div></div></td>
      <td data-testid="request-list.request-list-table-v2--cell-4" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 0.9%;"*/}}>
      <div className="sc-jkCMRl gjGtwC">{e.user}</div></td>
      <td data-testid="request-list.request-list-table-v2--cell-1" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 0.9%;"*/}}>
      {(() => {
      var f
       if(e.status==="opened") 
       {
        return (<Link className="cv-exclude-from-customisation sc-ekulBa AlQuL"  onClick={()=>clicks(e.id)} to="/v1/jim/user_editform" target="_self"><button className="css-370xbg">Edit</button></Link>)
       }
       else if(e.status==="Resolved")
       {     
         return (<Link className="cv-exclude-from-customisation sc-ekulBa AlQuL"  onClick={()=>clicks(e.id)} to="/v1/jim/feedback" target="_self"><button className="css-370xbg">Feedback</button></Link>
         )
       }
       else{
        return(
        <button className="css-370xbg1" disabled>Edit</button>
        )
       }
      
      })()}
      {/*e.status==="opened"?<Link className="cv-exclude-from-customisation sc-ekulBa AlQuL"  onClick={()=>clicks(e.id)} to="/edit" target="_self"><button className="css-370xbg">Edit</button></Link>:"you can't edit"*/}</td> 
      </tr>
      </tbody>
      
      </>
    })}
      </table></div></div></div>:
    <div className="sc-imDfJI jpQXKX"><div className="sc-hAnkBK bETBem">
      <img src={img} alt="No requests were found."/>
      <div className="sc-DNdyV RIerR">
      <h3>We couldn’t find any requests  </h3>
      <p>
    <span>To find a specific request, try searching using a different filter criteria or <a className="sc-Rmtcm DgkTO" href="/v1/jim/user_homepage" target="_self">view all requests</a>.
    </span>
    </p>
    </div>
    </div>
    </div>}

    </div>
  )

}

export default Alluserhotel
