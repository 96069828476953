import React, { useEffect } from 'react'
import '../css/home.css';
import '../css/cmt.css';
import { Link } from 'react-router-dom';
import imge from './image/jio.jpg';
import create from './image/createicon.jpg';
import { useState } from 'react';
import axios from 'axios';

export let li;

function Homehotel() {
 
    const [value,setValue]=useState("")

   useEffect(()=>
   {
axios.get('/hhh.html')
.then((response)=>
{
    setValue(response.data )
    console.log(response.data)
})
.catch((error)=>{
   console.log('error',error)
})
   
   },[]);
    //console.log(li)
  return (
    <div /*className="body" style={{backgroundImage:"url('https://sit2.jiosignage.jio.com/packs/media/images/login-graphic-303bb53da945f07412e83bde387aed2d.svg')"}}*/>
         {/*<Header m={70} serv="Jio Service Portal" disp="none"/>*/}
         <svg xmlns="http://www.w3.org/2000/svg" width="1270" height="230" viewBox="0 0 1270 230" fill="none">
            <p>Jio</p>
<path d="M1440 0H0V208C0 208 343.5 117.5 726.5 206C1109.5 294.5 1440 161.5 1440 161.5V0Z" fill="url(#paint0_linear)"/>
<defs> 
<linearGradient id="paint0_linear" x1="1216" y1="151" x2="316" y2="30.4999" gradientUnits="userSpaceOnUse">
<stop stop-color="#3F82DB"/>
<stop offset="1" stop-color="#0747A6"/>
</linearGradient>
</defs>
</svg>
<div style={{marginBottom: "170px",marginTop: "-210px"}}>
<a className="sc-hzDkRC cv-exclude-from-customisation gNaqGG" style={{transform:'none'}} href="/v1/jim/homepage" target="_self"><div className="sc-gZMcBi kcCKhl">
      {/*<img className="sc-iwsKbI PlFKx" src={img} alt="Jio Services Portal Home"/>*/}
      <img className="sc-iwsKbI PlFKx" src={imge} alt="Jio Services Portal Home"/>
      &nbsp; 
      <strong align="center" style={{color: "black",
    fontSize: "x-large"}}>Jio Service Support</strong></div></a> 
</div>     
<div style={{display:"flex",height: "350px",width: "750px"}}>
<div style={{width:"800px"}} dangerouslySetInnerHTML={{__html:value}}></div>
        <div data-test-id="help-center-content" >
            <div style={{height: "330px",
    width: "300px"}} className="sc-iNhVCk kGJshR">
           
                <div className="sc-jdeSqf ljcORE"></div>
                <div style={{height: "330px"}}className="sc-jdeSqf ljcORE">
                
                <div style={{paddingBottom: "0px",height: "338px"}} className="sidebar-closed sc-gRnDUn sAatJ">  
                    <section style={{marginTop: "-10px"}} className="sc-iYUSvU jlVbwK">  
                        {/*<h2 className="sc-fcdeBU jnQxTY" data-test-id="primary-heading">
                            <span></span>
                            </h2>*/}
                            {/*<Marquee style={{fontSize:"20px"}}speed={100}>Welcome to Jio Service Portal</Marquee>*/}
        <div className="sc-cmjSyW fUWJNj" data-test-id="help-center-portal-cards">
        <div className="sidebar-closed sc-sdtwF cJOWBq" data-test-id="portal-cards-list-item">
            <Link href="" className="sc-VJcYb fPTfed" data-test-id="portal:Employee Services" to="/v1/jim_user_login" target="_self" >  
            <div className="sc-hmXxxW ewjKEg">
        <div className="sc-fONwsr ldzcRq">
            <div className="sc-kUaPvJ sUzaF" style={{color: "#37474f",fontWeight: "var(--ds-font-weight-semibold,600)"}} title="Employee Services">
                <img src={create}/>Create Requests</div>
            <div className="sc-giadOv cYVick" title="<p>Welcome! You can raise a Employee Services using the options provided.</p>">
                <p style={{fontSize:"15px"}}>Welcome! You can raise a requests using the options provided.</p>
                </div></div>
                </div>
                </Link>
                </div>
                </div>
                {/*<div className="sc-cmjSyW fUWJNj" data-test-id="help-center-portal-cards">
        <div className="sidebar-closed sc-sdtwF cJOWBq" data-test-id="portal-cards-list-item">
            <Link className="sc-VJcYb fPTfed" data-test-id="portal:Employee Services"  to="/logins" target="_self" >  
            <div className="sc-hmXxxW ewjKEg">
        <div className="sc-fONwsr ldzcRq">
            <div className="sc-kUaPvJ sUzaF" style={{color: "#37474f",fontWeight:"var(--ds-font-weight-semibold,600)"}} title="Employee Services">
            <img src={list}/>Administrator</div>
            <div className="sc-giadOv cYVick" title="<p>Welcome! You can raise a request for Employee Services using the options provided.</p>">
                <p style={{fontSize:"15px"}}>Welcome Administrator! Empowering your Digital Realm.</p>
                </div></div>
                </div>
                </Link>
                </div>
                </div>*/}
                </section></div></div></div>
                </div>
                </div>
               
    </div>
  )
}

export default Homehotel
