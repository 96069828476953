import React from "react";
import { Link } from "react-router-dom";
import footer from '../css/footer.css'



const Footer = ({margin}) => {
  
// const navigate=useLocation();
// console.log("navigate",navigate)

//   const handleTermsClick = () => {
   
//     navigate.push('/terms')
//   };

  return (
    <>
      <div>
        <div className="sc-bMVAic eJvVfu"></div>
        <footer style={{marginTop:margin}}>
          <div className="left-content">
            <p>Copyright <span style={{fontSize:"12px"}}>©</span> 2024 Reliance Jio Infocomm Ltd. All rights reserved</p>
          </div>
          <div className="right-content">
            <p>
              <Link target="_blank" to="/terms">Terms & Conditions</Link>
              <Link target="_blank" to='/privacypolicy' style={{marginLeft:"10px"}}>Privacy Policy</Link>
              <Link to='#' style={{marginLeft:"10px"}}>Verison: 3.0</Link>
          </p>
           
          </div>
        </footer>
      </div>
      
    </>
  );
};

export default Footer;
