import "../css/details.css";
import "../css/cmd.css";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill/lib";
import Headerhotel from "./headerhotel";
import { useSelector } from "react-redux";
import Footer from "./Footer";
import {jwtDecode} from 'jwt-decode'
import CryptoJS from 'crypto-js';
import 'react-quill/dist/quill.snow.css';
import VideoModal from "./common/VideoModal";
import { fileTypeFromBuffer } from "file-type";

function Udetailshotel({showAlert,setAlertProps}) {

  const nam = useSelector((state) => state.userData);
  const m = useSelector((state) => state.yourData);
  const mail=useSelector((state)=>state.userMail)
  console.log(m, nam, mail);
  const [flag,setFlag] = useState(0)
  const [selectedFile, setSelectedFile] = useState([]);
  const [fileName,setFileName] = useState('')
  const fileInputRef = useRef(null); 
  const ref = useRef("");


  const token = localStorage.getItem("token");
  //const project=cookie.get("project")
  const [project,setProject]=useState("")
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoTitle,setVideoTitle]=useState('')
  const [videoLink,setVideoLink]=useState('')
  const [showVideo,setShowVideo]=useState(false)
  const [uploadErr,setUploadErr]=useState(false)
  const [fileUploadErrMessage,setFileUploadErrorMessage]=useState('')

 useEffect(() => {
  try{
const decodedToken = jwtDecode(token);
setProject(decodedToken.project)
  }
  catch(error)
  {
    navigate("/v1/jim_user_login")
  }
},[])
function hasHtmlElements(e) {
  const plainString=document.querySelector(".ql-editor p").innerHTML
  console.log(document.querySelector(".ql-editor p").innerHTML)
  if(plainString.includes("&lt;") || plainString.includes("&gt;"))
    {
      return true
    }
}

function decryptMessage(encryptedHexWithKey) {
  console.log(encryptedHexWithKey)
  // Extract the key from the encrypted string
  const key = encryptedHexWithKey.substring(0,8);
  console.log(key)
  // Remove the key from the encrypted string
  const encryptedHex = encryptedHexWithKey.substring(8);
console.log(encryptedHex)
  // Convert the key to a WordArray
  const keyWordArray = CryptoJS.enc.Hex.parse(key);
console.log(keyWordArray)
  // Decrypt the message
  const decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Hex.parse(encryptedHex),
    },
    keyWordArray,
    {
      iv: CryptoJS.enc.Hex.parse('e5f6a7b8'), // Use the same IV for decryption
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  console.log(decrypted)
  const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
  console.log(decryptedString)
  return decryptedString;
}
  const [comment,setComment]=useState([])
  const [review,setReview]=useState([])
  
  const [supportName,setSupportName]=useState('')
  const [data,setdata]=useState([])
  const [file,setFile]=useState('')

  
  const [chat, setChat] = useState([]);
  
  const [descript, setDescript] = useState("");
  
  const [div, setDiv] = useState("jhHSQy");
  const [styles, setStyles] = useState("puqikf");
  const [show, setShow] = useState("Show");
  const [color1, setColor1] = useState("#ccc");
  const [color2, setColor2] = useState("#ccc");
  const [color3, setColor3] = useState("#ccc");
  const [color4, setColor4] = useState("#ccc");
  const [color5, setColor5] = useState("#ccc");



  // checks if feedback present and returns comment and review
  useEffect( () => {
    fetch(`${process.env.REACT_APP_API_URL}/jios/${project}/${m}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
        if(data.statusCode===400)
        {
          navigate('/v1/jim_user_login')
        }
        console.log(data.statusCode);
        setComment(data.data[0].comment);
        setReview(data.data[0].review);
      })
      .catch(err=>console.log(err))
      console.log(ref.current)
    },[project])
    
    // fetches data(proj. table) 
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/userTicket/${project}/${m}`, {
        method: "GET",
        withCredentials: "true",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.json())
      .then((data) => {
        setdata(data.data);
        setSupportName(data.data.admin)
        const fileName=data.data.file
        const extension=fileName.substring(fileName.lastIndexOf("."))
        const fileWithoutExtensionId=fileName.substring(9,fileName.lastIndexOf("."))
        console.log(fileWithoutExtensionId)
        const fileId=fileName.substring(0,9)
        console.log(fileId)
        console.log(extension)
        const decryptedFile=decryptMessage(fileWithoutExtensionId)
        console.log(decryptedFile)
        setFile(fileId+decryptedFile+extension)
        if (data.statusCode === 400) {
          showAlert({
            title:  'Logged Out',
            message: 'You are logged out due to session timeout.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        });           navigate("/v1/jim_user_login");
        }
      })
      .catch((err) => console.log(err));
    }, [project]);
    
    // fetches chat data
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/chat/${project}/${m}`, {
        method: "GET",
        withCredentials: "true",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.json())
      .then((data) => {
        setChat(data.data);
        if (data.statusCode === 400) {
          //alert("User Logged Out")
          navigate("/v1/jim_user_login");
        }
      })
      .catch((err) => console.log(err));
    }, [flag,project]);

    // useEffect(() => {
    //   setChat((chat) => {
    //     return chat.map((chatItem) => {
    //       console.log('chatItem.date:', chatItem.date);
    //       let date = new Date(chatItem.date);
    //       console.log('date:', date);
    //       let formattedDate = date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' }).slice(0, 10);
    //       console.log('formattedDate:', formattedDate);
    //       let parts = formattedDate.split('/');
    //       formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    //       return { ...chatItem, date: formattedDate };
    //     });
    //   });
    // }, [chat])

    // checks if user is logged in 
    useEffect(() => {
      // console.log(token);
      // console.log(chat);
      // console.log(data);
      // console.log(comment);
      // console.log(m);
      if(localStorage.getItem("tokenauth")==="false")
      //if (cookie.get("tokenauth") === "false") 
      {
        window.location.href = "/v1/jim_user_login";
      }
    }, []);
    
    
    // sets color of stars
    const cl1 = () => {
      setColor1("#ffc700");
    };
    const cl2 = () => {
      cl1();
      setColor2("#ffc700");
    };
    const cl3 = () => {
      cl1();
      cl2();
      setColor3("#ffc700");
    };
    const cl4 = () => {
      cl1();
      cl2();
      cl3();
      setColor4("#ffc700");
    };
    const cl5 = () => {
      cl1();
      cl2();
      cl3();
      cl4();
      setColor5("#ffc700");
    };
    const [c, d] = useState(0);
    const view = () => {
      if (c === 0) {
        d(1);
        setDiv("cqxAqq");
        setShow("Hide");
        setStyles("4oxim9");
        if (review === 1) {
          cl1();
        } 
        else if (review === 2) {
          cl2();
        } else if (review === 3) {
          cl3();
        } else if (review === 4) {
          cl4();
        } else if (review === 5) {
          cl5();
        }
      } else {
        d(0);
        setShow("Show");
        setDiv("jhHSQy");
        setStyles("puqikf");
      }
    };
    const navigate = useNavigate();
    // checks if desc empty(which not possible since it is a required field) and sends data to backend
    const click = (y) => {
      const token = localStorage.getItem("token");
      console.log(token);
      const plainString = descript.replace(/<[^>]+>/g, '');
      const noSpace = plainString.split('')
      //const noSpace=/^[^-\s][a-zA-Z0-9_\s-]+$/
      console.log(plainString,"descript")
      if (plainString === "") {
        showAlert({
          title:  'Required',
          message: 'Please fill out this field.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });
      }
      else if(noSpace[0]==" " && noSpace[0]!="")
      {
        showAlert({
          title:  'Invalid Input',
          message: 'Please enter text first.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });
      }
      else {
        const htmlFlag=hasHtmlElements(descript)
        if(htmlFlag===true)
          {
            showAlert({
              title:  'Input Error',
              message: 'Special characters are not allowed.',
              okText: 'OK',
              callback: () => {
                  console.log('Alert closed');
                  setAlertProps(null)
              }
          });
          }
          else
          {
        const user = nam;
        const status = data.status
        const opt = data.opt;
        const summary = data.summary;
        const description = descript;
        const privateChat=0
        const obj = { user, opt,privateChat, summary, description, status };
        console.log(obj);
        const res = {
          method: "PUT",
          withCredentials: "true",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        };
        fetch(`${process.env.REACT_APP_API_URL}/chats/${project}/${m}`, res).then(
          async (data) => {
            console.log("added");
            const formData = new FormData();
            console.log('selected files===>',selectedFile)
            for(let i=0;i<selectedFile.length;i++)
            {
              console.log(selectedFile[i])
            formData.append("file",selectedFile[i]);
            }
            // for (var key of formData.entries()) {
            //   console.log(key[0] + ', ' + key[1])
            // }
            //formData.append("file",selectedFile);
            console.log('token===>', token)
            console.log(formData)
            const contentType = `multipart/form-data`;
            const result =await fetch(`${process.env.REACT_APP_API_URL}/uploadchatfile/${project}`,{
              method: 'Post',
              withCredentials: 'true',
              headers:
              {
                'Authorization':`Bearer ${token}`,
                //'Content-Type': contentType
              },
              body: formData,
          })
    
          setSelectedFile([])
           fileInputRef.current.value = ''; 
            //setFlag(!flag)
            setFlag(flag+1)
          }
          );
          try {
            navigate("/v1/jim/userdetail");  // Omit optional second argument
            setDescript("")
            setUploadErr(false)
            setFileUploadErrorMessage("")
            //window.location.reload(false);
          } catch (error) {
            navigate("/error", { state: { message: "Failed to add missing description" } }); // Pass optional second argument
          }
        }
      }
      };
      
      // reopen state of ticket
      const reopen = async () => {
        const token = localStorage.getItem("token");
        const status = "opened";
        const id = data.id;
        const obj = { status };
        console.log(obj);
        const res = {
          method: "PUT",
          withCredentials: "true",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        };
        fetch(`${process.env.REACT_APP_API_URL}/reopen/${project}/${m}`, res).then(
          () => {
            console.log("updated ticket status to open(reopen)");
          }
          )
          const payload = {
            method: "DELETE",
            withCredentials: "true",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
          const result = await fetch(`${process.env.REACT_APP_API_URL}/jio/${project}/${m}`,payload )
          .then(() => {
            console.log("Deleted Feedback");
          })
          fetch(`${process.env.REACT_APP_API_URL}/reopen`,
          {
              method: 'POST',
              withCredentials: 'true',
              headers: { 'Authorization':`Bearer ${token}`,'Content-Type': 'application/json' },
              body: JSON.stringify( {mail:mail,name:nam,id:m})     
            })
            .then(console.log("email send to user"))
            .catch(err=>console.log(err))

            await fetch(`${process.env.REACT_APP_API_URL}/adminEmail/${project}/${supportName}`,{
              method: 'GET',
              withCredentials: 'true',
              headers:
              {
                'Authorization':`Bearer ${token}`,
              },
            }
            )
            .then(res=>res.json())
            .then(data=>
              {fetch(`${process.env.REACT_APP_API_URL}/reopenSupport`,
              {
                method: 'POST',
                withCredentials: 'true',
                headers: { 'Authorization':`Bearer ${token}`,'Content-Type': 'application/json' },
                body: JSON.stringify( {mail:data.data[0]?.email,name:supportName,id:m,userName:nam}) 
              })
              .then(console.log("email send to previously assigned support"))
              .catch(err=>console.log(err))
            })
            await fetch(`${process.env.REACT_APP_API_URL}/admin/${project}`,
            {
              method: 'GET',
              withCredentials: 'true',
              headers:
              {
                'Authorization':`Bearer ${token}`,
              },
            })
            .then(res=>res.json())
            .then(data=>
              {
                let emailString = "";
                let nameString = ""
                data.data.forEach((item, index) => {
                  emailString += item.email;
                  if (index < data.data.length - 1) {
                    emailString += ";";
                  }
                  nameString += item.name;
                  if (index < data.data.length - 1) {
                    nameString += ",";
                  }
                });
                
                //data.data.map(element=>{
                  fetch(`${process.env.REACT_APP_API_URL}/reopenAdmin`,
                  {
                    method: 'POST',
                    withCredentials: 'true',
                    headers: { 'Authorization':`Bearer ${token}`,'Content-Type': 'application/json' },
                    body: JSON.stringify( {mail:emailString,name:nameString,id:id,userName:nam,userMail:mail,supportName:supportName}) 
                  })
                  .then(console.log("email send to admin for reopening"))
                  .catch(err=>console.log(err))
                //})
              }
            )
            try {
              navigate("/v1/jim/userdetail"); // Omit optional second argument
              window.location.reload(false);
            } catch (error) {
              navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
            }
          };
          
          
          const downloadFile = () => {
          const token = localStorage.getItem("token");
          console.log("hello here is file name", file)
          fetch(`${process.env.REACT_APP_API_URL}/download/${project}/${file}`, {
            method: "GET",
            withCredentials: "true",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => response.blob())
          .then((blob) => {
            if (blob) {
              const url = window.URL.createObjectURL(blob);
              console.log("Here is type",blob.type)
              console.log('Video URL:', url);
              if(blob.type == "video/mp4"){
             let videoSrc = url
              console.log("here is path",videoSrc)
                setVideoSrc(videoSrc)
                setVideoTitle('Video : '+file.substring(9))
                setVideoLink(videoSrc)
                setShowVideo(true)
                window.document.close()
              
              }else{
              window.open(url, '_blank');
              const a= document.createElement("a");
              a.href = url;
              a.download = file.substring(9); // Specify the desired file name and extension
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              }
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        };
        if (data.status === "Resolved") {
          const current = new Date();
          sessionStorage.setItem(
            m,
            `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
          );
        }

        // map chat.date to activityDate

        // useEffect(() => {
        //   setChat((prevChat) => {
        //     return prevChat.map((e) => {
        //       let date = new Date(e.date);
        //       let formattedDate =
        //         date.getFullYear() +
        //         "-" +
        //         String(date.getMonth() + 1).padStart(2, "0") +
        //         "-" +
        //         String(date.getDate()).padStart(2, "0");
        //       return { ...e, activityDate: formattedDate };
        //     });
        //   });
        // }, [chat]);
        // useEffect(() => {
        //   setChat((chat) => {
        //     return chat.map((chatItem) => {
        //       if (chatItem.date) {
        //         let date = new Date(chatItem.date);
        //         let formattedDate = date.toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata' }).slice(0, 10);
        //         return { ...chatItem, email: { ...chatItem, date: formattedDate } };
        //       }
        //       return chatItem;
        //     });
        //   });
        // }, [chat]);
        let date = new Date(data.date).toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit'});
        console.log(data.date);
        console.log(date);
        let parts = date.split('/');
        let formattedDateAbove = `${parts[0]}-${parts[1]}-${parts[2]}`;
        console.log(formattedDateAbove);

        // const handleFileChange = (event) => {
        //   console.log('file ====>',event.target.files)
        //   const file = event.target.files;
        //   console.log("here is file bro",file.name)
          
        //   setSelectedFile(file);
        //   setFileName(file.name)
        //   // Here you can also process the file further, e.g., upload it to a server
        // };

        const handleFileChange = async (event) => {
          setUploadErr(false)
          console.log('file ====>', event.target.files);
          const files = event.target.files; 
          if(files.length<=5)
            {
          const allowedMimeTypes= [('.jpg','image/jpeg'), ('.jpeg','image/jpeg'),
              ('.png','image/png'), 
              ('.pdf','application/pdf'), 
               ('.zip','application/x-zip-compressed'),
               ('.zip','application/zip'),  
              ('.mp4','video/mp4')];
          //
          console.log(Array.from(files))
          const validFiles =  Array.from(files).map(async file => {
            if(file.size<=5*1024*1024)
              {
                const buffer = file.arrayBuffer()
                const type=await fileTypeFromBuffer(await buffer)
            if (allowedMimeTypes.includes(file.type) && allowedMimeTypes.includes(type?.mime) && (file.type===type?.mime || file.type==="application/x-zip-compressed")) {
              //window.location.reload() 
              return file;
            } else {
              showAlert({
                title:  'File Upload Error',
                message: 'The file you upload is invalid, Please check the file format.',
                okText: 'OK',
                callback: () => {
                    console.log('Alert closed');
                    setAlertProps(null)
                }
            });
              return null;
            }
          }
          else
          {
           setUploadErr(true)
           setFileUploadErrorMessage("File size should less than 5MB")
          return null
          }
          });
          const results = await Promise.all(validFiles);
          console.log(results.filter(file => file!==null))
          setSelectedFile(results.filter(file => file!==null));
          setFileName(validFiles.map(file => file.name));
            }
            else
            {
              showAlert({
                title:  'File Limit Reached',
                message: 'You can upload maximum of 5 files at a time.',
                okText: 'OK',
                callback: () => {
                    console.log('Alert closed');
                    setAlertProps(null)
                }
            }); 
            }
        };





        const handleDownloadChatAttachment = (el) =>{
          console.log("*************",el)
          const project = "4646"
          fetch(`${process.env.REACT_APP_API_URL}/download/${project}/${el}`, {
            method: "GET",
            headers: {
              // Add any necessary headers here
              //'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
              'Authorization':`Bearer ${token}`,
            },
          })
            .then((response) => response.blob())
            .then((blob) => {
              if (blob) {
                const url = window.URL.createObjectURL(blob);
                if(blob.type == "video/mp4"){
                  let videoSrc = url
                  console.log("here is path",videoSrc)
                  setVideoSrc(videoSrc)
                    setVideoTitle('Video : '+el)
                    setVideoLink(videoSrc)
                    setShowVideo(true)
                    window.document.close()
                  
                  }else{
                    window.open(url, '_blank');
                  // Trigger the download
                  const a = document.createElement("a");
                  a.href = url;
                 
                  a.download = el; // Specify the desired file name and extension
                  document.body.appendChild(a);
                  //setVideoSrc(document.body.appendChild(a))
                  a.click();
                  
                  document.body.removeChild(a); // Clean up the anchor element
        
                  // Revoke the Blob URL
                  window.URL.revokeObjectURL(url);
                }

                // window.open(url, '_blank');
                // const a= document.createElement("a");
                // a.href = url;
                // a.download = el; // Specify the desired file name and extension
                // document.body.appendChild(a);
                // a.click();
                // window.URL.revokeObjectURL(url);
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
      
      
      
      
      
      
      
      
      
      
          // console.log("here is clicked attachment",e.attachment)
          // const url = `/IM_frontend/public/favicon.ico`;
          // // Create a temporary hidden anchor element
          
          // const a = document.createElement('a');
          // a.style.display = 'none';
          // a.href = url;
          // window.open(url, '_blank');
          // console.log("************",url)
          // // Set the download attribute to the file name
          // a.download = e.attachment;
          // // Append the anchor to the body
          // document.body.appendChild(a);
          // // Click the anchor to trigger the download
          // a.click();
          // // Remove the anchor from the body
          // document.body.removeChild(a);
        }
        




  return (
    <>
    <div>
      <Headerhotel
        backButton={1}
        requests={0}
        registrationRequest={0}
        adminRequests={0}
        raiseTicket={0}
        v={0}
        showAlert={showAlert} 
        setAlertProps={setAlertProps}
      />
      <div className="sc-hORach dWMRNy">
        <div className="sc-eTuwsz mQHAl"></div>
        <main className="sc-hXRMBi bJStMD sc-ibxdXY dKLBoM">
          <div className="sc-hrWEMg cesPGE">
            <header data-test-id="request-details.summary">
              <div className="sc-TFwJa dUxdnV" >
                <div>
                  <h1 className="sc-bHwgHz bGtLRv" style={{paddingLeft:"65px"}}>{data.summary}</h1>
                </div>
               
              </div>
            </header>

            <div className="sc-cCVOAp dYzIwj"></div>

            <div className="sc-bvTASY dhbBCH">
              <div className="sc-gJqsIT hXhZlB">
                <div className="sc-eEieub klGEsD">
                  <div className="sc-hMrMfs dvFdfp">
                    <div className="sc-fkyLDJ gdaJLx">
                      <div className="sc-jUpvKA eGWJLp">
                        <div className="sc-jdfcpN jOaoYz">
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              outline: "0px",
                            }}
                          >
                            <span
                              className="css-1m1hxa9"
                              style={{
                                backgroundColor: "coral",
                                fontWeight: "600",
                                color: "white",
                              }}
                            >
                              <p>
                                {nam[0].toUpperCase() + nam[1].toUpperCase()}
                              </p>
                              {/*<img src="https://secure.gravatar.com/avatar/0a8339570e62e5a4601c4e3e9d86fbec?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPB-5.png" alt="" className="css-13ep12v" style={{borderRadius: "50%"}}/>*/}
                            </span>
                          </div>
                          <div className="sc-iCwjlJ yANRH">
                            <div role="presentation">
                              <span>
                                <strong>{data.user}</strong> raised this on{" "}
                                {formattedDateAbove}{" "}
                                {data.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sc-jRuhRL jAxXjC">
                        <strong><div className="sc-kNBZmU kMDvmM">Description</div></strong>
                        <div className="sc-eopZyb CyzLZ">
                          <div className="ak-renderer-wrapper css-2c6ch1">
                            <div
                              style={{
                                display: "block",
                                width: "100%",
                                position: "absolute",
                              }}
                            ></div>
                            <div className="css-1jset9w">
                              <div className="ak-renderer-document">
                                <p
                                  data-renderer-start-pos="1"
                                  dangerouslySetInnerHTML={{
                                    __html: data.description,
                                  }}
                                ></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        {data.file !== null && data.file !== "" ? (
                          <p>
                            Attachment:{" "}
                            <button
                              className="css-370xbg"
                              onClick={downloadFile}
                              href="#"
                              style={{ color: "blue" }}
                              to="/edit"
                              target="_self"
                            >
                              {file.substring(9)}
                            </button>
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sc-kDhYZr hKEIRt">
                  <span className="sc-OxbzP fWuoyP">
                    <span>Activity</span>
                  </span>
                  <div className="sc-jRhVzh keVOrZ"></div>
                  {chat.filter(j=>j.privateChat===0).map((e, index) => {
                    let date = new Date(e.date).toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit' });
                    let parts = date.split('/');
                    let formattedDate = `${parts[0]}-${parts[1]}-${parts[2]}`;
                    return (
                      <div className="sc-kqlzXE cHtPAi">
                        <div
                          className="sc-jhaWeW GHUyr"
                          data-test-id="request-details.activity-item"
                        >
                          <div className="LayoutStyles__Container-sc-14140bv-1 hUwXrK">
                            <div className="LayoutStyles__AvatarSectionDiv-sc-14140bv-0 hvNajw">
                              <div
                                style={{
                                  display: "inlineBlock",
                                  position: "relative",
                                  outline: "0px",
                                }}
                              >
                                <span
                                  className="css-1qdk52b"
                                  style={{
                                    backgroundColor: "#00695C",
                                    fontWeight: "600",
                                    color: "white",
                                  }}
                                >
                                  <p style={{ marginLeft: "5px" }}>
                                    {e.user[0].toUpperCase() +
                                      e.user[1].toUpperCase()}
                                  </p>
                                  {/*<img src="" alt="" className="css-13ep12v" style={{bordeRadius: "50%"}}/>*/}
                                </span>
                              </div>
                            </div>
                            <div className="LayoutStyles__ContentSectionDiv-sc-14140bv-2 fErUzI">
                              <div>
                                <div className="HeaderStyles__TopItemsContainer-sc-1x5yo10-4 dChGZQ">
                                  <div className="HeaderStyles__TopItem-sc-1x5yo10-3 cjZbUi">
                                    <span className="FieldStyles__Span-sc-173n8o8-1 ekKeSG">
                                      {e.user}
                                    </span>
                                  </div>
                                  <div className="HeaderStyles__TopItem-sc-1x5yo10-3 cjZbUi">
                                    <div role="presentation">
                                      <span className="FieldStyles__Span-sc-173n8o8-1 jLNzsu">
                                        {formattedDate}{" "}
                                        {e.time}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="CommentStyles__Content-lr96k6-0 kuiQOK">
                                  <div className="ak-renderer-wrapper css-2c6ch1">
                                    <div
                                      style={{
                                        display: "block",
                                        width: "100%",
                                        position: "absolute",
                                      }}
                                    ></div>
                                    <div className="css-1jset9w">
                                      <div className="ak-renderer-document">
                                        <p
                                          data-renderer-start-pos="1"
                                          dangerouslySetInnerHTML={{
                                            __html: e.description,
                                          }}
                                        ></p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="FooterStyles__ActionsContainer-sc-7f01gi-2 irmqVw"></div>
                                {
                                  e.attachment ? e.attachment.split(';').map((el)=> <button className="btn btn-secondary mb-2 ml-1" value={el} onClick={()=>handleDownloadChatAttachment(el)}>{el.substring(14)}</button>)  : null
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                                        
                  })}
                </div>
              </div>

              <div className="sc-koErNt SBKnj">
              <div >
                <strong><span>Id: {data.id}</span></strong>
                  {/* <h1 className="sc-bHwgHz bGtLRv" style={{marginLeft:"50px"}}>
                  </h1> */}
                </div>
                <h3 className="sc-lnrBVv hzWGel">
                  <span>Status</span>
                </h3>
                <div data-test-id="request-details.status-lozenge">
                  <span className="css-cztx6i" style={{ maxWidth: "100%" }}>
                    <span
                      className="css-1et8jlg"
                      style={{
                        maxWidth: "calc(200px - var(--ds-space-100, 8px))",
                      }}
                    >
                      {data.status}
                    </span>
                  </span>
                </div>
                <h3 className="sc-lnrBVv hzWGel">
                  <span>Request type</span>
                </h3>
                <div
                  className="sc-glUWqk bPQdOV"
                  data-test-id="request-details.request-type"
                >
                  {/*<img className="sc-fFTYTi hQlek" src="https://globallogic.atlassian.net/rest/servicedeskapi/requesttype/icon/type/SD_REQTYPE/id/10550"/>*/}
                  <span className="sc-bTiqRo cpDveY">{data.opt}</span>
                </div>
                <br />
                {data.status === "Closed" ? (
                  <div>
                    <button className="button" onClick={reopen}>
                      Reopen
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div style={{ margin: "30px" }}>
            {data.status !== "Resolved" &&
              data.status !== "Closed" &&
              data.status !== "Rejected" ? (
                <><span className="sc-OxbzP fWuoyP">
                <span>Comments</span>
                </span>
             
                  <div
                    className="sc-chAAoq hcyXVm"
                    id="description"
                    data-test-id="rich-text-area-field"
                  >
                    <div className="sc-hAXbOi eotBHb">
                      <div className="css-1dr2qy6">
                        <div
                          style={{
                            display: "block",
                            width: "100%",
                            position: "absolute",
                          }}
                        ></div>
                        <div className=" css-18w41as">
                          <ReactQuill
                            theme="snow"
                            value={descript}
                            style={{ height: "130px" }}
                            onChange={setDescript}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ paddingTop: "50px" }}>
                  <input type="file" multiple  ref={fileInputRef} onChange={handleFileChange} style={{width: "223px"}}
                  accept=".pdf,.mp4,.png,.jpg,.jpeg,.zip"  
                  />
                   {
        uploadErr==true ? <p style={{color:"red",fontSize:"12px"}}>{fileUploadErrMessage}</p> : null
      }
      <p style={{color:"blue",fontSize:"12px", marginTop:"4px"}}>Max file size: 5 MB</p>
      <p style={{fontSize:"12px", marginTop:"0px"}}>Supported File Formats: zip,jpg,jpeg,png,pdf,mp4.</p>

                    <div className="sc-cbkKFq icDrbe" style={{justifyContent: 'center', width: '65%', marginLeft: '8px'}}>
                      <button
                        type="submit"
                        className="request-create-form-submit css-68ar9j"
                        onClick={click}
                      >
                        <span className="css-j8fq0c">
                          <span className="css-t5emrf">
                            <span>Submit</span>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {data.status === "Closed" ? (
                <div className="sc-hizQCF ljPvSw">
                  <div className="sc-eNPDpu eZXad">
                    <div className="sc-hARARD ejZygw">
                      <div
                        style={{
                          display: "inlineBlock",
                          position: "relative",
                          outline: "0px",
                        }}
                      >
                        <span
                          className="css-1qdk52b"
                          style={{
                            backgroundColor: "#00695C",
                            fontWeight: "600",
                            color: "white",
                          }}
                        >
                          <p style={{ marginLeft: "5px" }}>
                            {nam[0].toUpperCase() + nam[1].toUpperCase()}
                          </p>
                        </span>
                      </div>
                      {/*<img src="https://secure.gravatar.com/avatar/0a8339570e62e5a4601c4e3e9d86fbec?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPB-5.png" alt="" className="css-13ep12v" style={{borderRadius: "50%"}}/>*/}
                      <div className="sc-cgHJcJ dWOhZl">
                        <div role="presentation">
                          <span>
                            <h6>{data.user}</h6> submitted Feedback
                          </span>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className={`css-${styles}`}
                      onClick={view}
                    >
                      <span className="css-j8fq0c">
                        <span className="css-t5emrf">
                          <span>{show} details</span>
                        </span>
                      </span>
                    </button>
                  </div>
                  <div className={`sc-ccLTTT ${div}`}>
                    {/*<div align="left">*/}
                    <div /*className="login"*/>
                      <br />

                      <h5 style={{ textAlign: "left" }}>
                        Service Provided by us
                      </h5>
                      <div align="left" className="ratea">
                        <input
                          type="radio"
                          id="star5a"
                          name="ratea"
                          value="5"
                        />
                        <label
                          id="star5a"
                          style={{ color: `${color5}` }}
                          htmlFor="star5a"
                          title="text"
                        >
                          5 stars
                        </label>
                        <input
                          type="radio"
                          id="star4a"
                          name="ratea"
                          value="4"
                        />
                        <label
                          style={{ color: `${color4}` }}
                          id="star4a"
                          htmlFor="star4a"
                          title="text"
                        >
                          4 stars
                        </label>
                        <input
                          type="radio"
                          id="star3a"
                          name="ratea"
                          value="3"
                        />
                        <label
                          style={{ color: `${color3}` }}
                          htmlFor="star3a"
                          title="text"
                        >
                          3 stars
                        </label>
                        <input
                          type="radio"
                          id="star2a"
                          name="ratea"
                          value="2"
                        />
                        <label
                          style={{ color: `${color2}` }}
                          htmlFor="star2a"
                          title="text"
                        >
                          2 stars
                        </label>
                        <input
                          type="radio"
                          id="star1a"
                          name="ratea"
                          value="1"
                        />
                        <label
                          style={{ color: `${color1}` }}
                          htmlFor="star1a"
                          title="text"
                        >
                          1 stars
                        </label>
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />
                      <h5 style={{ textAlign: "left" }}>
                        Anything else you'd like to share with our team?
                      </h5>
                      <br />
                      <textarea
                        id="comment1"
                        required="required"
                        value={comment}
                        disabled
                        className="comment1 gwKMCV "
                      ></textarea>
                      <p/>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </main>
      </div>
      {showVideo && <VideoModal title={videoTitle} videoLink={videoLink} okText={"ok"} cancel={setShowVideo} />}
    </div>
    <div style={{marginTop:"100px",bottom:"0"}}>
    <Footer/>
    </div>
    </>
  );
}

export default Udetailshotel;
