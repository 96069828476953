import React, { useEffect , useRef, useState} from 'react';
import { Doughnut, Bar, getElementsAtEvent } from 'react-chartjs-2';
import Headeradminhotel from './headeradminhotel';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  // Bar related
  BarElement,
  CategoryScale, //x
  LinearScale, //y
} from 'chart.js';


ChartJS.register(ArcElement, Tooltip, Legend, Title, BarElement, CategoryScale, LinearScale);

const AdminStatisticshotel = ({showAlert,setAlertProps}) => {
const [datas,setdatas]=useState([])
const [supportList,setSupportList]=useState([])
const [totalAssignedTicketsData,setTotalAssignedTicketsData]=useState([])
const [pendingWorkData,setPendingWorkData]=useState([])
const [typesData,setTypesData]=useState([])
const [severityCount,setSeverityCount]=useState([])
const [priorityCount,setPriorityCount]=useState([])
const [allOpenCount,setAllOpenCount]=useState([])
const token=localStorage.getItem("adminToken")
//const project=cookie.get("project")
const navigate=useNavigate()
const [project,setProject]=useState("")


 useEffect(() => {
  try{
const decodedToken = jwtDecode(token);
setProject(decodedToken.project)
  }
  catch(error)
  {
    navigate("/v1/jim_admin_user_login")
  }
},[])

// Fetching countopen, progress, reject, resolve, close, countreopen according to project(admin) in an array
useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/bar/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    }
    )
    .then(res=>res.json())
    .then(data=>{
setdatas(data.data)
if(data.statusCode===400)
  {
    showAlert({
      title:  'Logged Out',
      message: 'You are logged out due to session timeout.',
      okText: 'OK',
      callback: () => {
          console.log('Alert closed');
          setAlertProps(null)
      }
  }); 
    navigate("/v1/jim_admin_user_login")
  }
   })
.catch(err=>console.log(err))
  }, [project])

  // Fetching support admin list
  useEffect(() => {fetch(`${process.env.REACT_APP_API_URL}/supportadmin/${project}`,
  {
    method: 'GET',
    withCredentials: 'true',
    headers:
    {
      'Authorization':`Bearer ${token}`,
    },
  }
  )
  .then(res=>res.json())
  .then(data=>
    {
      data.data.forEach(element => {
      supportList.push(element.name)
      });
    })
    .catch(err=>console.log(err))
  }, [project])

  // fetching assigned data
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/assignedbar/${project}`,
    
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>{
      setTotalAssignedTicketsData(data.data)})
      .catch(err=>console.log(err))
  }, [project])
  // fetching closed data
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/closedbar/${project}`,
    
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>{
      setPendingWorkData(data.data)})
.catch(err=>console.log(err))
  }, [project])
// fetching count of "types"
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/typescount/${project}`,
    
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>{
      setTypesData(data.data)})
      .catch(err=>console.log(err))
  }, [project])
// fetching count of tickets according to severity
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/severitycount/${project}`,
    
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>{
      setSeverityCount(data.data)})
      .catch(err=>console.log(err))
  }, [project])
// fetching count of tickets according to priority
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/prioritycount/${project}`,
    
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>{
      setPriorityCount(data.data)})
.catch(err=>console.log(err))
  }, [project])

  // fetching count of tickets which are open for a particular time

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/allopencount/${project}`,
    
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>{
      setAllOpenCount(data.data)})
      .catch(err=>console.log(err))
  }, [project])
  
  
  useEffect(() => {
    if(localStorage.getItem("tokenauth")==="false")
    //if(cookie.get("tokenauth")==="false")
   {
    window.location.href="/v1/jim_admin_user_login"
   }}, [])

// For D1 and D2
const openTasks = datas[0];
const closedTasks = datas[1];
const rejectedTasks = datas[2];
const inProgressTasks = datas[3];
const resolvedTasks = datas[4];
const type = Object.keys(typesData)

const doughnutData1 = {
  labels: ['Open','In-Progress','Rejected','Resolved','Closed'],
  datasets: [
    {
      label:'Ticket Count',
      data: [openTasks,inProgressTasks, rejectedTasks,resolvedTasks,closedTasks],
      //borderColor: 'black',
      backgroundColor: ['#ff1a1a', '#00bfff', '#ff8000','#00cccc','#3ec351'],
      link: [`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?status=opened`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?status=In Progress`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?status=Rejected`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?status=Resolved`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?status=Closed`]

    }
  ]
};

const linkArray=type.map((item)=>`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?type=${item}`)
const doughnutData2 = {
  labels: type,
  datasets: [
    {
      label:'Ticket Count',
      data: Object.values(typesData),
      backgroundColor: ['#0088FE','#00C49F','#FFBB28','#FF8042','#FF5733','#4CAF50','#9C27B0','#FFD700','#FF4500','#00CED1','#FF1493','#8A2BE2','#32CD32','#FF69B4','#FFA500'],
      //backgroundColor: ['#fa72bc', '#00bfff', '#ffa500','#00cccc','#3ec351'],
      // link: [`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?type=Billing`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?type=Playback`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?type=Report`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?type=Onboarding`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?type=Other`]
      link: linkArray
    }
  ]
};

// Bar chart data
const severity=['Blocker','Critical','Major','Minor']
console.log(severity,"Hi");
const priority = Object.keys(priorityCount);
const allOpenTimeData = Object.keys(allOpenCount);
const people = supportList;
const supportWiseAssignedTickets = totalAssignedTicketsData;
const supportWisePendingTickets = pendingWorkData;
//const dumBarLink='http://localhost:3000/v1/jim/admin_home?status=opened';



const barData1 = {
  labels: severity,
  datasets: [
    {
      label:'Ticket Count',
      //backgroxundColor: ['#99CCFF','#66A3FF','#3366FF','#0033CC','#000080'],
      backgroundColor: ['#000080','#3366FF','#66A3FF','#99CCFF'],
      data: Object.values(severityCount),
      borderWidth: 1,
      barPercentage: '0.7',
      categoryPercentage: '0.7',
      link: [`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?severity=Blocker-S1`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?severity=Critical-S2`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?severity=Major-S3`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?severity=Minor-S4`]
    },
  ],
};

const barData2 = {
  labels: priority,
  datasets: [
    {
      label:'Ticket Count',
      backgroundColor: ['#9B0000','#C60000','#FF0000'],
      data: Object.values(priorityCount),
      borderWidth: 1,
      barPercentage: '0.6',
      categoryPercentage: '0.6',
      link: [`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?priority=High`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?priority=Medium`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/admin_home?priority=Low`]
    },
  ],
};

const barData3 = {
  labels: allOpenTimeData,
  datasets: [
    {
      label:'Ticket Count',
      backgroundColor: ['#BE75BE','#9E4A9E','#872E87','#711971'],
      data: Object.values(allOpenCount),
      borderWidth: 1,
      barPercentage: '0.7',
      categoryPercentage: '0.7',
      //link: ['http://localhost:3000/v1/jim/admin_home?range=1','http://localhost:3000/v1/jim/admin_home?range=7','http://localhost:3000/v1/jim/admin_home?range=15','http://localhost:3000/v1/jim/admin_home?range=15+']
    }
  ],
};

const stackedBarData = {
 labels: people,
  datasets: [
    {
      label: 'Assigned Tickets',
      backgroundColor: '#f0ab00',
      data: supportWiseAssignedTickets,
      borderWidth: 1,
    },
    {
      label: 'Pending Tickets',
      backgroundColor: '#c46100',
      data: supportWisePendingTickets,
      borderWidth: 1
    },
  ],
};

// const handleScroll = () => {
//   const {scrollLeft, scrollWidth, clientWidth} = stackedBarChartRef.current;
//   const visibleCountStart = Math.floor((scrollLeft / scrollWidth) * people.length);
//   setVisibleItemsStart(visibleCountStart);
//   setVisibleItemsEnd(visibleCountStart + 5);
// };

// useEffect(() => {
//   stackedBarChartRef.current.addEventListener('scroll', handleScroll);
//   return () => stackedBarChartRef.current.removeEventListener('scroll', handleScroll);
// }, []); 
//ChartJS.defaults.plugins.legend.title.text = 'Legend';

const d1Options = {
  cutout : '40%',
  plugins: {
    legend: {
      title: {
        display: true,
        text: 'Tickets by Status',
        font: {
          family: 'Arial',
          size: 20,
          stype: 'normal',
          weight: 'bold',
        }
      }
    }
  }
};

const d2Options = {
  cutout : '40%',
  plugins: {
    legend: {
      title: {
        display: true,
        text: 'Tickets by Types',
        font: {
          family: 'Arial',
          size: 20,
          stype: 'normal',
          weight: 'bold',
        }
      }
    }
  }
};

const bar1Options = {
  scales: {
    x: { grid: { display: true }},
    y: {
      grid: { display: false },
      grace: '5%',
    },
  },
  plugins: {
    legend: {
      title: {
        display: true,
        text: 'Tickets by Severity',
        font: {
          family: 'Arial',
          size: 20,
          stype: 'normal',
          weight: 'bold',
        }
      },
      labels: {
        filter : function(){
          return false;
        }
        //display: false,
      }
    }
  }
};

const bar2Options = {
  scales: {
    x: { grid: { display: true }},
    y: {
      grid: { display: false },
      grace: '5%',
    },
  },
  plugins: {
    legend: {
      title: {
        display: true,
        text: 'Tickets by Priority',
        font: {
          family: 'Arial',
          size: 20,
          stype: 'normal',
          weight: 'bold',
        }
      },
      labels: {
        filter : function(){
          return false;
        }
        //display: false,
      }
    }
  }
};

const bar3Options = {
  scales: {
    x: { grid: { display: true }},
    y: {
      grid: { display: false },
      grace: '5%',
    },
  },
  plugins: {
    legend: {
      title: {
        display: true,
        text: 'Open Tickets',
        font: {
          family: 'Arial',
          size: 20,
          stype: 'normal',
          weight: 'bold',
        }
      },
      labels: {
        filter : function(){
          return false;
        }
        //display: false,
      }
    }
  }
};

const stackedBarOptions = {
  scales: {
    x: { grid: { display: true }, stacked: true },
    y: {
      grid: { display: false },
      grace: '5%',
      stacked: true,
    },
  },
  plugins: {
    legend: {
      title: {
        display: true,
        text: 'Support-wise',
        font: {
          family: 'Arial',
          size: 20,
          style: 'normal',
          weight: 'bold',
        }
      }
    },
    tooltip:{
      callbacks:{
        // make a function to set tooltip value as Ticket Count
        label: function(context){
          return "Ticket Count" + ': ' + context.parsed.y;
        }

      }
    }
  }
};
// References and Click events
const chartD1Ref = useRef();
const chartD2Ref = useRef();
const chartB1Ref = useRef();
const chartB2Ref = useRef();
// const chartB3Ref = useRef();
//const stackedBarChartRef = useRef(null);

const onD1Click = (event) => {
  if(getElementsAtEvent(chartD1Ref.current, event).length > 0){
    const datasetIndexNum = getElementsAtEvent(chartD1Ref.current, event)[0].datasetIndex;
    const dataPoint = getElementsAtEvent(chartD1Ref.current, event)[0].index;
    //console.log(doughnutData1.datasets[datasetIndexNum].link[dataPoint]);
    window.open(doughnutData1.datasets[datasetIndexNum].link[dataPoint], '_self');
}
};
const onD2Click = (event) => {
  if(getElementsAtEvent(chartD2Ref.current, event).length > 0){
    const datasetIndexNum = getElementsAtEvent(chartD2Ref.current, event)[0].datasetIndex;
    const dataPoint = getElementsAtEvent(chartD2Ref.current, event)[0].index;
    //console.log(doughnutData2.datasets[datasetIndexNum].link[dataPoint]);
    window.open(doughnutData2.datasets[datasetIndexNum].link[dataPoint], '_self');
}
};

const onBar1Click = (event) => {
  if(getElementsAtEvent(chartB1Ref.current, event).length > 0){
    const datasetIndexNum = getElementsAtEvent(chartB1Ref.current, event)[0].datasetIndex;
    const dataPoint = getElementsAtEvent(chartB1Ref.current, event)[0].index;

    window.open(barData1.datasets[datasetIndexNum].link[dataPoint], '_self');
    //window.open(dumBarLink, '_blank');  
  }
}
const onBar2Click = (event) => {
  if(getElementsAtEvent(chartB2Ref.current, event).length > 0){
    const datasetIndexNum = getElementsAtEvent(chartB2Ref.current, event)[0].datasetIndex;
    const dataPoint = getElementsAtEvent(chartB2Ref.current, event)[0].index;

    window.open(barData2.datasets[datasetIndexNum].link[dataPoint], '_self');
    //window.open(dumBarLink, '_blank');  
  }
}
// const onBar3Click = (event) => {
//   if(getElementsAtEvent(chartB3Ref.current, event).length > 0){
//     const datasetIndexNum = getElementsAtEvent(chartB3Ref.current, event)[0].datasetIndex;
//     const dataPoint = getElementsAtEvent(chartB3Ref.current, event)[0].index;

//     window.open(barData3.datasets[datasetIndexNum].link[dataPoint], '_self');
//     //window.open(dumBarLink, '_blank');  
//   }
// }
// const onStackedBarClick = (event) => {
//   if(getElementsAtEvent(chartRef.current, event).length > 0){
//     const datasetIndexNum = getElementsAtEvent(chartRef.current, event)[0].datasetIndex;
//     const dataPoint = getElementsAtEvent(chartRef.current, event)[0].index;

//     window.open(stackedBarData.datasets[datasetIndexNum].link[dataPoint], '_self');
//     //window.open(dumBarLink, '_blank');  
//   }
// }
return (
    <div >
         <Headeradminhotel l={0} k={0} h={1} backButton={1} j={1} f={1} i={1} m={70} showAlert={showAlert} setAlertProps={setAlertProps}/>
    <br/>
    <br/>
      <h1 style={{ textAlign: 'center' }}>Statistic Analysis Report</h1>
      <br/>
      <br/>
      <div style={{ margin: '0px,30px,0px,30px'}}>
        <div style={{ display: 'flex', marginLeft: '65px', marginRight: '65px' }}>
          <div className="card" style={{ padding: '20px', margin: '20px', width: '100%'}}>
            <div className="chart-container" style={{ display: 'flex', alignItems:'center', justifyContent: 'center', height: '350px' }}>
              <div style={{ width: '300px', height: '300px', marginLeft: '80px', marginRight: '50px' }}>
                <Doughnut data={doughnutData1} options={d1Options} onClick={onD1Click} ref={chartD1Ref} />
              </div>
            </div>  
          </div>
          <div className="card" style={{ padding: '20px', margin: '20px', width: '100%'}}>
            <div className="chart-container" style={{ display: 'flex',alignItems:'center' , justifyContent: 'center',height: '350px' }}>
              <div style={{ width: '300px', height: '300px', marginLeft: '80px', marginRight: '50px' }}>
                <Doughnut data={doughnutData2} options={d2Options} onClick={onD2Click} ref={chartD2Ref} />
              </div>
            </div>  
          </div>
        </div>
      </div>
      <div>
        <div style={{ display: 'flex',marginLeft: '65px', marginRight: '65px'  }}>
          <div className="card" style={{ padding: '20px', margin: '20px', width: '100%' }}>
            <div className="chart-container" style={{ display: 'flex', alignItems:'center', justifyContent: 'center',width: '100%' }}>
              <div style={{  width: '100%', marginLeft: '80px', marginRight: '50px' }}>
              <Bar
                data={barData1}
                options={bar1Options}
                onClick={onBar1Click}
                ref={chartB1Ref}
              />
              </div>
            </div>  
          </div>
          <div className="card" style={{ padding: '20px', margin: '20px', width: '100%' }}>
            <div className="chart-container" style={{ display: 'flex',alignItems:'center' , justifyContent: 'center',width: '100%' }}>
              <div style={{  width: '100%', marginLeft: '80px', marginRight: '50px' }}>
              <Bar
                data={barData2}
                options={bar2Options}
                onClick={onBar2Click}
                ref={chartB2Ref}
              />
              </div>
            </div>  
          </div>
        </div>
      </div>
      <br/>
      <div >
        <div style={{ display: 'flex', marginLeft: '65px', marginRight: '65px' }}>
          <div className="card" style={{ padding: '20px', margin: '20px', width: '100%' }}>
            <div className="chart-container" style={{ display: 'flex', alignItems:'center', justifyContent: 'center',width: '100%'}}>
              <div style={{ width: '100%', marginLeft: '80px', marginRight: '50px' }}>
              <Bar
                data={barData3}
                options={bar3Options}
                //onClick={onBar3Click}
                //ref={chartB3Ref}
              />
              </div>
            </div>  
          </div>
          <div className="card" style={{ padding: '20px', margin: '20px', width: '100%' }}>

            <div className="chart-container" style={{ display: 'flex',alignItems:'center' ,width: '100%', justifyContent: 'center'}}>
              <div style={{ marginLeft: '80px', marginRight: '50px',width: '100%' }}>
              <Bar
                  data={stackedBarData}
                  options={stackedBarOptions}
                //onClick={onStackedBarClick}
                //ref={chartRef}
              />
                      {/* <div ref={stackedBarChartRef} style={{ overflow: 'auto', whiteSpace: 'nowrap', width: '90%' }}>
                        {people.slice(visibleItemsStart, visibleItemsEnd).map(person => (
                        <div key={person} style={{ display: 'inline-block', padding: '10px' }}>
                          {person}
                          </div>
                          ))}
                          </div> */}
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminStatisticshotel;