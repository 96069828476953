import React from 'react'
import { useNavigate } from 'react-router-dom';
import Headerhotel from './headerhotel';

function Messagehotel() {
    var navigate=useNavigate()
    const button= () => {
        try {
          navigate(-2); // Omit optional second argument
        } catch (error) {
          navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
        }
      }
      const clicks= () => {
        try {
          navigate(-2); // Omit optional second argument
        } catch (error) {
          navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
        }
      }
  return (
    <div>
       <Headerhotel disp="none"/>
      
      <h1 style={{color: "#ffffff",textAlign: "center",fontFamily:"auto"}}>Jio Service Support</h1><br/>
   <div className="login">
   <span class="sc-AnqlK jNMwMZ">
     <button type="button" class="css-1undbr0" onClick={button}>
       <span class="css-j8fq0c">
         <span class="css-8xpfx5">
           <span role="img" aria-label="Back" class="css-snhnyn" /*style="--icon-primary-color: currentColor; --icon-secondary-color: var(--ds-surface, #FFFFFF);"*/>
             <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
               <path fill="#fff" fill-opacity=".01" d="M0 0h24v24H0z"></path>
               <path fill-rule="evenodd" clip-rule="evenodd" d="M12.207 5.793a1 1 0 010 1.414L8.414 11H18a1 1 0 110 2H8.414l3.793 3.793a1 1 0 11-1.415 1.414l-5.499-5.5A.997.997 0 015 12.028v-.057a.996.996 0 01.293-.678l5.499-5.5a1 1 0 011.415 0z" fill="currentColor"></path></svg></span></span>
               <span class="css-mu6jxl">Back</span></span></button></span>
               <br/>
               <br/>
      <p style={{textAlign:"justify"}}>    We have sent an email to the address registered with this account containing further instructions to recovery of your password.</p>
{/* <br/><p style={{textAlign:"justify"}}>Still having trouble signing in? Please contact us for support. </p> */}
<p>Still having trouble signing in? feel free to contact us at <a href="https://www.jio.com/help/contact-us#/">business@jio.com</a>.</p>
<br/>
<br/>
       <input style={{background:"#3399FF",border:"none",borderRadius:"2px",
height:"42px",marginTop:"20px"}} type="submit" name="log" id="log" value="Close" onClick={clicks}/>
       <br/><br/>
       {/*<a style={{float: "left"}} href="/register">Register for new User</a>*/}
       
       <br/>
       <br/>
     
 </div>
    </div>
  )
}

export default Messagehotel
