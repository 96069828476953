import React, { useEffect } from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OtpInput from 'react-otp-input';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
export default function OtpModal({
    showModal,
    email,
    selectedProject,
    setotp,
    showAlert,
    setAlertProps,
}) {
    console.log(setotp)
    //const veriOtp = localStorage.getItem("deleteOtp")
    const name = localStorage.getItem("key")
    const navigate=useNavigate()
    const [show, setShow] = useState(showModal)
    const adminEmail=sessionStorage.getItem("mail")
    console.log(adminEmail)
    const [code, setCode] = useState("");
    const handleChange = (code) => setCode(code);
    const [userOtp,setUserOtp] = useState(setotp)
    console.log("here is selected Email Id",email)
    console.log("here is selected Email project",selectedProject)
    //const [errMessage,setErrMessage] = useState("")


   useEffect(()=>{
    setShow(showModal)
    setUserOtp(setotp)

   },[showModal,setotp])

   useEffect(()=>{

   
    const token=localStorage.getItem("superToken")
    const obj={name:name,adminEmail,project:selectedProject}
    console.log(obj)
    const res={
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(obj)
  };
  fetch(`${process.env.REACT_APP_API_URL}/verifyOtpMail`, res)
        .then(()=>
        {
            console.log("Otp sent successfully")
        })
   },[setotp,selectedProject])

   useEffect(()=>{
    
},[setotp,selectedProject])


    const handleClose = () =>{ 
        setShow(false);
        setCode("")   
    }
console.log("+++++++++++++++++++++++++++++",userOtp)
    const handleVerifyOtp = () =>{
      const token=localStorage.getItem("superToken")
      const otpResult = (value) => {
        value = CryptoJS.enc.Utf8.parse(value.toString());
        var key = CryptoJS.lib.WordArray.random(256 / 8);
      
        var iv = CryptoJS.lib.WordArray.random(128 / 8);
      
        var encrypted = CryptoJS.AES.encrypt(value, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
      
        var final = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
      
        var keyBase64 = key.toString(CryptoJS.enc.Base64);
        var ivBase64 = iv.toString(CryptoJS.enc.Base64);
        var result = keyBase64 + ':' + ivBase64 + ':' + final;
        return result;
      }
  
      const finalOtp = otpResult(code)
        console.log("here is code",code.length)
        console.log("here is user otp>>>>>>>>>>",userOtp)
        fetch(`${process.env.REACT_APP_API_URL}/getVerifyOtp`,
        {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
         },
        body: JSON.stringify({project:selectedProject,email:adminEmail,otp:finalOtp})
        }
        )
        .then(res=>res.json())
        .then(data=>
          {  
             if(data.statusCode===200)
              {
          
       
        const token=localStorage.getItem("superToken")
        fetch(
            `${process.env.REACT_APP_API_URL}/admin/delete/${selectedProject}/${email}/${adminEmail}`,
            { method: "DELETE",
            withCredentials: "true",
            headers: {
              Authorization: `Bearer ${token}`,
            }
           }
          ).then(() => console.log("admin deleted successful"));
    
          fetch(`${process.env.REACT_APP_API_URL}/jiouser/delete/${selectedProject}`, {
            method: "DELETE",
            withCredentials: "true",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(() => {console.log("project delete successful")});
          fetch(`${process.env.REACT_APP_API_URL}/project/delete/${selectedProject}`, {
            method: "DELETE",
            withCredentials: "true",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(() => {
            console.log("Delete from jioprojects successful")
          });
          
        //updateList("deleted")
        setShow(false)
        showAlert({
          title: 'Project deleted',
          message: 'Your project is deleted successfully.',
          okText: 'OK',
          callback: (()=>
          {
            setAlertProps(null); 
            setCode("")
            window.location.reload(false)
          })
      });
        
}
else{
  if(code.length===0){
    showAlert({
      title: 'Enter OTP',
      message: 'Please enter valid OTP.',
      okText: 'OK',
      callback: () => {
        console.log('Alert closed');
        setAlertProps(null)
    }
  });
  }
else
{
  showAlert({
    title: 'Invalid OTP',
    message: 'Please enter valid OTP.',
    okText: 'OK',
    callback: () => {
      console.log('Alert closed');
      setAlertProps(null)
  }
});
}
}
})
.catch(err=>console.log(err))
    }
  
  return (
    <>
    {/* <Button variant="primary" onClick={handleShow}>
      Launch demo modal
    </Button> */}

    <Modal 
    show={show} 
    onHide={handleClose} 
    size="sm" centered 
     backdrop='static' 
     keyboard={false}  
    >
      <Modal.Header closeButton>
        <Modal.Title size="sm" style={{fontSize:"16px"}}>Enter OTP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <OtpInput
        value={code}
        onChange={handleChange}
        numInputs={6}
        separator={<span style={{ width: "8px" }}></span>}
        isInputNum={true}
        shouldAutoFocus={true}
        inputStyle={{
            backgroundColor:'#ecf2f7',
          border: "1px solid ",
          marginLeft:"4px",
          marginRight:"4px",
          borderRadius: "8px",
          width: "32px",
          height: "32px",
          fontSize: "12px",
          color: "#000",
          fontWeight: "400",
          caretColor: "blue"
        }}
        focusStyle={{
          border: "1px solid #CFD3DB",
          outline: "none"
        }}
        renderSeparator={<span style={{marginBottom:"10px"}}> - </span>}
        renderInput={(props) => <input {...props} />}
      />
    {/* <span style={{color:"red"}}>{errMessage}</span> */}
    </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={handleClose}>
          Resend OTP
        </Button> */}
        <Button variant="primary" size="sm" onClick={handleVerifyOtp}>
          Delete Project
        </Button>
        
      </Modal.Footer>
    </Modal>
  </>
  )
}
