import React, { useState } from 'react'
import svg from "../component/image/404.svg";



function ErrorPage() {
  
  return (
   
   <div>
      <div className="cont-404">
                <img src={svg} alt="svg" />
                <button>Back to Home</button>
            </div>
 </div>
    
   
  )
}

export default ErrorPage
