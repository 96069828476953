import "../css/details.css";
import "../css/cmd.css";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import 'react-quill/dist/quill.snow.css';


import "../css/admin.css";
import "../css/drop.css";
import ReactQuill from "react-quill/lib";
import arrow from "./image/down arrow.png";
import uparrow from "./image/up arrow.png";
import { useSelector } from "react-redux";
import Headersupportadminhotel from "./headersupportadminhotel";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {jwtDecode} from 'jwt-decode'
import CryptoJS from 'crypto-js';
import VideoModal from "./common/VideoModal";
import { fileTypeFromBuffer } from "file-type";

function Supportdetailshotel({showAlert,setAlertProps}) {
  //let nams= sessionStorage.getItem("key");
  const token = localStorage.getItem("supportToken");
  const nams = useSelector((state) => state.userSupportAdminData);
  //let m = sessionStorage.getItem("id");
  const m = useSelector((state) => state.yourSupportAdminData);
  //let na=sessionStorage.getItem("mail");
  const na = useSelector((state) => state.userSupportAdminMail);
  //let nam=sessionStorage.getItem("user");
  const nam = useSelector((state) => state.user);
  const [applyClass,setApplyClass] = useState("sc-hrWEMg cesPGE")
  const [ticketData,setTicketData] = useState('')
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoTitle,setVideoTitle]=useState('')
  const [videoLink,setVideoLink]=useState('')
  const [showVideo,setShowVideo]=useState(false)
  const [uploadErr,setUploadErr]=useState(false)
  const [fileUploadErrMessage,setFileUploadErrorMessage]=useState('')
  //console.log(det)
  /*if(d===undefined || m!==da )
{
  m=da
  console.log(1+m)
}
else{
  
   m=d
   console.log(2+m)
  
}
if(da===undefined  || m===d)
{
  m=d
  console.log(3+m) 
}
else{
 
   m=da
   console.log(4+m)
  
}*/
  /*if((z===undefined && r===undefined))
{
  m=d;
}
else if((r===undefined && d===undefined))
{
  m=z;

}
else{
  m=r;
}*/

  //console.log(d)

  const [data, setdata] = useState([]);
  const [file,setFile]=useState('')
 

  //const [da,setda]=useState([])
  const [location, setLocation] = useState([]);
  const [textarea, setTextarea] = useState([]);
  const [allFieldsData,setAllFieldsData]=useState([])
  const [value, setValue] = useState([]);
  const [calender, setCalender] = useState([]);
  const [radio, setradio] = useState([]);
  const [dropdownFields,setDropdownFields]=useState([])
  const [radiooptions, setRadioOptions] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [number, setNumber] = useState([]);
  const [tags, setTags] = useState([]);
  const [priority, setPriority] = useState([]);
  const [severity, setSeverity] = useState([]);
  const [ccList, setCcList] = useState([]);
  const [ccArray, setCcArray] = useState([]);
  const [showDesc, setShowDesc] = useState(true);
  const [supportName,setSupportName] = useState('')
  const [userDetailsEmail,setUserDetailsEmail] = useState('')
  const [flag,setFlag]=useState(true)
  const [showDropdown, setShowDropdown] = useState(false);
  const [fileName,setFileName]=useState('')

  const feed = useRef();
  //const project = cookie.get("project");
  const [project,setProject]=useState("")
  const [checked,setChecked]=useState(1)
  const [privateChats,setPrivateChats]=useState(0)
  const [selectedFile, setSelectedFile] = useState([]);
  const fileInputRef = useRef(null); 

  useEffect(() => {
   try{
 const decodedToken = jwtDecode(token);
 setProject(decodedToken.project)
   }
   catch(error)
   {
     navigate("/v1/jim_admin_user_login")
   }
 },[])
 function hasHtmlElements(e) {
  const plainString=document.querySelector(".ql-editor p").innerHTML
  console.log(document.querySelector(".ql-editor p").innerHTML)
  if(plainString.includes("&lt;") || plainString.includes("&gt;"))
    {
      return true
    }
}
 
 function decryptMessage(encryptedHexWithKey) {
  console.log(encryptedHexWithKey)
  // Extract the key from the encrypted string
  const key = encryptedHexWithKey.substring(0,8);
  // Remove the key from the encrypted string
  const encryptedHex = encryptedHexWithKey.substring(8);

  // Convert the key to a WordArray
  const keyWordArray = CryptoJS.enc.Hex.parse(key);

  // Decrypt the message
  const decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Hex.parse(encryptedHex),
    },
    keyWordArray,
    {
      iv: CryptoJS.enc.Hex.parse('e5f6a7b8'), // Use the same IV for decryption
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
  return decryptedString;
}
const getElement=(name)=>
  {
    var element=""
    if(name.fieldtype==="textarea" || name.fieldtype==="calender" || name.fieldtype==="dropdown"  || name.fieldtype==="HeadGroup" )
      {
      element=<div data-test-id="request-field-summary">
        <div className="sc-gGCbJM foiuyx">
          <label
            htmlFor="summary"
            className="css-1qzu3fk"
          >
            {`${name.fieldname}`}
            {console.log(value[name.fieldname.split(" ").join("")])}
          </label>
        </div>
        <div
          data-ds--text-field--container="true"
          className="css-1o07z2d"
        >
          <input
            id="summary"
            readOnly
            required
            value={value[name.fieldname.split(" ").join("")]}
            name="summary"
            type="text"
            data-ds--text-field--input="true"
            className="css-mfjdp3"
          />
          <br />
        </div>
      </div>
    }
    else if(name.fieldtype==="number")
      {
      element=<>
        <div data-test-id="request-field-summary">
          <div className="sc-gGCbJM foiuyx">
            <label
              htmlFor="mobnumber"
              className="css-1qzu3fk"
            >
              {name.fieldname}
            </label>
          </div>
          <div
            data-ds--text-field--container="true"
            className="css-1o07z2d"
          >
            <input
              type="tel"
              readOnly
              id="phoneNumber"
              name="phoneNumber"
              pattern="[0-9]{10}"
              placeholder="Number"
              className="css-mfjdp3"
              value={value[name.fieldname.split(" ").join("")]}
            />
            <br />
          </div>
        </div>
      </>
    }
    else if(name.fieldtype==="radio")
      {
      element=<>
        <div data-test-id="request-field-summary">
          <div className="sc-gGCbJM foiuyx">
            <label
              htmlFor="mobnumber"
              className="css-1qzu3fk"
            >
              {name.fieldname}
            </label>
          </div>

          {radiooptions
            .filter((j) => j.field === name.fieldname)
            .map((element) => (
              <>
                <input
                  type="radio"
                  readOnly
                  id={element.FieldName}
                  name={name.fieldname}
                  value={value[name.fieldname.split(" ").join("")]}
                  disabled
                  checked={
                    value[name.fieldname.split(" ").join("")] ===
                    element.FieldName
                  }
                />
                <label for={`${element.FieldName}`}>
                  {element.FieldName}
                </label>
                &nbsp;
              </>
            ))}
        </div>
      </>
    }
    return element
  }
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/getAllFields/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      { console.log(data.data)
        setAllFieldsData(data.data)
  })
  },[project])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/textareas/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        if (data.statusCode === 400) {
          showAlert({
            title:  'Logged Out',
            message: 'You are logged out due to session timeout.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        }); 
          navigate("/v1/jim_admin_user_login");
        }
        setTextarea(data.data);
      })
      .catch((err) => console.log(err));
  }, [project]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/supportTicket/${project}/${m}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTicketData(data)
        if (data.statusCode === 400) {
          //alert("User Logged Out")
          navigate("/v1/jim_admin_user_login");
        } else {
          if (data?.data?.cc !== null) {
            console.log("varen");
            const split = data?.data?.cc?.split(";");
            console.log("split" + split);

            for (var i in split) {
              ccArray.push({ value: split[i], label: split[i] });
              console.log(ccArray);
            }
            setCcList(data?.data?.cc);
            //setSelectedOption(ccArray)
          }
        }
      });
  }, [flag,project]);
  const [selectedOption, setSelectedOption] = useState(ccArray);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/calender/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 400) {
          //alert("User Logged Out")
          navigate("/v1/jim_admin_user_login");
        }
        setCalender(data.data);
      })
      .catch((err) => console.log(err));
  }, [project]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/radio/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 400) {
          // alert("User Logged Out")
          navigate("/v1/jim_admin_user_login");
        }
        setradio(data.data);
      })
      .catch((err) => console.log(err));
  }, [project]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/dropdown/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    }
    )
    .then(res=>res.json())
    .then(data=>
      {
      setDropdownFields(data.data)
      if(data.statusCode===400)
    {
     
    //alert("User Logged Out")
      navigate('/v1/jim_admin_user_login')
    }
  })
      .catch(err=>console.log(err))
  }, [project])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/options/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 400) {
          //alert("User Logged Out")
          navigate("/v1/jim_admin_user_login");
        }
        setRadioOptions(data.data);
      })
      .catch((err) => console.log(err));
  }, [project]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/number/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 400) {
          //alert("User Logged Out")
          navigate("/v1/jim_admin_user_login");
        }
        setNumbers(data.data);
      })
      .catch((err) => console.log(err));
  }, [project]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/getvalue/${project}/${m}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 400) {
          //alert("User Logged Out")
          navigate("/v1/jim_admin_user_login");
        }
        data.data.forEach((value) => {
          console.log(value);
          setValue(value);
        });
      })
      .catch((err) => console.log(err));
  }, [project]);
 
  const [chat, setChat] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/supportTicket/${project}/${m}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 400) {
          //alert("User Logged Out")
          navigate("/v1/jim_admin_user_login");
        }
        console.log(data.data);
        setdata(data.data);
        setUserDetailsEmail(data.data.mail);
        setNumber(data.data.mobile)
        setSeverity({ value: data.data.severity, label: data.data.severity });
        setPriority({ value: data.data.priority, label: data.data.priority });
        setLocation(data.data.location);
        if (data.data.tags!==null) {
          const split = data.data.tags.split(",");
          setTags(split);
        }
        const fileName=data.data.file
        const extension=fileName.substring(fileName.lastIndexOf("."))
        const fileWithoutExtensionId=fileName.substring(9,fileName.lastIndexOf("."))
        console.log(fileWithoutExtensionId)
        const fileId=fileName.substring(0,9)
        console.log(fileId)
        console.log(extension)
        const decryptedFile=decryptMessage(fileWithoutExtensionId)
        console.log(decryptedFile)
        setFile(fileId+decryptedFile+extension)
      })
      .catch((err) => console.log(err));
  }, [flag,project]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/chat/${project}/${m}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 400) {
          //alert("User Logged Out")
          navigate("/v1/jim_admin_user_login");
        }
        if(privateChats)
        {
      setChat(data.data.filter(j=>j.privateChat===1))
        }
        else{
        setChat(data.data);
        }
      })
      .catch((err) => console.log(err));
  }, [privateChats,flag,project]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/admin/${project}/${na}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 400) {
          //alert("User Logged Out")
          navigate("/v1/jim_admin_user_login");
        }
        console.log(data);
        //setName(data.data[0].email);
        setNumber(data.data[0].mobile);
        setSupportName(data.data[0].name)
        //setUserDetailsEmail(data.data[0].email)
      })
      .catch((err) => console.log(err));
  }, [project]);
 
  useEffect(() => {
    console.log(token);
    if(localStorage.getItem("tokenauth")==="false")
    //if (cookie.get("tokenauth") === "false") 
    {
      window.location.href = "/v1/jim_admin_user_login";
    }
  }, []);
  //var [opt,setOption]=useState('');
  //var [summary,setSummary]=useState('');
  const [descript, setDescript] = useState("");
  const navigate = useNavigate();

  const inProgress = (rej) => {
    const token = localStorage.getItem("supportToken");
    //rej.preventDefault();
    const status = "In Progress";
    const id = data.id;
    const obj = { status };
    console.log(obj);
    const res = {
      method: "PUT",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API_URL}/chat/${project}/${m}`, res).then(
      () => {
        console.log("added");
      }
    );
    console.log(
      `${process.env.REACT_APP_API_URL}/inprogress/${data.mail}/${data.user}/${id}/${nams}/${ccList}`
    );
    fetch(`${process.env.REACT_APP_API_URL}/admin/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then( async (data) => {
        console.log(" in progress data is here please check", data.data);
        let emailString = "";
        let nameString = ""
        data.data.forEach((item, index) => {
          emailString += item.email;
          if (index < data.data.length - 1) {
            emailString += ";";
          }
          nameString += item.name;
          if (index < data.data.length - 1) {
            nameString += ",";
          }
        });

        console.log(emailString);
        console.log(nameString)
        // data.data.map((element) => {

        console.log("&&&&&&&&&&&&&&&&",ticketData)
          
        fetch(
      `${process.env.REACT_APP_API_URL}/staffassigned`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization':`Bearer ${token}`
        },
        body: JSON.stringify({
          mail: ticketData.data.mail,
          name: ticketData.data.user,
          id: ticketData.data.id, 
          staff: ticketData.data.admin
        })})
      .then(console.log("email send"))
      .catch((err) => console.log(err));


          fetch(`${process.env.REACT_APP_API_URL}/inprogress`, {
            method: "POST",
            withCredentials: "true",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              mail: emailString,
              name: nameString,
              id: id,
              supportName: nams,
              cc: ccList,
            }),
          })
            .then(() => {
              console.log("email send");
              navigate("/v1/jim/supportdetails");

              //alert("inprogress api called");
            })
            .catch((err) => {
              console.log(err);
              console.log(
                "can you please tell me what is come in ccLIst",
                ccList
              );
              //alert("hello hre is error of inprogress", ccList);
            });
        // });
      });
   // alert("hello");
    try {
      //navigate("/v1/jim/supportdetails");
      //window.location.reload(false); // Omit optional second argument
      setFlag(!flag)
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };
  const resolve = (close) => {
    const token = localStorage.getItem("supportToken");
    //close.preventDefault();
    const status = "Resolved";
    const id = data.id;
    const obj = { status };
    console.log(obj);
    const res = {
      method: "PUT",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API_URL}/chat/${project}/${m}`, res).then(
      () => {
        console.log("added");
      }
    );
    console.log(feed.current);
    /* emailjs.sendForm('service_o2a36hr', 'template_5k9vkpd', feed.current, 'HNkWk6BSnhwAA3yID')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });*/
    fetch(
      `${process.env.REACT_APP_API_URL}/resolved`,
      {
        method: "POST",
        withCredentials: "true",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mail: data.mail,
          name: data.user,
          id: id,
          supportName: nams,
          cc: ccList,
        }),
      }
    )
      .then(console.log("email send"))
      .catch((err) => console.log(err));
    try {
      //navigate("/v1/jim/supportdetails");
      //window.location.reload(false); // Omit optional second argument
      setFlag(!flag)
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };
  const click = (c) => {
    const token = localStorage.getItem("supportToken");
    const plainString = descript.replace(/<[^>]+>/g, '');
    const noSpace = plainString.split('')
    //const noSpace=/^[^-\s][a-zA-Z0-9_\s-]+$/
    console.log(plainString,"descript")
    if (plainString === "") {
      showAlert({
        title:  'Required',
        message: 'Please fill out this field.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    }); 
    }
    else if(noSpace[0]==" " && noSpace[0]!="")
    {
      showAlert({
        title:  'Invalid Input',
        message: 'Please enter text first.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    });
    }
    else {
      const htmlFlag=hasHtmlElements(descript)
      if(htmlFlag===true)
        {
          showAlert({
            title:  'Input Error',
            message: 'Special characters are not allowed.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        });
        }
        else
        {
      //c.preventDefault()
      //setStatusFlag(data.status)
      const user = nams;
      const status = data.status;
      const opt = data.opt;
      const description = descript;
      const summary = data.summary;
      const privateChat=checked

      const obj = { user, opt,privateChat,summary, description, status };
      console.log(obj);
      const res = {
        method: "PUT",
        withCredentials: "true",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      };
      fetch(
        `${process.env.REACT_APP_API_URL}/chats/${project}/${data.id}`,
        res
      ).then(async () => {
        console.log("added");
          const formData = new FormData();
        console.log('selected files===>',selectedFile)
        for(let i=0;i<selectedFile.length;i++)
        {
          console.log(selectedFile[i])
        formData.append("file",selectedFile[i]);
        }
        // for (var key of formData.entries()) {
        //   console.log(key[0] + ', ' + key[1])
        // }
        //formData.append("file",selectedFile);
        console.log('token===>', token)
        console.log(formData)
        const contentType = `multipart/form-data`;
        const result =await fetch(`${process.env.REACT_APP_API_URL}/uploadchatfile/${project}`,{
          method: 'Post',
          withCredentials: 'true',
          headers:
          {
            'Authorization':`Bearer ${token}`,
            //'Content-Type': contentType
          },
          body: formData,
      })

      setSelectedFile([])
       fileInputRef.current.value = ''; 
        //setFlag(!flag)
        setFlag(!flag)
      });
      try {
        navigate("/v1/jim/supportdetails");
        setDescript("")
        setUploadErr(false)
        setFileUploadErrorMessage("")

        //window.location.reload(false); // Omit optional second argument
      } catch (error) {
        navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
      }
    }
  }
  };
  const statusChangeOnclick = () => {
    console.log("sjjk",data)
    if(data.status==="Rejected" || data.status==="Resolved" ||data.status==="Closed"  )
     {
      setShowDropdown(false)
    }
    else{
    setShowDropdown(!showDropdown);
   }
    console.log("shwetsupportStaffbutton is clicked");
  };

  const handleStatusSelect = (selectedOption) => {
    // Handle the selected option as needed
    console.log("Selected option:", selectedOption);
    if(selectedOption==="Resolve")
    {
      resolve()
    }
    else{
      inProgress()
    }
    setShowDropdown(false);
  };
  const [div1, setDiv1] = useState("jhHSQz");
  const [styles1, setStyles1] = useState("puqikv");
  const [show1, setShow1] = useState("Show");
  const [e, f] = useState(0);
  const userdetails = () => {
    if (e === 0) {
      f(1);
      setDiv1("cqxAqq");
      setShow1("Hide");
      setStyles1("4oxin9");
    } else {
      f(0);
      setShow1("Show");
      setDiv1("jhHSQz");
      setStyles1("puqikv");
    }
  };
  if (data?.status === "Resolved") {
    const current = new Date();
    sessionStorage.setItem(
      m,
      `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
    );
  }
  if (data?.status === "Closed") {
    const current = new Date();
    sessionStorage.setItem(
      m + "close",
      `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
    );
  }
  const [x, z] = useState(0);
  const down = () => {
    if (x === 0) {
      z(1);
    } else {
      z(0);
    }
  };
  const handleChange = (newTags) => {
    console.log(newTags);
    if (tags.length < newTags.length) {
      console.log("yes");
      if (newTags.length <= 10) {
        const tofindDuplicates = (arry) => {
          return arry.filter((item, index) => arry.indexOf(item) !== index);
        };
        const duplicates = tofindDuplicates(newTags);
        const newTag = newTags[newTags.length - 1];
        console.log(duplicates);
        console.log(tags);
        const tag = tags.map((element) => {
          return element.toLowerCase();
        });
        if (duplicates.length === 0) {
          if (!tag.includes(newTag.toLowerCase())) {
            setTags(newTags);
            console.log(newTags);
            console.log(tags);
            const tagString = newTags.join(",");
            const res = {
              method: "PUT",
              withCredentials: "true",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ tags: tagString }),
            };
            fetch(
              `${process.env.REACT_APP_API_URL}/addtags/${project}/${m}`,
              res
            ).then(() => {
              console.log("added");
            });
          } else {
            showAlert({
              title:  'Duplicate Tag',
              message: 'This tag is already exists.',
              okText: 'OK',
              callback: () => {
                  console.log('Alert closed');
                  setAlertProps(null)
              }
          }); 
          }
        } else {
          if (!tag.includes(duplicates[0].toLowerCase())) {
            setTags(newTags);
            console.log(newTags);
            console.log(tags);
            const tagString = newTags.join(",");
            const res = {
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ tags: tagString }),
            };
            fetch(
              `${process.env.REACT_APP_API_URL}/addtags/${project}/${m}`,
              res
            ).then(() => {
              console.log("added");
            });
          } else {
            showAlert({
              title:  'Duplicate Tag',
              message: 'This tag is already exists.',
              okText: 'OK',
              callback: () => {
                  console.log('Alert closed');
                  setAlertProps(null)
              }
          }); 
          }
        }
      } else {
        showAlert({
          title:  'Maximum limit ',
          message: 'You reach up to 10 tags.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });
      }
    } else {
      if (newTags.length <= 10) {
        const newTag = tags.filter((element) => !newTags.includes(element));
        console.log(newTag);
        console.log(tags);
        const tag = tags.map((element) => {
          return element.toLowerCase();
        });
        if (newTag[0].toLowerCase()) {
          setTags(newTags);
          console.log(newTags);
          console.log(tags);
          const tagString = newTags.join(",");
          const res = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ tags: tagString }),
          };
          fetch(
            `${process.env.REACT_APP_API_URL}/addtags/${project}/${m}`,
            res
          ).then(() => {
            console.log("added");
          });
        }
      }
    }
  };
  const [emailOptions, setEmailOptions] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/adminfindall/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        for (var i in data.data) {
          emailOptions.push({
            value: data.data[i].email,
            label: data.data[i].email,
          });
        }
      })
      .catch((err) => console.log(err));
  }, [project]);
  // Sample email suggestions
  console.log(emailOptions);
  console.log(selectedOption);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "blue" : "white",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "lightblue",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "black",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "red",
      ":hover": {
        backgroundColor: "red",
        color: "white",
      },
    }),
  };

  const handleChanges = (option) => {
    setSelectedOption(option);
    console.log(option);
    const ccList = [];
    for (var i in option) {
      ccList.push(option[i].value);
    }
    let cc = null;
    if (ccList.length === 0) {
      setCcList(null);
    } else {
      cc = ccList.join(";");
      setCcList(cc);
    }
    const res = {
      method: "PUT",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cc: cc }),
    };
    fetch(`${process.env.REACT_APP_API_URL}/addcc/${project}/${m}`, res).then(
      () => {
        console.log("added");
      }
    );
  };

  const priorityChange = (option) => {
    setPriority(option);
    const res = {
      method: "PUT",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ priority: option.value }),
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/addPriority/${project}/${m}`,
      res
    ).then(() => {
      console.log("added");
    });
  };

  const severityChange = (option) => {
    setSeverity(option);
    const res = {
      method: "PUT",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ severity: option.value }),
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/addseverity/${project}/${m}`,
      res
    ).then(() => {
      console.log("added");
    });
  };

  const getOptionStyle = (index, colour) => {
    const color = colour;
    return {
      backgroundColor: color,
      color: "black", // You can change text color to white or any other color for better contrast
      borderRadius: "25px",
      marginTop: "5px",
      marginBottom: "5px", // Adjust the border-radius as needed
    };
  };

  const customStyle = {
    option: (provided, state) => ({
      ...provided,
      ...getOptionStyle(state.data.index, state.data.color),
      cursor: "pointer",
      width: "100%",
      fontSize: "14px",
      fontWeight: "20px",
      transition: "background-color 0.3s ease, color 0.3s ease",
      



      '&:hover':{
        backgroundColor:"lightgray",
        color:"black",
        fontWeight:'500'
      },
      //marginLeft:'10%',
      //marginRight:'10%'
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#F5F5F5",
      borderRadius: "10%",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: severity.color,
    
    }),
    singleValue: (provided, state) => ({
      ...provided,
      ...getOptionStyle(state.data.index),
    }),
  };

  const getOptionStylePriority = (index, colour) => {
    const color = colour;
    return {
      backgroundColor: color,
      color: "black", // You can change text color to white or any other color for better contrast
      borderRadius: "25px",
      marginTop: "5px",
      marginBottom: "5px", // Adjust the border-radius as needed
    };
  };

  const priorityStyle = {
    option: (provided, state) => ({
      ...provided,
      ...getOptionStylePriority(state.data.index, state.data.color),
      cursor: "pointer",
      width: "100%",
      fontSize: "14px",
      overflowX: "hidden",
      width: "100%", // Adjust the width as per your requirement
      display: "inline-block", 
      transition: "background-color 0.3s ease, color 0.3s ease",

      "&:hover": {
        backgroundColor: "lightgray", // Change background color on hover
        color: "black", 
        fontWeight:'500'
      }
      //marginLeft:'10%',
      //marginRight:'10%'
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#F5F5F5",
      borderRadius: "10%",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: priority.color,
  
    }),
    singleValue: (provided, state) => ({
      ...provided,
      ...getOptionStylePriority(state.data.index),
    }),
  };

  const downloadFile = () => {
    const token = localStorage.getItem("supportToken");
    fetch(`${process.env.REACT_APP_API_URL}/download/${project}/${file}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        // Add any necessary headers here
        //'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        if (blob) {
          const url = window.URL.createObjectURL(blob);
          console.log("Here is type",blob.type)
          console.log('Video URL:', url);
          if(blob.type == "video/mp4"){
         let videoSrc = url
          console.log("here is path",videoSrc)
            setVideoSrc(videoSrc)
            setVideoTitle('Video : '+file.substring(9))
            setVideoLink(videoSrc)
            setShowVideo(true)
            window.document.close()
          
          }else{
          window.open(url, '_blank');
          const a= document.createElement("a");
          a.href = url;
          a.download = file.substring(9); // Specify the desired file name and extension
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  let date = new Date(data?.date).toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
  let parts = date.split('/');
  let formattedDateAbove4 = `${parts[0]}-${parts[1]}-${parts[2]}`;

  console.log(data)
  useEffect(()=>{  
    console.log("here is data of satus",data.status) 
if(data.status == 'Closed' || data.status == 'Rejected' || data.status == 'Resolved'){
  console.log("here is data of satus",data.status) 
  setApplyClass("sc-koErNt SBKnj disabled-div123")
}
else{
  setApplyClass("sc-koErNt SBKnj")
}
  },[data?.status])
 
  console.log("here is ticketData",ticketData)


  
  // const handleFileChange = (event) => {
  //   console.log('file ====>',event.target.files)
  //   const file = event.target.files;
  //   console.log("here is file bro",file.name)
  //   setSelectedFile(file);
  //   setFileName(file.name)
  //   // Here you can also process the file further, e.g., upload it to a server
  // };

  const handleFileChange = async (event) => {
    setUploadErr(false)
    console.log('file ====>', event.target.files);
    const files = event.target.files; 
    if(files.length<=5)
      {
    const allowedMimeTypes= [('.jpg','image/jpeg'), ('.jpeg','image/jpeg'),
        ('.png','image/png'), 
        ('.pdf','application/pdf'), 
         ('.zip','application/x-zip-compressed'),
         ('.zip','application/zip'),  
        ('.mp4','video/mp4')];
    //
    console.log(Array.from(files))
    const validFiles =  Array.from(files).map(async file => {
      if(file.size<=5*1024*1024)
        {
          const buffer = file.arrayBuffer()
          const type=await fileTypeFromBuffer(await buffer)
      if (allowedMimeTypes.includes(file.type) && allowedMimeTypes.includes(type?.mime)  && (file.type===type?.mime || file.type==="application/x-zip-compressed")) {
        return file;
      } else {
        showAlert({
          title:  'File Upload Error',
          message: 'The file you upload is invalid, Please check the file format.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });
        return null;
      }
    }
    else
    {
     setUploadErr(true)
     setFileUploadErrorMessage("File size should less than 5MB")
    return null
    }
    });
    const results = await Promise.all(validFiles);
    console.log(results.filter(file => file!==null))
    setSelectedFile(results.filter(file => file!==null));
    setFileName(validFiles.map(file => file.name));
      }
      else
      {
        showAlert({
          title:  'File Limit Reached',
          message: 'You can upload maximum of 5 files at a time.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      }); 
      }
  };

  const handleDownloadChatAttachment = (el) =>{
    console.log("*************",el)
    const project = "4646"
    fetch(`${process.env.REACT_APP_API_URL}/download/${project}/${el}`, {
      method: "GET",
      headers: {
        // Add any necessary headers here
        //'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
        'Authorization':`Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        if (blob) {
          const url = window.URL.createObjectURL(blob);
          if(blob.type == "video/mp4"){
            let videoSrc = url
            console.log("here is path",videoSrc)
            setVideoSrc(videoSrc)
              setVideoTitle('Video : '+el)
              setVideoLink(videoSrc)
              setShowVideo(true)
              window.document.close()
            
            }else{
              window.open(url, '_blank');
            // Trigger the download
            const a = document.createElement("a");
            a.href = url;
           
            a.download = el; // Specify the desired file name and extension
            document.body.appendChild(a);
            //setVideoSrc(document.body.appendChild(a))
            a.click();
            
            document.body.removeChild(a); // Clean up the anchor element
  
            // Revoke the Blob URL
            window.URL.revokeObjectURL(url);
          }
          // window.open(url, '_blank');
          // const a= document.createElement("a");
          // a.href = url;
          // a.download = el; // Specify the desired file name and extension
          // document.body.appendChild(a);
          // a.click();
          // window.URL.revokeObjectURL(url);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });










    // console.log("here is clicked attachment",e.attachment)
    // const url = `/IM_frontend/public/favicon.ico`;
    // // Create a temporary hidden anchor element
    
    // const a = document.createElement('a');
    // a.style.display = 'none';
    // a.href = url;
    // window.open(url, '_blank');
    // console.log("************",url)
    // // Set the download attribute to the file name
    // a.download = e.attachment;
    // // Append the anchor to the body
    // document.body.appendChild(a);
    // // Click the anchor to trigger the download
    // a.click();
    // // Remove the anchor from the body
    // document.body.removeChild(a);
  }

  return (
    <div>
      <Headersupportadminhotel
        raiseTicket={0}
        registrationRequest={0}
        adminRequests={0}
        backButton={1}
        d={0}
        showAlert={showAlert} 
        setAlertProps={setAlertProps}
      />
      <div className="sc-hORach dWMRNy">
        <div className="sc-eTuwsz mQHAl"></div>
        <main className="sc-hXRMBi bJStMD sc-ibxdXY dKLBoM">
          <div className="sc-hrWEMg cesPGE" >
            <header data-test-id="request-details.summary">
              <div className="sc-TFwJa dUxdnV">
                <div>
                  <h1 className="sc-bHwgHz bGtLRv" style={{paddingLeft:"65px"}}>{data.summary}</h1>
                </div>
                
              </div>
            </header>

            <div className="sc-cCVOAp dYzIwj"></div>

            <div className="sc-bvTASY dhbBCH">
              <div className="sc-gJqsIT hXhZlB">
                <div className="sc-eEieub klGEsD">
                  <div className="sc-hMrMfs dvFdfp">
                    <div className="sc-fkyLDJ gdaJLx">
                      <div className="sc-jUpvKA eGWJLp">
                        <div className="sc-jdfcpN jOaoYz">
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              outline: "0px",
                            }}
                          >
                            <span
                              className="css-1m1hxa9"
                              style={{
                                backgroundColor: "coral",
                                fontWeight: "600",
                                color: "white",
                              }}
                            >
                              <p style={{ marginLeft: "3px" }}>
                                {nam[0].toUpperCase() + nam[1].toUpperCase()}
                              </p>
                              {/*<img src="https://secure.gravatar.com/avatar/0a8339570e62e5a4601c4e3e9d86fbec?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPB-5.png" alt="" className="css-13ep12v" style={{borderRadius: "50%"}}/>*/}
                            </span>
                          </div>
                          <div className="sc-iCwjlJ yANRH">
                            <div role="presentation">
                              <span>
                                <strong>{data.user}</strong> raised this on{" "}
                                {formattedDateAbove4}{" "}
                                {data.time}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/*<button type="button" className="css-puqikf">
    <span className="css-j8fq0c">
    <span className="css-t5emrf">
  <span>Hide details</span></span></span></button>*/}
                      </div>
                      <div className="sc-jRuhRL jAxXjC">
                        <strong><div className="sc-kNBZmU kMDvmM">Description</div></strong>
                        <div className="sc-eopZyb CyzLZ">
                          <div className="ak-renderer-wrapper css-2c6ch1">
                            <div
                              style={{
                                display: "block",
                                width: "100%",
                                position: "absolute",
                              }}
                            ></div>
                            <div className="css-1jset9w">
                              <div className="ak-renderer-document">
                                <p
                                  data-renderer-start-pos="1"
                                  dangerouslySetInnerHTML={{
                                    __html: data.description,
                                  }}
                                  defaultValue={descript}
                                  onChange={(e) => setDescript(e.target.value)}
                                ></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        {data.file !== null && data.file !== "" ? (
                          <p>
                            Attachment:{" "}
                            <button
                              className="css-370xbg"
                              onClick={downloadFile}
                              href="#"
                              style={{ color: "blue" }}
                              to="/edit"
                              target="_self"
                            >
                              {file.substring(9)}
                            </button>
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sc-kDhYZr hKEIRt">
                  <span className="sc-OxbzP fWuoyP">
                    <span>Activity</span>
                    <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={privateChats} 
  onChange={()=>setPrivateChats(!privateChats)}/>
  <label class="form-check-label" for="flexCheckDefault" >
   Private Chats
  </label>
</div>
                  </span>
                  <div className="sc-jRhVzh keVOrZ"></div>
                  {chat.length >=1 ? chat.map((e, index) => {
                    let date2 = new Date(e.date).toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit'});
                    let parts = date2.split('/');
                    let formattedDate = `${parts[0]}-${parts[1]}-${parts[2]}`;
                      return (
                        <div className="sc-kqlzXE cHtPAi">
                          <div
                            className="sc-jhaWeW GHUyr"
                            data-test-id="request-details.activity-item"
                          >
                            <div className="LayoutStyles__Container-sc-14140bv-1 hUwXrK">
                              <div className="LayoutStyles__AvatarSectionDiv-sc-14140bv-0 hvNajw">
                                <div
                                  style={{
                                    display: "inlineBlock",
                                    position: "relative",
                                    outline: "0px",
                                  }}
                                >
                                  <span
                                    className="css-1qdk52b"
                                    style={{
                                      backgroundColor: "#00695C",
                                      fontWeight: "600",
                                      color: "white",
                                    }}
                                  >
                                    {console.log(e)}
                                    <p style={{ marginLeft: "5px" }}>
                                      {e.user[0].toUpperCase() +
                                        e.user[1].toUpperCase()}
                                    </p>
                                    {/*<img src="" alt="" className="css-13ep12v" style={{bordeRadius: "50%"}}/>*/}
                                  </span>
                                </div>
                              </div>
                              <div className="LayoutStyles__ContentSectionDiv-sc-14140bv-2 fErUzI">
                                <div>
                                  <div className="HeaderStyles__TopItemsContainer-sc-1x5yo10-4 dChGZQ">
                                    <div className="HeaderStyles__TopItem-sc-1x5yo10-3 cjZbUi">
                                      <span className="FieldStyles__Span-sc-173n8o8-1 ekKeSG">
                                        {e.user}
                                        {e.privateChat?<span title="private chat" className="sc-lcpuFF kHmppP" aria-hidden="true">*</span>:""}
                                      </span>
                                    </div>
                                    <div className="HeaderStyles__TopItem-sc-1x5yo10-3 cjZbUi">
                                      <div role="presentation">
                                        <span className="FieldStyles__Span-sc-173n8o8-1 jLNzsu">
                                          {formattedDate}{" "}
                                          {e.time}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="CommentStyles__Content-lr96k6-0 kuiQOK">
                                    <div className="ak-renderer-wrapper css-2c6ch1">
                                      <div
                                        style={{
                                          display: "block",
                                          width: "100%",
                                          position: "absolute",
                                        }}
                                      ></div>
                                      <div className="css-1jset9w">
                                        <div className="ak-renderer-document">
                                          <p
                                            data-renderer-start-pos="1"
                                            dangerouslySetInnerHTML={{
                                              __html: e.description,
                                            }}
                                          ></p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="FooterStyles__ActionsContainer-sc-7f01gi-2 irmqVw"></div>
                                  {
                                  e.attachment ? e.attachment.split(';').map((el)=> <button className="btn btn-secondary mb-2 ml-1" value={el} onClick={()=>handleDownloadChatAttachment(el)}>{el.substring(14)}</button>)  : null
                                }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    
                  }):null}
                </div>
              </div>
             
              <div className={applyClass} >
              <div className="share-img"  style={{marginLeft: "150px"}}><strong>ID: {data.id}</strong></div>
              {showDesc ? (
                  <div className="description-data" style={{    marginLeft: "119px"}}>
              
                    <div
                      data-test-id="request-details.status-lozenge"
                      className="request-detailsx dropdown-container"  tabIndex={0} onBlur={statusChangeOnclick}
                    >
                      <h3 className="sc-lnrBVv hzWGel">
                        <span>Status</span>
                        
                      </h3>
                   
       
                      {/* <div className="css-cztx6i" onClick={statusChangeOnclick}>
                        {data.status}
                        
  
  <div class="down-arrow" >&#x25BC;</div>

                      </div> */}
                      
                      
                        <div className="dropdown" style={{marginLeft: "0px",width: "160px"}}>
                          {/* Add your dropdown content here */}
                          {/* <div onClick={() => {handleStatusSelect("Option 1")}}>
                            Resolve
                          </div>
                          <div onClick={() => {handleStatusSelect("Option 2")}}>
                            Reject
                          </div> */}
                          {/* Add more options as needed */}
                          {data.status!=="Rejected" && data.status!=="Resolved" && data.status!=="Closed"?<select class="form-select"
                           id="capitalCity"
                           name="capitalCity"
                           value={data.status}
                           onChange={(e)=>{handleStatusSelect(e.target.value)}}
                         >
                          <option disabled>
                         {data.status}
                       </option>
                       {data.status==="opened"?<option onClick={() => {handleStatusSelect("InProgress")}} value={"InProgress"}>
                         InProgress
                       </option>:""}
                       {data.status==="In Progress"?<option onClick={() => {handleStatusSelect("Resolve")}} value={"Resolve"}>
                         Resolve
                       </option>:""}
                       </select>:<select class="form-select"
                           id="capitalCity"
                           name="capitalCity"
                           value={data.status}
                           onChange={(e)=>{handleStatusSelect(e.target.value)}}
                         >
                         <option disabled>
                         {data.status}
                       </option>
                       </select>}
                        </div>
                           
                      
                    </div>
                    <br />

                    <div className="request-detailsx dropdown-container">
                      <h3 className="sc-lnrBVv hzWGel">
                        <span>Request type</span>
                      </h3>
                      <div
                        className="dropdown" style={{marginLeft: "0px",width: "160px"}}
                       
                      >
                        {/*<img className="sc-fFTYTi hQlek" src="https://globallogic.atlassian.net/rest/servicedeskapi/requesttype/icon/type/SD_REQTYPE/id/10550"/>*/}
                        {/* <span className="sc-bTiqRo cpDveY">{data.opt}</span> */}
                        <input type="text" class="form-control" style={{marginBottom:"0px",backgroundColor:"white"}}
                           id="capitalCity"
                           name="capitalCity"
                           value={data.opt}
                           disabled
                         ></input>
                      </div>
                    </div>

                    <br />

                    <div className="request-detailsx dropdown-container">
                      <h3 className="sc-lnrBVv hzWGel">
                        <span>Severity</span>
                      </h3>
                      
                      <div
                        className="dropdown" style={{marginLeft: "0px",width: "160px",paddingLeft:"0px",paddingRight:"0px",paddingTop:"0px",paddingBottom:"0px"}}
                       
                      >
                      <Select 
                      // components={{ Menu: () => null }}
                      // closeMenuOnSelect={true}
                        closeMenuOnSelect={true}
                        components={makeAnimated}
                        value={severity}
                        options={[
                          {
                            value: "Blocker-S1",
                            label: "Blocker-S1",
                          },
                          {
                            value: "Critical-S2",
                            label: "Critical-S2",
                          },
                          { value: "Major-S3", label: "Major-S3" },
                          { value: "Minor-S4", label: "Minor-S4"},
                        ]}
                        styles={customStyle}
                        onChange={severityChange}
                      />
                      </div>
                    </div>
                    <br />
                    <div className="request-detailsx dropdown-container">
                      <h3 className="sc-lnrBVv hzWGel">
                        <span>Priority</span>
                      </h3>

                      <div
                        className="dropdown" style={{marginLeft: "0px",width: "160px",paddingLeft:"0px",paddingRight:"0px",paddingTop:"0px",paddingBottom:"0px"}}
                       >
                      <Select
                        closeMenuOnSelect={true}
                        components={makeAnimated}
                        value={priority}
                        options={[
                          { value: "High", label: "High"},
                          { value: "Medium", label: "Medium" },
                          { value: "Low", label: "Low" },
                         
                        
                        ]}
                        styles={priorityStyle}
                        onChange={priorityChange}
                      />
                    </div>
                    </div>

                    <br />
                    <div className="request-detailsx" style={{display:"block"}}>
                      <h3 className="sc-lnrBVv hzWGel">
                        <span>Tags</span>
                      </h3>
                      <TagsInput
                        value={tags}
                        onChange={handleChange}
                        addOnBlur={handleChange}
                        tagProps={{
                          className: "react-tagsinput-tag",
                          classNameRemove: "react-tagsinput-remove",
                        }}
                      />
                      </div>
                    
                    <div className="request-detailsx" style={{display:"block",width:"158px"}}>
                      <h3 className="sc-lnrBVv hzWGel">
                        <span>Add CC</span>
                      </h3>
                      <Select
                        closeMenuOnSelect={false}
                        components={makeAnimated}
                        isMulti
                        value={selectedOption}
                        options={emailOptions}
                        styles={customStyles}
                        onChange={handleChanges}
                        placeholder="Add cc"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div style={{ margin: "30px" }}>
            
              {data.status !== "Resolved" &&
              data.status !== "Closed" &&
              data.status !== "Rejected" ? (
                <><span className="sc-OxbzP fWuoyP">
                <span>Comments
                <div className="form-check form-switch" style={{paddingLeft: "7rem"}}>
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={checked}
  onChange={()=>setChecked(!checked)}/>
  <label class="form-check-label" for="flexSwitchCheckDefault">Private chat</label>
</div>
                </span>
              </span>
              
                  <div
                    className="sc-chAAoq hcyXVm"
                    id="description"
                    data-test-id="rich-text-area-field"
                  >
                    <div className="sc-hAXbOi eotBHb">
                      <div className="css-1dr2qy6">
                        <div
                          style={{
                            display: "block",
                            width: "100%",
                            position: "absolute",
                          }}
                        ></div>
                        <div className=" css-18w41as">
                          <ReactQuill
                            theme="snow"
                            value={descript}
                            style={{ height: "105px" }}
                            onChange={setDescript}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ paddingTop: "50px" }}>
                  <input type="file" multiple  ref={fileInputRef} onChange={handleFileChange} accept=".pdf,.mp4,.png,.jpg,.jpeg,.zip" style={{width: "223px"}} />
                  {
        uploadErr==true ? <p style={{color:"red",fontSize:"12px"}}>{fileUploadErrMessage}</p> : null
      }
      <p style={{color:"blue",fontSize:"12px", marginTop:"4px"}}>Max file size: 5 MB</p>
      <p style={{fontSize:"12px", marginTop:"0px"}}>Supported File Formats: zip,jpg,jpeg,png,pdf,mp4.</p>

                    <div className="sc-cbkKFq icDrbe" style={{justifyContent:'center', width: '65%', marginLeft: '8px'}}>
                      <button
                        type="submit"
                        className="request-create-form-submit css-68ar9j"
                        onClick={click}
                      >
                        <span className="css-j8fq0c">
                          <span className="css-t5emrf">
                            <span>Submit</span>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <br />
              <br />
              <div className="sc-hizQCF ljPvSw">
                <div className="sc-eNPDpu eZXad">
                  <div className="sc-hARARD ejZygw">
                    <div
                      style={{
                        display: "inlineBlock",
                        position: "relative",
                        outline: "0px",
                      }}
                    >
                      <span
                        className="css-1qdk52b"
                        style={{
                          backgroundColor: "#00695C",
                          fontWeight: "600",
                          color: "white",
                        }}
                      >
                        <p style={{ marginLeft: "5px" }}>
                          {nam[0].toUpperCase() + nam[1].toUpperCase()}
                        </p>
                      </span>
                    </div>
                    {/*<img src="https://secure.gravatar.com/avatar/0a8339570e62e5a4601c4e3e9d86fbec?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPB-5.png" alt="" className="css-13ep12v" style={{borderRadius: "50%"}}/>*/}
                    <div className="sc-cgHJcJ dWOhZl">
                      <div role="presentation">
                        <span>
                          <h6>{supportName}</h6>User Details
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className={`css-${styles1}`}
                    onClick={userdetails}
                  >
                    <span className="css-j8fq0c">
                      <span className="css-t5emrf">
                        <span>{show1} details</span>
                      </span>
                    </span>
                  </button>
                </div>
                <div className={`sc-ccLTTT ${div1}`}>
                  {x === 0 ? (
                    <>
                      <div data-test-id="request-field-summary">
                        <div className="sc-gGCbJM foiuyx">
                          <label htmlFor="summary" className="css-1qzu3fk">
                            Mail
                          </label>
                        </div>
                        <div
                          data-ds--text-field--container="true"
                          className="css-1o07z2d"
                        >
                          <input
                            id="summary"
                            readOnly
                            required
                            value={userDetailsEmail}
                            name="summary"
                            type="text"
                            data-ds--text-field--input="true"
                            className="css-mfjdp3"
                          />
                        </div>
                      </div>
                      <div data-test-id="request-field-summary">
                        <div className="sc-gGCbJM foiuyx">
                          <label htmlFor="summary" className="css-1qzu3fk">
                            Mobile Number
                          </label>
                        </div>
                        <div
                          data-ds--text-field--container="true"
                          className="css-1o07z2d"
                        >
                          <input
                            id="summary"
                            readOnly
                            required
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                            name="summary"
                            type="text"
                            data-ds--text-field--input="true"
                            className="css-mfjdp3"
                          />
                        </div>
                      </div>
                      <div data-test-id="request-field-summary">
                        <div className="sc-gGCbJM foiuyx">
                          <label htmlFor="summary" className="css-1qzu3fk">
                            Location
                          </label>
                        </div>
                        <div
                          data-ds--text-field--container="true"
                          className="css-1o07z2d"
                        >
                          <input
                            id="summary"
                            readOnly
                            required
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            name="summary"
                            type="text"
                            data-ds--text-field--input="true"
                            className="css-mfjdp3"
                          />
                          <br />
                        </div>
                      </div>
                      <div align="center">
                        <button
                          type="button"
                          style={{
                            border: "aliceblue",
                            backgroundColor: "white",
                          }}
                          onClick={down}
                        >
                          <img src={arrow} />
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div data-test-id="request-field-summary">
                        <div className="sc-gGCbJM foiuyx">
                          <label htmlFor="summary" className="css-1qzu3fk">
                            Mail
                          </label>
                        </div>
                        <div
                          data-ds--text-field--container="true"
                          className="css-1o07z2d"
                        >
                          <input
                            id="summary"
                            readOnly
                            required
                            value={userDetailsEmail}
                            name="summary"
                            type="text"
                            data-ds--text-field--input="true"
                            className="css-mfjdp3"
                          />
                        </div>
                      </div>
                      <div data-test-id="request-field-summary">
                        <div className="sc-gGCbJM foiuyx">
                          <label htmlFor="summary" className="css-1qzu3fk">
                            Mobile Number
                          </label>
                        </div>
                        <div
                          data-ds--text-field--container="true"
                          className="css-1o07z2d"
                        >
                          <input
                            id="summary"
                            readOnly
                            required
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                            name="summary"
                            type="text"
                            data-ds--text-field--input="true"
                            className="css-mfjdp3"
                          />
                        </div>
                      </div>
                      <div data-test-id="request-field-summary">
                        <div className="sc-gGCbJM foiuyx">
                          <label htmlFor="summary" className="css-1qzu3fk">
                            Location
                          </label>
                        </div>
                        <div
                          data-ds--text-field--container="true"
                          className="css-1o07z2d"
                        >
                          <input
                            id="summary"
                            readOnly
                            required
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            name="summary"
                            type="text"
                            data-ds--text-field--input="true"
                            className="css-mfjdp3"
                          />
                          <br />
                        </div>
                      </div>

                      {allFieldsData.length === 0 ?"": allFieldsData.filter(j=>j.groupname===null||j.groupname===value[radiooptions.filter(e=>e.FieldType==="HeadGroup"&&e.FieldName===j.groupname).map(f=>f.field)[0]?.split(" ").join("")]).map((name) => {return getElement(name)})}
                      <div align="center">
                        <button
                          type="button"
                          onClick={down}
                          style={{
                            border: "aliceblue",
                            backgroundColor: "white",
                          }}
                        >
                          <img src={uparrow} />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <br />
            </div>
          </div>
        </main>
      </div>
      {showVideo && <VideoModal title={videoTitle} videoLink={videoLink} okText={"ok"} cancel={setShowVideo} />}
    </div>
  );
}

export default Supportdetailshotel;
