import React, { useRef } from 'react'
import { useState,useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import img from './image/filenot.jpg';
import { li } from '../App';
import emailjs from '@emailjs/browser';
import Headeradminhotel from './headeradminhotel';
import cookie from 'js-cookie';
import {jwtDecode} from 'jwt-decode'


//const project=cookie.get("project")
function Requesthotel() {
  const token=localStorage.getItem("adminToken")
    const [data,setdata]=useState([])
    
  const [project,setProject]=useState("")


  useEffect(() => {
   try{
 const decodedToken = jwtDecode(token);
 setProject(decodedToken.project)
   }
   catch(error)
   {
     navigate("/v1/jim_user_login")
   }
 },[])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/finds/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    }
    )
    .then(res=>res.json())
    .then(data=>{setdata(data.data)
      if(data.statusCode===400)
      {
        alert("user logged out")
        navigate("/v1/jim_admin_user_login")
      }
  })
    .catch(err=>console.log(err))
  
  }, [project])
  useEffect(() => {

    console.log(token)
   if(localStorage.getItem("tokenauth")==="false")
   //if(cookie.get("tokenauth")==="false")
   {
    window.location.href="/v1/jim_admin_user_login"
   }
  }, [])
  console.log(data)

  /*const click=(e)=>
  {
    console.log(e);
  {<Detail deta={e}/>}
  }*/
  //console.log(List)
  const clicks=(v)=>
  {
    sessionStorage.setItem("id", v);
  }

const navigate=useNavigate()
 
  //const [status,setStatus]=useState("")
const hiddenAccept = useRef(null);
const hiddenDecline = useRef(null);
const accpt=useRef()
const dec=useRef()
const handleAccept = event => {
  hiddenAccept.current.click();
  console.log(hiddenAccept.current)
};
const handleDecline = event => {
    hiddenDecline.current.click();
    console.log(hiddenDecline.current)
  };
  const accept=(e,t)=>
  {
    const token=localStorage.getItem("adminToken")
    e.preventDefault()
    console.log("yes")
   //var t=sessionStorage.getItem("id")
    var name=t.name
    var pass=t.pass
    var email=t.email
    var mobile=t.mobile
    var user=t.user
    var status="Accepted"
    const obj={status}
    const object={email,name,pass,mobile,user}
    console.log(obj)
    const res={
      method: 'PUT',
      withCredentials: 'true', 
      headers: {'Authorization':`Bearer ${token}`, 'Content-Type': 'application/json' },
      body: JSON.stringify(obj)
  };
  const result={
    method: 'POST',
    withCredentials: 'true',
    headers: { 'Authorization':`Bearer ${token}`,'Content-Type': 'application/json' },
    body: JSON.stringify(object)
};
  fetch(`${process.env.REACT_APP_API_URL}/status/${project}/${t.email}`, res)
        .then(()=>
        {
            console.log("added")    
        })
        fetch(`${process.env.REACT_APP_API_URL}/useradd/${project}`, result)
        .then((data)=>
        {
          if(data.status===400)
          {
            alert("user logged out")
            navigate('/v1/jim_admin_user_login')
          }
            console.log("added")    
        })
       
        t.status="Accepted"
        
        //window.location.reload(false);
       /* emailjs.sendForm('service_u1cw0tj', 'template_xniuwwi', accpt.current, 'BcNE7hTZSQgnOCqn8')
        .then((result) => {
          
        }, (error) => {
            console.log(error.text);
        });*/
        fetch(`${process.env.REACT_APP_API_URL}/registrationstatus/${t.email}/${t.name}/${`your registration request is ${t.status}`}`)
        .then(console.log("email send"))
        .catch(err=>console.log(err))
      
        try {
          
          navigate("/v1/jim/admin_user_registration_request");
         // Omit optional second argument
        } catch (error) {
          navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
        }
  }
  const decline=(y,v)=>
  {
    y.preventDefault()
    var status="Declined"
    const obj={status}
    console.log(obj)
    const res={
      method: 'PUT',
      withCredentials:true,
      headers: { 'Authorization':`Bearer ${token}`,'Content-Type': 'application/json' },
      body: JSON.stringify(obj)

  };

  fetch(`${process.env.REACT_APP_API_URL}/status/${project}/${v.email}`, res)
        .then((data)=>
        {
          if(data.status===400)
          {
          alert("user logged out")
            navigate('/v1/jim_admin_user_login')
          }
            console.log("added")
            
        })
        v.status="Declined"
    /*emailjs.sendForm('service_u1cw0tj', 'template_xniuwwi', dec.current, 'BcNE7hTZSQgnOCqn8')*/
    fetch(`${process.env.REACT_APP_API_URL}/registrationstatus/${v.name}/${v.name}/${`your registration request is ${v.status}`}`)
    .then(console.log("email send"))
    .catch(err=>console.log(err))
  
    try {
        navigate("/v1/jim/admin_user_registration_request"); // Omit optional second argument
      } catch (error) {
        navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
      }
    }

  return (
    <>
         <Headeradminhotel requests={0} backButton={1} raiseTicket={0} registrationRequest={0} adminRequests={0}/>
      <div className="sc-hORach dWMRNy">
      <div className="sc-eTuwsz iBEElS">
      </div>
      <main className="sc-hXRMBi bJStMD sc-ibxdXY fgfUVu">
      <div className="sc-bwCtUz ijKeUx">
      <section className="sc-bJTOcE bFtVSx">
      <header>
      <span className="sc-rBLzX fayCzS">
      {/*<a className="sc-CtfFt ewysoW sc-Rmtcm DgkTO" href="/servicedesk/customer/portals" target="_self">GL Services Portal Home</a>
      */}</span>
      <div className="sc-TFwJa dUxdnV"><div><h1 className="sc-bHwgHz bGtLRv">Registration Requests</h1></div>
      </div>
      </header>
      <br/>
      <div className="sc-PLyBE iGtjzS">
      <div>
      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
      <div>
      <div>
      <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
      {/*<div>
      <button aria-controls="uid46" aria-expanded="false" aria-haspopup="true" actionsubjectid="requestListMenu" type="button" className="css-q68uj">
      <span className="css-j8fq0c">
      <span className="css-noix33">
      <span role="img" aria-label="more" className="css-snhnyn" >
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <g fill="currentColor" fill-rule="evenodd"><circle cx="5" cy="12" r="2">
      </circle>
      <circle cx="12" cy="12" r="2">
      </circle>
      <circle cx="19" cy="12" r="2"></circle>
      </g>
      </svg>
      </span>
      </span>
      </span>
      </button>
    </div>*/}
      </div>
      </div>
      <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
      <div style={{height: "100%", width: "100%"}}></div></div>
      <div className="styledContentContainer-p0j3f7-0 caSnlw"><div style={{position: "absolute", zIndex: "400", top: "0px", left: "0px",transform: "translate3d(-95px, 40px, 0px)"}}></div>
      </div></div></div></div></div>
      <div className="sc-jqIZGH bokdCL">
      </div>
     

<div>
      {data.length!==0?<div className="sc-ghsgMZ gDXBCU" data-test-id="request-list.request-list-table-v2">
      <div className="sc-dznXNo zdDVp">
      <div data-testid="request-list.request-list-table-v2--loading--container--advanced" className="css-h8zv0n">
      <table data-testid="request-list.request-list-table-v2--table" className="css-fkqt3q" style={{height: "150px",LocalDynamicTableHoverBg: "var(--ds-background-neutral-subtle-hovered, #FAFBFC)", LocalDynamicTableHighlightedBg: "var(--ds-background-selected, #DEEBFF)", LocalDynamicTableHoverHighlightedBg: "var(--ds-background-selected-hovered, #B3D4FF)", LocalDynamicTableRowFocusOutline: "var(--ds-border-focused, #4C9AFF)"}}>
      <thead data-testid="request-list.request-list-table-v2--head" className="css-1g54gxt">
      <tr>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-1xabuij" style={{/*"--local-dynamic-table-width: 0.5%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <span>Email Id</span>
      </th>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-100m01t" aria-sort="descending" style={{/*"--local-dynamic-table-width: 0.9%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
        <button type="button" aria-roledescription="Sort button">Name</button></th>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-1xabuij" style={{/*"--local-dynamic-table-width: 1.5%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <span>Mobile Number</span>
      </th>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-1xabuij" style={{/*"--local-dynamic-table-width: 1%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <span>Type</span>
      </th>
      {/*<th data-testid="request-list.request-list-table-v2--head--cell" className="css-1xabuij" style={{/*"--local-dynamic-table-width: 1%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"}>
      <span>Project</span>
      </th>*/}
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-11phftz" style={{/*"--local-dynamic-table-width: 0.9%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <button type="button" aria-roledescription="Sort button">Action</button>
      </th></tr></thead>
      {data!==null?data.map((e)=>{ 
        return <> <tbody data-testid="request-list.request-list-table-v2--body" style={{}}>
      <tr data-testid="request-list.request-list-table-v2--row-row-HRIN-24438" className="css-unmje5">
      <td data-testid="request-list.request-list-table-v2--cell-0" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 0.5%;"*/}}>
      <div role="presentation">
      <p className="cv-exclude-from-customisation sc-ekulBa AlQuL"  href="/servicedesk/customer/portal/10/HRIN-24438" target="_self">{e.email}</p></div></td>
      <td data-testid="request-list.request-list-table-v2--cell-1" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 0.9%;"*/}}>
      <p className="cv-exclude-from-customisation sc-ekulBa AlQuL"  onClick={()=>clicks(e)} to="/detail" target="_self">{e.name}</p></td>
      <td data-testid="request-list.request-list-table-v2--cell-2" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 1.5%;"*/}}>
      <div className="sc-jkCMRl gjGtwC">
      <p className="cv-exclude-from-customisation sc-ekulBa AlQuL" onClick={()=>clicks(e)} to="/detail" target="_self">{e.mobile}</p></div></td>
      <td data-testid="request-list.request-list-table-v2--cell-3" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 1%;"*/}}>
      <div role="presentation"><div data-test-id="request-details.status-lozenge">
      <span className="css-cztx6i" style={{maxWidth: "100%"}}><span className="css-1et8jlg" style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}}>{e.user}</span></span></div></div></td>
      {/*<td data-testid="request-list.request-list-table-v2--cell-3" className="css-zu8jlf" /*style="--local-dynamic-table-width: 1%;">
      <div class="row">
        <div class="col">
            <select>
                <option hidden >Select option</option>
                <option> Hotel </option>
                <option> Shop </option>
                <option> Signage </option>
            </select>
        </div>
    </div>
      </td>*/}
      <td data-testid="request-list.request-list-table-v2--cell-4" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 0.9%;"*/}}>
     

      <div className="sc-jkCMRl gjGtwC">
      <div class="w3-section">
        {e.status===""?  <div style={{display:"flex"}}>
        <form onSubmit={(event)=>accept(event,e)} ref={accpt}>
      <input type="text" name="name"  value={e.name} style={{display:"none"}}/>
      <input type="text" name="status"  value={e.status} style={{display:"none"}}/>
      <input type="text" name="email" value={e.email} style={{display:"none"}}/>
      <input type="text" name="message"  value="We are excited to inform you that your registration was successful! Welcome to our community, and thank you for choosing us." style={{display:"none"}}/>
        {/*<button type="button" class="w3-button w3-green" onClick={(event)=>accept(event,e)}>Accept</button>*/}
        <input
        type="submit"
        value="Accept"
        class="w3-button w3-green"
        onClick={(event)=>accept(event,e)}
        />
        </form>
        <form onSubmit={(event)=>decline(event,e)} ref={dec}>
      <input type="text" name="name"  value={e.name} style={{display:"none"}}/>
      <input type="text" name="status"  value={e.status} style={{display:"none"}}/>
      <input type="text" name="email" value={e.email} style={{display:"none"}}/>
      <input type="text" name="message"  value="We regret to inform you that your registration request has been declined." style={{display:"none"}}/>
        {/*<button class="w3-button w3-red" onClick={handleDecline}>Decline</button>*/}
        <input
        type="submit"
        required
        value="Decline"
        class="w3-button w3-red"
       />
        </form>
        </div>:e.status==="Declined"?<button type="button" class="w3-button w3-red" disabled>{e.status}</button>:<button type="button" class="w3-button w3-green" disabled>{e.status}</button>}
      </div>
        </div></td></tr>
      </tbody>
   
      </>
      }):""}
      </table></div></div></div>:
    <div className="sc-imDfJI jpQXKX"><div className="sc-hAnkBK bETBem">
      <img src={img} alt="No requests were found."/>
      <div className="sc-DNdyV RIerR">
      <h3>We couldn’t find any requests  </h3>
      
    </div>
    </div>
    </div>}
    </div>    
    <div className="sc-jzgbtB gUQSWG"></div>
    <div className="sc-jqIZGH bokdCL"></div>
    
    </section></div>
   </main>
   </div>
    </>
  )
}


export default Requesthotel
