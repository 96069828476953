import React from 'react'
import '../css/cmt.css';
import imge from './image/jio.jpg';
import { useNavigate } from 'react-router-dom';
import Profileadminhotel from './profileadminhotel';

function Headeradminhotel({
  requests,
  m,
  disp,
  serv,
  na,
  backButton,
  raiseTicket,
  registrationRequest,
  adminRequests,
  userManagement,
  requestsTrack,
  supportRequestsTrack,
  v,
  d,
  newAddition,
  adminmanage,
  form,
  showAlert,
  setAlertProps
}) {
  const navigate = useNavigate();
  const click = () => {
    try {
      navigate("/v1/jim/user_homepage"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };
  const graph = () => {
    try {
      navigate("/v1/jim/supportmap"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };
  const graphs = () => {
    try {
      navigate("/v1/jim/graph"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };
  const track = () => {
    try {
      navigate("/v1/jim/admin_dashboard"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };
  const tracks = () => {
    try {
      navigate("/v1/jim/statistics"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };
  const adminManagement = () => {
    try {
      navigate("/v1/jim/super_projectcreation"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };
  const back = () => {
    try {
      navigate(-1); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };
  const editform = () => {
    try {
      navigate("/v1/jim/super_projectformfields"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };
  const admin = () => {
    try {
      navigate("/v1/jim/admin_home"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };
  const list = () => {
    try {
      navigate("/v1/jim/admin_proj_memberlist"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };
  const navSettings = () => {
    try {
      navigate("/v1/jim/admin_proj_settings"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };
  const user = () => {
    try {
      navigate("/v1/jim/admin_adduser_support"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };
  const raise = () => {
    try {
      navigate("/v1/jim/create_ticket"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };
  const regrequest = () => {
    try {
      navigate("/v1/jim/admin_user_registration_request"); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };

  return (
    <>
      <div>
        <div className="sc-bMVAic eJvVfu" style={{ height: `${m}px` }}></div>
        <header className="sc-bRBYWo igIKoG">
          <a
            className="sc-hzDkRC cv-exclude-from-customisation gNaqGG"
            style={{ transform: "none" }}
            href="/v1/jim/admin_home"
            target="_self"
          >
            <div className="sc-gZMcBi kcCKhl">
              {/*<img className="sc-iwsKbI PlFKx" src={img} alt="Jio Services Portal Home"/>*/}
              <img
                className="sc-iwsKbI PlFKx"
                src={imge}
                alt="Jio Services Portal Home"
              />
              &nbsp;
              <strong
                align="center"
                style={{ color: "black", fontSize: "x-large" }}
              >
                {serv}
              </strong>
            </div>
          </a>

          <nav className="sc-jhAzac cXAxUP" style={{ display: disp }}>
            <ul className="sc-fBuWsC hRNIvE">
              {/*<li className="sc-eqIVtm hfyJKb">
      <div className="sc-jDwBTQ fYOBLE">
      <div className="sc-jAaTju eaUPvS sc-jWBwVP eRRDfp">
      <div className="sc-cvbbAY Wndrw"></div>
      <button className="sc-brqgnP busxTh" aria-expanded="false">
      <span className="sc-cMljjf hmocgh">
      <span role="img" aria-label="Search" className="css-1afrefi">
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path d="M16.436 15.085l3.94 4.01a1 1 0 01-1.425 1.402l-3.938-4.006a7.5 7.5 0 111.423-1.406zM10.5 16a5.5 5.5 0 100-11 5.5 5.5 0 000 11z" fill="currentColor" fillRule="evenodd">
  </path></svg></span></span></button></div></div></li>*/}
              {requestsTrack === 1 ? (
                <li className="sc-eqIVtm hfyJKb">
                  <div className="sc-csuQGl eTmqqm">
                    <div>
                      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
                        <div>
                          <div>
                            <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
                              <div>
                                <button
                                  className="sc-gPEVay kOqTiO"
                                  onClick={track}
                                >
                                  <div className="sc-jlyJG jrYKHX">
                                    <span>Dashboard</span>
                                  </div>
                                </button>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
                            <div
                              style={{ height: "100%", width: "100%" }}
                            ></div>
                          </div>
                          <div className="styledContentContainer-p0j3f7-0 caSnlw">
                            <div
                              style={{
                                top: "0px",
                                left: "0px",
                                position: "absolute",
                                zIndex: "400",
                                opacity: "0",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
              &nbsp;
              {d === 1 ? (
                <li className="sc-eqIVtm hfyJKb">
                  <div className="sc-csuQGl eTmqqm">
                    <div>
                      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
                        <div>
                          <div>
                            <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
                              <div>
                                <button
                                  className="sc-gPEVay kOqTiO"
                                  onClick={graph}
                                >
                                  <div className="sc-jlyJG jrYKHX">
                                    <span>Ticket Track</span>
                                  </div>
                                </button>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
                            <div
                              style={{ height: "100%", width: "100%" }}
                            ></div>
                          </div>
                          <div className="styledContentContainer-p0j3f7-0 caSnlw">
                            <div
                              style={{
                                top: "0px",
                                left: "0px",
                                position: "absolute",
                                zIndex: "400",
                                opacity: "0",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
              &nbsp;
              {v === 1 ? (
                <li className="sc-eqIVtm hfyJKb">
                  <div className="sc-csuQGl eTmqqm">
                    <div>
                      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
                        <div>
                          <div>
                            <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
                              <div>
                                <button
                                  className="sc-gPEVay kOqTiO"
                                  onClick={graphs}
                                >
                                  <div className="sc-jlyJG jrYKHX">
                                    <span>Ticket Track</span>
                                  </div>
                                </button>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
                            <div
                              style={{ height: "100%", width: "100%" }}
                            ></div>
                          </div>
                          <div className="styledContentContainer-p0j3f7-0 caSnlw">
                            <div
                              style={{
                                top: "0px",
                                left: "0px",
                                position: "absolute",
                                zIndex: "400",
                                opacity: "0",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
              &nbsp;
              {form === 1 ? (
                <li className="sc-eqIVtm hfyJKb">
                  <div className="sc-csuQGl eTmqqm">
                    <div>
                      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
                        <div>
                          <div>
                            <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
                              <div>
                                <button
                                  className="sc-gPEVay kOqTiO"
                                  onClick={editform}
                                >
                                  <div className="sc-jlyJG jrYKHX">
                                    <span>Edit form</span>
                                  </div>
                                </button>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
                            <div
                              style={{ height: "100%", width: "100%" }}
                            ></div>
                          </div>
                          <div className="styledContentContainer-p0j3f7-0 caSnlw">
                            <div
                              style={{
                                top: "0px",
                                left: "0px",
                                position: "absolute",
                                zIndex: "400",
                                opacity: "0",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
              &nbsp;
              {supportRequestsTrack === 1 ? (
                <li className="sc-eqIVtm hfyJKb">
                  <div className="sc-csuQGl eTmqqm">
                    <div>
                      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
                        <div>
                          <div>
                            <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
                              <div>
                                <button
                                  className="sc-gPEVay kOqTiO"
                                  onClick={tracks}
                                >
                                  <div className="sc-jlyJG jrYKHX">
                                    <span>Dashboard</span>
                                  </div>
                                </button>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
                            <div
                              style={{ height: "100%", width: "100%" }}
                            ></div>
                          </div>
                          <div className="styledContentContainer-p0j3f7-0 caSnlw">
                            <div
                              style={{
                                top: "0px",
                                left: "0px",
                                position: "absolute",
                                zIndex: "400",
                                opacity: "0",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
              &nbsp;
              {raiseTicket === 1 ? (
                <li className="sc-eqIVtm hfyJKb">
                  <div className="sc-csuQGl eTmqqm">
                    <div>
                      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
                        <div>
                          <div>
                            <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
                              <div>
                                <button
                                  className="sc-gPEVay kOqTiO"
                                  onClick={raise}
                                >
                                  <div className="sc-jlyJG jrYKHX">
                                    <span>Raise Ticket</span>
                                  </div>
                                </button>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
                            <div
                              style={{ height: "100%", width: "100%" }}
                            ></div>
                          </div>
                          <div className="styledContentContainer-p0j3f7-0 caSnlw">
                            <div
                              style={{
                                top: "0px",
                                left: "0px",
                                position: "absolute",
                                zIndex: "400",
                                opacity: "0",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
             
              {/* {userManagement === 1 ? (
                <li className="sc-eqIVtm hfyJKb">
                  <div className="sc-csuQGl eTmqqm">
                    <div>
                      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
                        <div>
                          <div>
                            <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
                              <div>
                                <button
                                  className="sc-gPEVay kOqTiO"
                                  onClick={navSettings}
                                >
                                  <div className="sc-jlyJG jrYKHX">
                                    <span>Settings</span>
                                  </div>
                                </button>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
                            <div
                              style={{ height: "100%", width: "100%" }}
                            ></div>
                          </div>
                          <div className="styledContentContainer-p0j3f7-0 caSnlw">
                            <div
                              style={{
                                top: "0px",
                                left: "0px",
                                position: "absolute",
                                zIndex: "400",
                                opacity: "0",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )} */}
                &nbsp; &nbsp;
              {userManagement === 1 ? (
                <li className="sc-eqIVtm hfyJKb">
                  <div className="sc-csuQGl eTmqqm">
                    <div>
                      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
                        <div>
                          <div>
                            <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
                              <div>
                                <button
                                  className="sc-gPEVay kOqTiO"
                                  onClick={list}
                                >
                                  <div className="sc-jlyJG jrYKHX">
                                    <span>Role Management</span>
                                  </div>
                                </button>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
                            <div
                              style={{ height: "100%", width: "100%" }}
                            ></div>
                          </div>
                          <div className="styledContentContainer-p0j3f7-0 caSnlw">
                            <div
                              style={{
                                top: "0px",
                                left: "0px",
                                position: "absolute",
                                zIndex: "400",
                                opacity: "0",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
              &nbsp;
              {newAddition === 1 ? (
                <li className="sc-eqIVtm hfyJKb">
                  <div className="sc-csuQGl eTmqqm">
                    <div>
                      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
                        <div>
                          <div>
                            <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
                              <div>
                                <button
                                  className="sc-gPEVay kOqTiO"
                                  onClick={user}
                                >
                                  <div className="sc-jlyJG jrYKHX">
                                    <span>Add New User</span>
                                  </div>
                                </button>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
                            <div
                              style={{ height: "100%", width: "100%" }}
                            ></div>
                          </div>
                          <div className="styledContentContainer-p0j3f7-0 caSnlw">
                            <div
                              style={{
                                top: "0px",
                                left: "0px",
                                position: "absolute",
                                zIndex: "400",
                                opacity: "0",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
              &nbsp;
              {backButton === 1 ? (
                <li className="sc-eqIVtm hfyJKb">
                  <div className="sc-csuQGl eTmqqm">
                    <div>
                      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
                        <div>
                          <div>
                            <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
                              <div>
                                <button
                                  className="sc-gPEVay kOqTiO"
                                  onClick={back}
                                >
                                  <div className="sc-jlyJG jrYKHX">
                                    <span>Back</span>
                                  </div>
                                </button>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
                            <div
                              style={{ height: "100%", width: "100%" }}
                            ></div>
                          </div>
                          <div className="styledContentContainer-p0j3f7-0 caSnlw">
                            <div
                              style={{
                                top: "0px",
                                left: "0px",
                                position: "absolute",
                                zIndex: "400",
                                opacity: "0",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
              &nbsp;
              {registrationRequest === 1 ? (
                <li className="sc-eqIVtm hfyJKb">
                  <div className="sc-csuQGl eTmqqm">
                    <div>
                      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
                        <div>
                          <div>
                            <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
                              <div>
                                <button
                                  className="sc-gPEVay kOqTiO"
                                  onClick={regrequest}
                                >
                                  <div className="sc-jlyJG jrYKHX">
                                    <span>Registration Request</span>
                                  </div>
                                </button>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
                            <div
                              style={{ height: "100%", width: "100%" }}
                            ></div>
                          </div>
                          <div className="styledContentContainer-p0j3f7-0 caSnlw">
                            <div
                              style={{
                                top: "0px",
                                left: "0px",
                                position: "absolute",
                                zIndex: "400",
                                opacity: "0",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
              {adminmanage === 1 ? (
                <li className="sc-eqIVtm hfyJKb">
                  <div className="sc-csuQGl eTmqqm">
                    <div>
                      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
                        <div>
                          <div>
                            <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
                              <div>
                                <button
                                  className="sc-gPEVay kOqTiO"
                                  onClick={adminManagement}
                                >
                                  <div className="sc-jlyJG jrYKHX">
                                    <span>Admin Management</span>
                                  </div>
                                </button>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
                            <div
                              style={{ height: "100%", width: "100%" }}
                            ></div>
                          </div>
                          <div className="styledContentContainer-p0j3f7-0 caSnlw">
                            <div
                              style={{
                                top: "0px",
                                left: "0px",
                                position: "absolute",
                                zIndex: "400",
                                opacity: "0",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
              {adminRequests === 1 ? (
                <li className="sc-eqIVtm hfyJKb">
                  <div className="sc-csuQGl eTmqqm">
                    <div>
                      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
                        <div>
                          <div>
                            <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
                              <div>
                                <button
                                  className="sc-gPEVay kOqTiO"
                                  onClick={click}
                                >
                                  <div className="sc-jlyJG jrYKHX">
                                    <span>Requests</span>
                                  </div>
                                </button>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
                            <div
                              style={{ height: "100%", width: "100%" }}
                            ></div>
                          </div>
                          <div className="styledContentContainer-p0j3f7-0 caSnlw">
                            <div
                              style={{
                                top: "0px",
                                left: "0px",
                                position: "absolute",
                                zIndex: "400",
                                transform: "translate3d(561px, 54px, 0px)",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
              {requests === 1 ? (
                <li className="sc-eqIVtm hfyJKb">
                  <div className="sc-csuQGl eTmqqm">
                    <div>
                      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
                        <div>
                          <div>
                            <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
                              <div>
                                <button
                                  className="sc-gPEVay kOqTiO"
                                  onClick={admin}
                                >
                                  <div className="sc-jlyJG jrYKHX">
                                    <span>Requests</span>
                                  </div>
                                </button>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
                            <div
                              style={{ height: "100%", width: "100%" }}
                            ></div>
                          </div>
                          <div className="styledContentContainer-p0j3f7-0 caSnlw">
                            <div
                              style={{
                                top: "0px",
                                left: "0px",
                                position: "absolute",
                                zIndex: "400",
                                transform: "translate3d(561px, 54px, 0px)",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
              {/*<li className="sc-eqIVtm hfyJKb">
    <div className="sc-kkGfuU fHzrwK">
    <div>
    <div className="Droplist-sc-1z05y4v-0 cLrmQm">
<div><div>
<div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
<div>
<span className="sc-eHgmQL gIogYT" data-test-id="user-avatar-container">
<div style={{display: "inline-block", position: "relative", outline: "0px"}}>
<button className="css-nr1tyk"  type="button" style={{backgroundColor: "coral",
    color: "whitesmoke", cursor: "pointer"}}><Profile/>
    
 
<img src="" alt="" className="css-13ep12v" style={{borderRadius: "50%"}}/>
</button></div></span></div></div></div>
<div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
<div style={{height: "100%", width: "100%"}}></div></div>


</div></div></div></div>

</li>*/}
              &nbsp;
              <li className="sc-eqIVtm hfyJKb">
                <Profileadminhotel showAlert={showAlert} setAlertProps={setAlertProps}/>
              </li>
            </ul>
          </nav>
        </header>
      </div>
    </>
  );
}

export default Headeradminhotel;
