import React, { useEffect, useState } from "react";
import "../css/profile.css";
import { useNavigate } from "react-router-dom";


//let name;
function SuperProfile({ userAuth,showAlert,setAlertProps }) {
 
  //const [name,setName]=useState(nam)
  //const [tell,setTell]=useState("")
  //const storedData = JSON.parse(localStorage.getItem('myData'));
  //const storedDatas = JSON.parse(localStorage.getItem('mydata'));

  /*const storedDatas = JSON.parse(localStorage.getItem('myDatas'));
 
  if(storedData===undefined || name!==storedDatas )
  {
    name=storedDatas
  }
  else{
    
     name=storedData
     
    
  }
  if(storedDatas===undefined  || name===storedData)
  {
   name=storedDatas
    
  }
  else{
   
    name=storedData
  }*/

  /*var f;

  if(nam===undefined || name!==nams )
  {
    
    name=nams
  }
  else{
    
     name=nam
     
    
  }
  if(nams===undefined  || name===nam)
  {
    name=nam
    
  }
  else{
   
    name=nams
     
    
  }*/

  let data = localStorage.getItem("key");
  const id = sessionStorage.getItem("mail");
  console.log(data);
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  const navigate = useNavigate();
  const click = () => {
    showAlert({
      title: 'Log out',
      message: 'Are you sure you want to log out ?',
      okText: 'OK',
      cancelText: 'Cancel',
      callback: function (res) {
        if(res)
          {
        const token=localStorage.getItem("superToken")
    const res = {
      method: "PUT",
      headers: { "Content-Type": "application/json",'Authorization':`Bearer ${token}`, },
    };
    fetch(`${process.env.REACT_APP_API_URL}/superlogout/${id}`, res).then(
      () => {
        console.log("added");
      }
    );

    //cookie.remove("token")
    localStorage.removeItem("superToken");
    sessionStorage.removeItem("mail");
    localStorage.removeItem("key");
    localStorage.removeItem("tokenauth")
    //userAuth(false)
    //localStorage.setItem("tokenauth","false")
    //cookie.set("tokenauth", "false");
    try {
       navigate("/v1/jim_super_login");
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  }
  else{
       console.log("request cancelled")
  }
  setAlertProps(null)
    }
  
  });
    
  };
  // console.log(storedDatas)

  return (
    <div>
      <div className="sc-csuQGl eTmqqm">
        <div>
          <div className="Droplist-sc-1z05y4v-0 cLrmQm">
            <div>
              <div>
                <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
                 
                  <div>
                    <button
                      className={`sc-gPEVay kOqTiO ${hovered ? "hovered" : ""}`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={click}
                    >
                      <div className="sc-jlyJG jrYKHX">
                        <span>{hovered ? "Logout" : data}</span>
                      </div>
                    </button>
                  </div>{" "}
                </div>
              </div>
              <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
                <div style={{ height: "100%", width: "100%" }}></div>
              </div>
              <div className="styledContentContainer-p0j3f7-0 caSnlw">
                <div
                  style={{
                    top: "0px",
                    left: "0px",
                    position: "absolute",
                    zIndex: "400",
                    transform: "translate3d(561px, 54px, 0px)",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperProfile;
