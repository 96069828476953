import React from "react";

import PrivacyPolicyStyle from "../css/PrivacyPolicyStyle.css"

const PrivacyPolicy = () => {
  return (
    <div>
      <header id="banner" role="banner" class="header">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <nav class="navbar navbar-expand-lg">
                {/* <a
                  class="col-4 col-sm-4 navbar-menu"
                  href="javascript:void(0);"
                >
                  <img
                    src="https://url.us.m.mimecastprotect.com/s/O6JIC2kNE1cpj0NQDUn0bda?domain=jiocentral.jioconnect.com"
                    alt=""
                  />
                </a> */}
         <a
                  class="logo custom-logo col-4 col-sm-4 brand"
                  href="#"
                  title="Go to jiocentral"
                >
                  <img
                    alt=""
                    src="https://jiocentral.jioconnect.com/o/jio-central-theme/images/img/logo.svg"
                    width=""
                    height=""
                  />
               
                </a>

                {/* <ul class="nav-rt ml-auto">
                  <li class="nav-item">
                    <a
                      class="nav-link btn notification m-md-0"
                      href="javascript:void(0);"
                    >
                      <img
                        src="https://url.us.m.mimecastprotect.com/s/MTQkC4x3JYiBoRpxwCBmP5D?domain=jiocentral.jioconnect.com"
                        class="pr-1"
                      />

                      <span
                        id="notification"
                        class="badge text-white p-1 w-auto h-auto"
                        style={{ display: "none" }}
                      >
                        {" "}
                        0{" "}
                      </span>
                    </a>
                  </li>
                </ul> */}
              </nav>
            </div>
          </div>
        </div>
      </header>
      <dd>
        <p align="center">
          <strong>JIO SERVICE SUPPORT PORTAL PRIVACY POLICY</strong>
        </p>
        <p>&nbsp;</p>
        <p style={{ marginLeft: "50px" }}>
          <strong>
            Thank you for using Jio Service Support! Your privacy is important to us.{" "}
          </strong>
          <strong>
            To better protect your privacy, and to help ensure a rewarding
            experience for all users, we provide this privacy policy.
          </strong>
        </p>
        <ol>
          <li
            style={{ listStyle: "none", marginLeft: "50px", marginTop: "10px" }}
            value="2"
          >
            <strong>1.</strong>&nbsp;<strong>SCOPE</strong>
          </li>
        </ol>{" "}
        <p>&nbsp;</p>
        <p style={{ marginLeft: "50px" }}>
          <strong>Jio Service Support</strong> (hereinafter referred to as
          “Portal”, “we”, “our”, “us”) is committed to protecting and respecting
          your privacy.
        </p>
        <p>&nbsp;</p>
        <ol style={{ listStyle: "none", paddingLeft: 0, marginLeft: "90px" }}>
          <li style={{ fontSize: "1em", lineHeight: "1.5" }} value="1.1">
            1.1 This policy (this “Privacy Policy”), together with the Terms and
            Conditions of the Portal outlines the Portal’s privacy practices
            regarding the collection, use and safeguard of your information
            through the Portal and the services offered thereupon.
          </li>
		  &nbsp;
          <li style={{ fontSize: "1em", lineHeight: "1.5" }} value="1.2">
            1.2 By using the Portal or registering or signing up for the Portal
            and submission of personal information; or if you are a minor, the
            submission of your personal information either by your parents (or
            your guardian) or by you with the permission of your parents (or
            guardian) to the Portal will be deemed as your acceptance of this
            Privacy Policy.
          </li>
		  &nbsp;

          <li style={{ fontSize: "1em", lineHeight: "1.5" }}>
            1.3 The Terms are an electronic record in terms of the Information
            Technology Act, 2000 (as amended / re-enacted) (“IT Act") and rules
            thereunder, and is published in accordance with the provisions of
            Rule 3 (1) of the of the Information Technology (Intermediary
            Guidelines and Digital Media Ethics Code) Rules, 2021, which
            mandates for publishing of rules and regulations, privacy policy and
            terms of use for access or usage of Portal. This electronic record
            is generated by a computer system and does not require any physical
            or digital signatures.
          </li>
		  &nbsp;

        </ol>
        <ol
          style={{ listStyle: "none", marginTop: "10px", marginLeft: "50px" }}
        >
          <li>
            <strong>2.&nbsp;</strong>
            <strong>Changes to the Privacy Policy:</strong>
          </li>
        </ol>
        <ol style={{ marginLeft: "00px", listStyle: "none" }}>
          <li value="2.1" style={{ marginLeft: "90px", marginTop: "10px" }}>
            2.1 We are obligated to protect your information in accordance with
            applicable laws, and regulations.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol style={{ listStyle: "none" }}>
          <li value="2.2" style={{ marginLeft: "90px" }}>
            2.2 This Privacy Policy is subject to modification based on changes
            in the business, legal and regulatory requirements and will be
            updated online. We will notify you whenever any change to the
            Privacy Policy are made. You are encouraged to periodically visit
            this page to review the Privacy Policy and any changes to it.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol style={{ listStyle: "none", marginLeft: "50px" }}>
          <li value="3">
            <strong>3.</strong>
            <strong>Third Party Services:</strong>
          </li>
        </ol>
        <ol style={{ listStyle: "none" }}>
          <li value="3.1" style={{ marginLeft: "90px", marginTop: "10px" }}>
            3.1 Please note that your mobile service provider, mobile operating
            system provider, third party applications including the applications
            pre-loaded on your smart phones powered by Android platform or
            customized Android ROM (“Device”), social media platforms and
            websites that you access, third party operators may also collect,
            use and share information about you and your usage. We cannot
            control how these third parties collect, use, share or secure this
            information. For information about third-party privacy practices,
            please consult their respective privacy policies.
          </li>
        </ol>
        <p>&nbsp;</p>
        <li value="4" style={{ listStyle: "none", marginLeft: "50px" }}>
          <strong>4.&nbsp;</strong>
          <strong>Personal Information we collect:</strong>{" "}
        </li>
        <ol
          style={{ listStyle: "none", marginLeft: "90px", marginTop: "10px" }}
        >
          <li value="4.1">
            4.1 Personal information is defined as information that can be used
            to identify you and may include details such as your name, age,
            gender, contact information, products and services you are
            interested in or require more information about. Insofar as
            sensitive personal information is concerned, it will carry the
            meaning as may be defined by applicable laws from time to time.
          </li>
          <p>&nbsp;</p>
          <li value="4.2">
            4.2 The following is the manner in which we collect, use, share and
            retain personal information:
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol style={{ listStyle: "none", marginLeft: "50px" }}>
          <li value="NaN">
            4.2.1 Collection:
            <ol
              style={{
                listStyle: "none",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              <li value="NaN">
                https://url.us.m.mimecastprotect.com/s/VH8iC5y4g1sZvwV1rI2A5Wh?domain=4.2.1.1 You agree that the Portal may collect such personal
                information, whenever relevant, to help providing you with
                information and to complete any transaction or provide any
                product you have requested or authorized. You also consent to
                the collection of certain personal information in the course of
                your applying for the products and/or services.
              </li>
            </ol>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol style={{ listStyle: "none", marginLeft: "50px" }}>
          <li value="NaN">
            4.2.2 Usage:
            <ol
              style={{
                listStyle: "none",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              <li value="NaN">
                https://url.us.m.mimecastprotect.com/s/3d4ZC68NjzUrDZnJ4sxYBrn?domain=4.2.2.1 The Portal seeks this information either to (i) validate
                and process your request for the services of the Portal or
                information; (ii) to improve the quality of the Portal; (iii) to
                assist you to determine which services best meet your needs; or
                (iv) to facilitate our internal business operations, including
                the fulfilment of any legal and regulatory requirements; or (v)
                to provide you with recommendation about services you may be
                interested in, based on your use of the Portal; or (vi) to
                provide you with marketing communications and advertising that
                the Portal believe may be of interest of you ;or (vii) to
                facilitate your purchases .
              </li>
            </ol>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol style={{ listStyle: "none", marginLeft: "50px" }}>
          <li value="NaN">
            4.2.3 Sharing:
            <ol
              style={{
                listStyle: "none",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              <li value="NaN">
                https://url.us.m.mimecastprotect.com/s/EPZWC730kWFAqnOYBCRybWt?domain=4.2.3.1 We may disclose personal information to our affiliates
                when necessary to perform services on our behalf or on your
                behalf, to provide display advertising and promotional services,
                providing search results and links (including paid listings and
                links), processing credit card payments, providing customer
                service etc.
              </li>
              <p>&nbsp;</p>
              <li value="NaN">
                https://url.us.m.mimecastprotect.com/s/u_cgC82NlBt6rB7gyfzJTf8?domain=4.2.3.2 We may also share personal information with external
                organizations or individuals if we believe that access, use,
                preservation or disclosure of the information is reasonably
                necessary to: (a) meet any applicable law, regulation, legal
                process or enforceable governmental request; (b)detect, prevent
                or otherwise address fraud, security or technical issues;
                (c)protect against harm to the rights, property or safety of our
                customers or the public, as required or permitted by law.
              </li>
            </ol>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol style={{ listStyle: "none", marginLeft: "50px" }}>
          <li value="NaN">
            4.2.4 Retention:
            <ol
              style={{
                listStyle: "none",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              <li value="NaN">
                https://url.us.m.mimecastprotect.com/s/gU5hC9rNmLik1r5jwSZACmv?domain=4.2.4.1 The information so collected shall be retained only for
                a limited duration necessary to fulfil the purposes outlined
                herein unless a longer retention period is required or permitted
                by law and only for the purposes defined above. Once the purpose
                is achieved, all personal information is deleted in a safe and
                secure mode.
              </li>
            </ol>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol style={{ listStyle: "none", marginLeft: "50px" }}>
          <li value="5">
            <strong>5. Non-Personal Information:</strong>

            <ol
              style={{
                listStyle: "none",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              <li value="5.1">
                5.1 Non-personal information is defined as any data that does
                not personally identify you and may include unique system or
                hardware identifiers, system or Portal software, and
                peripherals.
              </li>
              <p>&nbsp;</p>
              <li value="5.2">
                5.2 Any non-personal information, when clubbed with personal
                information shall be treated as personal information.
              </li>
              <p>&nbsp;</p>
              <li value="5.3">
                5.3 The following is the manner in which we collect, use, share
                and retain non-personal information.
              </li>
              <p>&nbsp;</p>
            </ol>
          </li>
        </ol>
        <ol style={{ listStyle: "none", marginLeft: "50px" }}>
          <li value="NaN">5.3.1 Collection:</li>
        </ol>
        <ol>
          <li
            style={{
              listStyle: "none",
              marginLeft: "90px",
              marginTop: "10px",
            }}
          >
            https://url.us.m.mimecastprotect.com/s/iew3C0R7BXCG8YRzAio_f1A?domain=5.3.1.1 &nbsp; You agree that the Portal may collect diagnostic,
            technical, usage related information, for the usage purposes
            described below.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol style={{ listStyle: "none", marginLeft: "50px" }}>
          <li value="NaN">
            5.3.2 Usage:
            <ol
              style={{
                listStyle: "none",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              <li value="NaN">
                https://url.us.m.mimecastprotect.com/s/LYjKCgJEPQHAk4LJzC6kwAP?domain=5.3.2.1 The above mentioned information is gathered periodically
                to provide and improve the Portal and services therein,
                facilitate the provision of software updates, product support
                and other services to you (if any) and to verify compliance with
                the terms of this Policy.
              </li>
            </ol>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol style={{ listStyle: "none", marginLeft: "50px" }}>
          <li value="NaN">
            5.3.3 Sharing:
            <ol
              style={{
                listStyle: "none",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              <li value="NaN">
                https://url.us.m.mimecastprotect.com/s/cBfACjRKPOCn9Z70xfgeUfr?domain=5.3.3.1 To enable the Portal partners and third party developers
                to improve their software, hardware and services designed for
                use with the Portal, we may also provide any such partner or
                third party developer with a subset of diagnostic information
                that is relevant to that partner’s or developer’s software,
                hardware and/or services, as long as the diagnostic information
                is in a form that does not personally identify you.
              </li>
            </ol>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol style={{ listStyle: "none", marginLeft: "50px" }}>
          <li value="NaN">
            5.3.4 Retention:
            <ol
              style={{
                listStyle: "none",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              <li value="NaN">
                https://url.us.m.mimecastprotect.com/s/F7vqCkRLPOCONoMvJfGPcvl?domain=5.3.4.1 The information so collected shall be retained only for
                a limited duration necessary to fulfil the purposes outlined
                herein unless a longer retention period is required or permitted
                by law and only for the purposes defined above. Once the purpose
                is achieved, all information is deleted in a safe and secure
                mode.
              </li>
            </ol>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol style={{marginLeft:"50px",listStyle:"none"}}>
          <li value="6">
           <strong>6.</strong> <strong>Location Services:</strong>
          </li>
        </ol>
        <ol
          style={{ listStyle: "none", marginLeft: "50px", marginTop: "10px" }}
        >
          <li value="6.1">
            6.1 The Portal, its licensees and agents, may access, collect and
            analyse your usage of the Portal and other information related to
            your location on a regular basis through Bluetooth and Wi-Fi signals
            and other technologies and data for the performance of the services
            provided by the Portal and to help improve the design,
            functionality, performance, and content of the Portal. In order to
            collect geo-location information, the location settings must be
            enabled on your device. Therefore, in some cases, the Portal may
            request that you enable the location settings.
          </li>
          <p>&nbsp;</p>
          <li value="6.2">
            6.2 Except in cases where any specific services requested by you
            require or involve personal identification, the Portal collects
            location data anonymously and in a form that does not personally
            identify you. By using the Portal, you agree and consent to
            transmission, collection, maintenance, processing and use of your
            location data and queries to provide and improve such services of
            the Portal.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol
          style={{ listStyle: "none", marginLeft: "50px", marginTop: "10px" }}
        >
          <li value="7">
            <strong>7. Cookies:</strong>
          </li>
        </ol>
        <ol
          style={{ listStyle: "none", marginLeft: "50px", marginTop: "10px" }}
        >
          <li value="7.1">
            7.1 Information collected by cookies and other technologies are
            treated as non-personal information. However, to the extent that IP
            addresses or similar identifiers are considered personal information
            by local law, we treat any information linked to such identifiers as
            personal information. Similarly, to the extent that non-personal
            information is combined with personal information, we will treat the
            combined information as personal information.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol
          style={{ listStyle: "none", marginLeft: "50px", marginTop: "10px" }}
        >
          <li value="8">
            <strong>8. Other Information the App collects:</strong>
          </li>
        </ol>
        <ol
          style={{ listStyle: "none", marginLeft: "50px", marginTop: "10px" }}
        >
          <li value="8.1">
            8.1 The Portal also may collect other information about you in ways
            that the Portal describe to you or otherwise with your consent. You
            can choose not to provide us with certain types of information, but
            doing so may affect your ability to avail and/or use some services.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol
          style={{ listStyle: "none", marginLeft: "50px", marginTop: "10px" }}
        >
          <li value="9">
            <strong>
              9. Third Party Applications, Websites &amp; Services:
            </strong>
          </li>
        </ol>
        <ol
          style={{ listStyle: "none", marginLeft: "50px", marginTop: "10px" }}
        >
          <li value="9.1">
            9.1 The Portal may include links to other websites/applications or
            may display advertisements from third parties and other content that
            links to third party websites. Such websites/applications are
            governed by their respective privacy policies, which are out of the
            Portal’s control. Use of any information you provide while using a
            third-party website or applications through the Portal, is governed
            by the privacy policy of the operator of the website / applications
            / operator you are using/ visiting. That policy may differ from that
            of the Portal. If you can't find the privacy policy of any of such
            websites /application via a link from the website's homepage, you
            should contact the relevant website /application operator directly
            for more information.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol
          style={{ listStyle: "none", marginLeft: "50px", marginTop: "10px" }}
        >
          <li value="10">
            <strong>10. Access, Correction and Deletion:</strong>
          </li>
        </ol>
        <ol
          style={{ listStyle: "none", marginLeft: "50px", marginTop: "10px" }}
        >
          <li value="10.1">
            10.1 The Portal strives hard to keep its records updated and
            accurate with your latest information. You shall be responsible to
            ensure that the information or data you provide from time to time is
            and shall be correct, current and updated and you have all the
            rights, permissions and consents to provide such information or
            data.
          </li>
          <p>&nbsp;</p>
          <li value="10.3">
            10.2 You may note that deletion of certain information or withdrawal
            of consent may lead to cancellation of your access to the Portal or
            your access to certain features and services of the Portal.
            Additionally, we may not be able to process your request of
            correction, updating or deletion, in case the same is not supported
            by valid documents or data retention is required by the applicable
            law or law enforcement requests or under any judicial proceedings or
            it is extremely difficult to implement (such as requests related to
            backup copies or if a new system is required to process the request
            or change of technical design) or risks the privacy of other users.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol style={{marginLeft:"50px",listStyle:"none"}}>
          <li value="11">
            <strong>11. Queries and Complaints:</strong>
          </li>
        </ol>
        <ol
          style={{ listStyle: "none", marginLeft: "50px", marginTop: "10px" }}
        >
          <li value="11.1">
            We are committed to protect your personal information collected and
            processed by us and look forward to your continued support for the
            same. In case of any feedback or concern regarding protection of
            your personal information, or any privacy-related feedback or
            concerns you may contact:Ms Radha Nair at &nbsp;
            <strong>grievance.officer@jio.com</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <hr align="left" size="1" width="33%" />
        
      </dd>
    </div>
  );
};

export default PrivacyPolicy;
