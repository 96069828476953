import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  yourData: null,
  userData: null,
  userMail: null,
  user: null,
  yourAdminData: null,
  userAdminData: null,
  userAdminMail: null,
  yourSupportAdminData: null,
  userSupportAdminData: null,
  userSupportAdminMail: null,
  
};

const yourSlice = createSlice({
  name: 'yourSlice',
  initialState,
  reducers: {
    updateData: (state, action) => {
      state.yourData = action.payload;
      console.log(state.yourData)
    },
    userName: (state, action) => {
        state.userData = action.payload;
        console.log(state.userData)
      },
       mail: (state, action) => {
        state.userMail = action.payload;
        console.log(state.userMail)
      },
      user: (state, action) => {
        state.user = action.payload;
        console.log(state.user)
      },
      updateAdminData: (state, action) => {
        state.yourAdminData = action.payload;
        console.log(state.yourAdminData)
      },
      userAdminName: (state, action) => {
          state.userAdminData = action.payload;
          console.log(state.userAdminData)
        },
         Adminmail: (state, action) => {
          state.userAdminMail = action.payload;
          console.log(state.userAdminMail)
        },
        updateSupportAdminData: (state, action) => {
          state.yourSupportAdminData = action.payload;
          console.log(state.yourSupportAdminData)
        },
        userSupportAdminName: (state, action) => {
            state.userSupportAdminData = action.payload;
            console.log(state.userSupportAdminData)
          },
           supportAdminmail: (state, action) => {
            state.userSupportAdminMail = action.payload;
            console.log(state.userSupportAdminMail)
          },


  },
});

export const { updateData,userName,mail,user,updateAdminData,userAdminName,Adminmail,updateSupportAdminData,userSupportAdminName,supportAdminmail} = yourSlice.actions;
export default yourSlice.reducer;