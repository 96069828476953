import { configureStore } from '@reduxjs/toolkit';
import yourSliceReducer from '../feature/slice'
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

const persistConfig=
{
    key:'root',
    storage,
}
const persistedReducer=persistReducer(persistConfig,yourSliceReducer)
//const middlewares = [...getDefaultMiddleware(), reduxImmutableStateInvariant()];
const store = configureStore({
  reducer:persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(reduxImmutableStateInvariant()),
  //{
    //yourSlice:yourSliceReducer,
    // other reducers...
  //},
});

const persistor=persistStore(store)
export { store,persistor};