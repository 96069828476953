import  React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import '../css/table.css';
import '../css/admin.css';
import img from './image/filenot.jpg';
import {jwtDecode} from 'jwt-decode'

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export let d;
function UserListTablehotel({Option,Tab,ya,addr,showAlert,setAlertProps}) {
  //<Details d={deta}/>
  //console.log(deta)
 const token=localStorage.getItem("adminToken")
 const adminMail=useSelector((state)=>state.userAdminMail)
 const navigate=useNavigate()
  const [data,setdata]=useState([])
  
  const [project,setProject]=useState("")


 useEffect(() => {
  try{
const decodedToken = jwtDecode(token);
setProject(decodedToken.project)
  }
  catch(error)
  {
    navigate("/v1/jim_admin_user_login")
  }
},[])
  useEffect(() => {
    fetch(addr,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      }

      )
    .then(res=>res.json())
    .then(data=>{
      setdata(data.data)
      if(data.statusCode===400)
        {
          showAlert({
            title:  'Logged Out',
            message: 'You are logged out due to session timeout.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        });
        navigate("/v1/jim_admin_user_login")
        }
      })
    .catch(err=>console.log(err))
  }, [project])
  /*const click=(e)=>
  {
    console.log(e);
  {<Detail deta={e}/>}
  }*/
//const project=cookie.get("project")
  const deleted=(e)=>
  {
    showAlert({
      title:  'Confirmation',
      message: 'Are you sure you want to delete the user?',
      okText: 'OK',
      cancelText: 'Cancel',
      callback: (res) => {
          console.log('Alert closed');
         
    if(res)
      {
    const token=localStorage.getItem("adminToken")
    if(e.user==="support")
    {
    fetch(`${process.env.REACT_APP_API_URL}/admindelete/${project}/${e.email}`, 
    {
       method: 'DELETE',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })
    .then(() =>{
      if(data.status===400)
        {
          showAlert({
            title:  'Logged Out',
            message: 'You are logged out due to session timeout.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        });
        navigate("/v1/jim_admin_user_login")
        }
        else{        
 showAlert({
  title:  'Deletion Successful',
  message: 'The support entry was deleted successfully.',
  okText: 'OK',
  callback: () => {
      console.log('Alert closed');
      setAlertProps(null)
      window.location.reload(true)
  }
}); 
          
        }
       console.log('support admin deleted successful')
      });
    }
    else if(e.user==="admin")
    {
    fetch(`${process.env.REACT_APP_API_URL}/admindelete/${project}/${e.email}`, 
    {
       method: 'DELETE',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })
    .then(() =>{
      if(data.status===400)
        {
          showAlert({
            title:  'Logged Out',
            message: 'You are logged out due to session timeout.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        });
        navigate("/v1/jim_admin_user_login")
        }
        else{
          showAlert({
            title:  'Deletion Successful',
            message: 'The admin entry was deleted successfully.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
                window.location.reload(true)
            }
          }); 
        }
       console.log(' admin deleted successful')
      });
    }
    else{
      fetch(`${process.env.REACT_APP_API_URL}/delete/${project}/${e.email}`, 
      {
        method: 'DELETE',
         withCredentials: 'true',
         headers:
         {
           'Authorization':`Bearer ${token}`,
         },
       })
    .then((data) => {
      if(data.status===400)
    {
      showAlert({
        title:  'Logged Out',
        message: 'You are logged out due to session timeout.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    });
    navigate("/v1/jim_admin_user_login")
    }
    else{
      showAlert({
        title:  'Deletion Successful',
        message: 'The user entry was deleted successfully.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
            window.location.reload(true)
        }
      }); 
    }
    console.log('user deleted successful')
  });
    }
  } 
  setAlertProps(null)
}
})
  }
  return (
   
    <div>
      {data.length!==0?<div className="sc-ghsgMZ gDXBCU" data-test-id="request-list.request-list-table-v2">
      <div className="sc-dznXNo zdDVp">
      <div data-testid="request-list.request-list-table-v2--loading--container--advanced" className="css-h8zv0n">
      <table data-testid="request-list.request-list-table-v2--table" className="css-fkqt3q" style={{height: "150px",LocalDynamicTableHoverBg: "var(--ds-background-neutral-subtle-hovered, #FAFBFC)", LocalDynamicTableHighlightedBg: "var(--ds-background-selected, #DEEBFF)", LocalDynamicTableHoverHighlightedBg: "var(--ds-background-selected-hovered, #B3D4FF)", LocalDynamicTableRowFocusOutline: "var(--ds-border-focused, #4C9AFF)"}}>
      <thead data-testid="request-list.request-list-table-v2--head" className="css-1g54gxt">
      <tr>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-1xabuij" style={{fontSize: "initial"/*"--local-dynamic-table-width: 0.5%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <span>Email</span>
      </th>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-100m01t" aria-sort="descending" style={{fontSize: "initial"/*"--local-dynamic-table-width: 0.9%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
        <button type="button" aria-roledescription="Sort button">Name</button></th>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-1xabuij" style={{fontSize: "initial"/*"--local-dynamic-table-width: 1.5%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <span>Mobile</span>
      </th>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-1xabuij" style={{fontSize: "initial"/*"--local-dynamic-table-width: 1%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <span>Role</span>
      </th>
      <th data-testid="request-list.request-list-table-v2--head--cell" className="css-1xabuij" style={{fontSize: "initial"/*"--local-dynamic-table-width: 1%; --local-dynamic-table-text-color: var(--ds-text-subtlest, #5E6C84);"*/}}>
      <span>Action</span>
      </th>
    
      </tr></thead>
      {data.map((e)=>{ 
        return <tbody data-testid="request-list.request-list-table-v2--body" style={{}}>
      <tr data-testid="request-list.request-list-table-v2--row-row-HRIN-24438" className="css-unmje5">
      <td data-testid="request-list.request-list-table-v2--cell-0" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 0.5%;"*/}}>
      <div role="presentation">
      <p className="cv-exclude-from-customisation sc-ekulBa AlQuL"  href="/servicedesk/customer/portal/10/HRIN-24438" target="_self">{e.email}</p></div></td>
      <td data-testid="request-list.request-list-table-v2--cell-1" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 0.9%;"*/}}>
      <p className="cv-exclude-from-customisation sc-ekulBa AlQuL"    target="_self">{e.name}</p></td>
      <td data-testid="request-list.request-list-table-v2--cell-2" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 1.5%;"*/}}>
      <div className="sc-jkCMRl gjGtwC">
      <p className="cv-exclude-from-customisation sc-ekulBa AlQuL"  target="_self">{e.mobile}</p></div></td>
      <td data-testid="request-list.request-list-table-v2--cell-3" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 1%;"*/}}> 
      <div role="presentation"><div data-test-id="request-details.status-lozenge">
      <span className="css-cztx6i" style={{maxWidth: "100%"}}><span className="css-1et8jlg" style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}}>{e.user.charAt(0).toUpperCase() + e.user.slice(1)}</span></span></div></div></td>
      <td data-testid="request-list.request-list-table-v2--cell-4" className="css-zu8jlf" style={{/*"--local-dynamic-table-width: 0.9%;"*/}}>
      {(e.email===adminMail && e.user==="admin") || e.mainAdmin===1?<button class="delete-button" title={e.user==="admin"?"Main Admin":"You"} disabled style={{"backgroundColor":"#ffffff"}} onClick={()=>{deleted(e)}}>
  <i class="fas fa-trash-alt"></i>
</button>:
<button title="Delete Member" class="delete-button" style={{"backgroundColor":"#ffffff"}} onClick={()=>{deleted(e)}}>
  <i class="fas fa-trash-alt"></i>
</button>}
      </td>
      </tr>
      </tbody>
      
    })}
      
      </table></div></div></div>:
    <div className="sc-imDfJI jpQXKX"><div className="sc-hAnkBK bETBem">
      <img src={img} alt="No requests were found."/>
      <div className="sc-DNdyV RIerR">
      <h3>We couldn’t find any requests  </h3>
      <p>
    <span>To find a specific request, try searching using a different filter criteria or <a className="sc-Rmtcm DgkTO" href="/all" target="_self">view all requests</a>.
    </span>
    </p>
    </div>
    </div>
    </div>}

    </div>
  )

}

export default UserListTablehotel
