import './App.css';

import { BrowserRouter, Navigate, Route, useLocation} from 'react-router-dom';
import { Routes } from 'react-router-dom';

import { useState } from 'react';


import { useEffect } from 'react';
import Feedbackhotel from './component/feedbackhotel';
import Commenthotel from './component/commenthotel';
import Headerhotel from './component/headerhotel';
// import AdminhotelShweta from './component/AdminhotelShweta';
import Adminhotel from './component/Adminhotel';
import Tablehotel from './component/Tablehotel';
import Detailshotel from './component/detailshotel';
import Userhotel from './component/Userhotel';
import Udetailshotel from './component/Udetailshotel';
import Homehotel from './component/homehotel';
import Loginhotel from './component/loginhotel';
import Supporthotel from './component/Supporthotel';
import Loginshotel from './component/loginshotel';
import Edithotel from './component/edithotel';
import Forgethotel from './component/forgethotel';
import Changehotel from './component/changehotel';
import Registershotel from './component/registershotel';
import Registrationhotel from './component/registrationhotel';
import Messagehotel from './component/messagehotel';
import Requesthotel from './component/requesthotel';
import Supportdetailshotel from './component/Supportdetailshotel';
import SuperAdmin from './component/SuperAdmin';
import AdminManagement from './component/AdminManagement';
import EditJioSignage from './component/EditJioSignage';
import AdminLogin from './component/AdminLogin';
import UserListhotel from './component/UserListhotel';
import AdminStatisticshotel from './component/AdminStatisticshotel';
import Statisticshotel from './component/Statisticshotel';
import AutoTicketAssignment from './component/AutoTicketAssignment';

import AdminForgethotel from './component/adminforgethotel';
import AdminMessagehotel from './component/adminmessagehotel';
import SuperProfile from './component/superadminprofile';
import SignInHotel from './component/singlesignin';
import { useDispatch } from 'react-redux';
import { mail } from './feature/slice';
import Changeadminhotel from './component/changeadminpasswordhotel';
import ErrorPage from './component/errorPage';
import TermsAndConditionsComponent from './component/TermsAndConditionsComponent';
import PrivacyPolicy from './component/PrivacyPolicy';// import crypto from 'crypto';
import AdminProfile from './component/AdminProfile';
import SupportProfile from './component/SupportProfile';
import UserProfile from './component/UserProfile';
import CustomAlert from './component/CustomAlert';
/*async function validateToken(setUserAuthenticated) {
  try {
    const token = localStorage.getItem("token");
    if (token) {
      // Make an asynchronous request to validate the token
      
      const response=await fetch('/protected-route',
      {
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })

      if (response.status === 200) {
        // Token is valid
        setUserAuthenticated(true);
      } else {
        // Token is not valid
        setUserAuthenticated(false);
      }
    } else {
      // No token found
      setUserAuthenticated(false);
    }
  } catch (error) {
    // Handle errors, e.g., network issues
    console.error("Token validation failed:", error);
  }
}*/
function App() {
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [alertProps, setAlertProps] = useState(null);
  const showAlert = ({ title, message, okText,cancelText,callback }) => {
   setAlertProps({ title, message, okText,cancelText,callback });
};
  
  /*const [token, setToken] = useState(false);
  if(userAuthenticated)
  {
    setToken(true)
  }*/
  useEffect(()=>
    {
      localStorage.setItem("admin",JSON.stringify({"number":10,pagenumber:1}))
      localStorage.setItem("user",JSON.stringify({"number":10,pagenumber:1}))
    },[])
  const tokens = localStorage.getItem("token");
 // const tokens=cookie.get("token")
  const token=localStorage.getItem("tokenauth")
  //const token=cookie.get("tokenauth")
  //const location=useLocation()
  const currentLocation=window.location.pathname
  //console.log(currentLocation)

  //const locations=useLocation()

  const queryParams = new URLSearchParams(window.location.search);
  // const iv = '0000000000000000'; //16 zeros
  // const key = "fbbaee133bcc12d9467682343a613598"
  // console.log(decodedToken,"decoded value")

  // //let encryptedEmail = cipher.update(decodedToken.email, 'utf8', 'base64');
  // //encryptedEmail += cipher.final('base64');


  // const decipher = crypto.createDecipheriv('aes-256-cbc', key, iv);
  // let decryptedEmail = decipher.update(queryParams.get('email').substring(32), 'base64', 'utf8');
  // decryptedEmail += decipher.final('utf8');
  // console.log(decryptedEmail,"email of decrypt")


  // let decryptedToken = decipher.update(queryParams.get('token').substring(32), 'base64', 'utf8');
  // decryptedEmail += decipher.final('utf8');
  // console.log(decryptedEmail,"email of decrypt")

  // // let decryptedUsername = decipher.update(payloadUsename, 'base64', 'utf8');
  // // decryptedUsername += decipher.final('utf8');
  // // console.log("++++++++++",decryptedUsername)

  // let decryptedTokenAuth = decipher.update(queryParams.get('tokenauth').substring(32), 'base64', 'utf8');
  // decryptedTokenAuth += decipher.final('utf8');
  // console.log("******************",decryptedTokenAuth)

  // let decryptedChannel = decipher.update(queryParams.get('channel').substring(32), 'base64', 'utf8');
  // decryptedChannel += decipher.final('utf8');
  // console.log("))))))))))))))))))))))",decryptedChannel)
  
  // // Get the values of parameter1 and parameter2
  // const parameter1 = decryptedEmail
  // const parameter2 = decryptedToken
  // const parameter3= decryptedTokenAuth
  // const parameter4=decryptedChannel

  const parameter1 = queryParams.get('email')
  const parameter2 = queryParams.get('token')
  const parameter3= queryParams.get('tokenauth')
  const parameter4=queryParams.get('channel')
  const dispatch=useDispatch()
  if(parameter1!==null && parameter1!=="ée")
  {
  dispatch(mail(parameter1))
 //cookie.set("project",parameter4)
  }
  
  console.log(userAuthenticated||token==="true")
  return (
    
    <>
   <BrowserRouter>
      <Routes>
            {/*hotel*/}
      <Route  path="/v1/jim/feedback"
      element={userAuthenticated || token==="true"?<Feedbackhotel token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_user_login"/>}>
      </Route>
      <Route exact  path="/v1/jim/create_ticket"
      element={userAuthenticated ||token==="true" || parameter3==="true"?<Commenthotel userAuth={setUserAuthenticated} token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_user_login"/>}>
      </Route>
       {/* <Route exact  path="/v1/jim/create_ticket"
      element={<Commenthotel token={tokens}/>}>
      </Route> */}
      <Route exact  path="/v1/jim/cmd"
      element={<Headerhotel userAuth={setUserAuthenticated}/>}>
      </Route>
      <Route exact  path="/v1/jim/admin_home"
      element={userAuthenticated || token==="true"?<Adminhotel token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_admin_user_login"/>}>
      </Route>
      {/* <Route exact  path="/v1/jim/admin_home_shweta"
      element={userAuthenticated || token==="true"?<AdminhotelShweta token={tokens}/>:<Navigate to="/v1/jim_admin_user_login"/>}>
      </Route> */}
      <Route exact  path="/v1/jim/table"
      element={<Tablehotel/>}></Route>
     <Route exact  path="/v1/jim/detail"
      element={userAuthenticated || token==="true"?<Detailshotel token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_admin_user_login"/>}>
      </Route>
      <Route exact  path="/v1/jim/user_homepage"
      element={userAuthenticated || token==="true"?<Userhotel token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_user_login"/>}>
      </Route>
      <Route exact  path="/v1/jim/user_profile"
      element={userAuthenticated || token==="true"?<UserProfile token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_user_login"/>}>
      </Route>
      <Route exact  path="/v1/jim/userdetail"
      element={userAuthenticated || token==="true"?<Udetailshotel token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_user_login"/>}>
      </Route>
      <Route exact  path="/v1/jim/homepage"
      element={<Homehotel/>}>
      </Route>
      
      <Route exact  path="/v1/jim_user_login"
       element={<Loginhotel  location={currentLocation}  userAuth={setUserAuthenticated} showAlert={showAlert} setAlertProps={setAlertProps}/>}
      //element={<Navigate to="/"/>}
      >
      </Route>
      <Route exact  path="/v1/jim/support"
      element={userAuthenticated || token==="true"?<Supporthotel token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_admin_user_login"/>}>
      </Route>
      <Route exact  path="/v1/jim_admin_user_login"
      element={<Loginshotel userAuth={setUserAuthenticated}/>}>
      </Route>
      <Route exact  path="/v1/jim/supportdetails"
      element={userAuthenticated || token==="true"?<Supportdetailshotel token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_admin_user_login"/>}>
      </Route>
      <Route exact  path="/v1/jim/user_editform"
      element={userAuthenticated || token==="true"?<Edithotel token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_user_login"/>}>
      </Route>
      <Route exact  path="/v1/jim/forget"
      element={<Forgethotel showAlert={showAlert} setAlertProps={setAlertProps}/>}>
      </Route>
    <Route exact  path={"/v1/jim/change/:data/:project/:id"} 
      element={<Changehotel showAlert={showAlert} setAlertProps={setAlertProps}/>}>
      </Route>
      <Route exact  path={"/v1/jim/admin_change/:data/:project/:id"} 
      element={<Changeadminhotel showAlert={showAlert} setAlertProps={setAlertProps}/>}>
      </Route>
     
      
      <Route exact  path="/v1/jim/admin_home/forget"
      element={<AdminForgethotel showAlert={showAlert} setAlertProps={setAlertProps}/>}>
      </Route>
      
      <Route exact  path="/v1/jim/register"
      element={<Registershotel />}>
      </Route>
      <Route exact  path="/v1/jim/admin_adduser_support"
      element={userAuthenticated || token==="true"?<Registrationhotel token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_admin_user_login"/>}>
      </Route>
      <Route exact  path="/v1/jim/message"
      element={<Messagehotel/>}>
      </Route>
      <Route exact  path="/v1/jim/admin_home/message"
      element={<AdminMessagehotel/>}>
      </Route>
      <Route exact  path="/v1/jim/admin_user_registration_request"
      element={userAuthenticated || token==="true"?<Requesthotel token={tokens}/>:<Navigate to="/v1/jim_admin_user_login"/>}>
      </Route>
      <Route exact  path="/v1/jim/admin_proj_memberlist"
      element={userAuthenticated || token==="true"?<UserListhotel token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_admin_user_login"/>}>
      </Route>
      <Route exact  path="/v1/jim/admin_proj_settings"
      element={userAuthenticated || token==="true"?<AutoTicketAssignment token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_admin_user_login"/>}>
      </Route>
      <Route exact  path="/v1/jim/admin_profile"
      element={userAuthenticated || token==="true"?<AdminProfile token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_admin_user_login"/>}>
      </Route>
      <Route exact  path="/v1/jim/admin_dashboard"
      element={userAuthenticated || token==="true"?<AdminStatisticshotel token={tokens} showAlert={showAlert} setAlertProps={setAlertProps} />:<Navigate to="/v1/jim_admin_user_login"/>}>
      </Route>
      <Route exact  path="/v1/jim/support_profile"
      element={userAuthenticated || token==="true"?<SupportProfile token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_admin_user_login"/>}>
      </Route>
      <Route exact  path="/v1/jim/statistics"
      element={userAuthenticated || token==="true"?<Statisticshotel token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_admin_user_login"/>}>
      </Route>
      <Route exact  path="/v1/jim/signin"
      element={<SignInHotel userAuth={setUserAuthenticated}/>}>
      </Route>
    
      {/*shop*/}
     
     
      <Route exact  path="/superprofile"
      element={<SuperProfile userAuth={setUserAuthenticated}/>}>
      </Route>
    
     
    
      <Route exact  path="/v1/jim/super_homepage"
      element={userAuthenticated || token==="true"?<SuperAdmin showAlert={showAlert} setAlertProps={setAlertProps} token={tokens}/>:<Navigate to="/v1/jim_super_login"/>}>
      </Route>
      <Route exact  path="/v1/jim/super_projectcreation"
      element={userAuthenticated || token==="true"?<AdminManagement token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_super_login"/>}>
      </Route>
      <Route exact  path="/v1/jim/super_editform"
      element={userAuthenticated || token==="true"?<EditJioSignage token={tokens} showAlert={showAlert} setAlertProps={setAlertProps}/>:<Navigate to="/v1/jim_super_login"/>}>
      </Route>
       {/*<Route exact  path="/v1/jim/add_admin"
      element={userAuthenticated || token==="true"?<Adminregistrationhotel token={token}/>:<Navigate to="/v1/jim_super_login"/>}>
  </Route>*/}

      {/*<Route exact  path="/doughnut"
      element={<Doughnut/>}>
      </Route>*/}
  
    
     
      <Route exact  path="/v1/jim_super_login"
      element={<AdminLogin userAuth={setUserAuthenticated}/>}>
      </Route>
      <Route path="/error"
      element= {<ErrorPage/>}>
       </Route>
      <Route path="/*"
      element= {<ErrorPage/>}>
       </Route>
       <Route path="/terms" element={<TermsAndConditionsComponent/>}></Route>
      <Route path='/privacypolicy' element={<PrivacyPolicy/>}></Route>
          </Routes>

          {alertProps && <CustomAlert {...alertProps} />}
      
    </BrowserRouter>
    </>
  );
}


export default App;

