import React, { useState, useEffect } from "react";
import "../css/cmd.css";
import img from "./image/jio.jpg";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import HeaderAdmin from "./headersuperadmin";
import Button from "react-bootstrap/esm/Button";
import 'react-quill/dist/quill.snow.css';
function EditJioSignage({showAlert,setAlertProps}) {
  const editor = useRef(null);
  //const storedData = JSON.parse(localStorage.getItem('mydata'));
  //const storedDatas = JSON.parse(localStorage.getItem(storedData));
  let data = sessionStorage.getItem("key");
  var [named, setNamed] = useState("");
  var [opt, setOption] = useState("");
  var [attach, setAttach] = useState("");
  var [mail, setMail] = useState("");
  var [mobile, setMobile] = useState("");
  var [name, setName] = useState("");
  var [location, setLocation] = useState("");
  var [calender, setCalender] = useState("");
  var [radio, setRadio] = useState("");
  var [dropdownFields,setDropdownFields]=useState("")
  var [select, setSelect] = useState("");
  var [options, setOptions] = useState("");
  var [list, setList] = useState([]);
  var [lists, setLists] = useState([]);
  var [number, setNumber] = useState([]);
  var [allFieldsData,setAllFieldsData]=useState([])
  var [type,setType]=useState([])
  var [groupName,setGroupName]=useState(null)
  var [groupFieldName,setGroupFieldName]=useState(null)
  const [state,setState]=useState([]);
  const navigate=useNavigate()
console.log(state)
const handle=(item,value)=>
  {
    setState(
(prevState)=>
 ({
    ...prevState,
    [item]:value,

  }))
}
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/getPlaceholder/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      { 
        if(data.statusCode===400)
          {
            showAlert({
              title:  'Logged Out',
              message: 'You are logged out due to session timeout.',
              okText: 'OK',
              callback: () => {
                  console.log('Alert closed');
                  setAlertProps(null)
              }
          }); 
            navigate('/v1/jim_super_login')
          } 
          else
          {
        setplaceholder(data.data[0]?.fieldname) 
          }      
  })
  },[])
  const getLabel=(name)=>
    {
      var element=<><div className="sc-gGCbJM foiuyx">
                        <label style={{ cursor: "move"}} htmlFor="mobnumber" className="css-1qzu3fk">
                          {name.groupname!==null?`${name.fieldname} - Sub Group`:name.fieldtype==="HeadGroup"?`${name.fieldname} - Head Group`:name.fieldname}
                          &nbsp;
                          {name.fieldtype!=="statictext" && name.fieldtype!=="staticnumber" && name.fieldtype!=="staticselect" && name.fieldtype!=="staticlocation"
                          && name.fieldtype!=="statictitle"?<input
                          title="required"
                            type="checkbox"
                            id="required"
                            name="required"
                            value="required"
                            checked={name.required==="true"}
                            onChange={()=>updateRequired(name.fieldname,name.required)}
                          />:""}
                        </label>
                        { name.fieldtype!=="statictext" && name.fieldtype!=="staticnumber" && name.fieldtype!=="staticselect" && name.fieldtype!=="staticlocation"
                        && name.fieldtype!=="statictitle" ?flaggedField.includes(name.fieldname)?<>&nbsp;<img width="24" height="24" src="https://img.icons8.com/plumpy/24/replace.png" alt="replace"/>&nbsp;<span><input type="text" id="myOutline"
                   placeholder="Add Other"
                   value={onChangeValue(name.id)}
                   onChange={(e)=>{
                    const nospecial=/^[a-zA-Z0-9 ]*$/;
                    if(nospecial.test(e.target.value)){
                      //(setEditFieldName(e.target.value))
                      setUpdatedFieldData((prevData) =>
                      prevData.some(e=>e.id===name.id) ?  prevData.map((item) => (item.id === name.id ? { ...item, newText: e.target.value } : item)) : [...prevData,{id:name.id,newText:e.target.value}]
                  );
                    }
                    else{
                      showAlert({
                        title:  'Input Error',
                        message: 'Special characters are not allowed. Please remove them and try again.',
                        okText: 'OK',
                        callback: () => {
                            console.log('Alert closed');
                            setAlertProps(null)
                        }
                    }); 
                    }
                    
                   }
                  }
                   // onClick={() => dropdown("Billing")}
                 />
                 <i style={{marginLeft: "-50px",cursor: "pointer"}} onClick={()=>replaceFieldName(name.id,name.fieldname,name.fieldtype)}>
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="20" viewBox="0 0 30 30">
<path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"></path>
</svg></i></span><img onClick={()=>closeEditField(name.fieldname)} width="30" height="30" src="https://img.icons8.com/ios/50/multiply.png" alt="multiply"/></>:
<span onClick={() =>setEditFlag(name.fieldname)}>

                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="20" viewBox="0 0 32 32">
<path d="M 23.90625 3.96875 C 22.859375 3.96875 21.8125 4.375 21 5.1875 L 5.1875 21 L 5.125 21.3125 L 4.03125 26.8125 L 3.71875 28.28125 L 5.1875 27.96875 L 10.6875 26.875 L 11 26.8125 L 26.8125 11 C 28.4375 9.375 28.4375 6.8125 26.8125 5.1875 C 26 4.375 24.953125 3.96875 23.90625 3.96875 Z M 23.90625 5.875 C 24.410156 5.875 24.917969 6.105469 25.40625 6.59375 C 26.378906 7.566406 26.378906 8.621094 25.40625 9.59375 L 24.6875 10.28125 L 21.71875 7.3125 L 22.40625 6.59375 C 22.894531 6.105469 23.402344 5.875 23.90625 5.875 Z M 20.3125 8.71875 L 23.28125 11.6875 L 11.1875 23.78125 C 10.53125 22.5 9.5 21.46875 8.21875 20.8125 Z M 6.9375 22.4375 C 8.136719 22.921875 9.078125 23.863281 9.5625 25.0625 L 6.28125 25.71875 Z"></path>
</svg>
</span>
:""}
{name.fieldtype==="dropdown" || name.fieldtype==="radio" || name.fieldtype==="HeadGroup" ?!flaggedRadioMenuOption.includes(name.fieldname)?<span onClick={()=>setRadioMenuFlag(name.fieldname)}><img width="20" height="20" src="https://img.icons8.com/material-two-tone/24/external-link.png" alt="external-link"/></span>:<span onClick={()=>setRadioMenuFlag(name.fieldname)}><img width="25" height="25" src="https://img.icons8.com/windows/32/close-window.png" alt="close-window"/></span>:""}
{name.fieldtype==="HeadGroup"?<p className="button-12" onClick={()=>{b(1);setType("subGroup");setGroupFieldName(name.fieldname);
  setGroupName(radiooptions.filter(e=>e.FieldType==="HeadGroup"&& e.field===name.fieldname).map(e=>e.FieldName)[0]);

}}>Add Sub Group</p>:""}

                      </div>
                      {name.fieldtype==="dropdown"  || name.fieldtype==="radio" || name.fieldtype==="HeadGroup"?flaggedRadioMenuOption.includes(name.fieldname) ?

<div className="scroll-menu">
{radiooptions.filter((j) => j.field === name.fieldname).map((element,index)=>{return !flaggedOption.includes(element.id)?<div key={index}
className="menu-item"
style={{width:"194px",backgroundColor: "#ffffff"}}
value={element.FieldName}
// onClick={() => dropdown("Billing")}
>
{element.FieldName}
<span onClick={() =>setOptionFlag(element.id)}>
<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="20" viewBox="0 0 32 32">
<path d="M 23.90625 3.96875 C 22.859375 3.96875 21.8125 4.375 21 5.1875 L 5.1875 21 L 5.125 21.3125 L 4.03125 26.8125 L 3.71875 28.28125 L 5.1875 27.96875 L 10.6875 26.875 L 11 26.8125 L 26.8125 11 C 28.4375 9.375 28.4375 6.8125 26.8125 5.1875 C 26 4.375 24.953125 3.96875 23.90625 3.96875 Z M 23.90625 5.875 C 24.410156 5.875 24.917969 6.105469 25.40625 6.59375 C 26.378906 7.566406 26.378906 8.621094 25.40625 9.59375 L 24.6875 10.28125 L 21.71875 7.3125 L 22.40625 6.59375 C 22.894531 6.105469 23.402344 5.875 23.90625 5.875 Z M 20.3125 8.71875 L 23.28125 11.6875 L 11.1875 23.78125 C 10.53125 22.5 9.5 21.46875 8.21875 20.8125 Z M 6.9375 22.4375 C 8.136719 22.921875 9.078125 23.863281 9.5625 25.0625 L 6.28125 25.71875 Z"></path>
</svg>
</span>
{/* <i class="fas fa-trash-alt" onClick={()=>deleteoption(element.FieldNamem,element.field)}></i> */}
{index<2?<button title="" disabled style={{backgroundColor: "rgb(255, 255, 255)",border:"none"}}>
<i class="fas fa-trash-alt"   onClick={()=>deleteoption(element.FieldNamem,element.field)}></i></button>:<button title="" style={{backgroundColor: "rgb(255, 255, 255)",border:"none"}}><i class="fas fa-trash-alt"  onClick={()=>deleteoption(element.FieldName,element.field)}></i></button>}
</div>:
<div><input type="text"
id="myOutline"
value={onChangeOptionValue(element.id)}
onChange={(e)=>{
  const nospecial=/^[a-zA-Z0-9 ]*$/;
  if(nospecial.test(e.target.value)){
    setUpdatedOptionData((prevData) =>
      prevData.some(e=>e.id===element.id) ?  prevData.map((item) => (item.id === element.id ? { ...item, newText: e.target.value } : item)) : [...prevData,{id:element.id,newText:e.target.value}]
  );
  }
  else{
    showAlert({
      title:  'Input Error',
      message: 'Special characters are not allowed. Please remove them and try again.',
      okText: 'OK',
      callback: () => {
          console.log('Alert closed');
          setAlertProps(null)
      }
  }); 
  }
  
 }
}
// onClick={() => dropdown("Billing")}
/>
<i style={{marginLeft: "-50px",cursor: "pointer"}} onClick={()=>replaceOptionName(element.id,element.FieldName,element.FieldType,element.field)}>
<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="20" viewBox="0 0 30 30">
<path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"></path>
</svg></i>
<img onClick={()=>closeEditOption(element.id)} width="30" height="30" src="https://img.icons8.com/ios/50/multiply.png" alt="multiply"/>
</div>

})}

{name.fieldtype!=="statictext" && name.fieldtype!=="staticnumber" &&  name.fieldtype!=="staticselect" && name.fieldtype!=="staticlocation"
&& name.fieldtype!=="statictitle"?!flaggedAddOption.includes(name.fieldname)?<p align="center"> <img onClick={()=>setAddOptionFlag(name.fieldname)} width="30" height="30" src="https://img.icons8.com/ios/50/plus--v1.png" alt="plus--v1"/></p>:
                   <><span><input type="text" id="myOutline"
                   value={editOptionName}
                   placeholder="Add Other"
                   onChange={(e)=>{
                    const nospecial=/^[a-zA-Z0-9 ]*$/;
                    if(nospecial.test(e.target.value)){
                       setEditOptionName(e.target.value)
                    }
                    else{
                      showAlert({
                        title:  'Input Error',
                        message: 'Special characters are not allowed. Please remove them and try again.',
                        okText: 'OK',
                        callback: () => {
                            console.log('Alert closed');
                            setAlertProps(null)
                        }
                    }); 
                    }
                    
                   }
                  }
                   // onClick={() => dropdown("Billing")}
                 />
                 <i style={{marginLeft: "-50px",cursor: "pointer"}} onClick={()=>addRadioOption(name)}>
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="20" viewBox="0 0 30 30">
<path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"></path>
</svg></i></span>
<img onClick={()=>setAddOptionFlag(name.fieldname)} width="30" height="30" src="https://img.icons8.com/ios/50/multiply.png" alt="multiply"/>
</>:""} 
</div>

: (
""
):""}
</>
 return element                    
                        
                    
}
const getOptionElement=(name)=>
  {
    var element=""
    element=<div data-test-id="request-field-summary">
    {c === 1 ?

         <div className="scroll-menu">
         {updatedData.filter(l=>l.id<=5).map((j,index)=>{return !flaggedItems.includes(j.id)?<div key={index}
           className="menu-item"
           style={{width:"194px"}}
           value={j.reasons}
           // onClick={() => dropdown("Billing")}
         >
           {j.reasons}
           <span onClick={() =>setFlag(j.id)}>
           <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="20" viewBox="0 0 32 32">
<path d="M 23.90625 3.96875 C 22.859375 3.96875 21.8125 4.375 21 5.1875 L 5.1875 21 L 5.125 21.3125 L 4.03125 26.8125 L 3.71875 28.28125 L 5.1875 27.96875 L 10.6875 26.875 L 11 26.8125 L 26.8125 11 C 28.4375 9.375 28.4375 6.8125 26.8125 5.1875 C 26 4.375 24.953125 3.96875 23.90625 3.96875 Z M 23.90625 5.875 C 24.410156 5.875 24.917969 6.105469 25.40625 6.59375 C 26.378906 7.566406 26.378906 8.621094 25.40625 9.59375 L 24.6875 10.28125 L 21.71875 7.3125 L 22.40625 6.59375 C 22.894531 6.105469 23.402344 5.875 23.90625 5.875 Z M 20.3125 8.71875 L 23.28125 11.6875 L 11.1875 23.78125 C 10.53125 22.5 9.5 21.46875 8.21875 20.8125 Z M 6.9375 22.4375 C 8.136719 22.921875 9.078125 23.863281 9.5625 25.0625 L 6.28125 25.71875 Z"></path>
</svg></span>
         </div>:
         <div><input type="text"
           value={j.reasons}
           onChange={(e)=>handleTextChange(j.id, e.target.value)}
           // onClick={() => dropdown("Billing")}
         />
         <i style={{marginLeft: "-50px",cursor: "pointer"}} onClick={()=>update(j.id)}>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="20" viewBox="0 0 30 30">
<path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"></path>
</svg></i></div>
        })}
        {updatedData.filter(l=>l.id>5).map((j,index)=>{return !flaggedItems.includes(j.id)?<div key={index}
           className="menu-item"
           style={{width:"194px"}}
           value={j.reasons}
           // onClick={() => dropdown("Billing")}
         >
           {j.reasons}
           <span onClick={() =>setFlag(j.id)}>
           <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="20" viewBox="0 0 32 32">
<path d="M 23.90625 3.96875 C 22.859375 3.96875 21.8125 4.375 21 5.1875 L 5.1875 21 L 5.125 21.3125 L 4.03125 26.8125 L 3.71875 28.28125 L 5.1875 27.96875 L 10.6875 26.875 L 11 26.8125 L 26.8125 11 C 28.4375 9.375 28.4375 6.8125 26.8125 5.1875 C 26 4.375 24.953125 3.96875 23.90625 3.96875 Z M 23.90625 5.875 C 24.410156 5.875 24.917969 6.105469 25.40625 6.59375 C 26.378906 7.566406 26.378906 8.621094 25.40625 9.59375 L 24.6875 10.28125 L 21.71875 7.3125 L 22.40625 6.59375 C 22.894531 6.105469 23.402344 5.875 23.90625 5.875 Z M 20.3125 8.71875 L 23.28125 11.6875 L 11.1875 23.78125 C 10.53125 22.5 9.5 21.46875 8.21875 20.8125 Z M 6.9375 22.4375 C 8.136719 22.921875 9.078125 23.863281 9.5625 25.0625 L 6.28125 25.71875 Z"></path>
</svg>
</span>
<i class="fas fa-trash-alt" onClick={()=>deleteOption(j.id)}></i>

         </div>:
         <div><input type="text"
           value={j.reasons}
           onChange={(e)=>handleTextChange(j.id, e.target.value)}
           // onClick={() => dropdown("Billing")}
         />
         <i style={{marginLeft: "-50px",cursor: "pointer"}} onClick={()=>update(j.id)}>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="20" viewBox="0 0 30 30">
<path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"></path>
</svg></i></div>
        })}
         {updatedData.length<=15 && <div><input type="text"
           value={option1}
           placeholder="Add Other"
           onChange={(e)=>{
            const nospecial=/^[a-zA-Z0-9 ]*$/;
            if(nospecial.test(e.target.value)){
              (setOption1(e.target.value))
            }
            else{
              showAlert({
                title:  'Input Error',
                message: 'Special characters are not allowed. Please remove them and try again.',
                okText: 'OK',
                callback: () => {
                    console.log('Alert closed');
                    setAlertProps(null)
                }
            }); 
            }
            
           }
          }
           // onClick={() => dropdown("Billing")}
         />
         <i style={{marginLeft: "-50px",cursor: "pointer"}} onClick={()=>addOption()}>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="20" viewBox="0 0 30 30">
<path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"></path>
</svg></i></div>}
        </div>
        
       : (
        ""
      )}
    </div>
    return element
  }
  const getElement=(name)=>{
    var element=""
    if(name.fieldtype==="statictext" || name.fieldtype==="staticnumber" || name.fieldtype==="statictitle")
      {
                element=
                <>
                 <div key={name.id}
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, name)}
                    onDragEnd={handleDragEnd}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, name)} data-test-id="request-field-summary">
         {getLabel(name)}<div
                  data-ds--text-field--container="true"
                  className="css-1o07z2d"
                >
                  <input
                    id="nameuser"
                    readOnly
                    name="nameuser"
                    type="text"
                    data-ds--text-field--input="true"
                    className="css-mfjdp3"
                  />
                </div>
                </div>
                </>
      }
      if(name.fieldtype==="staticselect")
        {
        element=<>
         <div key={name.id}
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, name)}
                    onDragEnd={handleDragEnd}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, name)} data-test-id="request-field-summary">
        {getLabel(name)}<div className=" css-5a7vsu-container">
      <div
        className=" css-bzqzs4-control"
        onClick={() => dropdown("")}
        //style={{pointerEvents: "none",
          //opacity: 0.4}}
      >
        <div className=" css-s88hxx">
          <div
            className=" css-8atqhb"
            onclick={() => dropdown("")}
            style={{
              opacity: "1",
              transition: "opacity 1ms ease 0s",
            }}
          >
            {opt === "" ? (
              <div className="sc-jrIrqw gRnkOb" disabled>Select</div>
            ) : (
              <div className="sc-jrIrqw gRnkOb">{opt}</div>
            )}
          </div>
          <input
            id="request-group-select"
            readOnly=""
            tabIndex="0"
            aria-autocomplete="list"
            className="css-62g3xt-dummyInput"
            defaultValue=""
          />
        </div>
        <div className=" css-t5ibhw">
          <div
            aria-hidden="true"
            className=" css-gg6ksl-indicatorContainer"
          >
            <span
              role="img"
              aria-label="open"
              className="css-snhnyn"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                role="presentation"
              >
                <path
                  d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z"
                  fill="currentColor"
                  fillRule="evenodd"
                ></path>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
    </div>
    {getOptionElement(name)}
    </>
        }
        else if(name.fieldtype==="staticlocation")
          {
        element=<>
         <div key={name.id}
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, name)}
                    onDragEnd={handleDragEnd}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, name)} data-test-id="request-field-summary">
        {getLabel(name)}<div
                  data-ds--text-field--container="true"
                  className="css-1o07z2d"
                >
                  <select
                    readOnly
                    class="form-select"
                    id="capitalCity"
                    name="capitalCity"
                    value={location}
                    onChange={handleCityChange}
                    disabled
                  >
                    <option value="">Location</option>
                    {capitalCities.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>
                </div>
                </>
          }
      else if(name.fieldtype==="textarea")
      {
         element= <>
          <div key={name.id}
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, name)}
                    onDragEnd={handleDragEnd}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, name)} data-test-id="request-field-summary">
         {getLabel(name)}<div
          data-ds--text-field--container="true"
          className="css-1o07z2d"
          >
          <input
         id="summary"
         readOnly
         name="mobnumber"
         type="text"
         data-ds--text-field--input="true"
         className="css-mfjdp3"
       /> 
       </div>
       </div>
       </>
      }
      else if(name.fieldtype==="number")
        {
           element=
          <>
           <div key={name.id}
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, name)}
                    onDragEnd={handleDragEnd}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, name)} data-test-id="request-field-summary">
            {getLabel(name)}
           <div
            data-ds--text-field--container="true"
            className="css-1o07z2d"
          >
          <input
           type="tel"
           placeholder="Number"
           id="phoneNumber"
           name="phoneNumber"
           pattern="[0-9]{10}"
           className="css-mfjdp3"
           readOnly
         />
         </div>
         </div>
         </>
        }
          else if(name.fieldtype==="calender")
            {
               element=
               <>
                <div key={name.id}
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, name)}
                    onDragEnd={handleDragEnd}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, name)} data-test-id="request-field-summary">
         {getLabel(name)}
               <div
               data-ds--text-field--container="true"
               className="css-1o07z2d"
             >
                <input
               type="date"
               readOnly
               id="calender"
               className="css-mfjdp3"
               name="calender"
             />
             </div>
             </div>
             </>
            }
            else if(name.fieldtype==="radio")
              {
                element=<>
                 <div key={name.id}
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, name)}
                    onDragEnd={handleDragEnd}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, name)} data-test-id="request-field-summary">
         {getLabel(name)}
                  {radiooptions
                  .filter((j) => j.field === name.fieldname)
                  .map((element,index) => (
                    <>
                      <input
                        type="radio"
                        disabled
                        name="radio"
                        value={`${element.FieldName}`}
                      />
                                                    {" "}
       
                      <label for={`${element.FieldName}`}>
                        {element.FieldName}
                      </label>
                      
                      &nbsp;
                      {/* <i class="fas fa-trash-alt" onClick={()=>deleteoption(element.FieldName)}></i> */}
                      &nbsp;
                    </>
                    
                  ))}
              {/* {!flaggedAddOption.includes(name.fieldname)?<img  onClick={()=>setAddOptionFlag(name.fieldname)} width="30" height="30" src="https://img.icons8.com/ios/50/plus--v1.png" alt="plus--v1"/>:
             <><span><input type="text" id="myOutline"
             value={editOptionName}
             placeholder="Add Other"
             onChange={(e)=>{
              const nospecial=/^[a-zA-Z0-9 ]*$/;
              if(nospecial.test(e.target.value)){
                 setEditOptionName(e.target.value)
              }
              else{
                showAlert({
                  title:  'Input Error',
                  message: 'Special characters are not allowed. Please remove them and try again.',
                  okText: 'OK',
                  callback: () => {
                      console.log('Alert closed');
                      setAlertProps(null)
                  }
              }); 
              }
              
             }
            }
             // onClick={() => dropdown("Billing")}
           />
           <i style={{marginLeft: "-50px",cursor: "pointer"}} onClick={()=>addRadioOption(name)}>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="20" viewBox="0 0 30 30">
       <path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"></path>
       </svg></i></span>
       <img onClick={()=>setAddOptionFlag(name.fieldname)} width="30" height="30" src="https://img.icons8.com/ios/50/multiply.png" alt="multiply"/>
       </>} */}
       </div></>
              }
                else if(name.fieldtype==="dropdown")
                  {
                    element=    <>
                     <div key={name.id}
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, name)}
                    onDragEnd={handleDragEnd}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, name)} data-test-id="request-field-summary">
                    {getLabel(name)}<div
                    data-ds--text-field--container="true"
                    className="css-1o07z2d"
                  >
                    <select name="cars" id="cars" className="css-mfjdp3">
             <option value="volvo">Select </option>
             {radiooptions
                                     .filter((j) => j.field === name.fieldname)
                                     .map((element) => (
                                       <option value={element.FieldName}>{element.FieldName}</option>))}
           </select>
           </div></div></>
                  } 
            return element
    }
  const staticField=[
    "Date",
    "Description",
    "Id",
    "Admin",
    "File",
    "Mail",
    "Mobile",
    "Opt",
    "Status",
    "Location",
    "Summary",
    "User",
    "Time"
  ]
  var [o, p] = useState(0);
  const [placeholder,setplaceholder] = useState("Enter Text Here")


  const [nam, setNam] = useState("");
  const [user, setUser] = useState([]);
  var [allFields,setAllFields]=useState([])
  var [allFieldWithoutSpace,setAllFieldWithoutSpace]=useState([])
  const [textarea, setTextarea] = useState([]);
  const [radiooptions, setRadioOptions] = useState([]);
  const [dropdownOptions,setDropdownOptions]=useState([])
  const project = sessionStorage.getItem("project");
  const token = localStorage.getItem("superToken");
  const [option1,setOption1]=useState("")
  const [reasons,setReasons]=useState([])
  const [flaggedItems, setFlaggedItems] = useState([]);
  const [updatedData, setUpdatedData] = useState();
  const [trigger, setTrigger] = useState(true);
  const [editFieldName,setEditFieldName]=useState("")
  const [editOptionName,setEditOptionName]=useState("")
  const [flaggedField,setFlaggedField]=useState([])
  const [flaggedOption,setFlaggedOption]=useState([])
  const [updatedFieldData, setUpdatedFieldData] = useState([]);
  const [updatedOptionData, setUpdatedOptionData] = useState([]);
  const [flaggedAddOption,setFlaggedAddOption]=useState([])
  const [flaggedRadioMenuOption,setFlaggedRadioMenuOption]=useState([])


console.log(allFields)
console.log(updatedFieldData)

 
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/getOptions/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {setReasons(data.data);setUpdatedData(data.data)})
      .catch((err) => console.log(err));
  }, [trigger]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/getAllFields/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      { 
        if(data.data.length!==0)
          {
            console.log(data.data)
        {allFields.push(data.data.map(j=>j.fieldname.toLowerCase()))
          allFieldWithoutSpace.push(data.data.map(j=>j.fieldname.split(" ").join("").toLowerCase()))
        }
        setAllFieldsData(data.data)
  }})
  },[trigger])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/textareas/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) =>
      { if(data.data.length!==0)
        // {allFields.push(data.data.map(j=>j.fieldname.toLowerCase()))
        //   allFieldWithoutSpace.push(data.data.map(j=>j.fieldname.split(" ").join("").toLowerCase()))
        // }
      setTextarea(data.data)
  })
      .catch((err) => console.log(err));
  }, [trigger]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/number/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) =>
      { if(data.data.length!==0)
        // {allFields.push(data.data.map(j=>j.fieldname.toLowerCase()))
        //   allFieldWithoutSpace.push(data.data.map(j=>j.fieldname.split(" ").join("").toLowerCase()))
        // } 
        setNumber(data.data)
  })
        
      .catch((err) => console.log(err));
  }, [trigger]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/calender/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => 
      { if(data.data.length!==0)
        // {allFields.push(data.data.map(j=>j.fieldname.toLowerCase()))
        //   allFieldWithoutSpace.push(data.data.map(j=>j.fieldname.split(" ").join("").toLowerCase()))
        // } 
      setCalender(data.data)})
      .catch((err) => console.log(err));
  }, [trigger]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/radio/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => 
        {
          if(data.data.length!==0)
            // {allFields.push(data.data.map(j=>j.fieldname.toLowerCase()))
            //   allFieldWithoutSpace.push(data.data.map(j=>j.fieldname.split(" ").join("").toLowerCase()))
            // } 
          setRadio(data.data)
  })
      .catch((err) => console.log(err));
  }, [trigger]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/dropdown/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => 
        {
          if(data.data.length!==0)
            // {allFields.push(data.data.map(j=>j.fieldname.toLowerCase()))
            //   allFieldWithoutSpace.push(data.data.map(j=>j.fieldname.split(" ").join("").toLowerCase()))
            // } 
          setDropdownFields(data.data)
  })
      .catch((err) => console.log(err));
  }, [trigger]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/dropdown/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setSelect(data.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/options/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setRadioOptions(data.data))
      .catch((err) => console.log(err));
  }, [trigger]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/dropDownOptions/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setDropdownOptions(data.data))
      .catch((err) => console.log(err));
  }, []);
  

  useEffect(() => {
    console.log(token);
    if(localStorage.getItem("tokenauth")==="false")
    //if (cookie.get("tokenauth") === "false") 
    {
      window.location.href = "/v1/jim_super_login";
    }
  }, []);


  const setFlag = (id) => {
    // Toggle the flag for the specific item
    setFlaggedItems((prevFlags) =>
      prevFlags.includes(id) ? prevFlags.filter((item) => item !== id) : [...prevFlags, id]
    );
  };
  const setEditFlag = (id) => {
    // Toggle the flag for the specific item
    setFlaggedField((prevFlags) =>
      prevFlags.includes(id) ? prevFlags.filter((item) => item !== id): [...prevFlags, id]
    );
  };

  const setRadioMenuFlag = (id) => {
    // Toggle the flag for the specific item
    setFlaggedRadioMenuOption((prevFlags) =>
      prevFlags.includes(id) ? prevFlags.filter((item) => item !== id): [ id]
    );
  };

  const setAddOptionFlag = (id) => {
    // Toggle the flag for the specific item
    setFlaggedAddOption((prevFlags) =>
      prevFlags.includes(id) ? prevFlags.filter((item) => item !== id): [ ...prevFlags,id]
    );
  };
  
  console.log(flaggedOption)
  const setOptionFlag = (id) => {
    // Toggle the flag for the specific item
    setFlaggedOption((prevFlags) =>
      prevFlags.includes(id) ? prevFlags.filter((item) => item !== id) : [...prevFlags, id]
    );
  };
 
  const done = (name, fieldName) => {
    if(name==="")
      {
        showAlert({
          title:  'Input Error',
          message: 'The input should not be empty. Please provide a valid value.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });  
      }
      else if(lists.filter(e=>e.fieldname?.toLowerCase()===name.toLowerCase()).map(j=>j.fieldname)[0]?.toLowerCase()===name.toLowerCase()){
         showAlert({
          title:  'Duplicate Entry',
          message: 'This entry already exists. Please enter a unique value.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      }); 
      }
      else{
    lists.push({
      fieldname: name,
      fieldtype: field,
      project: project,
      field: fieldName,
      groupname:groupName
    });
    setList(lists);
    setOptions("");
    p(1);
  }
  };

  const removeRadioOption = (name) => {
    console.log(name)
    const removedList=lists.filter(j=>j.fieldname!==name)
    console.log(removedList)
    setLists(removedList)
    setList(removedList);
    setOptions("");
    if(removedList.length===0)
      {
        p(0)
      }
  };

  const addoption = () => {
    if (o === 1) {
      p(0);
    }
  };
  const [draggingItem, setDraggingItem] = useState(null);

  const handleDragStart = (e, item) => {
      setDraggingItem(item);
      e.dataTransfer.setData('text/plain', '');
  };

  const handleDragEnd = () => {
      setDraggingItem(null);
  };

  const handleDragOver = (e) => {
      e.preventDefault();
  };

  const handleDrop = (e, targetItem) => {
      if (!draggingItem) return;

      const currentIndex = allFieldsData.indexOf(draggingItem);
      const targetIndex = allFieldsData.indexOf(targetItem);
      if (currentIndex !== -1 && targetIndex !== -1) {
          const newItems = [...allFieldsData];
          newItems.splice(currentIndex, 1);
          newItems.splice(targetIndex, 0, draggingItem);
          setAllFieldsData(newItems);
          const res = {
            method: "PUT",
            withCredentials: "true",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
            body: JSON.stringify({source:draggingItem,destination:targetItem}),
          };
          fetch(`${process.env.REACT_APP_API_URL}/updateReplaceEntry`, res).then(() => {
            console.log("added");
            setTrigger(!trigger)        
          }); 
      }
  };

  
  console.log(updatedData)
    const handleTextChange = (id, newText) => {
      const nospecial=/^[a-zA-Z0-9 ]*$/;
                      if(nospecial.test(newText))
    {           
      setUpdatedData((prevData) =>
        prevData.map((item) => (item.id === id ? { ...item, reasons: newText } : item))
      );
    }
    else{
      showAlert({
        title:  'Input Error',
        message: 'Special characters are not allowed. Please remove them and try again.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    }); 
    }
    };
    
    const addOption=()=>
    {
      const token=localStorage.getItem("superToken")
      var flag=false
      for(const i of updatedData)
      {
      if(option1.toLowerCase()===i.reasons.toLowerCase())
      {
        flag=true
      }
    }
    console.log(flag)
      if(option1!=''&&flag===false)
      {
       const res = {
        method: "POST",
        withCredentials: "true",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify({option:option1}),
      };
      fetch(`${process.env.REACT_APP_API_URL}/addOption/${project}`, res).then(() => {
        console.log("added");
        setTrigger(!trigger)
      });
      setOption1('')
      //window.location.reload('true')
     }
     else if(option1!=''&&flag===true)
     {
      showAlert({
        title:  'Duplicate Entry',
        message: 'This entry already exists. Please enter a unique value.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    });
     }
    else{
      showAlert({
        title:  'Input Error',
        message: 'The input should not be empty. Please provide a valid value.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    });  
    }}


    const addRadioOption=(field)=>
      {
        const listOfOption=radiooptions.filter((j) => j.field === field.fieldname).map(ele=>ele.FieldName.toLowerCase())
        if(listOfOption.includes(editOptionName.toLowerCase()))
          {
            showAlert({
              title:  'Input Error',
              message: 'You cannot add options that have already been added. ',
              okText: 'OK',
              callback: () => {
                  console.log('Alert closed');
                  setAlertProps(null)
              }
          });  
          }
       else if(editOptionName!=="")
          {
        const object={
          fieldname: editOptionName,
          fieldtype: field.fieldtype,
          project: project,
          field: field.fieldname,
        }
    const radioresult = {
      method: "POST",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(object),
    };
    fetch(`${process.env.REACT_APP_API_URL}/addFieldOption`, radioresult).then(
      () => {
        console.log("added");
        setTrigger(!trigger)
      }
    );
    setAddOptionFlag(field.fieldname)
    setEditOptionName('')
  }
  else{
    showAlert({
      title:  'Input Error',
      message: 'The input should not be empty. Please provide a valid value.',
      okText: 'OK',
      callback: () => {
          console.log('Alert closed');
          setAlertProps(null)
      }
  });  
  }
  }
    const onChangeValue=(id)=>
      {
        const object=updatedFieldData.find(e=>e.id===id)
        const name=object?object.newText:""
        return name;
      }

      const onChangeOptionValue=(id)=>
        {
          const object=updatedOptionData.find(e=>e.id===id)
          const name=object?object.newText:""
          return name;
        }
    const replaceFieldName=(id,oldName,fieldType)=>
      {
        const object=updatedFieldData.find(e=>e.id===id)
        const editFieldName=object?object.newText:""
        const all=allFields.flat()
        const token = localStorage.getItem("superToken");
      if (
        editFieldName == "date" ||
        editFieldName == "description" ||
        editFieldName == "id" ||
        editFieldName == "admin" ||
        editFieldName == "file" ||
        editFieldName == "mail" ||
        editFieldName == "mobile" ||
        editFieldName == "opt" ||
        editFieldName == "status" ||
        editFieldName == "location" ||
        editFieldName == "summary" ||
        editFieldName == "user" ||
        editFieldName == "time"
      ) {
        showAlert({
          title:  'Input Error',
          message: 'You cannot add fields that have already been added.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });
      }
      else if(staticField.includes(editFieldName))
      {
        showAlert({
          title:  'Input Error',
          message: 'You cannot add fields that have already been added.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });
      }
      else if(all.includes(editFieldName.toLowerCase()) || allFieldWithoutSpace.flat().includes(editFieldName.split(" ").join("").toLowerCase()))
      {
        showAlert({
          title:  'Input Error',
          message: 'You cannot add fields that have already been added.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });
      }
       else if(editFieldName!='')
        {
         const res = {
          method: "POST",
          withCredentials: "true",
          headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
          body: JSON.stringify({oldName:oldName,newName:editFieldName,fieldType:fieldType}),
        };
        fetch(`${process.env.REACT_APP_API_URL}/replaceFieldName/${project}`, res).then(() => {
          console.log("added");
          setAllFields([])
          setAllFieldWithoutSpace([])
          console.log(allFields)
          setTrigger(!trigger)
          setEditFlag(oldName)
          setUpdatedFieldData((prevData) =>
            prevData.some(e=>e.id===id) ?  prevData.map((item) => (item.id === id ? { ...item, newText: "" } : item)) : [...prevData,{id:id,newText:""}])
        });
        setEditFieldName('')
        //window.location.reload('true')
       }
       else{
        showAlert({
          title:  'Input Error',
          message: 'The input should not be empty. Please provide a valid value.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });  
       }
    }
    const updateRequired=(fieldName,checked)=>
      {
        var required=checked==="true"?"false":"true"
         const res = {
          method: "PUT",
          withCredentials: "true",
          headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
          body: JSON.stringify({fieldName,required}),
        };
        fetch(`${process.env.REACT_APP_API_URL}/updateRequired/${project}`, res).then(() => {
          console.log("added");
          setTrigger(!trigger)        
        }); 
    }

    const closeEditField=(name)=>
      {
        setEditFlag(name)
      }

      
    const closeEditOption=(id)=>
      {
          setOptionFlag(id)
      }
    console.log(updatedOptionData)
    const replaceOptionName=(id,oldName,fieldType,fieldName)=>
      {
        const object=updatedOptionData.find(e=>e.id===id)
        const editOptionName=object?object.newText:""
        const token = localStorage.getItem("superToken");
       const listOfOption=radiooptions.filter((j) => j.field === fieldName).map(ele=>ele.FieldName.toLowerCase())
       console.log(listOfOption)
       console.log(editOptionName)
       if(radiooptions.filter(j=>j.FieldType==="HeadGroup").map(e=>e.FieldName).includes(editOptionName))
        {
          showAlert({
            title:  'Input Error',
            message: 'You cannot add options that have already been added. ',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        });  
        }
        else if(listOfOption.includes(editOptionName.toLowerCase()))
          {
            showAlert({
              title:  'Input Error',
              message: 'You cannot add options that have already been added. ',
              okText: 'OK',
              callback: () => {
                  console.log('Alert closed');
                  setAlertProps(null)
              }
          });  
          }
       else if(editOptionName!='')
        {
         const res = {
          method: "POST",
          withCredentials: "true",
          headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
          body: JSON.stringify({oldName:oldName,newName:editOptionName,fieldType:fieldType,fieldName:fieldName}),
        };
        fetch(`${process.env.REACT_APP_API_URL}/replaceOptionName/${project}`, res).then(() => {
          console.log("added");
          setAllFields([])
          setAllFieldWithoutSpace([])
          setTrigger(!trigger)
          setOptionFlag(id)
        });
        setEditFieldName('')
        //window.location.reload('true')
       }
       else{
        showAlert({
          title:  'Input Error',
          message: 'The input should not be empty. Please provide a valid value.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });  
       }
      }
    const update=(j)=>
    {
      const obj=updatedData.filter(k=>k.id===j)
      console.log(obj[0].reasons)
      var flag=false
      for(const i of updatedData.filter(n=>n.id!==obj[0].id))
      {
      if(obj[0].reasons.toLowerCase()===i.reasons.toLowerCase())
      {
        flag=true
      }
    }
    console.log(flag)
      if(obj[0].reasons!=''&&flag===false)
      {
       const res = {
        method: "PUT",
        withCredentials: "true",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify({"id":j,option:obj[0].reasons}),
      };
      fetch(`${process.env.REACT_APP_API_URL}/editOption/${project}`, res).then(() => {
        console.log("added");
      });
     setFlag(j)}
     else if(obj[0].reasons!=''&&flag===true)
     {
      showAlert({
        title:  'Duplicate Entry',
        message: 'This entry already exists. Please enter a unique value.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    });
     }
    else{
      showAlert({
        title:  'Input Error',
        message: 'The input should not be empty. Please provide a valid value.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    });  
    }}
 

  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  let [c, d] = useState(0);

  const dropdown = (e) => {
    if (c === 0) {
      d(1);
    } else {
      d(0);
    }
    setOption(e);
  };

  const capitalCities = [
    "Mumbai",
    "Delhi",
    "Bangalore",
    "Kolkata",
    "Chennai",
    "Hyderabad",
    "Pune",
    "Ahmedabad",
    "Jaipur",
    "Lucknow",
    "Chandigarh",
    "Bhopal",
    "Indore",
    "Patna",
    "Kanpur",
    "Nagpur",
    "Coimbatore",
    "Vadodara",
    "Ludhiana",
    "Agra",
    "Nashik",
    "Kochi",
    "Varanasi",
    "Madurai",
    "Visakhapatnam",
    // Add more cities or create additional arrays as needed
  ];

  // Define initial state for the selected capital city

  // Function to handle changes in the selected city
  const handleCityChange = (event) => {
    setLocation(event.target.value);
  };
  const [a, b] = useState(0);
  const add = () => {
    setType("normal")
    setGroupName(null)
    b(1);
  };
  const close = () => {
    b(0);
  };

  const [field, setField] = useState("calender");
  const [fieldnam, setFieldnam] = useState("");
  const require = useRef();

  console.log(field);
  const save = () => {
    const all=allFields.flat()
    const token = localStorage.getItem("superToken");
    var fields = fieldnam;
    if (fieldnam.includes(" ")) {
      fields = fieldnam.split(" ").join("");
    }
    const fieldname = fields;
    const result = {
      method: "POST",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ fieldname }),
    };
    if (
      fieldnam == "date" ||
      fieldnam == "description" ||
      fieldnam == "id" ||
      fieldnam == "admin" ||
      fieldnam == "file" ||
      fieldnam == "mail" ||
      fieldnam == "mobile" ||
      fieldnam == "opt" ||
      fieldnam == "status" ||
      fieldnam == "location" ||
      fieldnam == "summary" ||
      fieldnam == "user" ||
      fieldnam == "time"
    ) {
      showAlert({
        title:  'Input Error',
        message: 'You cannot add fields that have already been added.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    });
    }
    else if(staticField.includes(fieldnam))
    {
      showAlert({
        title:  'Input Error',
        message: 'You cannot add fields that have already been added.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    });
    }
    else if(all.includes(fieldnam.toLowerCase()) || allFieldWithoutSpace.flat().includes(fieldnam.split(" ").join("").toLowerCase()))
    {
      showAlert({
        title:  'Input Error',
        message: 'You cannot add fields that have already been added.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    });
    }
    else if((field==="radio" || field==="dropdown" || field==="HeadGroup") && lists.length<2)
      {
        showAlert({
          title:  'Input Error',
          message: 'Minimum of two options is needed. Please provide at least two options.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });  
      }
      else if(radiooptions.filter(j=>j.FieldType==="HeadGroup").map(e=>e.FieldName).some(e=>list.map(f=>f.fieldname).includes(e)))
        {
          showAlert({
            title:  'Input Error',
            message: 'You cannot add options that have already been added. ',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        });  
        }
    else{
    if (fieldname) {
      fetch(
        `${process.env.REACT_APP_API_URL}/addNewColumn/${project}`,
        result
      ).then(() => {
        console.log("added");
      });
      console.log(list.map(e=>e.field=fieldnam))
      const radioresult = {
        method: "POST",
        withCredentials: "true",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(list.filter(j=>j.field=fieldnam)),
      };
      fetch(`${process.env.REACT_APP_API_URL}/radio/insert`, radioresult).then(
        () => {
          console.log("added");
        }
      );    
      var required;
      if (require.current.checked) {
        required = "true";
      } else {
        required = "false";
      }
      const fieldtype = field;
      const obj = { fieldname:fieldnam, fieldtype, required, project,groupname:groupName };
      console.log(obj);
      const res = {
        method: "POST",
        withCredentials: "true",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      };

      fetch(`${process.env.REACT_APP_API_URL}/insert`, res).then(() => {
        console.log("added");
      });
      //const object={fieldname}

      b(0);
      window.location.reload(false);
    } else {
       showAlert({
        title:  'Required',
        message: 'Please fill out all field.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    });
    }
  }
  };
  const deleteOption=(id)=>
  {
    fetch(`${process.env.REACT_APP_API_URL}/optionDelete/${project}/${id}`, 
    {
      method: 'DELETE',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then((data) => {
      setTrigger(!trigger)
    showAlert({
                        title:  'Deletion Complete',
                        message: 'Option was deleted successfully.',
                        okText: 'OK',
                        callback: () => {
                            console.log('Alert closed');
                            setAlertProps(null)
                        }
                    });  
    console.log('Option deleted successful')
    });
    }
  const deleted = async (name) => {
    // DELETE request using fetch inside useEffect React hook
    const token = localStorage.getItem("superToken");
    showAlert({
      title:  'Confirmation',
      message: 'Are you sure you want to delete this field?',
      okText: 'OK',
      cancelText: 'Cancel',
      callback: async (res) => {
          console.log('Alert closed');
          
          if(res)
       {
      if(name.fieldtype==="HeadGroup")
        {
          const deletecol = {
            method: "DELETE",
            withCredentials: "true",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ fieldname:name.fieldname }),
          };
    
          await fetch(
            `${process.env.REACT_APP_API_URL}/deleteGroup/${project}`,
            deletecol
          ).then(() => {
            console.log("deleted");
          });
          window.location.reload(false);
        }
      else
        {
      const fieldname = name.fieldname;
      const deletecol = {
        method: "POST",
        withCredentials: "true",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ fieldname:fieldname.split(" ").join("") }),
      };

      await fetch(
        `${process.env.REACT_APP_API_URL}/commend/${project}/deletes`,
        deletecol
      ).then(() => {
        console.log("added");
      });
      const deleterow = {
        method: "POST",
        withCredentials: "true",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ fieldname }),
      };

      await fetch(
        `${process.env.REACT_APP_API_URL}/fields/delete/${project}`,
        deleterow
      ).then(() => {
        console.log("added");
      });
      await deleteOptionsEntry(name.fieldname)
      window.location.reload(false);
    }
    } else {
      console.log("Your Request Cancelled");
    }
    setAlertProps(null)
  }
})
  };
  const deleteoption = async (name,fieldName) => {
    console.log(name)
    // DELETE request using fetch inside useEffect React hook
    const optionName = name;
    const deleterow = {
      method: "POST",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ optionName,fieldName }),
    };

    await fetch(
      `${process.env.REACT_APP_API_URL}/options/delete/${project}`,
      deleterow
    ).then(() => {
      console.log("added");
      setTrigger(!trigger)
    });
    console.log(list);
  };
  const deleteOptionsEntry = async (fieldName) => {
    const deleterow = {
      method: "DELETE",
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    };

    await fetch(
    `${process.env.REACT_APP_API_URL}/optionsEntryDelete/${project}/${fieldName}`,
      deleterow
    ).then(() => {
      console.log("deleted");
    });
  };
  const deleteRadioField=async(name)=>{
    //await deleteOptionsEntry(name)
    await deleted(name);
  }
  const deleteDropdownField=async(name)=>{
    //await deleteOptionsEntry(name)
    await deleted(name);
  }
  console.log(textarea);
  console.log(list);

  const handlePlaceholder = (e) =>{
      //e.preventDefault()
     showAlert({
                        title:  'Success',
                        message: 'Placeholder has been added successfully.',
                        okText: 'OK',
                        callback: () => {
                            console.log('Alert closed');
                            setAlertProps(null)
                        }
                    }); 
      const fieldtype = "DescPlaceholder";
      const fieldname=placeholder
      //const plainString = fieldname.replace(/<[^>]+>/g, '');
      const required="false"
      const obj = { fieldname:fieldname, fieldtype, required, project,groupname:groupName};
      console.log(obj);
      const res = {
        method: "POST",
        withCredentials: "true",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      };

      fetch(`${process.env.REACT_APP_API_URL}/insert`, res).then(() => {
        console.log("added");
      });
      
  }

  const handleChange = (value) =>{
    
      setplaceholder(value)
  }
  console.log("@@@@@@",placeholder)
  return (
    <>
    {allFieldsData.length!==0?
      <>
      <HeaderAdmin
        requests={0}
        backButton={1}
        raiseTicket={0}
        registrationRequest={0}
        adminRequests={0}
        showAlert={showAlert} 
        setAlertProps={setAlertProps}
      />
      <div>
        <div className="sc-hORach dWMRNy">
          <div className="sc-eTuwsz jmFfsW"></div>
          <main className="sc-hXRMBi bJStMD sc-ibxdXY fpdUcT">
            <div className="sc-hrWEMg cesPGE">
              <header>
                <span className="sc-rBLzX fayCzS"></span>
                <div className="sc-TFwJa dUxdnV">
                  <div className="sc-fjmCvl OGFXn">
                    <img className="sc-feJyhm fDacxp" src={img} />
                  </div>
                  <div>
                    <h1 className="sc-bHwgHz bGtLRv">{project}</h1>
                  </div>
                </div>
              </header>
              <br />
              
             {/* {textarea.length === 0
                ? ""
                :textarea.map((name) => ( */}
                {allFieldsData.length === 0
                ? ""
                :allFieldsData.filter(j=>j.groupname===null).map((name)=>(<>  
                {name.fieldtype==="HeadGroup"?
                <>
                <div key={name.id}
              draggable="true"
              onDragStart={(e) => handleDragStart(e, name)}
              onDragEnd={handleDragEnd}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, name)} data-test-id="request-field-summary">
               {getLabel(name)}<div
                data-ds--text-field--container="true"
                className="css-1o07z2d"
              >
                <select name="cars" id="cars" className="css-mfjdp3" onChange={(e)=>handle(name.fieldname,e.target.value)}>
         <option value="volvo">Select </option>
         {radiooptions
                                 .filter((j) => j.field === name.fieldname)
                                 .map((element) => (
                                   <option value={element.FieldName}>{element.FieldName}</option>))}
       </select>
       </div>
       
            </div>
            {name.fieldtype!=="statictext" && name.fieldtype!=="staticnumber" && name.fieldtype!=="staticselect" && name.fieldtype!=="staticlocation" 
                      && name.fieldtype!=="statictitle"?<button
                        class="delete-button"
                        onClick={() => deleted(name)}
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>:""}
                      
                {allFieldsData.length === 0
                ? ""
                :allFieldsData.filter(j=>j.groupname===state[name.fieldname]).map((name)=>(<>
                      <img width="20" height="20" src="https://img.icons8.com/ios-filled/50/chain.png" alt="chain"/>{getElement(name)}
                      {name.fieldtype!=="statictext" && name.fieldtype!=="staticnumber" && name.fieldtype!=="staticselect" && name.fieldtype!=="staticlocation" 
                      && name.fieldtype!=="statictitle"?<button
                        class="delete-button"
                        onClick={() => deleted(name)}
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>:""}
                    </>
                  )) }</>                
                   :<>{getElement(name)}
                   {name.fieldtype!=="statictext" && name.fieldtype!=="staticnumber" && name.fieldtype!=="staticselect" && name.fieldtype!=="staticlocation" 
                   && name.fieldtype!=="statictitle"?<button
                     class="delete-button"
                     onClick={() => deleted(name)}
                   >
                     <i class="fas fa-trash-alt"></i>
                   </button>:""}
                   </>
                   }
                  
                     
                      <br />    
                    </>
                  ))}
                   
             
              <div className="sc-krvtoX gLHWRW">
                <div
                  className=""
                  style={{
                    height: "auto",
                    overflow: "visible",
                    transition: "height 0.25s ease 0",
                  }}
                >
                  <div style={{ overflow: "visible" }}>
                  
                    <div data-test-id="request-field-description">
                      <div className="sc-gGCbJM foiuyx">
                        <label htmlFor="description" className="css-1qzu3fk">
                          Description
                        </label>
                      </div>
                      <div
                        className="sc-chAAoq hcyXVm"
                        id="description"
                        data-test-id="rich-text-area-field"
                      >
                        <div className="sc-hAXbOi eotBHb">
                          <div className="css-1dr2qy6">
                            <div
                              style={{
                                display: "block",
                                width: "100%",
                                position: "absolute",
                              }}
                            ></div>
                            <div className=" css-18w41as">
                              <div className="akEditor css-1yk94i7" required>
                                <ReactQuill
                                  //readOnly
                                  theme="snow"
                                  style={{ height: "105px" }}
                                  value={placeholder}
                                  placeholder = {placeholder}
                                  onChange={handleChange}
                                  //onKeyPress={handleKeyPress}
                                />
                                 
                              </div>
                              
                            </div>
                          </div>
                          <Button className="mt-2 btn-secondary"  onClick={handlePlaceholder} >Save</Button> 
                        </div>
                      </div>
                    </div>
                    <br />
                    <div data-test-id="request-field-attachment">
                      <div className="sc-gGCbJM foiuyx">
                        <label htmlFor="attachment" className="css-1qzu3fk">
                          Attachment
                        </label>
                      </div>
                      <div>
                        <div className="sc-hizQCF fkZtIa">
                          <div className="sc-eNPDpu gJJhIj">
                            <div className="sc-hARARD evXuov">
                              <span>
                                Select .jpg or .mp4 or .zip
                                {/*Drag and drop files, paste screenshots, or browse*/}
                              </span>
                            </div>
                            <button
                              type="button"
                              onClick={handleClick}
                              className="css-18u3ks8"
                            >
                              <span className="css-j8fq0c">
                                <span className="css-t5emrf">
                                  {attach === "" ? (
                                    <span>Browse</span>
                                  ) : (
                                    <span>
                                      {attach.replace("C:\\fakepath\\", "")}
                                    </span>
                                  )}
                                </span>
                              </span>
                            </button>
                            <input
                              type="text"
                              /*onChange={handleChange}*/
                              value={attach}
                              onChange={(e) => setAttach(e.target.value)}
                              accept=".jpg,.jpeg,.pdf,.zip,.mp4"
                              ref={hiddenFileInput}
                              style={{ display: "none" }}
                            />
                          </div>
                        </div>
                      </div>
                      <input
                        data-testid="media-picker-file-input"
                        type="file"
                        multiple=""
                        style={{ display: "none" }}
                      />
                    </div>
                    <br />
                    <div align="center">
                      <button onClick={add} style={{ border: "aliceblue",backgroundColor:"white"  }}>
                        {/* <img style={{ height: "50px"}} src={plus} />
                        <br/>
                        <br/> */}
                        <div id="dialog-header">
                            <h5>Add Fields</h5>
                          </div>
                      </button>
                    </div>
                    {a === 1 ? (
                      <>
                        <div id="dialog-box" align="right">
                          <div id="dialog-header">
                            <h4>Add Field</h4>
                            {/* <span onClick={close} id="dialog-close">
                              &times;
                            </span> */}
                          </div>
                          <br />
                          <form>
                            
                            {/* <label for="dropdown">
                              <p>Field Type</p>
                            </label> */}
            
                            {type==="subGroup"?<><div>
                              {/* <label>
                                <p>Field Name</p>
                              </label> */}
                              <label>  <p style={{
    fontSize: "1em",
    fontStyle: "inherit",
    lineHeight: 1.14286,
    color: "var(--ds-text, #172B4D)", // Adjust as needed
    fontWeight: "var(--ds-font-weight-semibold, 600)" // Adjust as needed
  }}>Group Name</p>
      </label>  
                            </div>
                            <select
                              name="type"
                              style={{ width: "250px" }}
                              id="type"
                              value={groupName}
                              onChange={(e) => setGroupName(e.target.value)}
                            >
                              {radiooptions.filter(e=>e.FieldType==="HeadGroup"&& e.field===groupFieldName).map(element=><option value={element.FieldName}>{element.FieldName}</option>)}
                            </select>
                            <br/>
                            <br/></>:""}
                            <label for="dropdown" ><p style={{
    fontSize: "1em",
    fontStyle: "inherit",
    lineHeight: 1.14286,
    color: "var(--ds-text, #172B4D)", // Adjust as needed
    fontWeight: "var(--ds-font-weight-semibold, 600)" // Adjust as needed
  }}>Field Type</p></label>&nbsp;
                            &nbsp;
                            <select
                              name="type"
                              style={{ width: "250px" }}
                              id="type"
                              value={field}
                              onChange={(e) => setField(e.target.value)}
                            >
                              <option value="calender">Calender</option>
                              <option value="radio">Radio Button</option>
                              <option value="dropdown">Dropdown</option>
                              <option value="textarea">Text Area</option>
                              <option value="number">Number</option>
                              {type!=="subGroup"?<option value="HeadGroup">Cascading Select</option>:""}
                            </select>
                            <br />
                            <br />
                            <div>
                              {/* <label>
                                <p>Field Name</p>
                              </label> */}
                              <label>  <p style={{
    fontSize: "1em",
    fontStyle: "inherit",
    lineHeight: 1.14286,
    color: "var(--ds-text, #172B4D)", // Adjust as needed
    fontWeight: "var(--ds-font-weight-semibold, 600)" // Adjust as needed
  }}>{field==="HeadGroup"?"Group Name":"Field Name"}</p>
      </label>
                              <input
                                type="text"
                                style={{ width: "250px" }}
                                value={fieldnam}
                                onChange={(e) => {
                                  const nospecial=/^[a-zA-Z0-9 ]*$/;
                                  if(nospecial.test(e.target.value)){
                                    (setFieldnam(e.target.value))
                                  }
                                  else{
                                    showAlert({
                                      title:  'Input Error',
                                      message: 'Special characters are not allowed. Please remove them and try again.',
                                      okText: 'OK',
                                      callback: () => {
                                          console.log('Alert closed');
                                          setAlertProps(null)
                                      }
                                  }); 
                                  }
                                 
                                }}
                                name="fieldname"
                                id="Uname"
                                placeholder="Field Name"
                              />
                              <br />
                            </div>
                            <br/>
                            {field === "radio"  || field==="dropdown" || field === "HeadGroup" ? (
                              <>
                                <div>
                                  {list.length !== 0
                                    ? 
                                    <><label>
                                    <p>Option</p>
                                  </label>
                                    {list.map((element) => (
                                       
                                           <div>
                                          <input
                                            type="text"
                                            style={{ width: "250px" }}
                                            readOnly
                                            value={element.fieldname}
                                            name="fieldname"
                                            id="Uname"
                                            placeholder="option"
                                          />
                                          <span><i class="fas fa-trash-alt" style={{marginLeft: "-19px",cursor: "pointer"}} onClick={()=>removeRadioOption(element.fieldname)}></i></span>
                                          </div> 
                                       
                                      ))}
                                    </>

                                    : ""}
                                  {o === 0 ? (
                                    <>
                                      <label>
                                        <p>Add Option</p>
                                      </label>
                                      <div><input type="text"
                     value={options}
                     style={{ width: "250px" }}
                     placeholder="option"                     onChange={(e)=>{
                      const nospecial=/^[a-zA-Z0-9. ]*$/;
                     if(nospecial.test(e.target.value)){
                        (setOptions(e.target.value))
                      }
                      else{
                        showAlert({
                          title:  'Input Error',
                          message: 'Special characters are not allowed. Please remove them and try again.',
                          okText: 'OK',
                          callback: () => {
                              console.log('Alert closed');
                              setAlertProps(null)
                          }
                      }); 
                      }
                      
                     }
                    }
                     // onClick={() => dropdown("Billing")}
                   />
                   <i style={{marginLeft: "-50px",cursor: "pointer"}}  onClick={()=>done(options,fieldnam)}>
                    <svg  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="20" viewBox="0 0 30 30">
<path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"></path>
</svg></i></div>
                                     
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                
                                <br />
                                {lists.length!==0?<><button
                                  type="button"
                                  onClick={addoption}
                                  value="+"
                                ><h3>+</h3></button><br/>
                                <br /></>:""}
                                
                              </>
                            ) : (
                              ""
                            )}
                            <input
                              type="checkbox"
                              id="required"
                              name="required"
                              value="required"
                              ref={require}
                            />
                            
                            <label for="required"> Required</label>
                            <br></br>
                          </form>
                          <div id="dialog-footer">
                            <button id="save-dialog" onClick={close}>
                              Cancel
                            </button>
                            <button id="close-dialog" onClick={save}>
                              Save
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <br />
                  </div>
                </div>
              </div>
              <div className="sc-jzgbtB gUQSWG"></div>
              <div className="sc-jqIZGH bokdCL"></div>   
            </div>
          </main>
        </div>
      </div>
      </>:<div style={{display:"flex",height:"458px",width:"100%",justifyContent:"center",alignItems:"center"}}>  <div class="spinner-border" style={{width: "3rem", height: "3rem", role:"status"}}>
        <span class="sr-only">Loading...</span>
      </div> </div>}
    </>
  );
}

export default EditJioSignage;
