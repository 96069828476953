import React, { useEffect , useRef, useState} from 'react';
import '../css/statistics.css';
import { Doughnut, Bar, getElementsAtEvent } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import Headersupportadminhotel from './headersupportadminhotel';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  // Bar related
  BarElement,
  CategoryScale,
  LinearScale,
  Title
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Title);


function Statisticshotel({showAlert,setAlertProps}) {
  const[supportData,setSupportData]=useState([])
  const[supportCompletedTasks,setSupportCompletedTasks]=useState([])
  const[supportAssignedTasks,setSupportAssignedTasks]=useState([])
  const[supportIncompleteTasks,setSupportIncompleteTasks]=useState([])
  const token=localStorage.getItem("supportToken")
  const navigate=useNavigate()
  const nam=useSelector((state)=>state.userSupportAdminData)
  //const project=cookie.get("project")
  
  const [project,setProject]=useState("")


 useEffect(() => {
  try{
const decodedToken = jwtDecode(token);
setProject(decodedToken.project)
  }
  catch(error)
  {
    navigate("/v1/jim_admin_user_login")
  }
},[])

  // fetching count(status) of ticket for support
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/bar/${project}/${nam}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {'Authorization':`Bearer ${token}`,
      },
    }
    )
    .then(res=>res.json())
    .then(data=>{
      setSupportData(data.data);
      setSupportCompletedTasks(data.data[4]);
      setSupportAssignedTasks(data.data[0]+data.data[1]+data.data[2]+data.data[3]+data.data[4]);
      setSupportIncompleteTasks(data.data[0]+data.data[1]+data.data[2]+data.data[3]);
      if(data.statusCode===400)
      {
        showAlert({
          title:  'Logged Out',
          message: 'You are logged out due to session timeout.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });
        navigate('/v1/jim_admin_user_login')
      }
    })
    .catch(err=>console.log(err))
  }, [project])
  
  useEffect(() => {
    console.log(token)
    if(localStorage.getItem("tokenauth")==="false")
    //if(cookie.get("tokenauth")==="false")
    {
      window.location.href="/v1/jim_admin_user_login"
    }
  },[])
  
  // Doughnut chart data

  const chartD1Ref = useRef();
  const doughnutData = {
    //labels: supportTasks
    labels: ['Assigned Tasks', 'Pending Tasks', 'Completed Tasks'],
    datasets: [{
      label:'Ticket Count',
      data: [supportAssignedTasks, supportIncompleteTasks, supportCompletedTasks],
      backgroundColor: ['#1a8cFF', '#ff1a1a', '#00b33c'],
      link: [`${process.env.REACT_APP_API_LOCALURL}/v1/jim/support?status=assigned`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/support?status=pending`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/support?status=completed`]
    }]
  };
  
  const onD1Click = (event) => {
    if(getElementsAtEvent(chartD1Ref.current, event).length > 0){
      const datasetIndexNum = getElementsAtEvent(chartD1Ref.current, event)[0].datasetIndex;
      const dataPoint = getElementsAtEvent(chartD1Ref.current, event)[0].index;
      //console.log(doughnutData.datasets[datasetIndexNum].link[dataPoint]);
      //window.open(doughnutData.datasets[datasetIndexNum].link[dataPoint], '_self');
    }
  };

  // Bar chart data
  const chartB1Ref = useRef();

  const barData1 = {
    //labels: ticketStatus,
    labels: ['Open', 'In-Progress','Rejected','Resolved', 'Closed'],
    datasets: [
      {
        label: 'Ticket Count',
        backgroundColor: ['#f72020', '#2059f7','#f75620','#9620f7','#006644'],
        data: Object.values(supportData),
        //data: Object.values(supportBarData),
        borderWidth: 1,
        link: [`${process.env.REACT_APP_API_LOCALURL}/v1/jim/support?status=opened`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/support?status=In Progress`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/support?status=Rejected`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/support?status=Resolved`,`${process.env.REACT_APP_API_LOCALURL}/v1/jim/support?status=Closed`]
      },
    ],
  };

  const d1Options = {
    cutout : '40%',
    plugins: {
      legend: {
        title: {
          display: true,
          text: 'Tickets by Progress',
          padding: {
            top: 5,
            bottom:10,
          },
          font: {
          family: 'Arial',
          size: 24,
          stype: 'normal',
          weight: 'bold',
        }
        }
      }
    }
  };


  const barOptions = {
    scales: {
      x: { grid: { display: true }},
      y: {
        grid: { display: false },
        grace: '5%',
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        title: {
          display: true,
          text: 'Tickets by Status',
          padding:{
            bottom:'50',
          },
          font: {
            family: 'Arial',
            size: 24,
            stype: 'normal',
            weight: 'bold',
          }
        },
        // function to filter and not display the labels on legend
        labels: {
          filter : function(){
            return false;
          }
          //display: false,
        }
      }
    }
  };

  const onBarClick = (event) => {
    if(getElementsAtEvent(chartB1Ref.current, event).length > 0){
      const datasetIndexNum = getElementsAtEvent(chartB1Ref.current, event)[0].datasetIndex;
      const dataPoint = getElementsAtEvent(chartB1Ref.current, event)[0].index;
  
      //window.open(barData1.datasets[datasetIndexNum].link[dataPoint], '_self');  
    }
  }
  
  return (
  <div>
    <Headersupportadminhotel l={0} backButton={1} k={0} h={1} j={1} f={1} i={1} m={70} showAlert={showAlert} setAlertProps={setAlertProps}/>
    <br/>
    <br/>
      <h1 style={{ textAlign: 'center' }}>Statistical Analysis Report</h1>
      <br/>
      <br/>
        <div classname="support-dashboard-outer1" style={{display: 'flex', flexDirection:'row', marginLeft: '30px', marginRight: '30px', height:'500px'}}>
          <div className="card" style={{ width: '100%' ,height: '97%', paddingRight:'0px', paddingLeft: '25px'}}>
            <div className="chart-container" style={{ display: 'flex', alignItems:'center', justifyContent: 'center', width: '90%', height: '90%'}}>
                <Doughnut data={doughnutData} options={d1Options} onClick={onD1Click} ref={chartD1Ref}/> {/*//*/}
            </div>  
          </div>
          <div className="card" style={{ width: '100%' ,height: '97%', marginBottom:'10px' }}>
            <div className="chart-container" style={{display: 'flex',alignItems:'center', width: '105%' ,height: '105%' }}>
              <Bar
                data={barData1}
                options={barOptions}
                onClick={onBarClick}
                ref={chartB1Ref}
              />
            </div>  
          </div>
        </div>
  </div>
  )
}

export default Statisticshotel
