import React from 'react'
import '../css/admin.css';
import '../css/drop.css';
import { useState,useEffect } from 'react';
import Headerhotel from './headerhotel';
import Utablehotel from './Utablehotel';
import Alluserhotel from './Alluserhotel';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userName } from '../feature/slice';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import {jwtDecode} from 'jwt-decode'

 
function Userhotel({showAlert,setAlertProps}) {
  //var [adm,setAdm]=useState(Clic);
  const token=localStorage.getItem("token")
  const dispatch=useDispatch()

  const navigate=useNavigate()
  var [opti,setOption]=useState('');
  var [req,setReqOption]=useState('');
  const [options,setOptions]=useState([])
  var [search,setSearch]=useState('');
  const[pass,setPass]=useState([])

  let [optionFlag,setOptionFlag]=useState(0)
  let [searchFlag,setSearchFlag]=useState(0)
  const [isLoading, setLoading] = useState(true);
  const datas=useSelector((state)=>state.userMail)
 // const nam=useSelector((state)=>state.userData)
  const [project,setProject]=useState("")

console.log(project)
  useEffect(() => {
  //  try{
 const decodedToken = jwtDecode(token);
 console.log(decodedToken.project)
 setProject(decodedToken.project)
   //}
  //  catch(error)
  //  {
  //    navigate("/v1/jim_user_login")
  //  }
 },[])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/getOptions/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
        console.log(data.data)
        setOptions(data.data)
      })
      .catch(err=>console.log(err))
  }, [project])
   const text=(e)=>
   {
    if(e.target.value==="")
    {
      setSearchFlag(0)
    }
    else 
    {
      setSearchFlag(1)
    }
    setSearch(e.target.value)
    fetch(`${process.env.REACT_APP_API_URL}/filterByUserSearch/${project}/${e.target.value}/${datas}`,{
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>{
      setPass(data.data)
      setLoading(false)

      if(data.statusCode===400)
      {
        setLoading(false)
        showAlert({
          title:  'Logged Out',
          message: 'You are logged out due to session timeout.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      }); 
        navigate('/v1/jim_user_login')
      } 
    })
      .catch(err=>{
        setLoading(false)
        console.log(err)
      })
    setOption("")
    
    setReqOption("")
    setOptionFlag(0)
    t(0)

    
   }
  const dropdown=(e)=>
  {
    setReqOption("")
    //setSearch('')
    if(optionFlag===0)
    {
      setOptionFlag(1)
    t(0)
    }
    else{
      setOptionFlag(0) 
    }
   
   setOption(e)
   fetch(`${process.env.REACT_APP_API_URL}/filterByUserOption/${project}/${e}/${datas}`,{
    method: 'GET',
    withCredentials: 'true',
    headers:
    {
      'Authorization':`Bearer ${token}`,
    },
  })
    .then(res=>res.json())
    .then(data=>{
      setPass(data.data)
      setLoading(false)
      if(data.statusCode===400)
      {
       setLoading(false)
      //alert("User Logged Out")
        navigate('/v1/jim_user_login')
      } 
    })
      .catch(err=>{
        setLoading(false)
        console.log(err)
      })
  }
  let [r,t]=useState(0)
  const request=(f)=>
  {
    setOption("")
    //setSearch("")
    if(r===0)
    {
    t(1)
    setOptionFlag(0)
    }
    else{
      t(0)
    }
   setReqOption(f)
   setReqOption(f)
    fetch(`${process.env.REACT_APP_API_URL}/filterByUserStatus/${project}/${f}/${datas}`,{
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
      setPass(data.data)
      setLoading(false)
      if(data.statusCode===400)
      {
       
      //alert("User Logged Out")
        navigate('/v1/jim_admin_user_login')
      } 
    })
      .catch(err=>console.log(err))
   console.log(req)
  }

  const searchClick=(e)=>
  {
    {
      e.preventDefault()
     
      if(search==="")
      {
        setSearchFlag(0)
      }
      else
      {
        setSearchFlag(1)
      
      fetch(`${process.env.REACT_APP_API_URL}/filterByUserSearch/${project}/${search}/${datas}`,{
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })
      
        
      
      .then(res=>res.json())
      .then(data=>{
        setPass(data.data)
        if(data.statusCode===400)
        {
         
       // alert("User Logged Out")
          navigate('/v1/jim_admin_user_login')
        } 
      })
        .catch(err=>console.log(err))
    
      console.log(search);
      }
      
    }
  }
   console.log(req)
  console.log(opti)
  if(localStorage.getItem("tokenauth")==="false")
  //if(cookie.get("tokenauth")==="false")
  {
   window.location.href="/v1/jim_user_login"
 }
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/user/${project}/${datas}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
        console.log(data.data)
        dispatch(userName(data.data[0].name))
      })
      .catch(err=>console.log(err))
  }, [project])
  // const dispatch=useDispatch()
  // dispatch(userName(nam))
  {/*useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/commented/${opti}`)
    .then(res=>res.json())
    .then(data=>
      setdata(data))
    .catch(err=>console.log(err))
  }, [])*/}
  {/*const res={
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(obj)
};
fetch('${process.env.REACT_APP_API_URL}/comment', res)
      .then(()=>
      {
          console.log("added")
      })*/}
  
  
const requestRef = useRef(null);

useEffect(() => {
  // Add a click event listener to the document to detect clicks outside the dropdown
  function handleClickOutside(event) {
    if (requestRef.current && !requestRef.current.contains(event.target)) {

      setOptionFlag(0)
    }
  }
 
  // Attach the event listener when the component mounts
  document.addEventListener('click', handleClickOutside);

  // Clean up the event listener when the component unmounts
  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, []);
const dropdownRef = useRef(null);

useEffect(() => {
  // Add a click event listener to the document to detect clicks outside the dropdown
  function handleClickOutside(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      t(0);
    }
  }

  // Attach the event listener when the component mounts
  document.addEventListener('click', handleClickOutside);

  // Clean up the event listener when the component unmounts
  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, []);
useEffect(() => {


 if(localStorage.getItem("tokenauth")==="false")
 //if(cookie.get("tokenauth")==="false")
 {
  window.location.href="/v1/jim_user_login"
 }
}, [])
  return (
    
    <>
    <Headerhotel requests={0} backButton={0} raiseTicket={1} registrationRequest={0} adminRequests={0} showAlert={showAlert} setAlertProps={setAlertProps}/>
      <div className="sc-hORach dWMRNy">
      <div className="sc-eTuwsz iBEElS">
      </div>
      <main className="sc-hXRMBi bJStMD sc-ibxdXY fgfUVu">
      <div className="sc-bwCtUz ijKeUx">
      <section className="sc-bJTOcE bFtVSx">
      <header>
      <span className="sc-rBLzX fayCzS">
      {/*<a className="sc-CtfFt ewysoW sc-Rmtcm DgkTO" href="/servicedesk/customer/portals" target="_self">GL Services Portal Home</a>
      */}</span>
<div className="sc-TFwJa dUxdnV"><div><h1 className="sc-bHwgHz bGtLRv">All Tickets
      &nbsp;
      <a href="/v1/jim/user_homepage" style={{color:"#172b4d"}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-refresh">
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
  <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
  </svg></a></h1>      
      </div>
      </div>
      </header>
      <div className="sc-PLyBE iGtjzS">
      <div>
      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
      <div>
      <div>
      <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
      {/*<div>
      <button aria-controls="uid46" aria-expanded="false" aria-haspopup="true" actionsubjectid="requestListMenu" type="button" className="css-q68uj">
      <span className="css-j8fq0c">
      <span className="css-noix33">
      <span role="img" aria-label="more" className="css-snhnyn" >
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <g fill="currentColor" fill-rule="evenodd"><circle cx="5" cy="12" r="2">
      </circle>
      <circle cx="12" cy="12" r="2">
      </circle>
      <circle cx="19" cy="12" r="2"></circle>
      </g>
      </svg>
      </span>
      </span>
      </span>
      </button>
    </div>*/}
      </div>
      </div>
      <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
      <div style={{height: "100%", width: "100%"}}></div></div>
      <div className="styledContentContainer-p0j3f7-0 caSnlw"><div style={{position: "absolute", zIndex: "400", top: "0px", left: "0px",transform: "translate3d(-95px, 40px, 0px)"}}></div>
      </div></div></div></div></div>
      <div className="sc-jqIZGH bokdCL">
      </div>
      <div className="sc-imDfJI jpQXKX" data-test-id="request-list.wrapper">
      <div className="sc-bSbAYC bxpmxB"><div id="request-search-filter">
      <div data-ds--text-field--container="true" className="css-1o07z2d">
      <input aria-label="free text filter" placeholder="Type to search..." data-test-id="request-list.filters.textFilter" data-ds--text-field--input="true" className="css-mfjdp3"  onChange={text} value={search} style={{marginBottom:"4px"}}/>
      <button type="button" className="css-q68uj"  style={{backgroundColor: "#BDBDBD"}} onClick={searchClick}><span className="css-j8fq0c">
      <span className="css-noix33">
      <span role="img" aria-label="Search my requests" className="css-snhnyn" >
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path d="M14.823 15.883a5.5 5.5 0 111.06-1.06l2.647 2.647c.293.293.53.59 0 1.06-.53.47-.767.293-1.06 0l-2.647-2.647zM11.5 15.5a4 4 0 100-8 4 4 0 000 8z" fill="currentColor">
      </path></svg></span></span></span></button>
      </div>
      </div>
      <div className="sc-bOCYYb gxwVbe"  >
      <div className="sc-iHhHRJ jnXgrr" ref={dropdownRef} onClick={()=>request(req)}>
      <button className="css-370xbg" data-testid="paginatedStatusFilter" type="button" tabindex="0">
      <span className="css-178ag6o">
      <div className="sc-kqlzXE iitdmc">
      <div className="sc-cFlXAS hPkIUt"><strong>Status:</strong>
      <div className="sc-hcnlBt iHCcjr">{req ? req : "Select"}</div></div></div>
      <div className="sc-OxbzP kJxPgr">
      <span aria-hidden="true" className="css-snhnyn" >
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z" fill="currentColor" fill-rule="evenodd">
      </path></svg></span></div></span></button></div>
      {r===1?
      <div className="scroll-menu" style={{width: "131px",marginTop:"inherit"}} >
        <div className="menu-item"  style={{borderBottom:"solid 1px lightgrey"}} value="opened" onChange={(e)=>setReqOption(e.target.value)} onClick={()=>{request("opened");
        setSearch("")
        setLoading(true)
        }}>
        <span className="css-qezc8k" style={{maxWidth: "100%"}}>
          <span className="css-18kwa17"   style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}} /*onChange={(e)=>setReqOption(e.target.value)} onClick={()=>request("opened")}*/>
          Opened</span>
      </span>
          </div>
          <div className="menu-item"  style={{borderBottom:"solid 1px lightgrey"}} value="In Progress"  onChange={(e)=>setReqOption(e.target.value)} onClick={()=>{request("In Progress");
          setSearch("")
          setLoading(true)
          }}>
        <span className="css-qezc8k" style={{maxWidth: "100%"}}>
          <span className="css-18kwa17" style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}} >In Progress</span>
          </span>
        </div>
        <div className="menu-item"  style={{borderBottom:"solid 1px lightgrey"}} value="Resolved" onChange={(e)=>setReqOption(e.target.value)} onClick={()=>{request("Resolved");
        setSearch("")
        setLoading(true)
        }}>
        <span className="css-qezc8k" style={{maxWidth: "100%"}}>
          <span className="css-18kwa17" style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}} >Resolved</span>
          </span>
        </div>
        <div className="menu-item"  style={{borderBottom:"solid 1px lightgrey"}} value="Rejected" onChange={(e)=>setReqOption(e.target.value)} onClick={()=>{request("Rejected");
        setSearch("")
        setLoading(true)
        }}>
        <span className="css-qezc8k" style={{maxWidth: "100%"}}>
          <span className="css-18kwa17" style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}}  >Rejected</span>
          </span>
        </div>
        <div className="menu-item" value="Close" onChange={(e)=>setReqOption(e.target.value)} onClick={()=>{request("Closed");
        setSearch("")
        setLoading(true)
        }}>
        <span className="css-qezc8k" style={{maxWidth: "100%"}}>
          <span className="css-18kwa17" style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}} >Closed</span>
          </span>
          </div>
        </div>:""}
      </div>
      
      <div className=" css-5a7vsu-container" id="reporterfilter">
      {/*<div className=" css-6m1tpa-control">
      <div className=" css-11tnip9">
      <div className=" css-sf6j83-singleValue" style={{opacity: "1", transition: "opacity 1ms ease 0s"}}>Where I am a participant</div>
      <input id="react-select-reporterfilter-input" readonly="" tabindex="0" aria-autocomplete="list" aria-label="requester filter" className="css-62g3xt-dummyInput" value=""/>
      </div>
      <div className=" css-snhw4r"><div aria-hidden="true" className=" css-1veywqn-indicatorContainer">
      <span role="img" aria-label="open" className="css-snhnyn" >
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z" fill="currentColor" fill-rule="evenodd"></path></svg>
</span></div></div></div>*/}</div>
      <div className="sc-bOCYYb gxwVbe"><div className="sc-iHhHRJ jnXgrr" ref={requestRef} onClick={()=>dropdown(opti)}>
      <button className="css-1f7f0z2" data-testid="paginatedRequestTypesFilter" type="button" tabindex="0">
      <span className="css-178ag6o"><div className="sc-kqlzXE iitdmc"><strong>Request type:</strong> {opti ? opti : "Select"}</div>
      <div className="sc-OxbzP kJxPgr">
      <span aria-hidden="true" className="css-snhnyn" >
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z" fill="currentColor" fill-rule="evenodd"></path></svg>
</span></div></span></button></div>{optionFlag===1?<div className="scroll-menu"  style={{width:"181px",marginTop:"inherit"}}>
    {/* <div className="menu-item"  value="Onboarding" onChange={(e)=>setOption(e.target.value)} onClick={()=>{dropdown("Onboarding");setSearch("")}}>Onboarding</div>
    <div className="menu-item"  value="Playback" onChange={(e)=>setOption(e.target.value)} onClick={()=>{dropdown("Playback");setSearch("")}}>Playback</div>
    <div className="menu-item"  value="Report" onChange={(e)=>setOption(e.target.value)} onClick={()=>{dropdown("Report");setSearch("")}}>Report</div>
    <div className="menu-item"  value="Billing" onChange={(e)=>setOption(e.target.value)} onClick={()=>{dropdown("Billing");setSearch("")}}>Billing</div>
    <div className="menu-item"  value="Others" onChange={(e)=>setOption(e.target.value)} onClick={()=>{dropdown("Others");setSearch("")}}>Others</div> */}
      {options.map(ele=>{return <div className="menu-item"   value={ele.reasons} onChange={(e)=>setOption(e.target.value)} onClick={()=>{dropdown(ele.reasons);
    setSearch("")
    }}>{ele.reasons}</div>
    })}
    <div className="menu-item"  value="Not Specified" onChange={(e)=>setOption(e.target.value)} onClick={()=>{dropdown("Not Specified");
      setSearch("")
      }}>Not Specified</div>
    </div>
    :""}</div></div></div>
      {/*{t===1?<Search searched={search}/>:<div className="sc-imDfJI jpQXKX"><div className="sc-hAnkBK bETBem">
      <img src="" alt="No requests were found."/>
      <div className="sc-DNdyV RIerR">
      <h3>We couldn’t find any requests</h3>
      <p>
    <span>To find a specific request, try searching using a different filter criteria or <a className="sc-Rmtcm DgkTO" href="/servicedesk/customer/user/requests" target="_self">view all requests</a>.
    </span>
    </p>
    </div>
    </div>
    </div>}
      {opti===''?
      <div className="sc-imDfJI jpQXKX"><div className="sc-hAnkBK bETBem">
      <img src="" alt="No requests were found."/>
      <div className="sc-DNdyV RIerR">
      <h3>We couldn’t find any requests</h3>
      <p>
    <span>To find a specific request, try searching using a different filter criteria or <a className="sc-Rmtcm DgkTO" href="/servicedesk/customer/user/requests" target="_self">view all requests</a>.
    </span>
    </p>
    </div>
    </div>
</div>:<Table Option={opti}/>}*/}
  
    {/*{(() => {
       if(opti!=='') 
       {
        return (<Utable  Option={opti} Tab={Clic}/>)
       }

       else if(s===1)
       {
         return (<Searchuser Searched={search}/>
         )
       }
       else{
         return (<div className="sc-imDfJI jpQXKX"><div className="sc-hAnkBK bETBem">
         <img src={img} alt="No requests were found."/>
         <div className="sc-DNdyV RIerR"><h3>We couldn’t find any requests</h3>
         <p>
       <span>To find a specific request, try searching using a different filter criteria or <a className="sc-Rmtcm DgkTO" href="/servicedesk/customer/user/requests" target="_self"> view all requests</a>.
       </span>
       </p>
       </div>
       </div>
   </div>)
       }
      })()}*/}
       {(() => {
      var f
       if(opti!=='') 
       {
        //f=`${process.env.REACT_APP_API_URL}/commented/${opti}`
        /*return (<Table   Option={opti} Tab={Clic}/>)*/
        return (isLoading ? <div style={{display:"flex",height:"458px",width:"100%",justifyContent:"center",alignItems:"center"}}>  <div class="spinner-border" style={{width: "3rem", height: "3rem", role:"status"}}>
        <span class="sr-only">Loading...</span>
      </div> </div> :<Alluserhotel addr={pass}/>)
       }
       else if(req!=='')
       {
        
        console.log("lo")
         /*return (<Status  Status={req}/>*/
         //f=`${process.env.REACT_APP_API_URL}/status/${req}`
         return (isLoading ? <div style={{display:"flex",height:"458px",width:"100%",justifyContent:"center",alignItems:"center"}}>  <div class="spinner-border" style={{width: "3rem", height: "3rem", role:"status"}}>
         <span class="sr-only">Loading...</span>
       </div> </div> :<Alluserhotel addr={pass}/>
         )
       }
       else if(searchFlag===1)
       {
        console.log("logic")
         /*return (<Search  Searched={search}/>*/
         //f=`${process.env.REACT_APP_API_URL}/search/${search}`
         return (isLoading ? <div style={{display:"flex",height:"458px",width:"100%",justifyContent:"center",alignItems:"center"}}>  <div class="spinner-border" style={{width: "3rem", height: "3rem", role:"status"}}>
         <span class="sr-only">Loading...</span>
       </div> </div> : <Alluserhotel addr={pass}/>
         )
       }
       else{
        return (<Utablehotel  addr={`${process.env.REACT_APP_API_URL}/userTickets/${project}`}/>)
        /*return (<div className="sc-imDfJI jpQXKX"><div className="sc-hAnkBK bETBem">
         <img src={img} alt="No requests were found."/>
         <div className="sc-DNdyV RIerR"><h3>We couldn’t find any requests</h3>
         <p>
       <span>To find a specific request, try searching using a different filter criteria or <a className="sc-Rmtcm DgkTO" href="/servicedesk/customer/user/requests" target="_self"> view all requests</a>.
       </span>
       </p>
       </div>
       </div>
   </div>)*/
   
       }
      
      })()}
    
    <div className="sc-jzgbtB gUQSWG"></div>
    <div className="sc-jqIZGH bokdCL"></div>
    
    </section></div>
   </main>
   </div>
   <Footer/>
    </>
   
  )
}

export default Userhotel
