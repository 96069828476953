import React, { useState } from 'react'
import '../css/login.css';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useRef } from 'react';
import imge from './image/login1.jpg';
import HeaderAdmin from './headersuperadmin';
import CryptoJs from 'crypto-js';
import Footer from './Footer';

export let nam;
function AdminLogin({userAuth,tokenAuth}) {
  var [name,setName]=useState('')
  var [message,setMessage]=useState('')
  var [attempt,setAttempt]=useState('')
  var [active,setActive]=useState('')
  var [pass,setPass]=useState('')
  const navigate=useNavigate()
 const [isLoading,setIsLoading] = useState(true)
 let [countDown,setCountDown]=useState("")
 const intervalFunction=(blockedAt)=>
   {
 const countDownDate =  new Date(parseInt(blockedAt, 10)).getTime()+15 * 60 * 1000;
 let interval1 = setInterval(()=>
   {
     const now = new Date().getTime();
     console.log(now)
     const distance = countDownDate - now;
     const  minutes= Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
     const seconds = Math.floor((distance % (1000 * 60)) / 1000);
     console.log(minutes + "m " + seconds + "s ")
     setCountDown(minutes + "m " + seconds + "s ")
     if (distance< 0) {
       clearInterval(interval1);
       setCountDown("")
       setAttempt("")
       setActive("Account is Active please login")
       }
   },1000);  
 }


 const [data,setdata]=useState(4)
 console.log(data)
 console.log(name)
 console.log(pass)

 const togglePassword = useRef("")
  const password = useRef("")
  const [e,s]=useState(0)

  useEffect(()=>{
    localStorage.removeItem("superToken")
    localStorage.removeItem("mail")
    localStorage.removeItem("tokenauth")
     },[])
  const Toggle=()=>
  {
    // toggle the type attribute
    const type = password.current.type === 'password' ? 'text' : 'password';
    password.current.type=type;
    // toggle the eye slash icon
    if(e===0)
    {
      s(1)
    togglePassword.current.className='bi-eye';
    }
    else{
      s(0)
      togglePassword.current.className='bi bi-eye-slash';
    }
};

const clicks= async () => {
  setIsLoading(false)
  const encryptedPassword=CryptoJs.AES.encrypt(pass,process.env.REACT_APP_API_KEY)
  console.log(pass)
  console.log(name) 
  if(pass===""||name==="")
  {
    setIsLoading(true)
    console.log("yes")
    setMessage("Enter Email & Password")
    setAttempt("")
    setActive("")
  }
  else{
    const response=await fetch(`${process.env.REACT_APP_API_URL}/superlogin`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body:JSON.stringify({"username":name,"password":encryptedPassword.toString()})
    }
    );
    console.log(response,"response")
    if(response.status===401)
    {
      const data = await response.json();
      setIsLoading(true)
      setMessage(`! Incorrect Email Or Password`)
      setAttempt(`${data.remainingLogAttempt} Attempts Left`)
      setActive("")
    }
  else if(response.status===404)
    {
      setIsLoading(true)
      setMessage('Invalid Email Id or Password')
      setAttempt("")
      setActive("")
    }
    else if(response.status===400)
      {
        const data = await response.json();
        setIsLoading(true)
        setAttempt("")
        setMessage(`Too many wrong attempts, ${data.message} for 15 Minutes`)
        intervalFunction(data.timeOut)
      }
  else if(response.status===200)
  {
    setIsLoading(true)
    setAttempt("")
    setActive("")
    setCountDown("")
    setMessage("")
    const data = await response.json();
    setdata(data);
    console.log(data)
    sessionStorage.setItem("mail", name);
    localStorage.setItem("key", data.name);
    localStorage.setItem("superToken",data.token)
    //cookie.set("token",data.token)
    try{
    const token = localStorage.getItem("superToken");
    //const token=cookie.get("token")
    if (token) {
      // Make an asynchronous request to validate the token
      
      const response=await fetch(`${process.env.REACT_APP_API_URL}/protected-adminroute`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })

      if (response.status === 200) {
        // Token is valid
        userAuth(true);
        //localStorage.setItem("tokenauth","true")
        //cookie.set("tokenauth","true")
         localStorage.setItem("tokenauth","true")
      } else {
        // Token is not valid
        userAuth(false);
      }
    } else {
      // No token found
      userAuth(false);
    }
  } catch (error) {
    // Handle errors, e.g., network issues
    console.error("Token validation failed:", error);
  }
  try {
  
   navigate("/v1/jim/super_homepage");  
    } catch (error) {
      console.log(error)
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  }
  else if(response.status===404)
  {
    setIsLoading(true)
   setMessage("! Incorrect Email Or Password")
  }
}
  
};
/*useEffect(() => {
  clicks();
  

}, [])*/



 /* const click=(y)=>
  {
    y.preventDefault()
    const type="admin"
    const obj={name,pass,type}
    console.log(obj)
    const res={
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(obj)
  };

  }*/
  const handlKeyPress=(event)=>
  {
    if(event.key==='Enter')
    {
      clicks(event)
    }
  }

return (
 <>
  <div className="body" style={{height: "100vh",backgroundImage:`url(${imge})`}}>
      <HeaderAdmin disp="none"/>
     <h1 style={{color: "#ffffff",textAlign: "center",fontFamily: "auto"}}>Jio Service Support</h1><br/>
     
  <div className="login"style={{background:"#ffffff63",paddingTop:"50px",paddingBottom:"30px"}}>
  <div className='SignIn-header'>
          <h4>Log In</h4>
        </div>
      <form>
      
      <div align="center" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <input type="radio"  checked id="Admin"  
              name="fav_language" value="admin" style={{width:"10px"}}/>
        <label for="admin" style={{color:"black"}}>Super Admin</label>
      </div>
      <label><b>Email
      </b>
      </label>
      <input type="email" name="username"  id="Uname" placeholder="MailId" onChange={(e)=>setName(e.target.value)}/>
      <br/><br/>
      <label><b>Password
      </b>
      </label>
      
      <input type="Password" name="password" onKeyDown={handlKeyPress} ref={password} id="Pass" placeholder="Password" onChange={(e)=>setPass(e.target.value)}/>
      <i className="bi-eye-slash"  ref={togglePassword} onClick={Toggle} id="togglePassword" style={{marginLeft: "-30px", cursor: "pointer"}}></i>
      <br/>
      <br/>
      </form>
      <strong>
      <button type="submit" name="log" id="log" value="Log In" style={{background:"#3399FF",border:"none",borderRadius:"2px",height:"42px"}} onClick={clicks}>
        {
          !isLoading ?  <div class="spinner-border spinner-border-sm">
          <span class="sr-only">Loading...</span>
          </div> :   <span>
          Log In
        </span>
        }
      
      </button>
      </strong>
      <br/> {active===""?<><p style={{color:"#ac0303"}}>{message}</p> {countDown?`TimeLeft: ${countDown}`:""}</>:<p style={{color:"darkgreen"}}>{active}</p>}
      <p>{attempt}</p>
      <br/>
      {/*<a style={{float: "left"}} href="/registers">Register for new User</a>*/}
      {/*<a className="a" style={{color:"black"}} href="/forget">Forgot Password</a>
      <br/>
<br/>*/}
    
</div>
  </div>
   <Footer margin={"auto"}/>
   </>
  )
}

export default AdminLogin
