import React, { useEffect } from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { useDispatch } from 'react-redux';

import cookie from 'js-cookie';
export default function OtpModal({ 
  showAlert,
  setAlertProps,   
  showModal,
  token,
  //updateList,
  email,
  selectedProject,
  setotp,
  userAuth,
  mail

}) {
    console.log(showModal,
        //updateList,
        email,
        selectedProject,
        setotp)
    console.log(setotp)
    //const veriOtp = localStorage.getItem("deleteOtp")
    const navigate=useNavigate()
    const [show, setShow] = useState(showModal)
    // const adminEmail=sessionStorage.getItem("mail")
    const userEmail=email
    console.log(userEmail)
    const [code, setCode] = useState("");
    const handleChange = (code) => {console.log(code);setCode(code)};
    const [userOtp,setUserOtp] = useState("")
    const [countDown,setCountDown]=useState("")
    const [buttonFlag,setButtonFlag]=useState(0)
    const dispatch=useDispatch()
    console.log("here is selected Email Id",email)
    console.log("here is selected Email project",selectedProject)
    
   


   useEffect(()=>{
    setShow(showModal)
    setButtonFlag(0)
    //setUserOtp(setotp)

   },[showModal,setotp])
    let [intervalArray,setIntervalArray]=useState([])
    const intervalFunction=()=>
      {
    const countDownDate = new Date().getTime()+2 * 60 * 1000;
    let interval = setInterval(()=>
      {
        const now = new Date().getTime();
        const distance = countDownDate - now;
        const  minutes= Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        console.log(minutes + "m " + seconds + "s ")
        setCountDown(minutes + "m " + seconds + "s ")
        if (distance< 0) {
          clearInterval(interval);
          setIntervalArray([])
          setCountDown("")
          setButtonFlag(1)
          }
      },1000); 
      intervalArray.push(interval) 
    }
    useEffect(() => {
     intervalFunction()
  }, [setotp]);
  console.log(intervalArray)


  const sendOtp=()=>{
    const obj={userEmail,project:selectedProject}
  console.log(obj)
  const res={
    method: 'POST',
    headers: { 'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
     },
    body: JSON.stringify(obj)
};
fetch(`${process.env.REACT_APP_API_URL}/verifyUserOtp`, res).then(res=>res.json())
      .then((data)=>
      {
        console.log(data)
          console.log("Otp sent successfully")
      })
    }

   useEffect(()=>{
       sendOtp()
   },[setotp])

   useEffect(()=>{
    
},[setotp])


    const handleClose = () =>{
        console.log(intervalArray)
        clearInterval(intervalArray[0])
        setIntervalArray([]) 
        setShow(false);
        setCode("")
        
    }

    const handleResendOtp=()=>
      {
        sendOtp()
        setButtonFlag(0)
        intervalFunction()
      }

console.log("+++++++++++++++++++++++++++++",userOtp)
const otpResult = (value) => {
  value = CryptoJS.enc.Utf8.parse(value.toString());
  var key = CryptoJS.lib.WordArray.random(256 / 8);

  var iv = CryptoJS.lib.WordArray.random(128 / 8);

  var encrypted = CryptoJS.AES.encrypt(value, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  var final = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

  var keyBase64 = key.toString(CryptoJS.enc.Base64);
  var ivBase64 = iv.toString(CryptoJS.enc.Base64);
  var result = keyBase64 + ':' + ivBase64 + ':' + final;
  return result;
}
    const handleVerifyOtp = async () =>{
      if(code.length===0)
        {
          showAlert({
            title: 'Enter OTP',
            message: 'Please enter valid OTP.',
            okText: 'OK',
            callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
        });
        }
        else
        {
         const enteredOtp=otpResult(code)
        console.log("here is code",code)
        console.log("here is user otp>>>>>>>>>>",userOtp)
        fetch(`${process.env.REACT_APP_API_URL}/getVerifyUserOtp`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
           },
          body: JSON.stringify({project:selectedProject,email:userEmail,otp:enteredOtp})
      })
        .then(res=>res.json())
        .then(async data=>
          {
            console.log(data.data)
            if(data.statusCode===200)
              {
        
        try{
            //const token = localStorage.getItem("token");
            //const token=cookie.get("token")
          if (data.data.token) {
            // Make an asynchronous request to validate the token
            console.log(data.data.token)
           
            const responses=await fetch(`${process.env.REACT_APP_API_URL}/protected-route`,
            {
              withCredentials: 'true',
              method: 'GET',
              headers:
              {
                'Authorization':`Bearer ${data.data.token}`,
              },
            })
            console.log(responses)
      
            if (responses.status === 200) {
              // Token is valid
              userAuth(true);
              dispatch(mail(data.data.name))
              localStorage.setItem("tokenauth","true")
              localStorage.setItem("token",data.data.token)
              //localStorage.setItem("tokenauth","true")
              //cookie.set("project",selectedProject)
              
            } else {
              // Token is not valid
              userAuth(false);
              //cookie.set("tokenauth","false")
            }
          } else {
            // No token found
            userAuth(false);
            //cookie.set("tokenauth","false")
          }
        } catch (error) {
          // Handle errors, e.g., network issues
          console.error("Token validation failed:", error);
        }
       navigate('/v1/jim/user_homepage')
          setShow(false);
        //updateList("deleted")
        //window.location.reload(false)
        // alert("project Deleted Successfully")
              
    
  }
  else{
    showAlert({
      title: 'Invalid OTP',
      message: data.message,
      okText: 'OK',
      callback: () => {
        console.log('Alert closed');
        setAlertProps(null)
    }
  });
  }
    }
  )
  .catch(err=>console.log(err))
        }
    }
  
  return (
    <>
    {/* <Button variant="primary" onClick={handleShow}>
      Launch demo modal
    </Button> */}

    <Modal size="sm" show={show} centered onHide={handleClose}
    backdrop="static"
    keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title size="sm" style={{fontSize:"16px"}}>Enter Otp </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <OtpInput
        value={code}
        onChange={handleChange}
        numInputs={6}
        separator={<span style={{ width: "8px" }}></span>}
        isInputNum={true}
        shouldAutoFocus={true}
        inputStyle={{
            backgroundColor:'#ecf2f7',

          border: "1px solid ",
          marginLeft:"4px",
          marginRight:"4px",
          borderRadius: "8px",
          width: "32px",
          height: "32px",

          fontSize: "12px",
          color: "#000",
          fontWeight: "400",
          caretColor: "blue"
        }}
        focusStyle={{
          border: "1px solid #CFD3DB",
          outline: "none"
        }}
        renderSeparator={<span style={{marginBottom:"10px"}}> - </span>}
        renderInput={(props) => <input {...props} />}
      />
    
    </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={handleClose}>
          Resend OTP
        </Button> */}
        <p>{countDown}</p>
        {buttonFlag===0?
        ""
        : <Button variant="primary"  size="sm"  onClick={handleResendOtp}>
        Resend OTP
      </Button>}
        <Button variant="primary"  size="sm"  onClick={handleVerifyOtp}>
          Verify OTP
        </Button>  
      </Modal.Footer>
    </Modal>
  </>
  )
}
