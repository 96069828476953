import React, { useCallback, useState } from 'react'
import '../css/login.css';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useRef } from 'react';
import Headerhotel from './headerhotel';
import { useDispatch } from 'react-redux';
import { mail } from '../feature/slice';
import CryptoJs from 'crypto-js'
import OtpModal from "./common/OtpModalLogin";
import Alert from '@mui/material/Alert';
import Footer from './Footer';




export let nam;
function Loginhotel({userAuth,location,showAlert,setAlertProps}) {
  //const token=localStorage.getItem("token")
  var [name,setName]=useState('')
  const [token,setToken]=useState('')
  var [message,setMessage]=useState('')
  const [isLoading,setIsLoading] = useState(true)
  var [pass,setPass]=useState('')
  //const [seconds, setSeconds] = useState(60);
  const [filteredProjectList, setFilteredProjectList] = useState([]);
  const [selectedProject,setSelectedProject] = useState('')
  const [show,setShow] = useState(false)
  const [emailId,setEmailId] = useState("")
  const [otp,setOtp] = useState('')
  const [close,setClose]=useState(0)
  const [attemptLeft,setAttemptLeft]=useState(4)
  const [icon,setIcon] = useState("bi bi-eye-slash")
  const [type,setType] = useState("password")
  const [height,setHeight]=useState('100vh')
  let [countDown,setCountDown]=useState("")
  let [intervalArray,setIntervalArray]=useState([])
 const intervalFunction=(blockedAt)=>
   {
 const countDownDate =  new Date(parseInt(blockedAt, 10)).getTime()+15 * 60 * 1000;
 let interval1 = setInterval(()=>
   {
     const now = new Date().getTime();
     console.log(now)
     const distance = countDownDate - now;
     const  minutes= Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
     const seconds = Math.floor((distance % (1000 * 60)) / 1000);
     console.log(minutes + "m " + seconds + "s ")
     setCountDown(minutes + "m " + seconds + "s ")
     if (distance< 0) {
       clearInterval(interval1);
       setIntervalArray([])
       setCountDown("")
       setAttemptLeft("")
       setIsBlocked(false)
       setClose(0)
       setActive("Account is Active please login")
       setHeight('120vh')
       }
   },1000);
   intervalArray.push(interval1)
   setIntervalArray(intervalArray)
 }


  const navigate=useNavigate()
  const dispatch=useDispatch()
  const [isBlocked,setIsBlocked] = useState("")
  const [active,setActive] = useState('') 
 const [data,setdata]=useState(4)
 console.log(data)
 console.log(name)
 console.log(pass)

 useEffect(()=>{
localStorage.removeItem("token")
localStorage.removeItem("mail")
localStorage.removeItem("tokenauth")
//cookie.remove("tokenauth")

 },[])
  const password = useRef("")
  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_API_URL}/finduserproject`,
  //   {
  //     method: 'GET',
  //     withCredentials: 'true',
  //     headers:
  //     {
  //       'Authorization':`Bearer ${token}`,
  //     },
  //   }
  //   )
 
//   const Toggle=()=>
//   {
//     // toggle the type attribute
//     const type = password.current.type === 'password' ? 'text' : 'password';
//     password.current.type=type;
//     // toggle the eye slash icon
//     if(e===0)
//     {
//       s(1)
//     togglePassword.current.className='bi-eye';
//     }
//     else{
//       s(0)
//       togglePassword.current.className='bi bi-eye-slash';
//     }
// };

const handleToggle = () =>{  
  if(icon == "bi bi-eye-slash") {
    setIcon("bi-eye")
    setType("text")
  }else{
    setIcon("bi bi-eye-slash")
    setType("password")
  } 
}




// const handleFilter = () => {
//   const result = projectList.map((el) => {
//     return el.filter((el) => {
//       return el.email == name ? temp.push(el.project) : null;
//     });
//   });
//   setFilteredProjectList(temp);
//   console.log("hello world+++++++++++++++++>",temp[0])
//   setSelectedProject(temp[0])
// };






const handleFilter = async() => {
  const result = fetch(`${process.env.REACT_APP_API_URL}/findProjectsById/${name}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      }
      )

      .then((res) => res.json())
      .then((data) => {
        console.log("data of admins", data[0]);
        setFilteredProjectList(data.data)
        if(data.data.length==1){
        setSelectedProject(data.data[0])
        }
        else if(data.data.length>1)
          {
            setHeight('120vh')
          }
        return data.data
      })
      .catch((err) => console.log(err));
      return result
}

useEffect(()=>{
// //   if(filteredProjectList.length == 1) {
// //   clicks()
// // }
// setSelectedProject(filteredProjectList[0])
},[])


const clicks= async (e) => {
  // alert("hello world")
  // setIsLoading(false)
  setClose(0)
  setIsBlocked("")

  //e.preventDefault()
  const result = await handleFilter()
  console.log("here is exact result",result)
  console.log("here is selectedProject *******",selectedProject)
  // if(selectedProject === "" || selectedProject === undefined){
    
  //   setIsLoading(true)
  //   setClose(0)
  // }
  // else{
  if(pass===""||name==="")
  {
    setIsLoading(true)
    setMessage("Please Enter Email & Password")
    setClose(1)
        setIsBlocked("")
  }
  else if(filteredProjectList.length>1 && selectedProject==="")
    {
      setMessage("Please Select Project")
      setClose(1)
    }
  else{
    // if(!result[0])
    //   {
    //     setIsLoading(true)
    // setMessage("!Incorrect Email Or Password")
    // setClose(1)
    //   setIsBlocked("")
    //   }
      //else{
        console.log("here is selected project1234",selectedProject)
        if(result.length > 1){
          //!selectedProject ? alert("Please Select Project") : console.log("na")
        }
     const encryptedPassword=CryptoJs.AES.encrypt(pass,process.env.REACT_APP_API_KEY)
     //const encryptedPassword=bcrypt.hashSync(pass, 10);
    const response=await fetch(`${process.env.REACT_APP_API_URL}/login`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body:JSON.stringify({name:name,pass:encryptedPassword.toString(),user:"user",projectName: result.length > 1 ? selectedProject : result[0]})
    }
    );
    console.log(response,"responseshweta")
    if(response.status===401)
    {
      const data = await response.json();
      setIsLoading(true)
      setMessage("! Incorrect Email Or Password")
      setHeight('120vh')
      setClose(1)
      setAttemptLeft(data.remainingLogAttempt)
      setIsBlocked("")
    }
    else if(response.status===404){
      //alert("Invalid user id or password")
      const data=await response.json()
      console.log(data.error)
      setMessage(data.error)
      setHeight('120vh')
      setClose(1)
    }
    else if(response.status===400){
      intervalArray.map(e=>clearInterval(e))
      setIntervalArray([])
      const data=await response.json()
      setMessage("")
      setIsBlocked(true)
      setClose(0)
      setActive("Account is Blocked for 15 Minutes")
      setHeight('120vh')
      setIsLoading(true)
      intervalFunction(data.timeOut)
    }
  else if(response.status===200)
  {
    const data = await response.json();
    const token=data.token
    setToken(token)
    const responseOfTokenAuth = await fetch(
      `${process.env.REACT_APP_API_URL}/check-firstToken`,
      {
        method: "GET",
        withCredentials: "true",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (responseOfTokenAuth.status === 200) {
      setIsLoading(true)   
      setShow(true)
      setMessage("")
      setEmailId(name)
      //const otp1 = Math.floor(100000 + Math.random() * 900000);
      const array = new Uint32Array(1);
      window.crypto.getRandomValues(array);
      const otp1 = array[0] % 900000 + 100000; 
      console.log("here is otp check it once bro",otp1);
      setOtp(otp1)
    } else {
      setShow(false)
    }
    

    //cookie.set("token",data.token)
  //   try{
  //     const token = localStorage.getItem("token");
  //     //const token=cookie.get("token")
  //   if (token) {
  //     // Make an asynchronous request to validate the token
  //     console.log(token)
  //     const responses=await fetch(`${process.env.REACT_APP_API_URL}/protected-route`,
  //     {
  //       withCredentials: 'true',
  //       method: 'GET',
  //       headers:
  //       {
  //         'Authorization':`Bearer ${token}`,
  //       },
  //     })
  //     console.log(responses)

  //     if (responses.status === 200) {
  //       // Token is valid
  //       userAuth(true);
  //       //localStorage.setItem("tokenauth","true")
  //       cookie.set("tokenauth","true")
  //       cookie.set("project",data.project)
        
  //     } else {
  //       // Token is not valid
  //       userAuth(false);
  //       cookie.set("tokenauth","false")
  //     }
  //   } else {
  //     // No token found
  //     userAuth(false);
  //     cookie.set("tokenauth","false")
  //   }
  // } catch (error) {
  //   // Handle errors, e.g., network issues
  //   console.error("Token validation failed:", error);
  // }
  try {
   
    if(location==="/v1/jim/homepage" || location==="/v1/jim_user_login" || location.includes('v1/jim/change'))
    {
     // navigate("/v1/jim/user_homepage")
    }
    else{
   //navigate(`${location}`);  
    }
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  }
  else if(response.status===404)
  {
    setIsLoading(true)
    setClose(1)
    setIsBlocked("")
   setMessage("! Incorrect Email Or Password")
   setHeight('120vh')
  }
//}

}
};
/*useEffect(() => {
  clicks();
  

}, [])*/



 /* const click=(y)=>
  {
    y.preventDefault()
    const type="admin"
    const obj={name,pass,type}
    console.log(obj)
    const res={
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(obj)
  };
 
  }*/
  const handlKeyPress=(event)=>
  {
    if(event.key==='Enter')
    {
      clicks(event)
    }
  }

return (
 <>
  <div className="body" style={{height: height,backgroundImage:`url(${'https://bluehost-cdn.com/media/user/login/_bh/webmail-login.svg'})`}}>
      <Headerhotel disp="none"/>
     <h1 style={{color: "#ffffff",textAlign: "center",fontFamily: "auto"}}>Jio Service Support</h1><br/>
  <div className="login" style={{background:"#ffffff63",paddingTop:"50px",paddingBottom:"30px",width:"460px"}}>
    <div className='SignIn-header'>
      <h4 style={{color:"#000080",textAlign:"cemter",fontFamily:"auto"}}>Log In</h4>
    </div>
      <form>
      <label><b>Email
      </b>
      </label>
      <input type="email" name="username"  id="Uname" placeholder="Enter Email" onChange={(e)=>setName(e.target.value)}
      onBlur={handleFilter}/>
      <br/><br/>
      <label><b>Password
      </b>
      </label>
      
      <input type={type} name="password" onKeyDown={handlKeyPress} ref={password} id="Pass" placeholder="Enter Password" onChange={(e)=>setPass(e.target.value)}/>
      <i 
      className={icon}
      //ref={togglePassword}
      onClick={handleToggle}
      id="togglePassword" 
      style={{marginLeft: "-30px", cursor: "pointer"}}></i>
      <br/>
      <div align="left">
            {filteredProjectList.length > 1 ? (
              <>
                <br />
                <label for="type">
                  <b>Select Project</b>
                </label>
                <select name="type" id="type" value={selectedProject} onChange={(e)=>{
                  e.preventDefault()
                  setSelectedProject(e.target.value)
                  }}>
                    <option>Select Project</option>
                  {filteredProjectList.map((el) => (
                    <option key={el} value={el} >
                      {el}
                    </option>
                  ))}
                </select>
              </>
            ) : null}
          </div>
          <br />
    
      </form>
      <strong>
        <button type="submit" name="log" id="log"  value="Log In" style={{background:"#3399FF",border:"none",borderRadius:"2px",height:"42px"}}   onClick={clicks}>
          {
            !isLoading ? <div class="spinner-border spinner-border-sm">
            <span class="sr-only">Loading...</span>
            </div> :   <span>
          Log In
          </span>
          }
        </button>
        </strong>
      {/* <br/> <p style={{color:"red"}}>{message}</p> */}
      <br></br>
      {/* <p style={{color:"red"}}>{message}</p>
      { isBlocked ? <span style={{color:"red"}}>{active}</span> : <span style={{color:"green"}}>{active}</span> } */}
      <br></br>
      {/*<a align="center" style={{float: "left"}} href="/v1/jim/signin">Sign In With JioSignage</a>
      <br/>*/}
      {/*<a style={{float: "left"}} href="/registers">Register for new User</a>*/}
      {/*<a style={{float: "left"}} href="/v1/jim/register">Register</a>*/}
      <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
      <a className="a" href="/v1/jim/forget">Forgot Password ?</a>
      </div>
      {message=="Please Enter Email & Password" || message=="Please Select Project" || message==="Invalid user id or password"?close?<Alert severity="warning" onClose={() => {setClose(0)}}>
        {message}
        </Alert>:""
        :close?<Alert severity="warning" onClose={() => {setClose(0)}}>
        {message}
          <p>{attemptLeft} attempts left</p>
        </Alert>:""}
        {isBlocked===false?<Alert severity="success">
        {/* <AlertTitle>Success</AlertTitle> */}
        {active}
        </Alert>:""}
        {isBlocked===true?<Alert severity="info">
        {/* <AlertTitle>Info</AlertTitle> */}
        Too many wrong attempts, {active}
        <br/>
        {countDown?`TimeLeft: ${countDown}`:""}
        </Alert>:""}
      <br/>
    
</div>

  </div>
  {show?<OtpModal 
     showAlert={showAlert} 
     setAlertProps={setAlertProps}
     showModal={show} 
     //updateList={updateList} 
     token={token}
     email={emailId}
     selectedProject = {selectedProject}
     setotp={otp}
     mail={mail}
     userAuth={userAuth}
      />
  :""}

<Footer margin={"auto"}/>   
</>
  )
}

export default Loginhotel
