import React from 'react'
import '../css/drop.css';
import { useState,useEffect } from 'react';
import Allhotel from './Allhotel';
import Tablehotel from './Tablehotel';
import Headeradminhotel from './headeradminhotel';
import Footer from './Footer'
import { useDispatch, useSelector } from 'react-redux';
import { userAdminName } from '../feature/slice';
import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'

  
function Adminhotel({Clic,showAlert,setAlertProps}) {
  //var [adm,setAdm]=useState(Clic);
  const [pageNumber,setPageNumber] = useState(1)
  let [num, setNum]= useState(10);
  const [numOfTickets,setNumOfTickets] = useState() 
  // const[statusFlag,setStatusFlag]=useState(false);
  const [nam,setNam]=useState('')
  const [fromDate,setFromDate]=useState('')
  const [toDate,setToDate]=useState('dd-mm-yy')
  const [pass,setPass]=useState([])
  const token=localStorage.getItem("adminToken")
  const navigate=useNavigate()
  const [isLoading, setLoading] = useState(true);
  const [priority,setPriority]=useState('')
  //let datas = sessionStorage.getItem("mail");
  const datas=useSelector((state)=>state.userAdminMail)
  //const project=cookie.get("project")

  const [options,setOptions]=useState([])
 const [project,setProject]=useState("")


 useEffect(() => {
  try{
const decodedToken = jwtDecode(token);
setProject(decodedToken.project)
  }
  catch(error)
  {
    navigate("/v1/jim_admin_user_login")
  }
},[])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/getOptions/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
        console.log(data.data)
        setOptions(data.data)
      })
      .catch(err=>console.log(err))
  }, [project])

  
  console.log(datas)
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/admin/${project}/${datas}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })
      .then(res=>res.json())
      .then(data=>
        setNam(data.data[0].name))
        .catch(err=>console.log(err))
    }, [project])

    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/ticketCount/${project}/${num}/${pageNumber}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })
      .then(res=>res.json())
      .then(data=>
        {
        setNumOfTickets(data.data.totalCount)})
    
        .catch(err=>console.log(err))
    }, [num,pageNumber,project])

    console.log(datas)
    const totalCountFunc =  async () =>{
      const resp =  await fetch(`${process.env.REACT_APP_API_URL}/bar/${project}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })
    //   .then(res=>res.json())
    //   .then(data=>
    //     {
    //       setTotalTicketCount(data.data)
    //      return data.data
    //    // let sum=(data.data).reduce(cur,acc=>{return cur+acc},0)
    //     //console.log(sum)
    //     //setNumOfTickets(sum)
        
    // })
    //     .catch(err=>console.log(err))
    let data = await resp.json()
    console.log("hello here is daata",data)
        return //setNumOfTickets(data.data.reduce((cur,acc) => Number(cur) + Number(acc) , 0))
    }
    useEffect( () => {
      totalCountFunc();
}, [project])
   
    useEffect(() => {

      console.log(token)
     if(localStorage.getItem("tokenauth")==="false")
     //if(cookie.get("tokenauth")==="false")
     {
      window.location.href="/v1/jim_admin_user_login"
     }
    }, [])
    
    console.log(nam)
    const dispatch=useDispatch()
    dispatch(userAdminName(nam))
    //sessionStorage.setItem("key", nam);
  console.log(Clic)
  var [opti,setOption]=useState('');
  
  var [req,setReqOption]=useState('');
  var [filterdate,setFilterdate]=useState('');
  let [optionFlag,setOptionFlag]=useState(0)
  let [searchFlag,setSearchFlag]=useState(0)
  let [statusFlag,setStatusFlag]=useState(0)
  const statusQueryParams = new URLSearchParams(window.location.search);
  useEffect(()=>{
  const paramName=statusQueryParams.get('status');
  console.log(paramName)
  if(paramName!==null && paramName){
    setReqOption(paramName)
    console.log("aditya from useeffect",paramName);
    //console.log(`${process.env.REACT_APP_API_URL}/status/${project}/${paramName}`)
    fetch(`${process.env.REACT_APP_API_URL}/status/${project}/${paramName}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      }
    })
    .then(res=>res.json())
    .then(data=>
      { 
      setPass(data.data)
      setLoading(false)  })
      .catch(err=>console.log(err))
    }
},[project])

// re-directing and showing the tickets according to the type of the ticket
  
const typeQueryParams = new URLSearchParams(window.location.search);

useEffect(()=>{
const paramName=typeQueryParams.get('type')
if(paramName!==null && paramName){
  setOption(paramName)
  // console.log(`${process.env.REACT_APP_API_URL}/commented/${project}/${paramName}`)
  fetch(`${process.env.REACT_APP_API_URL}/commented/${project}/${paramName}`,
  {
    method: 'GET',
    withCredentials: 'true',
    headers:
    {
      'Authorization':`Bearer ${token}`,
    }
  })
  .then(res=>res.json())
  .then(data=>
    {
    setPass(data.data);
    setLoading(false)
    console.log(pass,"shwetapass")
})
    .catch(err=>console.log(err))
  }
},[project])

// re-directing and showing the tickets according to the severity of the ticket

const severityQueryParams = new URLSearchParams(window.location.search);
useEffect(()=>{
  const paramName=severityQueryParams.get('severity')
  if(paramName!==null && paramName){
    setPriority(paramName)
    fetch(`${process.env.REACT_APP_API_URL}/severity/${project}/${paramName}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      }
    })
    .then(res=>res.json())
    .then(data=>
      {
        setPass(data.data)
        setLoading(false)
      })
      .catch(err=>console.log(err))
    }
  },[project])

// re-directing and showing the tickets according to the priority of the ticket

const priorityQueryParams = new URLSearchParams(window.location.search);
useEffect(()=>{
const paramName=priorityQueryParams.get('priority')
if(paramName!==null && paramName){
  setPriority(paramName);
  //console.log(`${process.env.REACT_APP_API_URL}/priority/${project}/${paramName}`)
  fetch(`${process.env.REACT_APP_API_URL}/priority/${project}/${paramName}`,
  {
    method: 'GET',
    withCredentials: 'true',
    headers:
    {
      'Authorization':`Bearer ${token}`,
    }
  })
  .then(res=>res.json())
  .then(data=>
    {
    setPass(data.data)
    setLoading(false)

})
    .catch(err=>console.log(err))
  }
},[project])

  
   const text=(e)=>
   {
    if(e.target.value==="")
    {
      setSearchFlag(0)
    }
    else
    {
      setSearchFlag(1)
    }
    setSearch(e.target.value)
    fetch(`${process.env.REACT_APP_API_URL}/search/${project}/${e.target.value}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
      setPass(data.data)
      setLoading(false)
  
   })
      .catch(err=>console.log(err))
    setFilterdate("")
    setFromDate("")
    setToDate("")
    setOption("")
    setReqOption("")
    setOptionFlag(0)
    setStatusFlag(0)
    setFilterFlag(0)
    
   }
  const dropdown=(e)=>
  {
    setFilterdate("")
    setFromDate("")
    setToDate("")
    setReqOption("");
    if(optionFlag===0)
    {
      setOptionFlag(1)
      setStatusFlag(0)
     setFilterFlag(0)
    }
    else{
      setOptionFlag(0)
    }
   
   setOption(e)
   fetch(`${process.env.REACT_APP_API_URL}/commented/${project}/${e}`,
   {
    method: 'GET',
    withCredentials: 'true',
    headers:
    {
      'Authorization':`Bearer ${token}`,
    },
  })
    .then(res=>res.json())
    .then(data=>{
      setPass(data.data)
      setLoading(false)
    })
      .catch(err=>{
        setLoading(false)
        console.log(err)
      })

  }
  
  const request=(f)=>
  {
    setFilterdate("")
    setFromDate("")
    setToDate("")
    setOption("")
  //  setStatusFlag(true);
   console.log("requestfunction")
    if(statusFlag===0)
    {
    setStatusFlag(1)
    setOptionFlag(0)
    setFilterFlag(0)
    }
    else{
      setStatusFlag(0)
    }
    setReqOption(f)
   { console.log("aditya from request")}
   fetch(`${process.env.REACT_APP_API_URL}/status/${project}/${f}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
        
      setPass(data.data)
      setLoading(false)
  })
      .catch(err=>{
        //isLoading(false)
        setLoading(false)
        console.log(err)
      })
  }
 const selecting=(e)=>
 {
  console.log("ha")
  setReqOption(e.target.value)
  console.log(e.target.value);
 }
  var [search,setSearch]=useState('');
  const searchClick=()=>
  {
    console.log(search)
   if(search==="")
    {
      setSearchFlag(0)
    }
    else
    {
      setSearchFlag(1)
    
    fetch(`${process.env.REACT_APP_API_URL}/search/${project}/${search}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      setPass(data.data))
      .catch(err=>console.log(err))
    console.log(search);
    }
      }

  console.log(opti)
  console.log(req)

  
      const [filterFlag,setFilterFlag]=useState(0)
      const [exportFlag,setExportFlag]=useState(0)
      const filter=()=>
      {
        setLoading(true)
        setStatusFlag(0)
        setOptionFlag(0)
        setExportFlag(1)
        setReqOption("")
        setOption("")
        if(filterFlag===0)
        {
          setFilterFlag(1)
        } 
        setFilterdate(fromDate+"_to_"+toDate)   
        fetch(`${process.env.REACT_APP_API_URL}/filterdate/${project}/${fromDate+" to "+toDate}`,
        {
          method: 'GET',
          withCredentials: 'true',
          headers:
          {
            'Authorization':`Bearer ${token}`,
          },
        })
        .then(res=>res.json())
        .then(data=>{
          setPass(data.data)
          setLoading(false)
        }
          )

          .catch(err=>console.log(err))
        
      }
      const exports= async ()=>
      {
        await fetch(`${process.env.REACT_APP_API_URL}/report/${project}/${filterdate}/${datas}`, {
          method: 'GET',
          withCredentials: 'true',
          headers: {
                'Authorization':`Bearer ${token}`,
          },
        })
        await fetch(`${process.env.REACT_APP_API_URL}/download/${project}/${filterdate}.xlsx`, {
          method: 'GET',
          headers: {
            'Authorization':`Bearer ${token}`,
          
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            setToDate('dd-mm-yy')
            if (blob) {
              
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = `${`${filterdate}.xlsx`}`; // Specify the desired file name and extension
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            }
            setFromDate('dd-mm-yy')
            setToDate("")

          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
        setExportFlag(0)
      }

     console.log(filterdate)
 console.log(fromDate)
console.log("here is toDate", toDate)
const requestRef = useRef(null);

useEffect(() => {
  // Add a click event listener to the document to detect clicks outside the dropdown
  function handleClickOutside(event) {
    if (requestRef.current && !requestRef.current.contains(event.target)) {
 
      setOptionFlag(0)
    }
  }

  // Attach the event listener when the component mounts
  document.addEventListener('click', handleClickOutside);

  // Clean up the event listener when the component unmounts
  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, []);
const dropdownRef = useRef(null);

useEffect(() => {
  // Add a click event listener to the document to detect clicks outside the dropdown
  function handleClickOutside(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setStatusFlag(0);
    }
  }

  // Attach the event listener when the component mounts
  document.addEventListener('click', handleClickOutside);

  // Clean up the event listener when the component unmounts
  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, []);

console.log(pass)

let today = new Date();
let dd = String(today.getDate()).padStart(2, '0');
let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy = today.getFullYear();
today = yyyy + '-' + mm + '-' + dd;

let Totoday = new Date();
let dd1 = String(Totoday.getDate()).padStart(2, '0');
let mm2 = String(Totoday.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy2 = Totoday.getFullYear();
Totoday = yyyy2+ '-' + mm2 + '-' + dd1;






console.log("*************here is pageCountData",numOfTickets)












//  let currentIndex=0
// console.log("hello here is pageCount", pageCount)
// const showNextElements= () =>{
//   const nextElements = pageCountData.slice(currentIndex, currentIndex + 6);
//   currentIndex += 6;
// setPageCountData(next)
//   // Display the next 6 elements (you can adapt this to your UI)
//   console.log(nextElements);
// }
  return (
    
    <>
    <Headeradminhotel requests={0} backButton={0} raiseTicket={0} registrationRequest={0} userManagement={1} adminRequests={0} requestsTrack={1} showAlert={showAlert} setAlertProps={setAlertProps}/>
      <div className="sc-hORach dWMRNy">
      <div className="sc-eTuwsz iBEElS">
      </div>
      <main className="sc-hXRMBi bJStMD sc-ibxdXY fgfUVu">
      <div className="sc-bwCtUz ijKeUx">
      <section className="sc-bJTOcE bFtVSx">
      <header>
      <span className="sc-rBLzX fayCzS">
      </span>
      <div className="sc-TFwJa dUxdnV"><div><h1 className="sc-bHwgHz bGtLRv">All Tickets&nbsp;
      <a href="/v1/jim/admin_home" style={{color:"#172b4d"}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-refresh">
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
  <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
  </svg></a></h1>
      </div>
      </div>
      </header>
      <div className="sc-PLyBE iGtjzS">
      <div>
      <div className="Droplist-sc-1z05y4v-0 cLrmQm">
      <div>
      <div>
      <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
      {/*<div>
      <button aria-controls="uid46" aria-expanded="false" aria-haspopup="true" actionsubjectid="requestListMenu" type="button" className="css-q68uj">
      <span className="css-j8fq0c">
      <span className="css-noix33">
      <span role="img" aria-label="more" className="css-snhnyn" >
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <g fill="currentColor" fill-rule="evenodd"><circle cx="5" cy="12" r="2">
      </circle>
      <circle cx="12" cy="12" r="2">
      </circle>
      <circle cx="19" cy="12" r="2"></circle>
      </g>
      </svg>
      </span>
      </span>
      </span>
      </button>
    </div>*/}
      </div>
      </div>
      <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
      <div style={{height: "100%", width: "100%"}}></div></div>
      <div className="styledContentContainer-p0j3f7-0 caSnlw"><div style={{position: "absolute", zIndex: "400", top: "0px", left: "0px",transform: "translate3d(-95px, 40px, 0px)"}}></div>
      </div></div></div></div></div>
      <div className="sc-jqIZGH bokdCL">
      </div>
      <div className="sc-imDfJI jpQXKX" data-test-id="request-list.wrapper">
      <div className="sc-bSbAYC bxpmxB"><div id="request-search-filter">
      <div data-ds--text-field--container="true" className="css-1o07z2d">
        
      <input aria-label="free text filter" onChange={text} placeholder="Type to search..." data-test-id="request-list.filters.textFilter" data-ds--text-field--input="true" className="css-mfjdp3"   value={search} style={{marginBottom:"4px"}}/>
      <button type="button" className="css-q68uj"  style={{backgroundColor: "#BDBDBD"}} onClick={searchClick}><span className="css-j8fq0c">
      <span className="css-noix33">
      <span role="img" aria-label="Search my requests" className="css-snhnyn" >
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path d="M14.823 15.883a5.5 5.5 0 111.06-1.06l2.647 2.647c.293.293.53.59 0 1.06-.53.47-.767.293-1.06 0l-2.647-2.647zM11.5 15.5a4 4 0 100-8 4 4 0 000 8z" fill="currentColor">
      </path></svg></span></span></span></button></div></div>
      {priority!==""?      <div className="sc-bOCYYb gxwVbe"  >
<div className="sc-iHhHRJ jnXgrr">
      <button className="css-1f7f0z2"  data-testid="paginatedRequestTypesFilter" type="button" tabindex="0">
      <span className="css-178ag6o"><div className="sc-kqlzXE iitdmc"><strong></strong>{priority}</div>
  </span></button>
      </div></div>:""}
      <div className="sc-bOCYYb gxwVbe"  >
      <div className="sc-iHhHRJ jnXgrr" ref={dropdownRef} onClick={()=>request(req)}>
      <button className="css-370xbg" data-testid="paginatedStatusFilter" type="button" tabindex="0">
      <span className="css-178ag6o">
      
      <div className="sc-kqlzXE iitdmc">
      <div className="sc-cFlXAS hPkIUt"><strong>Status:</strong>
      <div className="sc-hcnlBt iHCcjr">{req ? req : ""}</div></div></div>

      <div className="sc-OxbzP kJxPgr">
      <span aria-hidden="true" className="css-snhnyn" >
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z" fill="currentColor" fill-rule="evenodd">
      </path></svg></span></div></span></button></div>
      {statusFlag===1?
      <div className="scroll-menu" style={{marginTop:"inherit",width: "131px"}} >
        <div className="menu-item"  style={{borderBottom:"solid 1px lightgrey"}} value="opened" onChange={selecting} onClick={()=>{request("opened");
        setSearch("")
        setLoading(true)
        }}>
        <span className="css-qezc8k" style={{maxWidth: "100%"}}>
          <span className="css-18kwa17"   style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}} /*onChange={(e)=>setReqOption(e.target.value)} onClick={()=>request("opened")}*/>
          Opened</span>
      </span>
          </div>
          <div className="menu-item" style={{borderBottom:"solid 1px lightgrey"}} value="In Progress"  onChange={selecting} onClick={()=>{request("In Progress");
          setSearch("")
          setLoading(true)
          }}>
        <span className="css-qezc8k" style={{maxWidth: "100%"}}>
          <span className="css-18kwa17" style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}} >In Progress</span>
          </span>
        </div>
        <div className="menu-item"  style={{borderBottom:"solid 1px lightgrey"}} value="Resolved" onChange={selecting} onClick={()=>{request("Resolved");
        setSearch("")
        setLoading(true)
        }}>
        <span className="css-qezc8k" style={{maxWidth: "100%"}}>
          <span className="css-18kwa17" style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}} >Resolved</span>
          </span>
        </div>
        <div className="menu-item"  style={{borderBottom:"solid 1px lightgrey"}} value="Rejected" onChange={selecting} onClick={()=>{request("Rejected");
        
        setSearch("")
        setLoading(true)
        }}>
        <span className="css-qezc8k" style={{maxWidth: "100%"}}>
          <span className="css-18kwa17" style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}}  >Rejected</span>
          </span>
        </div>
        <div className="menu-item"   value="Close" onChange={selecting} onClick={()=>{request("Closed");
        setSearch("")
        setLoading(true)
        }}>
        <span className="css-qezc8k" style={{maxWidth: "100%"}}>
          <span className="css-18kwa17" style={{maxWidth: "calc(200px - var(--ds-space-100, 8px))"}} >Closed</span>
          </span>
          </div>
        </div>:""}
      </div>
      
      
      <div className=" css-5a7vsu-container" id="reporterfilter">
      {/*<div className=" css-6m1tpa-control">
      <div className=" css-11tnip9">
      <div className=" css-sf6j83-singleValue" style={{opacity: "1", transition: "opacity 1ms ease 0s"}}>Where I am a participant</div>
      <input id="react-select-reporterfilter-input" readonly="" tabindex="0" aria-autocomplete="list" aria-label="requester filter" className="css-62g3xt-dummyInput" value=""/>
      </div>
      <div className=" css-snhw4r"><div aria-hidden="true" className=" css-1veywqn-indicatorContainer">
      <span role="img" aria-label="open" className="css-snhnyn" >
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z" fill="currentColor" fill-rule="evenodd"></path></svg>
</span></div></div></div>*/}</div>
      <div className="sc-bOCYYb gxwVbe"><div className="sc-iHhHRJ jnXgrr" ref={requestRef} onClick={()=>dropdown(opti)}>
      <button className="css-1f7f0z2" data-testid="paginatedRequestTypesFilter" type="button" tabindex="0">
      <span className="css-178ag6o"><div className="sc-kqlzXE iitdmc"><strong>Request type:</strong> {opti ? opti :""}</div>
      <div className="sc-OxbzP kJxPgr">
      <span aria-hidden="true" className="css-snhnyn" >
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z" fill="currentColor" fill-rule="evenodd"></path></svg>
</span></div></span></button></div>{optionFlag===1?<div className="scroll-menu" style={{width:"181px",marginTop:"inherit"}}>
    {/* <div className="menu-item"  value="Onboarding" onChange={(e)=>setOption(e.target.value)} onClick={()=>{dropdown("Onboarding");setSearch("")}}>Onboarding</div>
    <div className="menu-item"  value="Playback" onChange={(e)=>setOption(e.target.value)} onClick={()=>{dropdown("Playback");setSearch("")}}>Playback</div>
    <div className="menu-item"  value="Report" onChange={(e)=>setOption(e.target.value)} onClick={()=>{dropdown("Report");setSearch("")}}>Report</div>
    <div className="menu-item"  value="Billing" onChange={(e)=>setOption(e.target.value)} onClick={()=>{dropdown("Billing");setSearch("")}}>Billing</div>
    <div className="menu-item"  value="Others" onChange={(e)=>setOption(e.target.value)} onClick={()=>{dropdown("Others");setSearch("")}}>Others</div> */}
      {options.map(ele=>{return <div className="menu-item"  value={ele.reasons} onChange={(e)=>setOption(e.target.value)} onClick={()=>{dropdown(ele.reasons);
      setSearch("")
      }}>{ele.reasons}</div>
      })}
      <div className="menu-item"  value="Not Specified" onChange={(e)=>setOption(e.target.value)} onClick={()=>{dropdown("Not Specified");
      setSearch("")
      }}>Not Specified</div>

    </div>:""}</div>
    <div className=" css-5a7vsu-container" id="reporterfilter">
    <div className="sc-bOCYYb gxwVbe">

    <label for="birthday">From:&nbsp;</label>
    <input type="date" className="birthday-class" value={fromDate} max={today}  
onChange={(e)=>
    {
      setReqOption("")
      setOption("")
      setFilterdate("")
      setExportFlag(0)
      setFromDate(e.target.value)
      setSearch("")
     document.getElementById("to").setAttribute('min',e.target.value)
     if(toDate>e.target.value)
     {
      setToDate(e.target.value)
     }
     else{
      setToDate("")
      setToDate(e.target.value)
     }
   }}
    id="from" name="from"/>
 
 &nbsp;&nbsp;
  <label for="birthday">To:&nbsp;</label>
  <input type="date" className="birthday-class" value={toDate} 
    onChange={(e)=>
  {
    
      setReqOption("")
      setOption("")
      setFilterdate("")
      setExportFlag(0)
      setSearch("")
      setToDate(e.target.value)} 

  }
    id="to"  name="to"/>
  
  
  {fromDate!=="" && toDate!==""?<button className="css-370xbg" onClick={filter} data-testid="paginatedStatusFilter"  type="button" tabindex="0" style={{
        marginLeft:"2px",
        //padding:"10px 20px",
        border: "2px solid #333",
        //paddingLeft:"20px",
        //backgroundColor: "#fff",
        color: "#333",
        //fontSize: "16px",
        transition: "border-color 0.3s ease",
        ':hover': {
          borderColor: '#ff6600',
        }
        }}>
      {/* <span  align="center" className="css-178ag6o">
      <div  className="sc-kqlzXE iitdmc">
      <div  className="sc-cFlXAS hPkIUt"> */}
        <b>Filter</b>
      {/* </div>
      </div>
      <div className="sc-OxbzP kJxPgr">
      </div>
      </span> */}
      </button>:""}
     
      {exportFlag===1&&fromDate!==""&&toDate!==""&&pass.length!==0?<button className="css-370xbg"  onClick={exports}  data-testid="paginatedStatusFilter" type="button" tabindex="0" style={{
        marginLeft:"2px",
        //padding:"10px 20px",
        border: "2px solid #333",
        //paddingLeft:"20px",
        //backgroundColor: "#fff",
        color: "#333",
        //fontSize: "16px",
        transition: "border-color 0.3s ease",
        ':hover': {
          borderColor: '#ff6600',
        }
        }}>
      {/* <span  align="center" className="css-178ag6o">
      <div  className="sc-kqlzXE iitdmc">
      <div  className="sc-cFlXAS hPkIUt"> */}
        <strong>Export</strong>
      {/* </div></div>
      <div className="sc-OxbzP kJxPgr">
      </div></span> */}
      </button>:""}
  </div>
  </div>
  </div>
  </div>
      {/*{t===1?<Search searched={search}/>:<div className="sc-imDfJI jpQXKX"><div className="sc-hAnkBK bETBem">
      <img src="" alt="No requests were found."/>
      <div className="sc-DNdyV RIerR">
      <h3>We couldn’t find any requests</h3>
      <p>
    <span>To find a specific request, try searching using a different filter criteria or <a className="sc-Rmtcm DgkTO" href="/servicedesk/customer/user/requests" target="_self">view all requests</a>.
    </span>
    </p>
    </div>
    </div>
    </div>}
      {opti===''?
      <div className="sc-imDfJI jpQXKX"><div className="sc-hAnkBK bETBem">
      <img src="" alt="No requests were found."/>
      <div className="sc-DNdyV RIerR">
      <h3>We couldn’t find any requests</h3>
      <p>
    <span>To find a specific request, try searching using a different filter criteria or <a className="sc-Rmtcm DgkTO" href="/servicedesk/customer/user/requests" target="_self">view all requests</a>.
    </span>
    </p>
    </div>
    </div>
</div>:<Table Option={opti}/>}*/}

    {(() => {
     
       if(opti!=='') 
       {
        //f=`${process.env.REACT_APP_API_URL}/commented/${opti}`
        /*return (<Table   Option={opti} Tab={Clic}/>)*/
        return (isLoading?<div style={{display:"flex",height:"458px",width:"100%",justifyContent:"center",alignItems:"center"}}>  <div class="spinner-border" style={{width: "3rem", height: "3rem", role:"status"}}>
        <span class="sr-only">Loading...</span>
      </div> </div>:<Allhotel addr={pass}/>)
       }
       else if(priority!=='')
        { 
         console.log("lo")
          /*return (<Status  Status={req}/>*/
          //f=`${process.env.REACT_APP_API_URL}/status/${req}`
          return (isLoading?<div style={{display:"flex",height:"458px",width:"100%",justifyContent:"center",alignItems:"center"}}>  <div class="spinner-border" style={{width: "3rem", height: "3rem", role:"status"}}>
          <span class="sr-only">Loading...</span>
        </div> </div>:<Allhotel addr={pass}/>
          )
        }
        else if(req!=='')
       { 
        console.log("lo")
         /*return (<Status  Status={req}/>*/
         //f=`${process.env.REACT_APP_API_URL}/status/${req}`
         return (isLoading?<div style={{display:"flex",height:"458px",width:"100%",justifyContent:"center",alignItems:"center"}}>  <div class="spinner-border" style={{width: "3rem", height: "3rem", role:"status"}}>
         <span class="sr-only">Loading...</span>
       </div> </div>:<Allhotel addr={pass}/>
         )
       }
       else if(searchFlag===1)
       {
        console.log("lo")
         /*return (<Search  Searched={search}/>*/
         //f=`${process.env.REACT_APP_API_URL}/search/${search}`
         
         return (isLoading?<div style={{display:"flex",height:"458px",width:"100%",justifyContent:"center",alignItems:"center"}}>  <div class="spinner-border" style={{width: "3rem", height: "3rem", role:"status"}}>
         <span class="sr-only">Loading...</span>
       </div> </div>:<Allhotel addr={pass}/>
         )
       }
       else if(filterdate!=='')
       {
        console.log("li")
        console.log(req)
        
         /*return (<Search  Searched={search}/>*/
         //f=`${process.env.REACT_APP_API_URL}/search/${search}`
         return (isLoading?<div style={{display:"flex",height:"458px",width:"100%",justifyContent:"center",alignItems:"center"}}>  <div class="spinner-border" style={{width: "3rem", height: "3rem", role:"status"}}>
         <span class="sr-only">Loading...</span>
       </div> </div>:<Allhotel addr={pass}/>
         )
       }

       else{

        return (<Tablehotel showAlert={showAlert} setAlertProps={setAlertProps} addr={`${process.env.REACT_APP_API_URL}/comment/${project}`}/>)
        /*return (<div className="sc-imDfJI jpQXKX"><div className="sc-hAnkBK bETBem">
         <img src={img} alt="No requests were found."/>
         <div className="sc-DNdyV RIerR"><h3>We couldn’t find any requests</h3>
         <p>
       <span>To find a specific request, try searching using a different filter criteria or <a className="sc-Rmtcm DgkTO" href="/servicedesk/customer/user/requests" target="_self"> view all requests</a>.
       </span>
       </p>
       </div>
       </div>
   </div>)*/
   
       }
      
      })()}
    
    <div className="sc-jzgbtB gUQSWG"></div>
    <div className="sc-jqIZGH bokdCL"></div>
    
    </section></div>
   </main>
   </div>
   <Footer/>
    </>
   
  )
}

export default Adminhotel

