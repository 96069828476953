import React from 'react'
import '../css/jios.css';
import { useEffect } from 'react';
import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Headerhotel from './headerhotel';
import { useDispatch, useSelector } from 'react-redux';
import { updateData, userName } from '../feature/slice';
import {jwtDecode} from 'jwt-decode'


export default function Feedbackhotel({showAlert,setAlertProps}) {
  const token=localStorage.getItem("token")
  var [review,setComment1]=useState('');
  
  var [comment,setComment4]=useState('');
 
  
  const navigate=useNavigate()
 // const project=cookie.get("project")
  const [cc,setCc]=useState("")
  //var nam=sessionStorage.getItem("key");
  const nam=useSelector((state)=>state.userData)

  //var m= sessionStorage.getItem("id");
  const m=useSelector((state)=>state.yourData)
  const [project,setProject]=useState("")


 useEffect(() => {
  try{
const decodedToken = jwtDecode(token);
setProject(decodedToken.project)
  }
  catch(error)
  {
    navigate("/v1/jim_user_login")
  }
},[project])
  useEffect(() => {
   
    console.log(token)
    
   if(localStorage.getItem("tokenauth")==="false")
   //if(cookie.get("tokenauth")==="false")
   {
    window.location.href="/v1/jim_user_login"
   }
  }, [])
  console.log(token)
  const dispatch=useDispatch()
    dispatch(userName(nam))
    dispatch(updateData(m))
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/userTicket/${project}/${m}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })
      .then(res=>res.json())
      .then(data=>
        {
        setCc(data.data.cc)
        if(data.statusCode===400)
        {
         
        //alert("User Logged Out")
          navigate('/v1/jim_user_login')
        }
    })
        .catch(err=>console.log(err))
    }, [project])
    const submit=(y)=>
    {
      //y.preventDefault()
      const token=localStorage.getItem("token")
      fetch(`${process.env.REACT_APP_API_URL}/admin/${project}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      }
      )
      .then(res=>res.json())
      .then(data=>
        { 
          console.log(data.statusCode)
          if(data.statusCode===400)
      {
       console.log("hi")
      //alert("User Logged Out")
        navigate('/v1/jim_user_login')
      }
      let emailString = "";
        let nameString = ""
        data.data.forEach((item, index) => {
          emailString += item.email;
          if (index < data.data.length - 1) {
            emailString += ";";
          }
          nameString += item.name;
          if (index < data.data.length - 1) {
            nameString += ",";
          }
        });
         //data.data.map(element=>{
        fetch(`${process.env.REACT_APP_API_URL}/ticketstatus`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization':`Bearer ${token}`
          },
          body: JSON.stringify({
            mail: emailString,
            name: nameString,
            user: nam,
            id: m,
            status: "closed",
            cc: cc
          })})
        .then(console.log("email send"))
        .catch(err=>console.log(err))
        })
      //})
        .catch(err=>console.log(err))
    }
  const click=async (event)=>
  {
   event.preventDefault()
   const token=localStorage.getItem("token")
   console.log(token)
    const ide=m
    var user=nam
    const obj={ide,review,comment,user}
    console.log(obj)
    const res={
      method: 'POST',
      withCredentials: 'true',
      headers: {'Authorization':`Bearer ${token}`,'Content-Type': 'application/json' },
      body: JSON.stringify(obj)
  };
  await fetch(`${process.env.REACT_APP_API_URL}/jio/${project}`, res)
        .then((data)=>
        {
       
          console.log(data)
            console.log("added")
        })
      
        console.log(token)
        const id=m
        var user=""
        const description=""
        const summary=""
        const opt=""
        const status="Closed"
        const object={id,description,summary,opt,user,status}
        console.log(object)
        const result={
          method: 'PUT',
          withCredentials: 'true',
          headers: {'Authorization':`Bearer ${token}`,'Content-Type': 'application/json' },
          body: JSON.stringify(object)
      };
      await fetch(`${process.env.REACT_APP_API_URL}/chat/${project}/${m}`, result)
            .then(async (data)=>
            { 
              console.log(data.status)
              if(data.status===400)
              {
                navigate("/v1/jim_user_login")
              }
              else{
                showAlert({
                  title:  'Feedback Submitted',
                  message: 'Your feedback has been successfully submitted.',
                  okText: 'OK',
                  callback: () => {
                      console.log('Alert closed');
                      setAlertProps(null)
                      try {
                        navigate("/v1/jim/user_homepage");
                         // Omit optional second argument
                         //window.location.reload(false);
                      } catch (error) {
                        navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
                      }
                  }
              });
              }
                console.log("added")
                
            })
           
            
          //window.location.reload(false);
       
  }
 
  return (
    <>
    <div >
     <Headerhotel requests={0} backButton={1} raiseTicket={0} registrationRequest={0} adminRequests={0} showAlert={showAlert} setAlertProps={setAlertProps}/>
    <div  align="center">
      <form onSubmit={(y)=>
        { return click(y),submit(y)}}>
    <div className="login" style={{paddingTop:"30px",paddingBottom:"50px",height:"550px"}}>
        <h2 align="center">Feedback Form</h2> 
        <br/>
      <p>Thanks for using Jio service, We'd like to know a bit more about your experience with us.</p>
      <p align="center">How would you rate us on:</p>
      <br/>
      <p/>
    <h5 style={{textAlign: "left"}}>Service Provided by us<span className="sc-lcpuFF kHmppP" aria-hidden="true" >*</span></h5>
     <div  align="left" className="ratea" >
    <input required type="radio" id="star5a" name="ratea" value="5"  onChange={(e)=>setComment1(e.target.value)}/>
    <label style={{marginBottom:"00px"}} htmlFor="star5a" title="text">5 stars</label>
    <input style={{marginBottom:"00px"}}required type="radio" id="star4a" name="ratea" value="4"  onChange={(e)=>setComment1(e.target.value)} />
    <label style={{marginBottom:"00px"}} htmlFor="star4a" title="text">4 stars</label>
    <input required type="radio" id="star3a" name="ratea" value="3" onChange={(e)=>setComment1(e.target.value)} />
    <label style={{marginBottom:"00px"}} htmlFor="star3a" title="text">3 stars</label>
    <input required type="radio" id="star2a" name="ratea" value="2"  onChange={(e)=>setComment1(e.target.value)}/>
    <label style={{marginBottom:"00px"}} htmlFor="star2a" title="text">2 stars</label>
    <input required type="radio" id="star1a" name="ratea" value="1" onChange={(e)=>setComment1(e.target.value)}/>
    <label style={{marginBottom:"00px"}} htmlFor="star1a" title="text">1 stars</label>
  </div>
  <br/>
  <br/>
  <br/>
  {/*<h5 style={{paddingRight:"70px"}}>Service Provided by us<span className="sc-lcpuFF kHmppP" aria-hidden="true"  >*</span></h5>
     <div  align="center" className="rateb" required>
    <input required type="radio" id="star5b" name="rateb" value="5" onChange={(e)=>setComment2(e.target.value)}/>
    <label htmlFor="star5b" title="text">5 stars</label>
    <input required type="radio" id="star4b" name="rateb" value="4" onChange={(e)=>setComment2(e.target.value)}/>
    <label htmlFor="star4b" title="text">4 stars</label>
    <input required type="radio" id="star3b" name="rateb" value="3" onChange={(e)=>setComment2(e.target.value)}/>
    <label htmlFor="star3b" title="text">3 stars</label>
    <input required type="radio" id="star2b" name="rateb" value="2" onChange={(e)=>setComment2(e.target.value)}/>
    <label htmlFor="star2b" title="text">2 stars</label>
    <input required type="radio" id="star1b" name="rateb" value="1" onChange={(e)=>setComment2(e.target.value)}/>
    <label htmlFor="star1b" title="text">1 star</label>
  </div>
  <h5 style={{paddingRight:"70px"}}>Service Provided by us<span className="sc-lcpuFF kHmppP" aria-hidden="true" >*</span></h5>
     <div  align="center" className="ratec" required>
    <input required type="radio" id="star5c" name="ratec" value="5" onChange={(e)=>setComment3(e.target.value)}/>
    <label htmlFor="star5c" title="text">5 stars</label>
    <input required type="radio" id="star4c" name="ratec" value="4" onChange={(e)=>setComment3(e.target.value)}/>
    <label htmlFor="star4c" title="text">4 stars</label>
    <input required type="radio" id="star3c" name="ratec" value="3" onChange={(e)=>setComment3(e.target.value)}/>
    <label htmlFor="star3c" title="text">3 stars</label>
    <input required type="radio" id="star2c" name="ratec" value="2" onChange={(e)=>setComment3(e.target.value)}/>
    <label htmlFor="star2c" title="text">2 stars</label>
    <input required type="radio" id="star1c" name="ratec" value="1" onChange={(e)=>setComment3(e.target.value)}/>
    <label htmlFor="star1c" title="text">1 star</label>
  </div>*/}
  <br/>
  <h5 style={{textAlign: "left"}}>Anything else you'd like to share with our team?<span className="sc-lcpuFF kHmppP" aria-hidden="true" >*</span></h5>
  <br/><textarea style={{marginTop:"00px"}} id="comment1" required="required" defaultValue={comment} onChange={(e)=>setComment4(e.target.value)}   className="comment1 gwKMCV ">
  </textarea>
 <p/>
  {/*<h5>Anything that you wish to change?<span className="sc-lcpuFF kHmppP" aria-hidden="true" >*</span></h5>
  <textarea id="comment2" required="required"  defaultValue={comment2} onChange={(e)=>setComment5(e.target.value)}   className="comment2 gwKMCV "></textarea>*/}
   
   <br/>
  <input style={{marginBottom:"00px"}} type="submit"  /*style={{paddingBottom: "5px",
   o paddingTop: "5px"}}*/   className="request-create-form-submit css-68ar9j" /*className="button hGQpYF"*/ />
  {/*<span className="spanbutton enIueZ"><span>Submit</span>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-right">
  <path d="M5 12h14"></path><path d="m12 5 7 7-7 7"></path></svg></span>*/}
  </div>
  </form>
  </div>
  </div>
    </>
  )
  
}
