
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Headeradminhotel from "./headeradminhotel";
import "../css/admin.css";

export default function AdminProfile({showAlert,setAlertProps}) {
  const [project, setProject] = useState('')
  const [details, setDetails] = useState([]) 
  const token = localStorage.getItem("adminToken");
  const email = useSelector((state) => state.userAdminMail);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const decodedToken = jwtDecode(token)
      setProject(decodedToken.project)
    } catch (error) {
      navigate("/v1/jim_admin_user_login");
    }
  }, []);

  useEffect(() => {
    if (project && email) {
      fetch(`${process.env.REACT_APP_API_URL}/admin/${project}/${email}`, {
        method: 'GET',
        withCredentials: true, // No need to stringify 'true'
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(res => res.json())
        .then(data => setDetails(data.data))
        .catch(err => console.log(err));
    }
  }, [project, email]);

  console.log("+++++++++++++++++", details);

  return (
    <div style={{ height: "100vh" }}>
      <Headeradminhotel
        raiseTicket={0}
        registrationRequest={0}
        adminRequests={0}
        backButton={1}
        showAlert={showAlert} 
        setAlertProps={setAlertProps}
      />
      <h2 style={{ color: "#ffffff", textAlign: "center", fontFamily: "auto", }}>
        Profile Details
      </h2>
      <div className="container jumbotron mt-4" style={{
        background: "white",
        //height:"80%",
        paddingTop: "100px",
        paddingBottom: "110px",
        background: 'white'
      }}>
        <div className="row">
          <div className="col-sm-2 mt-4"></div>
          <div className="col-sm-3 mt-4 text-center">
            <label style={{color:"#030303"}}><b>User Name</b> </label>
          </div>
          <div className="col-sm-2 mt-4 text-center">
            <label style={{color:"#030303"}}><b> :</b></label>
          </div>
          <div className="col-sm-3 mt-4 text-center">
            <span><p>{details[0]?.name || 'Loading...'}</p></span>
          </div>
          <div className="col-sm-2 mt-4"></div>
        </div>
        <div className="row">
          <div className="col-sm-2 mt-4"></div>
          <div className="col-sm-3 mt-4 text-center">
            <label style={{color:"#030303"}}><b>Email </b></label>
          </div>
          <div className="col-sm-2 mt-4 text-center">
            <label style={{color:"#030303"}}><b>:</b></label>
          </div>
          <div className="col-sm-3 mt-4 text-center">
            <span><p>{details[0]?.email || 'Loading...'}</p></span>
          </div>
          <div className="col-sm-2 mt-4"></div>
        </div>
        <div className="row">
          <div className="col-sm-2 mt-4"></div>
          <div className="col-sm-3 mt-4 text-center">
            <label style={{color:"#030303"}}><b>Mobile No. </b></label>
          </div>
          <div className="col-sm-2 mt-4 text-center">
            <label style={{color:"#030303"}}><b> :</b></label>
          </div>
          <div className="col-sm-3 mt-4 text-center">
            <span><p>{details[0]?.mobile || 'Loading...'}</p></span>
          </div>
          <div className="col-sm-2 mt-4"></div>
        </div>
        <div className="row">
          <div className="col-sm-2 mt-4"></div>
          <div className="col-sm-3 mt-4 text-center">
            <label style={{color:"#030303"}}><b>Project</b></label>
          </div>
          <div className="col-sm-2 mt-4 text-center">
            <label style={{color:"#030303"}}><b> :</b></label>
          </div>
          <div className="col-sm-3 mt-4 text-center">
            <span><p>{project || 'Loading...'}</p></span>
          </div>
          <div className="col-sm-2 mt-4"></div>
        </div>
        <div className="row">
          <div className="col-sm-2 mt-4"></div>
          <div className="col-sm-3 mt-4 text-center">
            <label style={{color:"#030303"}}><b>User type </b></label>
          </div>
          <div className="col-sm-2 mt-4 text-center">
            <label style={{color:"#030303"}}><b> :</b></label>
          </div>
          <div className="col-sm-3 mt-4 text-center">
            <span><p>{details[0]?.user || 'Loading...'}</p></span>
          </div>
          <div className="col-sm-2 mt-4"></div>
        </div>
      </div>
    </div>
  );
}
