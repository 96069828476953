import React, { useEffect, useState } from "react";
import '../css/profile.css';
import "../css/profileDropDown.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Adminmail, userAdminName, updateAdminData } from "../feature/slice";
import { jwtDecode } from "jwt-decode";
import { FaUser, FaSignOutAlt, FaCog } from 'react-icons/fa';

// Create a CSS file for styling

//let name;
function Profileadminhotel({showAlert,setAlertProps}) {
  //const [name,setName]=useState(nam)
  //const [tell,setTell]=useState("")
  //const storedData = JSON.parse(localStorage.getItem('myData'));
  //const storedDatas = JSON.parse(localStorage.getItem('mydata'));

  /*const storedDatas = JSON.parse(localStorage.getItem('myDatas'));
 
  if(storedData===undefined || name!==storedDatas )
  {
    name=storedDatas
  }
  else{
    
     name=storedData
     
    
  }
  if(storedDatas===undefined  || name===storedData)
  {
   name=storedDatas
    
  }
  else{
   
    name=storedData
  }*/

  /*var f;

  if(nam===undefined || name!==nams )
  {
    
    name=nams
  }
  else{
    
     name=nam
     
    
  }
  if(nams===undefined  || name===nam)
  {
    name=nam
    
  }
  else{
   
    name=nams
     
    
  }*/

  //let data = sessionStorage.getItem("key");
  const data = useSelector((state) => state.userAdminData);
  const id = useSelector((state) => state.userAdminMail);
  //const project=cookie.get("project")

  const dispatch = useDispatch();
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  const navigate = useNavigate();
  const click = () => {
    showAlert({
      title: 'Log out',
      message: 'Are you sure you want to log out ?',
      okText: 'OK',
      cancelText: 'Cancel',
      callback: function (res) {
        if(res)
          {
    const token = localStorage.getItem("adminToken");
    const decodedToken = jwtDecode(token);
    const project = decodedToken?.project;
    const res = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/adminlogout/${project}/${id}`,
      res
    ).then(() => {
      console.log("added");
    });
    //cookie.remove("token")
    //localStorage.setItem("token","")
    localStorage.removeItem("adminToken");
    localStorage.removeItem("tokenauth");
    //cookie.remove("tokenauth")

    dispatch(Adminmail(null));
    dispatch(userAdminName(null));
    dispatch(updateAdminData(null));

    //userAuth(false)
    //localStorage.setItem("tokenauth","false")
    //cookie.set("tokenauth","false")
    try {
      navigate("/v1/jim_admin_user_login");
      //window.location.reload(false)
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  }
    setAlertProps(null)
  }})
  };
  // console.log(storedDatas)

  function menuToggle() {
    const toggleMenu = document.querySelector(".menu");
    toggleMenu.classList.toggle("active");
  }

  const handleAutoTicketNav = () =>{
    navigate("/v1/jim/admin_proj_settings")
  }

  const handleAdminProfile = () =>{
    navigate("/v1/jim/admin_profile")
  }

  return (
    <div>
      <div className="sc-csuQGl eTmqqm">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="Droplist-sc-1z05y4v-0 cLrmQm">
            <div>
              <div>
                <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT">
                  <div>
                    <button
                      className={`sc-gPEVay kOqTiO ${hovered ? "hovered" : ""}`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      //onClick={click}
                      onClick={menuToggle}
                    >
                      <div className="sc-jlyJG jrYKHX">
                        {/* <span>{hovered ? "Logout" : data}</span> */}
                        <span> {data}</span>
                      </div>
                    </button>
                  </div>{" "}
                </div>
              </div>
              <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
                <div style={{ height: "100%", width: "100%" }}></div>
              </div>
              <div className="styledContentContainer-p0j3f7-0 caSnlw">
                <div
                  style={{
                    top: "0px",
                    left: "0px",
                    position: "absolute",
                    zIndex: "400",
                    transform: "translate3d(561px, 54px, 0px)",
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div class="action">
            {/* <div class="profile" onClick={menuToggle} style={{border:"solid red 2px",display:"flex",justifyContent:"center",alignItems:"center"}}>
        <div>{data}</div>
      </div> */}
            <div class="menu">
              <ul>
                <li style={{cursor:'pointer'}}>
                <a onClick={handleAdminProfile} onMouseOver={(e) => {
            e.currentTarget.style.color = '#007bff';
          }}
          onMouseOut={(e) => { 
            e.currentTarget.style.color = 'black';
          }} > <FaUser className="mr-2" />My profile</a>
                </li>
                <li style={{cursor:'pointer'}}>
                <a  onClick={handleAutoTicketNav} onMouseOver={(e) => {
            e.currentTarget.style.color = '#007bff';
          }}
          onMouseOut={(e) => { 
            e.currentTarget.style.color = 'black';
          }}> <FaCog className="mr-2" />Auto Assignment</a>
                </li>
                {/* <li>
                <FaCog className="mr-2" /><a >Escalation Matrix</a>
                </li> */}
                <li style={{cursor:'pointer'}}>
                <a onClick={click} onMouseOver={(e) => {
            e.currentTarget.style.color = '#007bff';
          }}
          onMouseOut={(e) => { 
            e.currentTarget.style.color = 'black';
          }}> <FaSignOutAlt className="mr-2" />Logout</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>


    //   <div>
    //   <div className="sc-csuQGl eTmqqm"><div>
    //   <div className="Droplist-sc-1z05y4v-0 cLrmQm"><div><div>
    //   <div className="Droplist__Trigger-sc-1z05y4v-3 eteVrT"><div>

    //   <button className={`sc-gPEVay kOqTiO ${hovered ? "hovered" : ""}`}
    //     onMouseEnter={handleMouseEnter}
    //     onMouseLeave={handleMouseLeave} onClick={click}>
    //   <div className="sc-jlyJG jrYKHX">
    //   <span>{hovered ? "Logout" :data}</span></div></button></div> </div></div>
    // <div className="Layer__FixedTarget-qunuuz-0 cXwmnx">
    // <div style={{height: "100%", width: "100%"}}></div></div>
    // <div className="styledContentContainer-p0j3f7-0 caSnlw">
    // <div style={{top: "0px", left: "0px", position: "absolute", zIndex: "400", transform: "translate3d(561px, 54px, 0px)"}}></div></div></div></div>
    // </div></div>

    //   </div>
  );
}

export default Profileadminhotel;
