import React from "react";
import { useState, useEffect } from "react";
import Headeradminhotel from "./headeradminhotel";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
import "../css/admin.css";
//import { set } from "immer/dist/internal";
export default function AutoTicketAssignment({showAlert,setAlertProps}) {
  const token = localStorage.getItem("adminToken");
  const [selected, setSelected] = useState("");
  const [supportList, setSupportList] = useState([]);
  const [supportListL2, setSupportList2] = useState([]);
  const [supportListL3, setSupportList3] = useState([]);
  const [project, setProject] = useState("");
  const [initialData, setInitialData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [onFlag, setOnFlag] = useState(true);
  const [isDisabled, setIsDisabled] = useState(0);
  const navigate = useNavigate();
  const [formDetailsL1, setFormDetailsL1] = useState([]);
  const [escalationTime, setEscaltionTime] = useState(3.1);
  const [inActivityTime, setInAcitvityTime] = useState(0.5);
  const [selectedEmails, setSelectedEmails] = useState({
    l1: "",
    l2: "",
    l3: "",
  });
  const [alert1, setAlert1] = useState(false);
  const [message, setMessage] = useState("");
  const [message1, setMessage1] = useState("");
  const handleChange = (level, event) => {
    const { value } = event.target;
    console.log("++++++++++", event.target);
    setSelectedEmails((prevState) => ({
      ...prevState,
      [level]: value,
      l1: level === "l1" ? value : "",
      l2: level === "l2" ? value : "",
      l3: level === "l3" ? value : "",
    }));
  };

  const numberInputStyle = {
    "::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: "0",
    },
    "::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: "0",
    },
    "-moz-appearance": "textfield",
  };

  useEffect(() => {
    try {
      const decodedToken = jwtDecode(token);
      setProject(decodedToken.project);
    } catch (error) {
      navigate("/v1/jim_admin_user_login");
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("adminToken");
    fetch(`${process.env.REACT_APP_API_URL}/supportadmin/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSupportList(data.data);
        if (data.statusCode === 400) {
          //alert("User Logged Out")
          navigate("/v1/jim_admin_user_login");
        }
      })
      .catch((err) => console.log(err));
  }, [project]);

  useEffect(() => {
    const decodedToken = jwtDecode(token);
    setProject(decodedToken.project);
    fetch(
      `${process.env.REACT_APP_API_URL}/getAutoAssignSupport/${decodedToken.project}`,
      {
        method: "POST",
        withCredentials: "true",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("+++++++++>>>>", data);
        console.log("*********", data.data);
        setInitialData(data?.data);
        setFormDetailsL1(data?.data);
        setIsDisabled(data?.data?.isAutoAssign);

        //setSelected(initialData[0].isAutoAssign)
        if (data.statusCode === 400) {
          //alert("User Logged Out")
          navigate("/v1/jim_admin_user_login");
        }
      })
      .catch((err) => console.log(err));
  }, [project, flag, onFlag]);

  const handleAutoAssign = async () => {
    const token = localStorage.getItem("adminToken");

    const data = {
      projectName: project,
      isAutoAssign: selected == "On" ? true : false,
      L1: selectedEmails.l1,
      L2: selectedEmails.l2,
      L3: selectedEmails.l3,
    };

    if (
      (data?.isAutoAssign && data.projectName && data.L1) ||
      data.L2 ||
      data.L3
    ) {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/addAutoAssignSupport/${project}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );
      const data1 = await response.json();
      console.log("&&&", data1);
      if (data1.statusCode == 400) {
        showAlert({
          title:  'Error',
          message: data1.error,
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });
      } else if (data1.statusCode == 200) {
        setFlag(!flag);
        setSelected("");
        
 showAlert({
  title:  'Auto-Assign Feature Enabled',
  message: data1.message,
  okText: 'OK',
  callback: () => {
      console.log('Alert closed');
      setAlertProps(null)
  }
});
      } else {          
 showAlert({
  title:  'Oops!',
  message: 'Something Went Wrong, Please try again later',
  okText: 'OK',
  callback: () => {
      console.log('Alert closed');
      setAlertProps(null)
  }
}); 
      }
    } else {
      showAlert({
        title:  'Incomplete Form Submission',
        message: 'Please ensure all required fields are filled out before submitting the form.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
      }); 
    }
  };

  const handleRadioChange = (e) => {
    console.log("here is radio buttons value", e.target.value);
    setSelected(e.target.value);
    if (e.target.value == "Off") {
      fetch(
        `${process.env.REACT_APP_API_URL}/deleteAutoAssignSupport/${project}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((data) => {
          console.log(data);
          setFlag(!flag);
          if (!initialData) {
            showAlert({
              title:  'Auto-Assign Feature Disabled',
              message: 'The auto-assign feature is currently turned off.',
              okText: 'OK',
              callback: () => {
                  console.log('Alert closed');
                  setAlertProps(null)
              }
            }); 
          }
        })
        .catch((error) => {
          console.error("Error deleting data:", error);
        });
    }
  };

  //console.log(JSON.parse(formDetailsL1?.L1))
  const [form1, setForm1] = useState(true);
  const [form2, setForm2] = useState(false);
  const [form3, setForm3] = useState(false);
  const [selectedValuesL1, setSelectedValuesL1] = useState([]);
  const [selectedValuesL2, setSelectedValuesL2] = useState([]);
  const [selectedValuesL3, setSelectedValuesL3] = useState([]);
  const [selectedValueListL1, setSelectedValueListL1] = useState("");
  const [selectedValueListL2, setSelectedValueListL2] = useState("");
  const [selectedValueListL3, setSelectedValueListL3] = useState("");
  const [handleEscInputL1, setHandleEscInputL1] = useState("");
  const [handleEscInputL2, setHandleEscInputL2] = useState("");
  const [handleEscInputL3, setHandleEscInputL3] = useState("");
  const [payloadL1, setPayloadL1] = useState("");
  const [payloadL2, setPayloadL2] = useState("");
  const [payloadL3, setPayloadL3] = useState("");
  const [autoAssignData, setautoAssignData] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidEmailL2, setIsValidEmailL2] = useState(true);
  const [isValidEmailL3, setIsValidEmailL3] = useState(true);
  const [disabledItems, setDisabledItems] = useState([]);
  const [disabledItems1, setDisabledItems1] = useState([]);
  const [newValueL1, setNewValueL1] = useState("");
  const [newValueL2, setNewValueL2] = useState("");
  const [newValueL3, setNewValueL3] = useState("");
  const [newEmailL1, setNewEmailL1] = useState("");
  const [newEmailL2, setNewEmailL2] = useState("");
  const [newEmailL3, setNewEmailL3] = useState("");
  let form2SupportList = [];
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const fetchAutoAssignSupport = async () => {
    const token = localStorage.getItem("adminToken");
    const decodedToken = jwtDecode(token);
    setProject(decodedToken.project);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/getAutoAssignSupport/${decodedToken.project}`,
      {
        method: "POST",
        withCredentials: "true",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    console.log("+++++++++>>>>", data);
    if (data.statusCode === 400) {
      navigate("/v1/jim_admin_user_login");
    } else {
      console.log("+++++++++++", data);
      //setautoAssignData(data.data);
      //setSelected(data.data[0].isAutoAssign);
    }
    return data;
  };

  useEffect(() => {
    console.log(project);
    const result = fetchAutoAssignSupport(project).then((data) => {
      console.log(data?.data?.isAutoAssign);
      setautoAssignData(data?.data?.isAutoAssign);
      setIsChecked(data?.data?.isAutoAssign);
      if (data.data.isAutoAssign) {
        setAlert1(true);
        setMessage("Auto Assign is Activated");
      } else {
        setAlert1(false);
      }
      const defaultDataL1 = JSON.parse(data?.data?.L1 || "[]");
      const defaultDataL2 = JSON.parse(data?.data?.L2 || "[]");
      const defaultDataL3 = JSON.parse(data?.data?.L3 || "[]");
      setSelectedValuesL1(defaultDataL1?.slice(1, -1));
      setSelectedValuesL2(defaultDataL2?.slice(1, -1));
      setSelectedValuesL3(defaultDataL3?.slice(1, -1));
      console.log("here is default data", defaultDataL1[0]?.name);
      setNewValueL1(defaultDataL1[0]?.name);
      setNewValueL2(defaultDataL2[0]?.name);
      setNewValueL3(defaultDataL3[0]?.name);
      setNewEmailL1(defaultDataL1[defaultDataL1.length - 1]?.email);
      setNewEmailL2(defaultDataL2[defaultDataL2.length - 1]?.email);
      setNewEmailL3(defaultDataL3[defaultDataL3.length - 1]?.email);
      setSelectedValueListL2({
        name: defaultDataL2[0]?.name,
        email: defaultDataL2[0]?.email,
        admin: "L2",
      });
      setSelectedValueListL3({
        name: defaultDataL3[0]?.name,
        email: defaultDataL3[0]?.email,
        admin: "L3",
      });
      console.log(data);
      setEscaltionTime(data?.data?.inctime);
      setInAcitvityTime(data?.data?.esctime);
    });
    console.log("********", result);
    // const token = localStorage.getItem("adminToken");
    // fetch(`${process.env.REACT_APP_API_URL}/getAutoAssignSupport/${project}`, {
    //     method: "POST",
    //     withCredentials: "true",
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //     .then((res) => res.json())
    //     .then((data) => {
    //       console.log("+++++++++>>>>", data);
    //       //setautoAssignData(data.data);
    //       //setSelected(initialData[0].isAutoAssign)
    //       if (data.statusCode === 400) {
    //         //alert("User Logged Out")
    //         navigate("/v1/jim_admin_user_login");
    //       }
    //     })
    //     .catch((err) => console.log(err));
  }, [project]);

  const getDetails = async () => {};
  const multiselectOptions = supportList.filter(
    (item) =>
      !disabledItems.some((disabledItem) => disabledItem.email === item.email)
  );
  const multiselectOptions1 = supportListL2.filter(
    (item) =>
      !disabledItems.some((disabledItem) => disabledItem.email === item.email)
  );
  const multiselectOptions2 = supportListL3.filter(
    (item) =>
      !disabledItems1.some((disabledItem) => disabledItem.email === item.email)
  );
  const filterSupportList = (supportList, selectedValuesL1) => {
    //setSupportList(supportList.filter(item => !selectedValuesL1.some(selected => selected.email === item.email)))
    return supportList.filter(
      (item) =>
        !selectedValuesL1.some((selected) => selected.email === item.email)
    );
  };
  console.log("**********************", multiselectOptions2);

  const handleSelectL1 = (selectedList, selectedItem) => {
    console.log("***********", selectedItem);

    const data = {
      name: selectedItem.name,
      email: selectedItem.email,
      admin: "L1",
    };
    setSelectedValuesL1([...selectedValuesL1, data]);
  };

  console.log("************", selectedValuesL1);
  const handleRemoveL1 = (selectedList, removedItem) => {
    setSelectedValuesL1(
      selectedValuesL1.filter((item) => item !== removedItem)
    );
  };

  const handleSelectL2 = (selectedList, selectedItem) => {
    const data = {
      name: selectedItem.name,
      email: selectedItem.email,
      admin: "L2",
    };
    setSelectedValuesL2([...selectedValuesL2, data]);
  };
  const handleRemoveL2 = (selectedList, removedItem) => {
    setSelectedValuesL2(
      selectedValuesL2.filter((item) => item !== removedItem)
    );
  };

  const handleSelectL3 = (selectedList, selectedItem) => {
    const data = {
      name: selectedItem.name,
      email: selectedItem.email,
      admin: "L3",
    };
    setSelectedValuesL3([...selectedValuesL3, data]);
  };
  const handleRemoveL3 = (selectedList, removedItem) => {
    setSelectedValuesL3(
      selectedValuesL3.filter((item) => item !== removedItem)
    );
  };

  const handleSupportL1 = async () => {
    console.log(selectedValuesL1.length);
    if (!selectedValueListL1) {
      showAlert({
        title:  'Default Support Missing',
        message: 'Please add the deafult support before proceeding.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
      }); 
    } else if (!handleEscInputL1) {
      showAlert({
        title:  'Invalid Email Address',
        message: 'Please enter a valid email address for support-L1',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
      }); 
    } else {
      //const result = selectedValuesL1.map(item => `${item.name}:${item.email}`).join(';');
      //const convertedString = JSON.stringify(selectedValuesL1)
      //console.log("----->>>",convertedString)
      //const payload = selectedValueListL1 + result+";" + "Support:" + handleEscInputL1
      console.log("******", selectedValuesL1);
      console.log("******", handleEscInputL1);

      const escData = {
        name: "User",
        email: handleEscInputL1,
        admin: "L1",
      };

      const payload = [selectedValueListL1, ...selectedValuesL1, escData];
      const payloadStirng = JSON.stringify(payload);
      console.log(payloadStirng);
      //console.log("++++++++++",JSON.parse(payloadStirng))
      const parsedData = JSON.parse(payloadStirng);
      const filteredStringifiedData = JSON.stringify(parsedData);
      console.log(filteredStringifiedData);

      const updatedSupportList = supportList.filter((supportItem) => {
        return !payload.some(
          (removeItem) =>
            supportItem.name === removeItem.name &&
            supportItem.email === removeItem.email
        );
      });

      setSupportList2(updatedSupportList);
      setForm2(true);
      setForm1(false);
      // let Arr = selectedValuesL1
      // let joinArr = Arr.unshift(selectedValueListL1)
      // console.log("********",Arr.unshift(selectedValueListL1))

      // console.log("******",escData)
      // console.log(selectedValuesL1.unshift(selectedValueListL1))
      // console.log("&&&&&&&&&&&&",payload1)
      // const payload = payload1.pop(escData)
      setPayloadL1(filteredStringifiedData);
      // console.log("here is payload",payload)    
 showAlert({
  title:  'Support Details Added',
  message: 'Your Support L1 details have been added successfully',
  okText: 'OK',
  callback: () => {
      console.log('Alert closed');
      setAlertProps(null)
  }
}); 

      // const data = {
      //     projectName: project,
      //     isAutoAssign: true,
      //     L1: filteredStringifiedData,
      //     L2: "",
      //     L3: "",
      //   };

      // const response = await fetch(
      //     `${process.env.REACT_APP_API_URL}/addAutoAssignSupport/${project}`,
      //     {
      //       method: "POST",
      //       headers: {
      //         "Content-Type": "application/json",
      //         Authorization: `Bearer ${token}`,
      //       },
      //       body: JSON.stringify(data),
      //     }
      //   );
      //   const data1 = await response.json();
      //   console.log("&&&", data1);

      //   if (data1.statusCode == 400) {
      //     alert(data1.error);
      //   } else if (data1.statusCode == 200) {
      //     setOnFlag(!onFlag)
      //     setFlag(!flag);
      //     window.location.reload()
      //     alert(data1.message);
      //   } else {
      //     alert("Something Went Wrong");
      //   }
    }
  };

  const handleSupportL2 = () => {
    console.log("****************", selectedValueListL2);
    if (selectedValueListL2.name == undefined) {
      showAlert({
        title:  'Default Support Missing',
        message: 'Please add the deafult support before proceeding.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
      }); 
    } else if (!handleEscInputL2) {
      showAlert({
        title:  'Invalid Email Address',
        message: 'Please enter a valid email address for support-L2',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
      });
    } else {
      // const result = selectedValuesL2.map(item => `${item.name}:${item.email}`).join(';');
      // const payload = selectedValueListL2 + result + ";" + "Support:" + handleEscInputL2
      const escData = {
        name: "User",
        email: handleEscInputL2,
        admin: "L2",
      };

      const payload = [selectedValueListL2, ...selectedValuesL2, escData];
      const payloadStirng = JSON.stringify(payload);
      console.log(payloadStirng);
      //console.log("++++++++++",JSON.parse(payloadStirng))
      const parsedData = JSON.parse(payloadStirng);
      const filteredStringifiedData = JSON.stringify(parsedData);
      console.log(filteredStringifiedData);

      setPayloadL2(filteredStringifiedData);
      //console.log("here is payload",payload)
      const updatedSupportList = supportListL2.filter((supportItem) => {
        return !payload.some(
          (removeItem) =>
            supportItem.name === removeItem.name &&
            supportItem.email === removeItem.email
        );
      });
      setSupportList3(updatedSupportList);
      setForm3(true);
      setForm2(false);
      showAlert({
        title:  'Support Details Added',
        message: 'Your Support L2 details have been added successfully',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
      }); 
    }
  };

  const handleSupportL3 = () => {
    if (selectedValueListL3.name == undefined) {
      showAlert({
        title:  'Default Support Missing',
        message: 'Please add the deafult support before proceeding.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
      }); 
    } else if (!handleEscInputL3) {
      showAlert({
        title:  'Invalid Email Address',
        message: 'Please enter a valid email address for support-L3',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
      });
    } else {
      // const result = selectedValuesL3.map(item => `${item.name}:${item.email}`).join(';');
      // const payload = selectedValueListL3 + result + ";" + "Support:" + handleEscInputL3
      const escData = {
        name: "User",
        email: handleEscInputL3,
        admin: "L3",
      };

      const payload = [selectedValueListL3, ...selectedValuesL3, escData];
      const payloadStirng = JSON.stringify(payload);
      console.log(payloadStirng);
      //console.log("++++++++++",JSON.parse(payloadStirng))
      const parsedData = JSON.parse(payloadStirng);
      const filteredStringifiedData = JSON.stringify(parsedData);
      console.log(filteredStringifiedData);
      setPayloadL3(filteredStringifiedData);
      //console.log("here is payload",payload)
      setForm3(false);
      setAlert1(true);
      // if(!escalationTime){
      //   setMessage1("Please Fill  Escalation,InAcitvity Time & Turn on auto assign toggle.")
      // }else if(!inActivityTime){
      //   setMessage1("Please Fill Escalation Time & Turn on auto assign toggle.")
      // }else if(!inActivityTime && !escalationTime){
      //   setMessage1("Please Fill Escalation,InActivity Time & Turn on auto assign toggle.")
      // }else{
      //   setMessage1("Please Turn On Auto Assign Toggle Button")
      // }
      // setEscaltionTime(inActivityTime ? inActivityTime : 3.1)
      // setInAcitvityTime(escalationTime ? escalationTime : 0.5)

      setEscaltionTime(3.1)
      setInAcitvityTime(0.5)
      showAlert({
        title:  'Support Details Added',
        message: 'Your Support L3 details have been added successfully',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
      }); 
    
      //alert("Support L3 details added Successfully");
    }
  };

  async function handleChangeToggle(event) {
    console.log(event.target.checked);
    setIsChecked(event.target.checked);
    console.log(payloadL1);
    console.log("inactivity Time ======>>",inActivityTime)
    console.log("closure Time ======>>",escalationTime)
    if (event.target.checked == true) {
      if (!payloadL1) {
        showAlert({
          title:  'Oops!',
          message: 'Please fill L1 support Details',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
        });
        setIsChecked(false);
      } else if (!payloadL2) {
        showAlert({
          title:  'Oops!',
          message: 'Please fill L2 support Details',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
        });
        setIsChecked(false);
      } else if (!payloadL3) {
        showAlert({
          title:  'Oops!',
          message: 'Please fill L3 support Details',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
        });
        setIsChecked(false);
      } else if (inActivityTime <= 0) {
        showAlert({
          title:  'Oops!',
          message: 'Please fill InActivity Escalation Time',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
        });
        setIsChecked(false);
      } else if (escalationTime<= 0) {
        showAlert({
          title:  'Oops!',
          message: 'Please fill Ticket Closure Escalation Time',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
        });
        setIsChecked(false);
      } 
       else {
        if(inActivityTime>escalationTime){
          showAlert({
            title:  'Oops!',
            message: 'Ticket Closure Escalation Time is Greater Than Inactivity Time',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
          });
          setIsChecked(false);
        }
        else{
        const data = {
          projectName: project,
          isAutoAssign: event.target.checked,
          L1: payloadL1,
          L2: payloadL2,
          L3: payloadL3,
          esctime: inActivityTime,
          inctime: escalationTime,
        };

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/addAutoAssignSupport/${project}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
          }
        );
        const data1 = await response.json();
        console.log("&&&", data1);

        if (data1.statusCode == 400) {
          showAlert({
            title:  'Error',
            message: data1.error,
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
          });
        } else if (data1.statusCode == 200) {
          setOnFlag(!onFlag);
          setFlag(!flag);
          //window.location.reload()
          //setAlert1(false)
          setMessage("Auto Assignment is Activated");
          showAlert({
            title:  'Auto-Assign feature enabled',
            message: data1.message,
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
          });
        } else {
          showAlert({
            title:  'Oops!',
            message: 'Something Went Wrong, Please try again later',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
          });
        }
        
       }}
    } else {
      showAlert({
        title:  'Confirmation',
        message: "Disabling this setting will erase all your current auto-assignment data. If you enable it again later, you’ll need to re-enter new data. Are you sure you want to proceed?",
        okText: 'OK',
        cancelText: 'Cancel',
        callback: async (res) => {
            console.log('Alert closed');
      if (res)
         {
        fetch(
          `${process.env.REACT_APP_API_URL}/deleteAutoAssignSupport/${project}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((data) => {
            setIsChecked(false);
            setFlag(!flag);
            setautoAssignData(0);
            showAlert({
              title:  'Auto-Assign Feature Disabled',
              message: 'The auto-assign feature is currently turned off.',
              okText: 'OK',
              callback: () => {
                  console.log('Alert closed');
                  setAlertProps(null)
                  window.location.reload();
              }
            }); 
            //   console.log(data);
            //   setFlag(!flag);
            //   if (!initialData) {
            //     alert("Auto Assign is Off");
            //   }
          })
          .catch((error) => {
            console.error("Error deleting data:", error);
          });
      } else {
        //alert("Deactivate Auto Assignment Cancelled");
        setIsChecked(true);
        //window.location.reload()
      }
      setAlertProps(null)
    }})
    }
  }

  console.log("here is selected value"+inActivityTime);
  console.log("here is selected value456"+ escalationTime);
  //console.log("here is selected value", initialData[0]);

  return (
    <div>
      <div style={{ height: "100vh" }}>
        <Headeradminhotel
          raiseTicket={0}
          registrationRequest={0}
          adminRequests={0}
          backButton={1}
          showAlert={showAlert} 
          setAlertProps={setAlertProps}
        />

        <h2
          style={{ color: "#ffffff", textAlign: "center", fontFamily: "auto" }}
        >
          Auto Ticket Assignment
        </h2>
        <br />

        <div className="jumbotron mt-2" style={{ background: "white" }}>
          <div className="row">
            {message ? (
              <h4 className="text-success text-center">{message}</h4>
            ) : (
              <h4 className="text-danger text-center">{message1}</h4>
            )}

            {/* <h4
                  className="text-danger text-center"
                >
                  {message1}
                </h4> */}
          </div>
          {/* <div className="row mt-4">
            <div
              className={
                autoAssignData == 1 || isChecked == true
                  ? "disabled_3757 col-sm-3"
                  : "col-sm-3"
              }
              // className="col-sm-3"
            >
              <label>InActivity Escalation Time:</label>
              <span className="text-danger">*</span>
              <p className="mb-1"> ( In Hours ) </p>
              <input
                type="number"
                className="form-control"
                id="Escalationtime"
                aria-describedby="hours"
                //placeholder="Enter Escalation Time"
                value={inActivityTime}
                onChange={(e) => {
                  setInAcitvityTime(e.target.value);
                }}
              />
            </div>
            <div className="col-sm-1"></div>
            <div
              className={
                autoAssignData == 1 || isChecked == true
                  ? "disabled_3757 col-sm-3"
                  : "col-sm-3"
              }
            >
              <label>Ticket Closure Escalation Time:</label>
              <span className="text-danger">*</span>
              <p className="mb-1"> ( In Hours ) </p>
              <input
                type="number"
                className="form-control"
                id="Escalationtime"
                aria-describedby="hours"
                //placeholder="Enter Escalation Time"
                value={escalationTime}
                onChange={(e) => {
                  setEscaltionTime(e.target.value);
                }}
              />
            </div>
            <div className="col-sm-4 d-flex justify-content-center mt-4">
              <div>
                <label>Auto Support Assign :</label>
              </div>
              <div class="form-check form-switch ml-5 mt-1">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={isChecked}
                  onChange={handleChangeToggle}
                />
              </div>
            </div>
          
          </div>
            <hr style={{backgroundColor:"black",height:"1.5px",border:"none"}} /> */}



            
          {/* <div className={autoAssignData == 1 ? "disabled_3757 row" : "row"}>
            <div className="col-sm-7"></div>
            <div className="col-sm-2 mt-4">
              <lable style={{ color: "#010a0e87", fontSize: "17px" }}>
                Escalation Time : <span className="text-danger"> *</span>
              </lable>
             
            </div>
            <div className="col-sm-2 mt-3" style={{marginLeft:"-36px"}}>
            <input
                  type="number"
                  className="form-control"
                  id="Escalationtime"
                  aria-describedby="hours"
                  placeholder="Enter Escalation Time"
                  value={escalationTime}
                  onChange = {(e) =>{
                        setEscaltionTime(e.target.value)
                    }}
                  style={{
                    '::-webkit-inner-spin-button': {
                      '-webkit-appearance': 'none',
                      margin: '0',
                    },
                    '::-webkit-outer-spin-button': {
                      '-webkit-appearance': 'none',
                      margin: '0',
                    },
                    '-moz-appearance': 'textfield',
                  }}
                />
            </div>
          </div> */}

          {console.log("here is testing", newValueL1)}
          <div className={autoAssignData == 1 ? "disabled_3757 row" : "row"}>
            <div className={!form1 ? "disabled_3757 col-sm-4" : "col-sm-4"}>
              <div className="row mt-4">
                <label>Default Support - L1 :<span className="text-danger">*</span></label>
                
                <select
                  className="form-select ml-2 mb-2"
                  style={{ width: "95%" }}
                  aria-label="Default select example"
                  //defaultValue={newValueL1}
                  onChange={(e) => {
                    const selectedOption = e.target.selectedOptions[0];
                    const selectedName = selectedOption.textContent;
                    //setNewValueL1(selectedOption.textContent)
                    const selectedEmail = e.target.value;
                    setDisabledItems([
                      ...disabledItems,
                      { name: selectedName, email: selectedEmail },
                    ]);
                    setSelectedValueListL1({
                      name: selectedName,
                      email: selectedEmail,
                      admin: "L1",
                    });
                  }}
                  //value ={newValueL1}
                >
                  <option selected disabled>
                    {newValueL1 || "Select L1 Support"}
                  </option>
                  {supportList
                    ? supportList.map((el) =>
                        !selectedValuesL1.some(
                          (selected) => selected.email === el.email
                        ) ? (
                          <option value={el.email}>{el.name}</option>
                        ) : null
                      )
                    : null}
                </select>
              </div>
              <div className="row mt-2">
                <label>Support List - L1 :</label>
                <Multiselect
                  options={
                    multiselectOptions
                    //setSupportList(filterSupportList(supportList, selectedValuesL1))
                  }
                  selectedValues={selectedValuesL1}
                  displayValue="name"
                  closeOnSelect={false}
                  onSelect={handleSelectL1}
                  onRemove={handleRemoveL1}
                  selectionLimit={5}
                />
              </div>
              <div className="row mt-4">
                <label>Escalation Email - L1 :<span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control ml-2 "
                  placeholder="Enter Support L1"
                  aria-label="Enter Support L1"
                  aria-describedby="button-addon2"
                  //style={{ width: "95%" }}
                  value={newEmailL1}
                  style={{
                    width: "95%",
                    borderColor: isValidEmail ? "" : "red",
                  }}
                  onChange={(e) => {
                    if (emailRegex.test(e.target.value)) {
                      setHandleEscInputL1(e.target.value);
                      setIsValidEmail(emailRegex.test(e.target.value));
                    } else {
                      setHandleEscInputL1("");
                      setIsValidEmail(emailRegex.test(e.target.value));
                    }
                  }}
                />
              </div>
              <div className="row mt-4">
                <button
                  type="button"
                  className="btn btn-outline-primary ml-2"
                  style={{ width: "95%" }}
                  onClick={handleSupportL1}
                >
                  Save L1 Support
                </button>
              </div>
            </div>
            <div className={!form2 ? "disabled_3757 col-sm-4" : "col-sm-4"}>
              <div className="row mt-4">
                <label>Default Support - L2 :<span className="text-danger">*</span></label>
                <select
                  className="form-select ml-2 mb-2"
                  style={{ width: "95%" }}
                  aria-label="Default select example"
                  //value ={selectedValueListL2?.name}
                  onChange={(e) => {
                    const selectedOption = e.target.selectedOptions[0];
                    const selectedName = selectedOption.textContent;
                    const selectedEmail = selectedOption.value;
                    console.log(selectedName); // This will log the selected name
                    setDisabledItems([
                      ...disabledItems,
                      { name: selectedName, email: selectedEmail },
                    ]);
                    setSelectedValueListL2({
                      name: selectedName,
                      email: selectedEmail,
                      admin: "L2",
                    });
                  }}
                >
                  <option selected disabled>
                    {newValueL2 || "Select L2 Support"}
                  </option>
                  {supportListL2
                    ? supportListL2.map((el) =>
                        !selectedValuesL2.some(
                          (selected) => selected.email === el.email
                        ) ? (
                          <option value={el.email}>{el.name}</option>
                        ) : null
                      )
                    : null}
                </select>
              </div>
              <div className="row mt-2">
                <label>Support List - L2 :</label>
                <Multiselect
                  options={multiselectOptions1}
                  selectedValues={selectedValuesL2}
                  displayValue="name"
                  closeOnSelect={false}
                  onSelect={handleSelectL2}
                  onRemove={handleRemoveL2}
                  selectionLimit={5}
                />
              </div>
              <div className="row mt-4">
                <label>Escalation Email - L2 :<span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control ml-2 "
                  placeholder="Enter Support L2"
                  aria-label="Enter Support L2"
                  aria-describedby="button-addon2"
                  //style={{ width: "95%" }}
                  value={newEmailL2}
                  style={{
                    width: "95%",
                    borderColor: isValidEmailL2 ? "" : "red",
                  }}
                  onChange={(e) => {
                    if (emailRegex.test(e.target.value)) {
                      setHandleEscInputL2(e.target.value);
                      setIsValidEmailL2(emailRegex.test(e.target.value));
                    } else {
                      setHandleEscInputL2("");
                      setIsValidEmailL2(emailRegex.test(e.target.value));
                    }
                  }}
                />
              </div>
              <div className="row mt-4">
                <button
                  type="button"
                  className="btn btn-outline-warning ml-2"
                  style={{ width: "95%" }}
                  onClick={handleSupportL2}
                >
                  Save L2 Support
                </button>
              </div>
            </div>
            <div className={!form3 ? "disabled_3757 col-sm-4" : "col-sm-4"}>
              {/* <div className="row mt-4">
                <label>Default Support - L3 :</label>
                <input
                  type="text"
                  className="form-control ml-2 "
                  placeholder="Enter Support L1"
                  aria-label="Enter Support L1"
                  aria-describedby="button-addon2"
                  style={{ width: "95%" }}
                />
              </div> */}
              <div className="row mt-4">
                <label>Default Support - L3 :<span className="text-danger">*</span></label>
                <select
                  className="form-select ml-2 mb-2"
                  //value ={selectedValueListL3?.name}
                  style={{ width: "95%" }}
                  aria-label="Default select example"
                  onChange={(e) => {
                    const selectedOption = e.target.selectedOptions[0];
                    const selectedName = selectedOption.textContent;
                    const selectedEmail = e.target.value;
                    console.log(selectedName); // This will log the selected name
                    setDisabledItems1([
                      ...disabledItems1,
                      { name: selectedName, email: selectedEmail },
                    ]);
                    setSelectedValueListL3({
                      name: selectedName,
                      email: selectedEmail,
                      admin: "L3",
                    });
                    //setSelectedValueListL3(e.target.value)
                  }}
                >
                  <option selected disabled>
                    {newValueL3 || " Select L3 Support"}
                  </option>
                  {supportListL3
                    ? supportListL3.map((el) =>
                        !selectedValuesL2.some(
                          (selected) => selected.email === el.email
                        ) ? (
                          <option value={el.email}>{el.name}</option>
                        ) : null
                      )
                    : null}
                </select>
              </div>
              <div className="row mt-2">
                <label>Support List - L3 :</label>
                <Multiselect
                  options={multiselectOptions2}
                  selectedValues={selectedValuesL3}
                  displayValue="name"
                  closeOnSelect={false}
                  onSelect={handleSelectL3}
                  onRemove={handleRemoveL3}
                  selectionLimit={5}
                />
              </div>
              <div className="row mt-4">
                <label>Escalation Email - L3 :<span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control ml-2 "
                  placeholder="Enter Support L3"
                  aria-label="Enter Support L3"
                  aria-describedby="button-addon2"
                  //style={{ width: "95%" }}
                  style={{
                    width: "95%",
                    borderColor: isValidEmailL3 ? "" : "red",
                  }}
                  value={newEmailL3}
                  onChange={(e) => {
                    if (emailRegex.test(e.target.value)) {
                      setHandleEscInputL3(e.target.value);
                      setIsValidEmailL3(emailRegex.test(e.target.value));
                    } else {
                      setHandleEscInputL3("");
                      setIsValidEmailL3(emailRegex.test(e.target.value));
                    }
                    //setHandleEscInputL3(e.target.value)
                  }}
                />
              </div>
              <div className="row mt-4">
                <button
                  type="button"
                  className="btn btn-outline-danger ml-2"
                  style={{ width: "95%" }}
                  onClick={handleSupportL3}
                >
                  Save L3 Support
                </button>
              </div>
            </div>
          </div>
          <br />
          <hr style={{backgroundColor:"black",height:"1.5px",border:"none"}} />
          <div className="row mt-4">
            <div
              className={
                autoAssignData == 1 || isChecked == true
                  ? "disabled_3757 col-sm-3"
                  : "col-sm-3"
              }
              // className="col-sm-3"
            >
              <label>InActivity Escalation Time:</label>
              <span className="text-danger">*</span>
              <p className="mb-1"> ( In Hours ) </p>
              <input
                type="number"
                className="form-control"
                id="Escalationtime"
                aria-describedby="hours"
                //placeholder="Enter Escalation Time"
                value={inActivityTime}
                onChange={(e) => {
                  console.log(">>>>>>>>>>>>>>>>>>",e.target.value)
                  setInAcitvityTime(e.target.value);
                  
                }}
              />
            </div>
            <div className="col-sm-1"></div>
            <div
              className={
                autoAssignData == 1 || isChecked == true
                  ? "disabled_3757 col-sm-3"
                  : "col-sm-3"
              }
            >
              <label>Ticket Closure Escalation Time:</label>
              <span className="text-danger">*</span>
              <p className="mb-1"> ( In Hours ) </p>
              <input
                type="number"
                className="form-control"
                id="Escalationtime"
                aria-describedby="hours"
                //placeholder="Enter Escalation Time"
                value={escalationTime}
                onChange={(e) => {
                  console.log(">>>>>>>>>>>>>>>>>>1234",e.target.value)
                  setEscaltionTime(e.target.value);
                }}
              />
            </div>
            <div className="col-sm-4 d-flex justify-content-center mt-4">
              <div>
                <label>Auto Support Assign :</label>
              </div>
              <div class="form-check form-switch ml-5 mt-1">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={isChecked}
                  onChange={handleChangeToggle}
                />
              </div>
            </div>
            {/* <div className="row">
              {alert1 ? (
                <h4
                  className={
                    message == "Please Fill escalation time & Turn on auto assign toggle."
                      ? "text-danger text-center"
                      : "text-success text-center"
                  }
                >
                  {message}
                </h4>
              ) : null}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
