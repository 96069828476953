import "../css/details.css";
import "../css/cmd.css";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate,useParams } from "react-router-dom";
import "../css/admin.css";
import "../css/drop.css";
import ReactQuill from "react-quill";
import arrow from "./image/down arrow.png";
import uparrow from "./image/up arrow.png";
import shareIcon from "./image/Share-icon.png"
import Headeradminhotel from "./headeradminhotel";
import { useSelector } from "react-redux";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Modal from "react-modal";
import {jwtDecode} from 'jwt-decode'
import CryptoJS from 'crypto-js';
import 'react-quill/dist/quill.snow.css';
import VideoModal from "./common/VideoModal";
import { fileTypeFromBuffer } from "file-type";
function Detailshotel({showAlert,setAlertProps}) {
  //let nams = sessionStorage.getItem("key");
  //let m = sessionStorage.getItem("id");

  const token = localStorage.getItem("adminToken");
  const nams = useSelector((state) => state.userAdminData);
  //let nam=sessionStorage.getItem("user")
  //let na=sessionStorage.getItem("mail");
  const m = useSelector((state) => state.yourAdminData);

  const nam = useSelector((state) => state.user);

  console.log(nams);
  const ref = useRef("");


  const [tempEmail, setTempEmail] = useState("");
    const [data,setdata]=useState([])
    const [file,setFile]=useState('')
    const [fileName,setFileName]=useState('')
    //const [da,setda]=useState([])
    const [adm,setAdm]=useState([])
    const [number,setNumber]=useState([])
    const [name,setName]=useState([])
    const [location,setLocation]=useState([])
   const [fieldvalues,setFieldvalues]=useState([])
   const [allFieldsData,setAllFieldsData]=useState([])
   const [textarea,setTextarea]=useState([])
   const [value,setValue]=useState([])
   const [calender,setCalender]=useState([])
   const [radio,setradio]=useState([])
   const [dropdownFields,setDropdownFields]=useState([])
   const [radiooptions,setRadioOptions]=useState([])
   const [numbers,setNumbers]=useState([])
   //const project=cookie.get("project")
   const [tags, setTags] = useState([]);
   const [showDropdown, setShowDropdown] = useState(false);
  const [showDesc, setShowDesc] = useState(true);
  const [isModalVisible, setModalVisible] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [flag,setFlag]=useState(false)
  const [status,setStatus]=useState("")
  const [checked,setChecked]=useState(1)
  const [privateChats,setPrivateChats]=useState(0)
  const [applyClass,setApplyClass] = useState("sc-koErNt SBKnj")
  const [selectedFile, setSelectedFile] = useState([]);
  const fileInputRef = useRef(null); 
  const [project,setProject]=useState("")
  const [temp1, setTemp1] = useState([]);
  const [supportGroupFlag,setSupportGroupFlag] = useState(false)
  const [dataL1,setDataL1] = useState('')
  const [dataL2,setDataL2] = useState('')
  const [dataL3,setDataL3] = useState('')
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoTitle,setVideoTitle]=useState('')
  const [videoLink,setVideoLink]=useState('')
  const [showVideo,setShowVideo]=useState(false)
  const [uploadErr,setUploadErr]=useState(false)
  const [fileUploadErrMessage,setFileUploadErrorMessage]=useState('')
  useEffect(() => {
   try{
 const decodedToken = jwtDecode(token);
 setProject(decodedToken.project)
   }
   catch(error)
   {
     navigate("/v1/jim_admin_user_login")
   }
 },[])  
 function hasHtmlElements(e) {
  const plainString=document.querySelector(".ql-editor p").innerHTML
  console.log(document.querySelector(".ql-editor p").innerHTML)
  if(plainString.includes("&lt;") || plainString.includes("&gt;"))
    {
      return true
    }
}
 function decryptMessage(encryptedHexWithKey) {
  console.log(encryptedHexWithKey)
  // Extract the key from the encrypted string
  const key = encryptedHexWithKey.substring(0,8);
  // Remove the key from the encrypted string
  const encryptedHex = encryptedHexWithKey.substring(8);

  // Convert the key to a WordArray
  const keyWordArray = CryptoJS.enc.Hex.parse(key);

  // Decrypt the message
  const decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Hex.parse(encryptedHex),
    },
    keyWordArray,
    {
      iv: CryptoJS.enc.Hex.parse('e5f6a7b8'), // Use the same IV for decryption
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
  return decryptedString;
}
useEffect(() => {
  fetch(`${process.env.REACT_APP_API_URL}/getAllFields/${project}`,
  {
    method: 'GET',
    withCredentials: 'true',
    headers:
    {
      'Authorization':`Bearer ${token}`,
    },
  })
  .then(res=>res.json())
  .then(data=>
    { console.log(data.data)
      setAllFieldsData(data.data)
})
},[project])
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/textareas/${project}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })
      .then(res=>res.json())
      .then(data=>
        {
          if(data.statusCode===400)
      {
       
        showAlert({
          title:  'Logged Out',
          message: 'You are logged out due to session timeout.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      }); 
        navigate('/v1/jim_admin_user_login')
      }
        setTextarea(data.data)
    })
        .catch(err=>console.log(err))
    }, [project])
    console.log(textarea)
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/calender/${project}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      }
      )
      .then(res=>res.json())
      .then(data=>
        {
        setCalender(data.data)
        if(data.statusCode===400)
      {
       
      //alert("User Logged Out")
        navigate('/v1/jim_admin_user_login')
      }
    })
        .catch(err=>console.log(err))
    }, [project])
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/radio/${project}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      }
      )
      .then(res=>res.json())
      .then(data=>
        {
        setradio(data.data)
        if(data.statusCode===400)
      {
       
      //alert("User Logged Out")
        navigate('/v1/jim_admin_user_login')
      }
    })
        .catch(err=>console.log(err))
    }, [project])
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/dropdown/${project}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      }
      )
      .then(res=>res.json())
      .then(data=>
        {
        setDropdownFields(data.data)
        if(data.statusCode===400)
      {
       
      //alert("User Logged Out")
        navigate('/v1/jim_admin_user_login')
      }
    })
        .catch(err=>console.log(err))
    }, [project])
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/options/${project}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      }
      )
      .then(res=>res.json())
      .then(data=>
        {
        setRadioOptions(data.data)
        
 if(data.statusCode===400)
 {
  
 //alert("User Logged Out")
   navigate('/v1/jim_admin_user_login')
 }
    })
        .catch(err=>console.log(err))
    }, [project])
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/number/${project}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      }
      )
      .then(res=>res.json())
      .then(data=>
        {
        setNumbers(data.data)
        if(data.statusCode===400)
        {
         
        //alert("User Logged Out")
          navigate('/v1/jim_admin_user_login')
        }
        
      })
      .catch((err) => console.log(err));
  }, [project]);
  console.log(textarea);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/calender/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
        .catch(err=>console.log(err))
    }, [project])
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/getvalue/${project}/${m}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      }
      )
      .then(res=>res.json())
      .then(data=>
        {
          
 if(data.statusCode===400)
 {
  
 //alert("User Logged Out")
   navigate('/v1/jim_admin_user_login')
 }
        setFieldvalues(data.data);
        data.data.forEach((value)=>
        {
          console.log(value);
          setValue(value);
        });
      })
      .catch((err) => console.log(err));
  }, [project]);


   
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/supportadmin/${project}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      }
      )
      .then(res=>res.json())
      .then(data=>
        {
        setAdm(data.data)
        if(data.statusCode===400)
      {
       
      //alert("User Logged Out")
        navigate('/v1/jim_admin_user_login')
      }
      })
        .catch(err=>console.log(err))
    }, [project])
   
 
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/comments/${project}/${m}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      }
      )
      .then(res=>res.json())
      .then(data=>{
        if(data.statusCode===400)
        {
         
        //alert("User Logged Out")
          navigate('/v1/jim_admin_user_login')
        }
        else{
        if(data.data.cc!==null)
        {
          console.log("varen")
          const split=data.data.cc.split(';')
          console.log("split"+split)
         
          for(var i in split)
          {if(split[i]!==''){
            ccArray.push({value:split[i],label:split[i]})
          }
            
          }
          setCcList(data.data.cc)
          //setSelectedOption(ccArray)
        }}
      })
      .catch((err) => console.log(err));
  }, [flag,project]);

  const [chat, setChat] = useState([]);
 
  const [cList, setCcList] = useState();
  const [ccArray, setCcArray] = useState([]);
  const [priority, setPriority] = useState([]);
  const [severity, setSeverity] = useState([]);
  const [admin, setAdmin] = useState("Select Support Staff");




  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_API_URL}/comments/${project}/${m}`, {
  //     method: "GET",
  //     withCredentials: "true",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       if (data.statusCode === 400) {
  //         //alert("User Logged Out")
  //         navigate("/v1/jim_admin_user_login");
  //       } else {
  //         if (data.data.cc !== null) {
  //           console.log("varen");
  //           const split = data.data.cc.split(",");
  //           console.log("split" + split);

  //           for (var i in split) {
  //             if(split[i]!=''){
  //               ccArray.push({ value: split[i], label: split[i] });
  //               console.log(ccArray);
  //             }
       
  //           }
  //           setCcList(data.data.cc);
  //           //setSelectedOption(ccArray)
  //         }
  //       }
  //     });
  // }, []);
  console.log("+++++++++++++++>",temp1)
  const getElement=(name)=>
    {
      var element=""
      if(name.fieldtype==="textarea" || name.fieldtype==="calender" || name.fieldtype==="dropdown"  || name.fieldtype==="HeadGroup" )
        {
        element=<div data-test-id="request-field-summary">
          <div className="sc-gGCbJM foiuyx">
            <label
              htmlFor="summary"
              className="css-1qzu3fk"
            >
              {`${name.fieldname}`}
              {console.log(value[name.fieldname.split(" ").join("")])}
            </label>
          </div>
          <div
            data-ds--text-field--container="true"
            className="css-1o07z2d"
          >
            <input
              id="summary"
              readOnly
              required
              value={value[name.fieldname.split(" ").join("")]}
              name="summary"
              type="text"
              data-ds--text-field--input="true"
              className="css-mfjdp3"
            />
            <br />
          </div>
        </div>
      }
      else if(name.fieldtype==="number")
        {
        element=<>
          <div data-test-id="request-field-summary">
            <div className="sc-gGCbJM foiuyx">
              <label
                htmlFor="mobnumber"
                className="css-1qzu3fk"
              >
                {name.fieldname}
              </label>
            </div>
            <div
              data-ds--text-field--container="true"
              className="css-1o07z2d"
            >
              <input
                type="tel"
                readOnly
                id="phoneNumber"
                name="phoneNumber"
                pattern="[0-9]{10}"
                placeholder="Number"
                className="css-mfjdp3"
                value={value[name.fieldname.split(" ").join("")]}
              />
              <br />
            </div>
          </div>
        </>
      }
      else if(name.fieldtype==="radio")
        {
        element=<>
          <div data-test-id="request-field-summary">
            <div className="sc-gGCbJM foiuyx">
              <label
                htmlFor="mobnumber"
                className="css-1qzu3fk"
              >
                {name.fieldname}
              </label>
            </div>

            {radiooptions
              .filter((j) => j.field === name.fieldname)
              .map((element) => (
                <>
                  <input
                    type="radio"
                    readOnly
                    id={element.FieldName}
                    name={name.fieldname}
                    value={value[name.fieldname.split(" ").join("")]}
                    disabled
                    checked={
                      value[name.fieldname.split(" ").join("")] ===
                      element.FieldName
                    }
                  />
                  <label for={`${element.FieldName}`}>
                    {element.FieldName}
                  </label>
                  &nbsp;
                </>
              ))}
          </div>
        </>
      }
      return element
    }
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/comments/${project}/${m}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.data.statusCode === 400) {
          //alert("User Logged Out")
          navigate("/v1/jim_admin_user_login");
        }
        console.log("###################",data.data)
        
        setdata(data.data);
        setStatus(data.data.status)
        setAdmin(data.data.admin);
        setTemp1(data.data.admin);
        setNumber(data.data.mobile);
        setName(data.data.mail);
        setSeverity({ value: data.data.severity.label, label: data.data.severity });
        setPriority({ value: data.data.priority, label: data.data.priority });
        setLocation(data.data.location);
        if (data.data.tags!== null) {
          const split = data.data.tags.split(",");
          setTags(split);
        }
        const fileName=data.data.file
        const extension=fileName.substring(fileName.lastIndexOf("."))
        const fileWithoutExtensionId=fileName.substring(9,fileName.lastIndexOf("."))
        console.log(fileWithoutExtensionId)
        const fileId=fileName.substring(0,9)
        console.log(fileId)
        console.log(extension)
        const decryptedFile=decryptMessage(fileWithoutExtensionId)
        console.log(decryptedFile)
        setFile(fileId+decryptedFile+extension)
      })
      .catch((err) => console.log(err));
  }, [flag,project,admin]);

  console.log(data)
  console.log(severity,"shwetsupportStaffseveritity");
  console.log(priority);
  // console.log(selectedOption)
  console.log("sri" + temp1);
  const [selectedOption, setSelectedOption] = useState(ccArray);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/chat/${project}/${m}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 400) {
          //alert("User Logged Out")
            navigate('/v1/jim_admin_user_login')
          }
          if(privateChats)
          {
        setChat(data.data.filter(j=>j.privateChat===1))
          }
          else{
            setChat(data.data)
          }
    })
        .catch(err=>console.log(err))
      
      }, [privateChats,flag,project,selectedFile])
    // useEffect(() => {

    //   fetch(`${process.env.REACT_APP_API_URL}/adminEmail/${project}/${admin}`,
    //   {
    //     method: 'GET',
    //     withCredentials: 'true',
    //     headers:
    //     {
    //       'Authorization':`Bearer ${token}`,
    //     },
    //   })
    //   .then(res=>res.json())
    //   .then(data=>
    //     {setFrom(data.data);
    //       console.log(data.data)
    //     setTempEmail(data.data)})
    //     .catch(err=>console.log(err))
    // }, [project,admin])
console.log(tempEmail)
    
    console.log(fieldvalues)
    console.log(value)
    
    /*useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/download/${project}/${data.file}`)
      .then((response) => response.blob())
      .then((blob) => {
        setFileData(blob);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log(fieldvalues);
  console.log(value);

  const [fileData, setFileData] = useState([]);
  /*useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/download/${project}/${data.file}`)
    .then((response) => response.blob())
    .then((blob) => {
      setFileData(blob);
    })
      .catch((error) => {
        console.error('Error fetching data:', error);
  })}, [])*/
  //var [opt,setOption]=useState('');
  //var [summary,setSummary]=useState('');
  const [descript, setDescript] = useState("");
  const navigate = useNavigate();
  const [supportStaff,setSupportStaff] = useState(0);
  const [comment, setComment] = useState([]);
  const [review, setReview] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/jios/${project}/${m}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 400) {
          //alert("User Logged Out")
          navigate("/v1/jim_admin_user_login");
        }
        console.log(data);
        setComment(data.data[0].comment);
        setReview(data.data[0].review);
      })
      .catch((err) => console.log(err));
    console.log(ref.current);
  }, [project]);
  // useEffect(()=>
  //   {
  //     updateSupportAdminData()
  //   },[flag])
  useEffect(() => {
    console.log(token);
    if(localStorage.getItem("tokenauth")==="false")
    //if (cookie.get("tokenauth") === "false")
   {
      window.location.href = "/v1/jim_admin_user_login";
    }
  }, []);
  const [div, setDiv] = useState("jhHSQy");
  const [styles, setStyles] = useState("puqikf");
  const [show, setShow] = useState("Show");
  const [color1, setColor1] = useState("#ccc");
  const [color2, setColor2] = useState("#ccc");
  const [color3, setColor3] = useState("#ccc");
  const [color4, setColor4] = useState("#ccc");
  const [color5, setColor5] = useState("#ccc");
  //for(var i=0;i<=review;i++)
  //{
  //console.log()
  //}

  const cl1 = () => {
    setColor1("#ffc700");
  };
  const cl2 = () => {
    cl1();
    setColor2("#ffc700");
  };
  const cl3 = () => {
    cl1();
    cl2();
    setColor3("#ffc700");
  };
  const cl4 = () => {
    cl1();
    cl2();
    cl3();
    setColor4("#ffc700");
  };
  const cl5 = () => {
    cl1();
    cl2();
    cl3();
    cl4();
    setColor5("#ffc700");
  };
  const [c, d] = useState(0);
  const [e, f] = useState(0);
  const view = () => {
    if (c === 0) {
      d(1);
      setDiv("cqxAqq");
      setShow("Hide");
      setStyles("4oxim9");
      if (review === 1) {
        cl1();
      } else if (review === 2) {
        cl2();
      } else if (review === 3) {
        cl3();
      } else if (review === 4) {
        cl4();
      } else if (review === 5) {
        cl5();
      }
    } else {
      d(0);
      setShow("Show");
      setDiv("jhHSQy");
      setStyles("puqikf");
    }
  };
  const handleChanges = (option) => {
    const token = localStorage.getItem("adminToken");
    setSelectedOption(option);
    console.log(option);
    const ccList = [];
    for (var i in option) {
      ccList.push(option[i].value);
    }
    let cc = null;
    if(ccList.length===0)
    {
      setCcList(null)
    }
    else{
      cc = ccList.join(";");
      setCcList(cc);
    }
    console.log(cc)
    const res = {
      method: "PUT",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cc: cc }),
    };
    fetch(`${process.env.REACT_APP_API_URL}/addcc/${project}/${m}`, res).then(
      () => {
        console.log("added");
      }
    );
  };

 
  const reject = async (rej) => {
    const token = localStorage.getItem("adminToken");
    //rej.preventDefault();
    const user = nams;
    const status = "Rejected";
    const id = data.id;
    const opt = data.opt;
    const summary = data.summary;
    const description = data.description;
    const obj = { status };
    console.log(obj);
    const res = {
      method: "PUT",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    await fetch(`${process.env.REACT_APP_API_URL}/chat/${project}/${m}`, res).then(
      () => {
        console.log("added");
      }
    );
    fetch(
      `${process.env.REACT_APP_API_URL}/rejected`,
      {
        method: "POST",
        withCredentials: "true",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mail: data.mail,
          name: data.user,
          id: id,
          supportName: nams,
          cc: cList,
        }),
      }
    )
      .then(console.log("email send"))
      .catch((err) => console.log(err));

    try {
      //navigate("/v1/jim/detail");
      //window.location.reload(false); // Omit optional second argument
      setFlag(!flag)
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
   
  };
  const resolve = async (close) => {
    const token = localStorage.getItem("adminToken");
    //close.preventDefault();
    const user = nams;
    const status = "Resolved";
    const id = data.id;
    const opt = data.opt;
    const summary = data.summary;
    const description = data.description;
    const obj = { status };
    console.log(obj);
    const res = {
      method: "PUT",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API_URL}/chat/${project}/${m}`, res).then(
      () => {
        console.log("added");
      }
    );
   

    
    console.log(obj);
   
    /*emailjs.sendForm('service_o2a36hr', 'template_5k9vkpd', feed.current, 'HNkWk6BSnhwAA3yID')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });*/
     fetch(
      `${process.env.REACT_APP_API_URL}/resolved`,
      {
        method: "POST",
        withCredentials: "true",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mail: data.mail,
          name: data.user,
          id: id,
          supportName: nams,
          cc: cList,
        }),
      }
    )
      .then(console.log("email send"))
      .catch((err) => console.log(err));

    try {
     // navigate("/v1/jim/detail");
     // window.location.reload(false); // Omit optional second argument
     setFlag(!flag)
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  };

 
  const click = async () => {
    //c.preventDefault()
    const token = localStorage.getItem("adminToken");
    const plainString = descript.replace(/<[^>]+>/g, '');
    //const noSpace=/^[^-\s][a-zA-Z0-9_\s-]+$/
    const noSpace = plainString.split('')
    console.log(plainString,"descript")
    if (plainString === "") {
      showAlert({
        title:  'Required',
        message: 'Please fill out this field.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    }); 
    }
    else if(noSpace[0]==" " && noSpace[0]!="")
    {
      showAlert({
        title:  'Invalid Input',
        message: 'Please enter text first.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    });
    } 
     else {
      const htmlFlag=hasHtmlElements(descript)
      if(htmlFlag===true)
        {
          showAlert({
            title:  'Input Error',
            message: 'Special characters are not allowed.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        }); 
        }
        else
        {
      // c.preventDefault();
      const user = nams;
      const status = data.status;
      const opt = data.opt;
      const summary = data.summary;
      const description = descript;
      const privateChat=checked
      const obj = { user,privateChat, opt, summary, description, status };
      console.log(obj);
      const res = {
        method: "PUT",
        withCredentials: "true",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      };
      await fetch(
        `${process.env.REACT_APP_API_URL}/chats/${project}/${data.id}`,
        res
      ).then(async () => {
        console.log("added");
        const formData = new FormData();
        console.log('selected files===>',selectedFile)
        for(let i=0;i<selectedFile.length;i++)
        {
          console.log(selectedFile[i])
        formData.append("file",selectedFile[i]);
        }
        // for (var key of formData.entries()) {
        //   console.log(key[0] + ', ' + key[1])
        // }
        //formData.append("file",selectedFile);
        console.log('token===>', token)
        console.log(formData)
        const contentType = `multipart/form-data`;
        const result =await fetch(`${process.env.REACT_APP_API_URL}/uploadchatfile/${project}`,{
          method: 'Post',
          withCredentials: 'true',
          headers:
          {
            'Authorization':`Bearer ${token}`,
            //'Content-Type': contentType
          },
          body: formData,
      })

      setSelectedFile([])
       fileInputRef.current.value = ''; 
        setFlag(!flag)
      });
    
      try {
        //navigate("/v1/jim/detail");
        setDescript("")
        setUploadErr(false)
        setFileUploadErrorMessage("")
        //window.location.reload(false); // Omit optional second argument
      } catch (error) {
        navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
      }
    }
  }
  };
  console.log(admin);

  const form = useRef();



  const checkAutoAssignSupport = async () =>{
    try {
      const decodedToken = jwtDecode(token);
 
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getAutoAssignSupport/${decodedToken.project || project}`, {
        method: "POST",
        withCredentials: "true",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const data = await response.json();
      console.log("+++++++++>>>>", data);
      
      // Check for statusCode and handle accordingly
      if (data.statusCode === 200) {
        setSupportGroupFlag(true)
        console.log("Here is data in L1 details",JSON.parse(data?.data?.L1 || "[]"))
        const L1 = JSON.parse(data?.data?.L1 || "[]")
        const L2 = JSON.parse(data?.data?.L2 || "[]")
        const L3 = JSON.parse(data?.data?.L3 || "[]")
        setDataL1(L1[0]?.name)
        setDataL2(L2[0]?.name)
        setDataL3(L3[0]?.name)
        return data.data
      }
      console.log("+++++++++++",data.data)
      if(data.statusCode == 401){
        setSupportGroupFlag(false)
      }
  
      console.log("************123", data.data);
      
      return data.data; // Return the data
    } catch (err) {
      //setSupportGroupFlag(true)
      console.log(err);
      return null; // or handle the error as needed
    }
  }

  useEffect(() =>{
    checkAutoAssignSupport()
  },[])

 


 
  const updateSupportAdminData=async (admin) =>{
    const result = await checkAutoAssignSupport()
    console.log("here is result",result)
    const L1 = JSON.parse(result?.L1 || "[]") 
    const L2 = JSON.parse(result?.L2 || "[]") 
    const L3 = JSON.parse(result?.L3 || "[]") 
    const L1Details = L1[0]
    const L2Details = L2[0]
    const L3Details = L3[0]
    console.log("here is details123",L1Details)
    let newAdmin=admin
    if(admin == "L1"){
      newAdmin= L1Details?.name
      console.log("L1 name", L1Details?.name)
      setAdmin(L1Details?.name)
    }else if(admin == "L2"){
      newAdmin= L2Details?.name
      setAdmin(L2Details?.name)
    }else if(admin == "L3"){
      newAdmin= L3Details?.name
      setAdmin(L3Details?.name)
    }
    else{
      newAdmin= admin
      setAdmin(admin)
    }
    console.log("here is admin",newAdmin)
    //setAdmin(admin)
    showAlert({
      title:  'Ticket Update',
      message: `Ticket assigned to ${admin}`,
      okText: 'OK',
      callback: () => {
          console.log('Alert closed');
          setAlertProps(null)
      }
  });  
    setFlag(!flag)
    const token = localStorage.getItem("adminToken");
    
    console.log(`${process.env.REACT_APP_API_URL}/adminEmail/${project}/${newAdmin}`)
      const adminEmail=await fetch(`${process.env.REACT_APP_API_URL}/adminEmail/${project}/${newAdmin}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })
      .then(res=>res.json())
      .then(data=>
    data.data[0].email 
  )
  .catch(err=>console.log(err))
  console.log(admin)
    const obj = { admin };
    console.log(obj);
    const res = {
      method: "PUT",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    console.log(nams);
    console.log(adminEmail)
    fetch(
      `${process.env.REACT_APP_API_URL}/admin/${project}/${data.id}`,
      res
    ).then(() => {
      console.log("added");
    });
    console.log(form.current);
    /*emailjs.sendForm('service_o2a36hr', 'template_v1yi8td', form.current, 'HNkWk6BSnhwAA3yID')
.then((result) => {
    console.log(result.text);
}, (error) => {
    console.log(error.text);
});*/
    if (!temp1) {
      fetch(
        `${process.env.REACT_APP_API_URL}/assigned`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization':`Bearer ${token}`
          },
          body: JSON.stringify({
            mail: adminEmail,
            name: admin,
            id: data.id
          })})
        .then(console.log("email send"))
        .catch((err) => console.log(err));
    } else {
      let temp = temp1
      if(temp == "L1"){
        temp= L1Details?.name
        //setAdmin(L1Details.name)
      }else if(temp == "L2"){
        temp= L2Details?.name
        //setAdmin(L2Details.name)
      }else if(temp == "L3"){
        temp= L3Details?.name
        //setAdmin(L3Details.name)
      }
      else{
        temp= temp
        //setAdmin(admin)
      }
      console.log("******************",temp);
      fetch(`${process.env.REACT_APP_API_URL}/adminEmail/${project}/${temp}`,
      {
        method: "GET",
        withCredentials: "true",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      )
        .then((res) => res.json())
        .then((datas) => {
          console.log(datas.data.email);
          setTempEmail(datas.data.email);
          fetch(
            `${process.env.REACT_APP_API_URL}/reassigned`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                'Authorization':`Bearer ${token}`
              },
              body: JSON.stringify({
                mail: adminEmail,
                name: admin,
                id: data.id,
                cc: datas.data[0].email
              })})
            .then(console.log("email send"))
            .catch((err) => console.log(err));
          fetch(
            `${process.env.REACT_APP_API_URL}/supportchange`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                'Authorization':`Bearer ${token}`
              },
              body: JSON.stringify({
                mail: datas.data[0].email,
                name: temp,
                id: data.id
              })})
            .then(console.log("email send"))
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }
    // fetch(
    //   `${process.env.REACT_APP_API_URL}/staffassigned`,
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       mail: name,
    //       name: nam,
    //       id: data.id, 
    //       staff: admin
    //     })})
    //   .then(console.log("email send"))
    //   .catch((err) => console.log(err));
    
    try {
      navigate("/v1/jim/detail");
      //window.location.reload(false);
      // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }

    //setAdmin("");

  }
 
//   const assigned = (c) => {
//     const token = localStorage.getItem("adminToken");
//     c.preventDefault();
//     const obj = { admin };
//     console.log(obj);
//     const res = {
//       method: "PUT",
//       withCredentials: "true",
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(obj),
//     };
//     console.log(nams);
//     fetch(
//       `${process.env.REACT_APP_API_URL}/admin/${project}/${data.id}`,
//       res
//     ).then(() => {
//       console.log("added");
//     });
//     console.log(form.current);
//     /*emailjs.sendForm('service_o2a36hr', 'template_v1yi8td', form.current, 'HNkWk6BSnhwAA3yID')
// .then((result) => {
//     console.log(result.text);
// }, (error) => {
//     console.log(error.text);
// });*/
//     if (cancellation === 0) {
//       fetch(
//         `${process.env.REACT_APP_API_URL}/assigned/${email}/${admin}/${data.id}`)
//         .then(console.log("email send"))
//         .catch((err) => console.log(err));
//     } else {
//       console.log(temp);
//       fetch(`${process.env.REACT_APP_API_URL}/adminEmail/${project}/${temp}`,
//       {
//         method: "GET",
//         withCredentials: "true",
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//       )
//         .then((res) => res.json())
//         .then((datas) => {
//           console.log(datas.data.email);
//           setTempEmail(datas.data.email);
//           fetch(
//             `${process.env.REACT_APP_API_URL}/reassigned/${email}/${admin}/${data.id}/${datas.data[0].email}`
//           )
//             .then(console.log("email send"))
//             .catch((err) => console.log(err));
//           fetch(
//             `${process.env.REACT_APP_API_URL}/supportchange/${datas.data[0].email}/${temp}/${data.id}`
//           )
//             .then(console.log("email send"))
//             .catch((err) => console.log(err));
//         })
//         .catch((err) => console.log(err));
//     }
//     fetch(
//       `${process.env.REACT_APP_API_URL}/staffassigned/${name}/${nam}/${data.id}/${admin}`
//     )
//       .then(console.log("email send"))
//       .catch((err) => console.log(err));
    
//     try {
//       navigate("/v1/jim/detail");
//       //window.location.reload(false);
//       // Omit optional second argument
//     } catch (error) {
//       navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
//     }

//     //setAdmin("");
//   };
  const downloadFile = () => {
    fetch(`${process.env.REACT_APP_API_URL}/download/${project}/${file}`, {
      method: "GET",
      headers: {
       
        'Authorization':`Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        if (blob) {
          console.log(blob)
          const url = window.URL.createObjectURL(blob);
          console.log("Here is type",blob.type)
          console.log('Video URL:', url);
          if(blob.type == "video/mp4"){
         let videoSrc = url
          console.log("here is path",videoSrc)
            setVideoSrc(videoSrc)
            setVideoTitle('Video : '+file.substring(9))
            setVideoLink(videoSrc)
            setShowVideo(true)
            window.document.close()
          }else{
          window.open(url, '_blank');
          const a= document.createElement("a");
          a.href = url;
          a.download = file.substring(9); // Specify the desired file name and extension
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const [div1, setDiv1] = useState("jhHSQz");
  const [styles1, setStyles1] = useState("puqikv");
  const [show1, setShow1] = useState("Show");
  const userdetails = () => {
    if (e === 0) {
      f(1);
      setDiv1("cqxAqq");
      setShow1("Hide");
      setStyles1("4oxin9");
    } else {
      f(0);
      setShow1("Show");
      setDiv1("jhHSQz");
      setStyles1("puqikv");
    }
  };

  if (data.status === "Closed") {
    const current = new Date();
    sessionStorage.setItem(
      m + "close",
      `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
    );
  }

  console.log(value);
  const [x, z] = useState(0);
  const down = () => {
    if (x === 0) {
      z(1);
    } else {
      z(0);
    }
  };
  const requestRef = useRef(null);

  useEffect(() => {
    // Add supportStaffclick event listener to the document to detect clicks outside the dropdown
    function handleClickOutside(event) {
      if (requestRef.current && !requestRef.current.contains(event.target)) {
        d(0);
      }
    }

    // Attach the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  // const dropdownRef = useRef(null);

  console.log(data.admin);

  const handleChange = (newTags) => {
    const token = localStorage.getItem("adminToken");
    console.log(newTags);
    if (tags.length < newTags.length) {
      console.log("yes");
      if (newTags.length <= 11) {
        const tofindDuplicates = (arry) => {
          return arry.filter((item, index) => arry.indexOf(item) !== index);
        };
        const duplicates = tofindDuplicates(newTags);
        const newTag = newTags[newTags.length - 1];
        console.log(duplicates);
        console.log(tags);
        const tag = tags.map((element) => {
          return element.toLowerCase();
        });
        if (duplicates.length === 0) {
          if (!tag.includes(newTag.toLowerCase())) {
            setTags(newTags);
            console.log(newTags);
            console.log(tags);
            const tagString = newTags.join(",");
            const res = {
              method: "PUT",
              withCredentials: "true",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ tags: tagString }),
            };
            fetch(
              `${process.env.REACT_APP_API_URL}/addtags/${project}/${m}`,
              res
            ).then(() => {
              console.log("added");
            });
          } else {
            showAlert({
              title:  'Duplicate Tag',
              message: 'This tag is already exists.',
              okText: 'OK',
              callback: () => {
                  console.log('Alert closed');
                  setAlertProps(null)
              }
          }); 
          }
        } else {
          if (!tag.includes(duplicates[0].toLowerCase())) {
            setTags(newTags);
            console.log(newTags);
            console.log(tags);
            const tagString = newTags.join(",");
            const res = {
              method: "PUT",
              withCredentials: "true",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ tags: tagString }),
            };
            fetch(
              `${process.env.REACT_APP_API_URL}/addtags/${project}/${m}`,
              res
            ).then(() => {
              console.log("added");
            });
          } else {
            showAlert({
              title:  'Duplicate Tag',
              message: 'This tag is already exists.',
              okText: 'OK',
              callback: () => {
                  console.log('Alert closed');
                  setAlertProps(null)
              }
          }); 
          }
        }
      } else {
        showAlert({
          title:  'Maximum limit ',
          message: 'You reach up to 10 tags.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });  
      }
    } else {
      if (newTags.length <= 10) {
        const newTag = tags.filter((element) => !newTags.includes(element));
        console.log(newTag);
        console.log(tags);
        const tag = tags.map((element) => {
          return element.toLowerCase();
        });
        if (newTag[0].toLowerCase()) {
          setTags(newTags);
          console.log(newTags);
          console.log(tags);
          const tagString = newTags.join(",");
          const res = {
            method: "PUT",
            withCredentials: "true",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ tags: tagString }),
          };
          fetch(
            `${process.env.REACT_APP_API_URL}/addtags/${project}/${m}`,
            res
          ).then(() => {
            console.log("added");
          });
        }
      }
    }
  };

  const [emailOptions, setEmailOptions] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/adminfindall/${project}`, {
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
        for(var i in data.data)
        {
        emailOptions.push({value:data.data[i].email,label:data.data[i].email})
        }
      })
      .catch((err) => console.log(err));
  }, [project]);
  // Sample email suggestions
  console.log(emailOptions);
  console.log(selectedOption);

  const customStyles = {
    width:"400px",
    option: (provided, state) => ({
      ...provided,
      width: "600px",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "blue" : "white",

    }),
 
    multiValue: (styles) => {

      return {
       
        ...styles,
        width: "200px",
        backgroundColor: "lightblue",

      };
    },
    multiValueLabel: (styles) => ({
     
      ...styles,
      width: "200px",
      color: "black",

    }),
    multiValueRemove: (styles) => ({
   
      ...styles,
      // width: "200px",
      color: "red",
      ":hover": {
        backgroundColor: "red",
        color: "white",

      },
    }),
  };
  
  const getOptionStyle = (index, colour) => {
    const color = colour;
    return {
      backgroundColor: color,
      color: "black", // You can change text color to white or any other color for better contrast
      borderRadius: "25px",
      marginTop: "5px",
      marginBottom: "5px",
      overflowX: "hidden", // Adjust the border-radius as needed
 
    };
  };

  const customStyle = {
    option: (provided, state) => ({
      ...provided,
      ...getOptionStyle(state.data.index, state.data.color),
      cursor: "pointer",
      width: "auto",
      fontSize: "14px",
      fontWeight: "20px",
      transition: "background-color 0.3s ease, color 0.3s ease",
      



      '&:hover':{
        backgroundColor:"lightgray",
        color:"black",
        fontWeight:'500'
      },
      //marginLeft:'10%',
      //marginRight:'10%'
      
    }),

    menu: (provided, state) => ({
      ...provided,
      zIndex:1000,
      backgroundColor: "#F5F5F5",
      borderRadius: "10%",
      // width: "100px",
      

    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: severity.color,
      //borderRadius: "50px",
      
    }),
    singleValue: (provided, state) => ({
      ...provided,
      ...getOptionStyle(state.data.index),

      
    }),
  };

  const priorityStyle = {
    option: (provided, state) => ({
      ...provided,
      ...getOptionStyle(state.data.index, state.data.color),
      cursor: "pointer",
      width: "100%",
      fontSize: "14px",
      overflowX: "hidden",
      width: "100%", // Adjust the width as per your requirement
      display: "inline-block", 
      transition: "background-color 0.3s ease, color 0.3s ease",

      "&:hover": {
        backgroundColor: "lightgray", // Change background color on hover
        color: "black", 
        fontWeight:'500'
        // Change text color on hover
      },
      //marginLeft:'10%',
      //marginRight:'10%'
      // overflow:'visible',

    }),
    menu: (provided, state) => ({
      ...provided,

      backgroundColor: "#F5F5F5",
      borderRadius: "10%",
      
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: priority.color,
      // borderRadius: "50px",

    }),
    singleValue: (provided, state) => ({
      ...provided,
      ...getOptionStyle(state.data.index),    
        // width: "110px",

    }),
  };
  
  const priorityChange = (option) => {
    const token = localStorage.getItem("adminToken");
    setPriority({value:option.value,label:option.value,color:option.color});

    const res = {
      method: "PUT",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ priority: option.value }),
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/addpriority/${project}/${m}`,
      res
    ).then(() => {
      console.log("added");
    });
  };
  const handleButtonClick = () => {
    setModalVisible(true);
    console.log("share button clicked");
  };

  const handleModalInputChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setUserEmail("")
  };

  const handleModalAddClick = async () => {
    const descriptionValue = data.description;
    const statusValue = data.status;
    const ticketRaiseDate = data.date;
    const ticketRaiseTime = data.time;
    const ticketId = data.id;
    const severityDetails = data.severity;
    const priorityDetails = data.priority;
    const senderName = nams;
    try {
      if(userEmail!=="")
        {
      // POST request to your server to send the email
      const response=await fetch(`${process.env.REACT_APP_API_URL}/send-email`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization':`Bearer ${token}`
        },
        body: JSON.stringify(
          {
            to: userEmail,
            senderName,
            subject: "Ticket Information",
            description: descriptionValue,
            status: statusValue,
            chat,
            ticketRaiseDate,
            ticketRaiseTime,
            ticketId,
            severityDetails,
            priorityDetails,
          }
        )})
      if(response.status===500)
        {
          setModalVisible(false);
          showAlert({
            title:  'Email Sending Error',
            message: 'An error occurred while attempting to send an email, Please try again.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        });  
        }
        else{
          setModalVisible(false);
          showAlert({
            title:  'Email Sent Successfully',
            message: 'Your email has been sent successfully.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        }); 
        }
      }
      else{
        showAlert({
          title:  'Enter email address',
          message: 'Please enter email address.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });
      }
  
      // After handling the functionality, close the modal
      
    } catch (error) {
      console.error(error);
      showAlert({
        title:  'Email Sending Error',
        message: 'An error occurred while attempting to send an email, Please try again.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    });
    }
  };

  const severityChange = (option) => {
    const token = localStorage.getItem("adminToken");

    setSeverity({value:option.label,label:option.value,color:option.color}); // Set the value of the selected option
    console.log("**********************",option.value);
    const res = {
        method: "PUT",
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ severity: option.value }),
    };
    fetch(`${process.env.REACT_APP_API_URL}/addseverity/${project}/${m}`, res)
        .then(() => {
            console.log("added");
        });
};

  const statusChangeOnclick = () => {
    console.log("sjjk",data)
    if(data.status==="Rejected" || data.status==="Resolved" ||data.status==="Closed"  )
     {
      setShowDropdown(false)
    }
    else{
    setShowDropdown(!showDropdown);
   }
    console.log("shwetsupportStaffbutton is clicked");
  };

  const handleStatusSelect = (selectedOption) => {
    // Handle the selected option as needed
    setStatus(selectedOption)
    console.log("Selected option:", selectedOption);
    if(selectedOption==="Resolve")
    {
      resolve()
    }
    else{
      reject()
    }
    setShowDropdown(false);
  };

  const toggleItem = () => {
    setShowDesc(!showDesc);
  };

  console.log("#################", isModalVisible);
  const date = new Date(data.date).toLocaleDateString('en-In', {timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit'});
  const parts = date.split('/');
  let formattedDateAbove = `${parts[0]}-${parts[1]}-${parts[2]}`;

  useEffect(() =>{
    console.log("here is data of satus",data.status) 
    if(data.status == 'Closed' || data.status == 'Rejected' || data.status == 'Resolved'){
      console.log("here is data of satus",data.status) 
      setApplyClass("sc-koErNt SBKnj disabled-div123")
    }
    else{
      setApplyClass("sc-koErNt SBKnj")
    }
  },[data.status])

  // const handleFileChange = (event) => {
  //   console.log('file ====>',event.target.files)
  //   const file = event.target.files;
  //   console.log("here is file bro",file.name)
  //   setSelectedFile(file);
  //   setFileName(file.name)
  //   // Here you can also process the file further, e.g., upload it to a server
  // };

  const handleFileChange = async (event) => {
    setUploadErr(false)
    console.log('file ====>', event.target.files);
    const files = event.target.files; 
    if(files.length<=5)
      {
    const allowedMimeTypes= [('.jpg','image/jpeg'), ('.jpeg','image/jpeg'),
        ('.png','image/png'), 
        ('.pdf','application/pdf'), 
         ('.zip','application/x-zip-compressed'),
         ('.zip','application/zip'),  
        ('.mp4','video/mp4')];
    //
    console.log(Array.from(files))
    const validFiles =  Array.from(files).map(async file => {
      if(file.size<=5*1024*1024)
        {
          const buffer = file.arrayBuffer()
          const type=await fileTypeFromBuffer(await buffer)
      if (allowedMimeTypes.includes(file.type) && allowedMimeTypes.includes(type?.mime) && (file.type===type?.mime || file.type==="application/x-zip-compressed")) {
        return file;
      } else {
        showAlert({
          title:  'File Upload Error',
          message: 'The file you upload is invalid, Please check the file format.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });
        return null;
      }
    }
    else
    {
     setUploadErr(true)
     setFileUploadErrorMessage("File size should less than 5MB")
    return null
    }
    });
    const results = await Promise.all(validFiles);
    console.log(results.filter(file => file!==null))
    setSelectedFile(results.filter(file => file!==null));
    setFileName(validFiles.map(file => file.name));
      }
      else
      {
        showAlert({
          title:  'File Limit Reached',
          message: 'You can upload maximum of 5 files at a time.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      }); 
      }
  };

  // const handleDownloadChatAttachment = (el) =>{
  //   console.log("*************",el)
  //   const project = "4646"
  //   fetch(`${process.env.REACT_APP_API_URL}/download/${project}/${el}`, {
  //     method: "GET",
  //     headers: {
  //       // Add any necessary headers here
  //       //'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
  //       'Authorization':`Bearer ${token}`,
  //     },
  //   })
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       setFileData(blob);
  //       if (blob) {
  //         const url = window.URL.createObjectURL(blob);
  //         window.open(url, '_blank');
  //         const a= document.createElement("a");
  //         a.href = url;
  //         a.download = el; // Specify the desired file name and extension
  //         document.body.appendChild(a);
  //         a.click();
  //         window.URL.revokeObjectURL(url);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });










  //   // console.log("here is clicked attachment",e.attachment)
  //   // const url = `/IM_frontend/public/favicon.ico`;
  //   // // Create a temporary hidden anchor element
    
  //   // const a = document.createElement('a');
  //   // a.style.display = 'none';
  //   // a.href = url;
  //   // window.open(url, '_blank');
  //   // console.log("************",url)
  //   // // Set the download attribute to the file name
  //   // a.download = e.attachment;
  //   // // Append the anchor to the body
  //   // document.body.appendChild(a);
  //   // // Click the anchor to trigger the download
  //   // a.click();
  //   // // Remove the anchor from the body
  //   // document.body.removeChild(a);
  // }


  const handleDownloadChatAttachment = (el) => {
    console.log("*************", el);
    const project = "4646";

    fetch(`${process.env.REACT_APP_API_URL}/download/${project}/${el}`, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        if (blob) {
          const url = window.URL.createObjectURL(blob);
          console.log("Here is type",blob.type)
          console.log('Video URL:', url); // Debugging statement
          if(blob.type == "video/mp4"){
          let videoSrc = url
          console.log("here is path",videoSrc)
          setVideoSrc(videoSrc)
            setVideoTitle('Video : '+el)
            setVideoLink(videoSrc)
            setShowVideo(true)
          window.document.close() 
          }else{
            window.open(url, '_blank');
          // Trigger the download
          const a = document.createElement("a");
          a.href = url;
         
          a.download = el; // Specify the desired file name and extension
          document.body.appendChild(a);
          //setVideoSrc(document.body.appendChild(a))
          a.click();
          
          document.body.removeChild(a); // Clean up the anchor element

          // Revoke the Blob URL
          window.URL.revokeObjectURL(url);
        }
      }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };


  useEffect(()=>{

  },[])

 
 
  // const handleSupportGroupChange = async(e) =>{
  //   const result = await checkAutoAssignSupport()
  //   console.log("here is result",result)
  //   const L1 = JSON.parse(result.L1) || []
  //   const L2 = JSON.parse(result.L2) || []
  //   const L3 = JSON.parse(result.L3) || []
  //   const L1Details = L1[0]
  //   const L2Details = L2[0]
  //   const L3Details = L3[0]
  //   console.log("^^^^^^^^",L1Details)
  //   //console.log(e.target.value)
  //   if(e.target.value=='L1'){
  //     if(temp){
  //       const res = {
  //         method: "PUT",
  //         withCredentials: "true",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({admin:e.target.value}),
  //       };
  //        await fetch(
  //       `${process.env.REACT_APP_API_URL}/admin/${project}/${data.id}`,
  //       res
  //     ).then(async () => {
  //       console.log("added");
  //       await fetch(
  //         `${process.env.REACT_APP_API_URL}/reassigned`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             mail: L1Details.email ,
  //             name: L1Details.name,
  //             id: data.id,
  //             cc: `${L2Details?.email || L3Details?.email || tempEmail} `
  //           })})
  //         .then(console.log("email send"))
  //         .catch((err) => console.log(err));
  //     });
  //     }

  //     await fetch(
  //       `${process.env.REACT_APP_API_URL}/supportchange`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ 
  //           mail: tempEmail || L2Details?.email || L3Details?.email ,
  //           name: temp || L2Details?.name || L3Details?.name,
  //           id: data.id
  //         })})
  //       .then(console.log("email send"))
      
  //       alert("Support Group L1 Assigned")
  //       window.location.reload()
  //     console.log(e.target.value)

  //   }else if(e.target.value=='L2'){
  //     const res = {
  //       method: "PUT",
  //       withCredentials: "true",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({admin:e.target.value}),
  //     };
  //     if(temp){
  //       await fetch(
  //      `${process.env.REACT_APP_API_URL}/admin/${project}/${data.id}`,
  //      res
  //    ).then(async () => {
  //      console.log("added");
  //      await fetch(
  //        `${process.env.REACT_APP_API_URL}/reassigned`,
  //        {
  //          method: "POST",
  //          headers: {
  //            "Content-Type": "application/json",
  //          },
  //          body: JSON.stringify({
  //            mail: L2Details.email ,
  //            name: L2Details.name,
  //            id: data.id,
  //            cc: `${L1Details.email || L3Details.email || tempEmail} `
  //          })})
  //        .then(console.log("email send"))
  //        .catch((err) => console.log(err));
  //    });
  //    }

  //    await fetch(
  //      `${process.env.REACT_APP_API_URL}/supportchange`,
  //      {
  //        method: "POST",
  //        headers: {
  //          "Content-Type": "application/json",
  //        },
  //        body: JSON.stringify({ 
  //          mail: tempEmail || L1Details.email || L3Details.email ,
  //          name: temp || L1Details.name || L3Details.name,
  //          id: data.id
  //        })})
  //      .then(console.log("email send"))
  //      alert("Support Group L2 Assigned")
  //      window.location.reload()
  //     console.log(e.target.value)
  //   }else if(e.target.value=='L3'){


  //     if(temp){
  //       const res = {
  //         method: "PUT",
  //         withCredentials: "true",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({admin:e.target.value}),
  //       };
  //       await fetch(
  //      `${process.env.REACT_APP_API_URL}/admin/${project}/${data.id}`,
  //      res
  //    ).then(async () => {
  //      console.log("added");
  //      await fetch(
  //        `${process.env.REACT_APP_API_URL}/reassigned`,
  //        {
  //          method: "POST",
  //          headers: {
  //            "Content-Type": "application/json",
  //          },
  //          body: JSON.stringify({
  //            mail: L3Details.email ,
  //            name: L3Details.name,
  //            id: data.id,
  //            cc: `${L1Details.email || L2Details.email || tempEmail} `
  //          })})
  //        .then(console.log("email send"))
  //        .catch((err) => console.log(err));
  //    });
  //    }

  //    await fetch(
  //      `${process.env.REACT_APP_API_URL}/supportchange`,
  //      {
  //        method: "POST",
  //        headers: {
  //          "Content-Type": "application/json",
  //        },
  //        body: JSON.stringify({ 
  //          mail: tempEmail || L1Details.email || L2Details.email ,
  //          name: temp || L1Details.name || L2Details.name,
  //          id: data.id
  //        })})
  //      .then(console.log("email send"))

      
  //     alert("Support Group L3 Assigned")
  //     window.location.reload()
  //     //console.log(e.target.value)
  //   }else{
  //     alert("please select support group")
  //   }
  // }
  console.log("here is group flag", supportGroupFlag)
  return (
    <div>
        <Headeradminhotel raiseTicket={0} registrationRequest={0} adminRequests={0} backButton={1} v={0} showAlert={showAlert} setAlertProps={setAlertProps}/>
        
      <div className="sc-hORach dWMRNy">
        <div className="sc-eTuwsz mQHAl"></div>
        <main className="sc-hXRMBi bJStMD sc-ibxdXY dKLBoM">
          <div className="sc-hrWEMg cesPGE">
            {/*<h4 style={{textAlign:"center"}}>{sessionStorage.getItem("id")}</h4>*/}
            <header data-test-id="request-details.summary">
              <div className="sc-TFwJa dUxdnV">
                <div>
                  <h1 className="sc-bHwgHz bGtLRv" style={{paddingLeft:"65px",marginLeft:"-36px"}}>{data.summary}</h1>
                </div>
              </div>
            </header>

            {/* <div className="sc-cCVOAp dYzIwj"></div> */}

            <div className="sc-bvTASY dhbBCH">
              <div className="sc-gJqsIT hXhZlB">
                <div className="sc-eEieub klGEsD">
                  <div className="sc-hMrMfs dvFdfp">
                    <div className="sc-fkyLDJ gdaJLx" style={{marginLeft:"-36px"}}>
                      <div className="sc-jUpvKA eGWJLp">
                        <div className="sc-jdfcpN jOaoYz">
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              outline: "0px",
                            }}
                          >
                            <span
                              className="css-1m1hxa9"
                              style={{
                                backgroundColor: "coral",
                                fontWeight: "600",
                                color: "white",
                              }}
                            >
                              <p style={{ marginLeft: "3px" }}>
                                {nam[0]?.toUpperCase() + nam[1]?.toLowerCase()}
                              </p>
                              {/*<img src="https://secure.gravatar.com/avatar/0a8339570e62e5a4601c4e3e9d86fbec?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPB-5.png" alt="" className="css-13ep12v" style={{borderRadius: "50%"}}/>*/}
                            </span>
                          </div>
                          <div className="sc-iCwjlJ yANRH">
                            <div role="presentation">
                              <span>
                                <strong>{data.user}</strong> raised this on{" "}
                                {formattedDateAbove}{" "}
                                {data.time}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/*<button type="button" className="css-puqikf">
    <span className="css-j8fq0c">
    <span className="css-t5emrf">
  <span>Hide details</span></span></span></button>*/}
                      </div>
                      <div className="sc-jRuhRL jAxXjC">
                        <strong><div className="sc-kNBZmU kMDvmM">Description</div></strong>
                        <div className="sc-eopZyb CyzLZ">
                          <div className="ak-renderer-wrapper css-2c6ch1">
                            <div
                              style={{
                                display: "block",
                                width: "100%",
                                position: "absolute",
                              }}
                            ></div>
                            <div className="css-1jset9w">
                              <div className="ak-renderer-document">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: data.description,
                                  }}
                                  data-renderer-start-pos="1"
                                  defaultValue={descript}
                                  onChange={(e) => setDescript(e.target.value)}
                                ></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        {data.file !== null && data.file !== "" ? (
                          <p>
                            Attachment:{" "}
                            <button
                              className="css-370xbg"
                              onClick={downloadFile}
                              href="#"
                              style={{ color: "blue" }}
                              to="/edit"
                              target="_self"
                            >
                              {console.log(data.file)}
                            {file.substring(9)}
                            </button>
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sc-kDhYZr hKEIRt" style={{marginLeft:"-36px"}}>
                  <span className="sc-OxbzP fWuoyP">
                    <span>Activity</span>
                    <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={privateChats} 
  onChange={()=>setPrivateChats(!privateChats)}/>
  <label class="form-check-label" for="flexCheckDefault" >
   Private Chats
  </label>
</div>
                  </span>
                  <div className="sc-jRhVzh keVOrZ"></div>
                  {chat?.length>=1 ? chat.map((e,index) => {
                    const date = new Date(e.date).toLocaleDateString('en-In', {timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit'});
                    const parts = date.split('/');
                    let formattedDate = `${parts[0]}-${parts[1]}-${parts[2]}`;
                    return (
                      <div className="sc-kqlzXE cHtPAi">
                        <div
                          className="sc-jhaWeW GHUyr"
                          data-test-id="request-details.activity-item"
                        >
                          <div className="LayoutStyles__Container-sc-14140bv-1 hUwXrK">
                            <div className="LayoutStyles__AvatarSectionDiv-sc-14140bv-0 hvNajw">
                              <div
                                style={{
                                  display: "inlineBlock",
                                  position: "relative",
                                  outline: "0px",
                                }}
                              >
                                <span
                                  className="css-1qdk52b"
                                  style={{
                                    backgroundColor: "#00695C",
                                    fontWeight: "600",
                                    color: "white",
                                  }}
                                >
                                  <p style={{ marginLeft: "5px" }}>
                                    {e.user[0].toUpperCase() +
                                      e.user[1].toUpperCase()}
                                  </p>
                                  {/*<img src="" alt="" className="css-13ep12v" style={{bordeRadius: "50%"}}/>*/}
                                </span>
                              </div>
                            </div>
                            <div className="LayoutStyles__ContentSectionDiv-sc-14140bv-2 fErUzI">
                              <div>
                                <div className="HeaderStyles__TopItemsContainer-sc-1x5yo10-4 dChGZQ">
                                  <div className="HeaderStyles__TopItem-sc-1x5yo10-3 cjZbUi">
                                    <span className="FieldStyles__Span-sc-173n8o8-1 ekKeSG">
                                      {e.user}
                                      {e.privateChat?<span title="private chat" className="sc-lcpuFF kHmppP" aria-hidden="true">*</span>:""}
                                    </span>
                                  </div>
                                  <div className="HeaderStyles__TopItem-sc-1x5yo10-3 cjZbUi">
                                    <div role="presentation">
                                      <span className="FieldStyles__Span-sc-173n8o8-1 jLNzsu">
                                      {formattedDate}{" "}
                                        {e.time}
                                        
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="CommentStyles__Content-lr96k6-0 kuiQOK">
                                  <div className="ak-renderer-wrapper css-2c6ch1">
                                    <div
                                      style={{
                                        display: "block",
                                        width: "100%",
                                        position: "absolute",
                                      }}
                                    ></div>
                                    <div className="css-1jset9w">
                                      <div className="ak-renderer-document">
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: e.description,
                                          }}
                                          data-renderer-start-pos="1"
                                        ></p> 
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="FooterStyles__ActionsContainer-sc-7f01gi-2 irmqVw"></div>
                                {
                                  e.attachment ? e.attachment.split(';').map((el)=> <button className="btn btn-secondary mb-2 ml-1" value={el} onClick={()=>handleDownloadChatAttachment(el)}>{el.substring(14)}</button>)  : null
                                }
                              
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                                        
                  }):null}
                </div>
              </div>

              <div className={applyClass}>
                {/* <div className="Description-main">
                  <h3 className="s
                  c-lnrBVv hzWGel"><span>Description</span></h3>
                  </div> */}
                 
                 
                  <Modal
                        isOpen={isModalVisible}
                        onRequestClose={handleModalClose}
                        contentLabel="Share Modal"
                      >
                        <span className="close" onClick={handleModalClose}>
                          &times;
                        </span>
                        <label htmlFor="userEmail"  style={{marginTop: "15px",
                        marginBottom: "15px"}}>Enter email to share ticket details</label>
                        <input
                        style={{width: "-webkit-fill-available"}}
                          type="email"
                          id="userEmail"
                          value={userEmail}
                          onChange={handleModalInputChange}
                        />
                        <br/>
                        <br/>
                        <button
                          className="add_but"
                          onClick={handleModalAddClick}
                        >
                          Share
                        </button>
                       
                      </Modal>
                      <div className="share-img">

                      <div className="share-img"  style={{marginLeft: "150px"}}><strong>ID: {data.id}</strong></div>
                <div className="share-img-container">
                  <img
                    onClick={handleButtonClick}
                    className="share-img"
                    src={shareIcon}
                    alt="SVG Image"
                    title="Share Details"
                  />
                </div>
                </div>

                {/* <div className="description-container">
                  <h6 className="description-title">Description</h6>
                  <div className="arrow-container">
                    
                    <button
                      className="arrow-btn"
                      style={{ fontSize: "1.2em", color: "green" }}
                      onClick={toggleItem}
                    >
                      {showDesc ? "🔽" : "🔼"}
                    </button>
                  </div>
                </div> */}

                {showDesc ? (
                  <div className="description-data" style={{    marginLeft: "119px"}}>
              
                    <div
                      data-test-id="request-details.status-lozenge"
                      className="request-detailsx dropdown-container"  tabIndex={0} onBlur={statusChangeOnclick}
                    >
                      <h3 className="sc-lnrBVv hzWGel">
                        <span>Status</span>
                        
                      </h3>
                   
       
                      {/* <div className="css-cztx6i" onClick={statusChangeOnclick}>
                        {data.status}
                        
  
  <div class="down-arrow" >&#x25BC;</div>

                      </div> */}
                      
                      
                        <div className="dropdown" style={{marginLeft: "0px",width: "160px"}}>
                          {/* Add your dropdown content here */}
                          {/* <div onClick={() => {handleStatusSelect("Option 1")}}>
                            Resolve
                          </div>
                          <div onClick={() => {handleStatusSelect("Option 2")}}>
                            Reject
                          </div> */}
                          {/* Add more options as needed */}
                          {data.status!=="Rejected" && data.status!=="Resolved" && data.status!=="Closed"?<select class="form-select"
                           id="capitalCity"
                           name="capitalCity"
                           value={status}
                           onChange={(e)=>{handleStatusSelect(e.target.value)}}
                         >
                          <option disabled>
                         {data.status}
                       </option>
                       {data.status==="In Progress"?<option onClick={() => {handleStatusSelect("Resolve")}} value={"Resolve"}>
                         Resolve
                       </option>:""}
                       <option onClick={() => {handleStatusSelect("Reject")}} value={"Reject"}>
                         Reject
                       </option>
                       </select>:<select class="form-select"
                           id="capitalCity"
                           name="capitalCity"
                           value={data.status}
                           onChange={(e)=>{handleStatusSelect(e.target.value)}}
                         >
                         <option disabled>
                         {data.status}
                       </option>
                       </select>}
                        </div>
                           
                      
                    </div>
                    <br />

                    <div className="request-detailsx dropdown-container">
                      <h3 className="sc-lnrBVv hzWGel">
                        <span>Request type</span>
                      </h3>
                      <div
                        className="dropdown" style={{marginLeft: "0px",width: "160px"}}
                       
                      >
                        {/*<img className="sc-fFTYTi hQlek" src="https://globallogic.atlassian.net/rest/servicedeskapi/requesttype/icon/type/SD_REQTYPE/id/10550"/>*/}
                        {/* <span className="sc-bTiqRo cpDveY">{data.opt}</span> */}
                        <input type="text" class="form-control" style={{marginBottom:"0px",backgroundColor:"white"}}
                           id="capitalCity"
                           name="capitalCity"
                           value={data.opt}
                           disabled
                         ></input>
                      </div>
                    </div>

                    <br />

                    {
                    data.status !== "Resolved" &&
                    data.status !== "Closed" &&
                    data.status !== "Rejected" ? (
                      <form ref={form}>
                        <div className="request-detailsx dropdown-container">
                          <h3 className="sc-lnrBVv hzWGel">
                           {" "}
                            <p>Assignee </p>
                          </h3>
                          <div
                        className="dropdown" style={{marginLeft: "0px",width: "160px",paddingLeft:"0px",paddingRight:"0px",paddingTop:"0px",paddingBottom:"0px"}}>
                          <select class="form-select"
                           id="capitalCity"
                           name="capitalCity"
                           value={admin}
                          onChange={(e)=>{
                            console.log(e.target.value)
                            updateSupportAdminData(e.target.value)
                          }}
                         
                         >
                        {data.admin?<option disabled> {data.admin} </option>:<option readOnly>Select Support</option>}

                        {
                          supportGroupFlag == true ? <>
                          {
                            admin == "L1" ? null :   <option
                            className="menu-items"
                            value="L1"
                            //disabled={supportGroupFlag}
                          >
                            L1
                          </option>
                          }

                          {
                             admin == "L2" ? null : <option
                             className="menu-items"
                             value="L2"
                             //disabled={supportGroupFlag}
                           >
                             L2
                           </option>
                          }
                        {
                           admin == "L3" ? null : <option
                           className="menu-items"
                           value="L3"
                           //disabled={supportGroupFlag}
                         >
                           L3
                         </option>
                        }
                        
                        </>: null
                        }
                        {/* <option
                                    className="menu-items"
                                    value="L1"
                                    //disabled={supportGroupFlag}
                                  >
                                    L1
                                  </option>
                                  <option
                                    className="menu-items"
                                    value="L2"
                                    //disabled={supportGroupFlag}
                                  >
                                    L2
                                  </option>
                                  <option
                                    className="menu-items"
                                    value="L3"
                                    //disabled={supportGroupFlag}
                                  >
                                    L3
                                  </option> */}
                                  {
                                    console.log("+++++ask",dataL1)
                                  }
                         {/* {adm
                              .filter((j) => j.name !== admin)
                              .map((c) => {
                                return (
                                 <option
                                    className="menu-items"
                                    value={c.name}
                                  >
                                    {c.name}
                                  </option>
                                )
                              })} */}

                            {adm
                              .filter((j) => {
                                              if (admin === 'L1') {
                                              return dataL1.indexOf(j.name) === -1;
                                              } else if (admin === 'L2') {
                                              return dataL2.indexOf(j.name) === -1;
                                              } else if (admin === 'L3') {
                                              return dataL3.indexOf(j.name) === -1;
                                              } else {
                                              return j.name !== admin;
                                              }
                                            })
                                            .map((c) => {
                                                        return (
                                                                <option
                                                                 className="menu-items"
                                                                value={c.name}
                                                                key={c.name} // Add a key for better performance
                                                                >
                                                                {c.name}
                                                                </option>
                                                                 );
                                                        })}
                       </select>
                       </div>
                        </div>
                       
                        {/* {supportStaff=== 1 ? (
                          <div className="scroll-menus">
                            {adm
                              .filter((j) => j.name !== temp)
                              .map((c) => {
                                return (
                                  <div
                                    className="menu-items"
                                    value={c.userName}
                                    onChange={(e) => setAdmin(e.target.value)}
                                    onClick={() => dropdown(c)}
                                  >
                                    {c.name}
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          ""
                        )} */}

                        {/* {admin !== null && admin !== undefined ? (
                          <div className="request-detailsx1">
                            <button className="button" >
                              Assign
                            </button>
                          </div>
                        ) : (
                          ""
                        )} */}
                        {/* {cancellation === 1 ? (
                          <button class="btn danger" onClick={cancelSelect}>
                            <u>cancel</u>
                          </button>
                        ) : (
                          ""
                        )} */}
                      </form>
                    ) : data.admin !== null ? (
                      <>
                        <div className="request-detailsx dropdown-container">
<h3 className="sc-lnrBVv hzWGel">Assignee: </h3>
                          {/* <div className="sc-glUWqk bPQdOV">
                            <span className="sc-bTiqRo cpDveY">
                              {data.admin}
                            </span>
                          </div> */}
                          <div className="dropdown"
                       style={{marginLeft: "0px",width: "160px"}}
                       
                      >
                        {/*<img className="sc-fFTYTi hQlek" src="https://globallogic.atlassian.net/rest/servicedeskapi/requesttype/icon/type/SD_REQTYPE/id/10550"/>*/}
                        {/* <span className="sc-bTiqRo cpDveY">{data.opt}</span> */}
                        <input type="text" class="form-control" style={{marginBottom:"0px",backgroundColor:"white"}}
                           disabled
                           id="capitalCity"
                           name="capitalCity"
                           value={admin}
                         ></input>
                      </div>

                        </div>
                        {/* {data.status !== "Resolved" &&
                        data.status !== "Closed" &&
                        data.status !== "Rejected" && adm.length>1 ? (
                          <button class="btn info" onClick={change}>
                            <u>
                              <h3 className="sc-lnrBVv hzWGel">change</h3>
                            </u>
                          </button>
                        ) : (
                          ""
                        )} */}
                      </>
                    ) : (
                      ""
                    )}

                    {/*<div style={{margin:"20px"}}>
                    <label for="cars">Select Support Enginner</label>
    
                      <select name="admin" id="admin">
                       {adm.map((c)=>
                      {
                    return (<option value={c}>{c}</option>)
                       })} 
                     </select>
                      </div>*/}

                    {console.log(data.status)}
                    {/*(data.status!=="Closed" && data.status!=="Rejected") && data.admin===null?<button className="button" /*onClick={assigned}>Assign</button>:""*/}
                    {/* <div className="request-detailsx">
                  <h3 className="sc-lnrBVv hzWGel"> <span> Status </span></h3>
                  {(data.status !== "Resolved" && data.status !== "Closed" && data.status !== "Rejected") ? (
                    <div>
                      <form onSubmit={resolve} ref={feed}>
                        <select className="dropdown" onChange={(e) => e.target.value === 'reject' ? reject() : null}>
                          <option value="resolve">Resolve</option>
                          <option value="reject">Reject</option>
                        </select>
                      </form>
                    </div>
                  ) : ""}
                </div> */}
                    <br />
                    
                    {/* <div className="dropdown" style={{marginLeft: "0px",width: "160px",paddingLeft:"0px",paddingRight:"0px",paddingTop:"0px",paddingBottom:"0px"}}>
                      <h3 className="sc-lnrBVv hzWGel ml-2 mt-3">
                        <span>Asignee Group</span>
                      </h3>
                      <select className="form-select ml-2" 
                      //value={temp=='L1' || temp=='L2' || temp=='L3' ? temp : ""  } 
                      onChange={handleSupportGroupChange}>
                      <option value="L1">Select Group</option>
                        <option value="L1">L1</option>
                        <option value="L2">L2</option>
                        <option value="L3">L3</option>
                      </select>
                      </div> */}
                     
                    <div className="request-detailsx dropdown-container">
                      <h3 className="sc-lnrBVv hzWGel">
                        <span>Severity</span>
                      </h3>
                      
                      <div
                        className="dropdown" style={{marginLeft: "0px",width: "160px",paddingLeft:"0px",paddingRight:"0px",paddingTop:"0px",paddingBottom:"0px"}}
                       
                      >
                      <Select 
                      // components={{ Menu: () => null }}
                      // closeMenuOnSelect={true}
                        closeMenuOnSelect={true}
                        components={makeAnimated}
                        value={severity}
                        options={[
                          {
                            value: "Blocker-S1",
                            label: "Blocker-S1",
                          },
                          {
                            value: "Critical-S2",
                            label: "Critical-S2",
                          },
                          { value: "Major-S3", label: "Major-S3" },
                          { value: "Minor-S4", label: "Minor-S4"},
                        ]}
                        styles={customStyle}
                        onChange={severityChange}
                      />
                      </div>
                    </div>
                    <br />
                    <div className="request-detailsx dropdown-container">
                      <h3 className="sc-lnrBVv hzWGel">
                        <span>Priority</span>
                      </h3>

                      <div
                        className="dropdown" style={{marginLeft: "0px",width: "160px",paddingLeft:"0px",paddingRight:"0px",paddingTop:"0px",paddingBottom:"0px"}}
                       >
                      <Select
                        closeMenuOnSelect={true}
                        components={makeAnimated}
                        value={priority}
                        options={[
                          { value: "High", label: "High"},
                          { value: "Medium", label: "Medium" },
                          { value: "Low", label: "Low" },
                         
                        
                        ]}
                        styles={priorityStyle}
                        onChange={priorityChange}
                      />
                    </div>
                    </div>

                    <br />
                    <div className="request-detailsx" style={{display:"block"}}>
                      <h3 className="sc-lnrBVv hzWGel">
                        <span>Tags</span>
                      </h3>
                      <TagsInput
                        value={tags}
                        onChange={handleChange}
                        addOnBlur={handleChange}
                        tagProps={{
                          className: "react-tagsinput-tag",
                          classNameRemove: "react-tagsinput-remove",
                        }}
                      />
                      </div>
                    
                    <div className="request-detailsx" style={{display:"block",width:"158px"}}>
                      <h3 className="sc-lnrBVv hzWGel">
                        <span>Add CC</span>
                      </h3>
                      <Select
                        closeMenuOnSelect={false}
                        components={makeAnimated}
                        isMulti
                        value={selectedOption}
                        options={emailOptions}
                        styles={customStyles}
                        onChange={handleChanges}
                        placeholder="Add cc"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div style={{ margin: "30px" }}>
            {data.status !== "Resolved" &&
              data.status !== "Closed" &&
              data.status !== "Rejected" ? (
                <><span className="sc-OxbzP fWuoyP">
                <span>Comments
                <div className="form-check form-switch" style={{paddingLeft: "7rem"}}>
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={checked}
  onChange={()=>setChecked(!checked)}/>
  <label class="form-check-label" for="flexSwitchCheckDefault">Private chat</label>
</div></span>
              </span>
              
                  <div
                    className="sc-chAAoq hcyXVm"
                    id="description"
                    data-test-id="rich-text-area-field"
                  >
                    <div className="sc-hAXbOi eotBHb">
                      <div className="css-1dr2qy6">
                        <div
                          style={{
                            display: "block",
                            width: "100%",
                            position: "absolute",
                          }}
                        ></div>
                        <div className=" css-18w41as">
                          <ReactQuill
                            theme="snow"
                            value={descript}
                            style={{ height: "130px" }}
                            onChange={setDescript}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                 
                  <div style={{ paddingTop: "50px" }}>
                  <input type="file" multiple  ref={fileInputRef} onChange={handleFileChange} accept=".pdf,.mp4,.png,.jpg,.jpeg,.zip" style={{width: "223px"}} />
                  {
        uploadErr==true ? <p style={{color:"red",fontSize:"12px"}}>{fileUploadErrMessage}</p> : null
      }
      <p style={{color:"blue",fontSize:"12px", marginTop:"4px"}}>Max file size: 5 MB</p>
      <p style={{fontSize:"12px", marginTop:"0px"}}>Supported File Formats: zip,jpg,jpeg,png,pdf,mp4.</p>
                    <div className="sc-cbkKFq icDrbe" style={{justifyContent:'center', width: '65%', marginLeft: '8px'}}>
                      <button
                        type="submit"
                        className="request-create-form-submit css-68ar9j"
                        onClick={click}
                      >
                        <span className="css-j8fq0c">
                          <span className="css-t5emrf">
                            <span>Submit</span>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <br />
              <div className="sc-hizQCF ljPvSw">
                <div className="sc-eNPDpu eZXad">
                  <div className="sc-hARARD ejZygw">
                    <div
                      style={{
                        display: "inlineBlock",
                        position: "relative",
                        outline: "0px",
                      }}
                    >
                      <span
                        className="css-1qdk52b"
                        style={{
                          backgroundColor: "#00695C",
                          fontWeight: "600",
                          color: "white",
                        }}
                      >
                        <p style={{ marginLeft: "5px" }}>
                          {nam[0].toUpperCase() + nam[1].toUpperCase()}
                        </p>
                      </span>
                    </div>
                    {/*<img src="https://secure.gravatar.com/avatar/0a8339570e62e5a4601c4e3e9d86fbec?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPB-5.png" alt="" className="css-13ep12v" style={{borderRadius: "50%"}}/>*/}
                    <div className="sc-cgHJcJ dWOhZl">
                      <div role="presentation">
                        <span>
                          <h6>{data.user}</h6>User Details
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className={`css-${styles1}`}
                    onClick={userdetails}
                  >
                    <span className="css-j8fq0c">
                      <span className="css-t5emrf">
                        <span>{show1} details</span>
                      </span>
                    </span>
                  </button>
                </div>
                <div className={`sc-ccLTTT ${div1}`}>
                  {x === 0 ? (
                    <>
                      <div data-test-id="request-field-summary">
                        <div className="sc-gGCbJM foiuyx">
                          <label htmlFor="summary" className="css-1qzu3fk">
                            Email
                          </label>
                        </div>
                        <div
                          data-ds--text-field--container="true"
                          className="css-1o07z2d"
                        >
                          <input
                            id="summary"
                            readOnly
                            required
                            value={name}
                            name="summary"
                            type="text"
                            data-ds--text-field--input="true"
                            className="css-mfjdp3"
                          />
                        </div>
                      </div>
                      <div data-test-id="request-field-summary">
                        <div className="sc-gGCbJM foiuyx">
                          <label htmlFor="summary" className="css-1qzu3fk">
                            Mobile Number
                          </label>
                        </div>
                        <div
                          data-ds--text-field--container="true"
                          className="css-1o07z2d"
                        >
                          <input
                            id="summary"
                            readOnly
                            required
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                            name="summary"
                            type="text"
                            data-ds--text-field--input="true"
                            className="css-mfjdp3"
                          />
                        </div>
                      </div>
                      <div data-test-id="request-field-summary">
                        <div className="sc-gGCbJM foiuyx">
                          <label htmlFor="summary" className="css-1qzu3fk">
                            Location
                          </label>
                        </div>
                        <div
                          data-ds--text-field--container="true"
                          className="css-1o07z2d"
                        >
                          <input
                            id="summary"
                            readOnly
                            required
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            name="summary"
                            type="text"
                            data-ds--text-field--input="true"
                            className="css-mfjdp3"
                          />
                          <br />
                        </div>
                      </div>
                      {number.length!==0 || textarea.length!==0 || calender.length!==0 || radio.length!==0 || radiooptions.length!==0? <div align="center">
                        <button
                          type="button"
                          style={{
                            border: "aliceblue",
                            backgroundColor: "white",
                          }}
                          onClick={down}
                        >
                          <img src={arrow} />
                        </button>
                      </div>:""}
                    </>
                  ) : (
                    <>
                      <div data-test-id="request-field-summary">
                        <div className="sc-gGCbJM foiuyx">
                          <label htmlFor="summary" className="css-1qzu3fk">
                            Email
                          </label>
                        </div>
                        <div
                          data-ds--text-field--container="true"
                          className="css-1o07z2d"
                        >
                          <input
                            id="summary"
                            readOnly
                            required
                            value={name}
                            name="summary"
                            type="text"
                            data-ds--text-field--input="true"
                            className="css-mfjdp3"
                          />
                        </div>
                      </div>
                      <div data-test-id="request-field-summary">
                        <div className="sc-gGCbJM foiuyx">
                          <label htmlFor="summary" className="css-1qzu3fk">
                            Mobile Number
                          </label>
                        </div>
                        <div
                          data-ds--text-field--container="true"
                          className="css-1o07z2d"
                        >
                          <input
                            id="summary"
                            readOnly
                            required
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                            name="summary"
                            type="text"
                            data-ds--text-field--input="true"
                            className="css-mfjdp3"
                          />
                        </div>
                      </div>
                      <div data-test-id="request-field-summary">
                        <div className="sc-gGCbJM foiuyx">
                          <label htmlFor="summary" className="css-1qzu3fk">
                            Location
                          </label>
                        </div>
                        <div
                          data-ds--text-field--container="true"
                          className="css-1o07z2d"
                        >
                          <input
                            id="summary"
                            readOnly
                            required
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            name="summary"
                            type="text"
                            data-ds--text-field--input="true"
                            className="css-mfjdp3"
                          />
                          <br />
                        </div>
                      </div>
                     
                      {allFieldsData.length === 0 ?"": allFieldsData.filter(j=>j.groupname===null||j.groupname===value[radiooptions.filter(e=>e.FieldType==="HeadGroup"&&e.FieldName===j.groupname).map(f=>f.field)[0]?.split(" ").join("")]).map((name) => {return getElement(name)})}
                      <div align="center">
                        <button
                          type="button"
                          onClick={down}
                          style={{
                            border: "aliceblue",
                            backgroundColor: "white",
                          }}
                        >
                          <img src={uparrow} />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <br />
              {data.status === "Closed" ? (
                <div className="sc-hizQCF ljPvSw">
                  <div className="sc-eNPDpu eZXad">
                    <div className="sc-hARARD ejZygw">
                      <div
                        style={{
                          display: "inlineBlock",
                          position: "relative",
                          outline: "0px",
                        }}
                      >
                        <span
                          className="css-1qdk52b"
                          style={{
                            backgroundColor: "#00695C",
                            fontWeight: "600",
                            color: "white",
                          }}
                        >
                          <p style={{ marginLeft: "5px" }}>
                            {nam[0].toUpperCase() + nam[1].toUpperCase()}
                          </p>
                        </span>
                      </div>
                      {/*<img src="https://secure.gravatar.com/avatar/0a8339570e62e5a4601c4e3e9d86fbec?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPB-5.png" alt="" className="css-13ep12v" style={{borderRadius: "50%"}}/>*/}
                      <div className="sc-cgHJcJ dWOhZl">
                        <div role="presentation">
                          <span>
                            <h6>{data.user}</h6> submitted Feedback
                          </span>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className={`css-${styles}`}
                      onClick={view}
                    >
                      <span className="css-j8fq0c">
                        <span className="css-t5emrf">
                          <span>{show} details</span>
                        </span>
                      </span>
                    </button>
                  </div>
                  <div className={`sc-ccLTTT ${div}`}>
                    {/*<div align="left">*/}
                    <div /*className="login"*/>
                      <br />

                      <h5 style={{ textAlign: "left" }}>
                        Service Provided by us
                      </h5>
                      <div align="left" className="ratea">
                        <input
                          type="radio"
                          id="star5a"
                          name="ratea"
                          value="5"
                          
                        />
                        <label
                          id="star5a"
                          style={{ color: `${color5}` }}
                          htmlFor="star5a"
                          title="text"
                        >
                          5 stars
                        </label>
                        <input
                          type="radio"
                          id="star4a"
                          name="ratea"
                          value="4"
                         
                        />
                        <label
                          style={{ color: `${color4}` }}
                          id="star4a"
                          htmlFor="star4a"
                          title="text"
                        >
                          4 stars
                        </label>
                        <input
                          type="radio"
                          id="star3a"
                          name="ratea"
                          value="3"
                         
                        />
                        <label
                          style={{ color: `${color3}` }}
                          htmlFor="star3a"
                          title="text"
                        >
                          3 stars
                        </label>
                        <input
                          type="radio"
                          id="star2a"
                          name="ratea"
                          value="2"
                          
                          
                        />
                        <label
                          style={{ color: `${color2}` }}
                          htmlFor="star2a"
                          title="text"
                        >
                          2 stars
                        </label>
                        <input
                          type="radio"
                          id="star1a"
                          name="ratea"
                          value="1"
                         
                        />
                        <label
                          style={{ color: `${color1}` }}
                          htmlFor="star1a"
                          title="text"
                        >
                          1 stars
                        </label>
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />
                      <h5 style={{ textAlign: "left" }}>
                        Anything else you'd like to share with our team?
                      </h5>
                      <br />
                      <textarea
                        id="comment1"
                        required="required"
                        value={comment}
                        disabled
                        className="comment1 gwKMCV "
                      ></textarea>
                      <p />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </main>
      </div>
      {showVideo && <VideoModal title={videoTitle} videoLink={videoLink} okText={"ok"} cancel={setShowVideo} />}
    </div>
  );
}

export default Detailshotel;
