import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const CustomAlert = ({ title, message, okText,cancelText,callback }) => {
    console.log(title)
    console.log(message)
  
  

  
    return (
      <Modal size="sm" show={true} centered onHide={callback}
      backdrop="static"
      keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title size="sm" style={{fontSize: "1.428572em",
      fontWeight: "bold"
  }}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
     {message}
         <br/>
         <br/>
      <Button style={{float: "inline-end"}} onClick={()=>callback(true)} size="sm"  >
           {okText}
          </Button>
          
          {cancelText && <Button style={{float: "inline-end",marginRight: "10px"}} onClick={()=>callback(false)} size="sm"  >
           {cancelText}
          </Button>}
      </Modal.Body>
     
      </Modal>
    );
};

export default CustomAlert;