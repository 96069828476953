import React, { useState,useEffect} from 'react'
import '../css/cmd.css';
import img from './image/jio.jpg';
import uploadImage from './image/upload.png'
import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import Headerhotel from './headerhotel';
import { useDispatch, useSelector } from 'react-redux';
import { userName,mail as mai } from '../feature/slice';
//import { FileUploader } from "react-drag-drop-files";
import Footer from './Footer';
import {jwtDecode} from 'jwt-decode'
import {fileTypeFromBuffer} from 'file-type'
import 'react-quill/dist/quill.snow.css';





function Commenthotel({userAuth,showAlert,setAlertProps}) {
  
  //const storedData = JSON.parse(localStorage.getItem('mydata'));
  //const storedDatas = JSON.parse(localStorage.getItem(storedData));
  //let data = sessionStorage.getItem("key");
  const data=useSelector((state)=>state.userData)
  console.log(data)
  var [opt,setOption]=useState('');
  var [summary,setSummary]=useState('');
  var [descript,setDescript]=useState('');
  var [attach,setAttach]=useState('')
  var [mail,setMail]=useState('');
  var [mobile,setMobile]=useState('');
  var [location,setLocation]=useState('Mumbai')
  const [city,setCity] = useState("")
  const [isLoading,setIsLoading] = useState(true)
  const [options,setOptions]=useState([])
  const [uploadErr,setUploadErr] = useState(false)
  const [fileUploadErrMessage,setFileUploadErrMessage]=useState("")
  const [showInput,setShowInput] = useState(false)
  const [allFieldsData,setAllFieldsData]=useState([])
  const [value,setValue]=useState('')
  
  const navigate=useNavigate()

  const [nam,setNam]=useState('')
  const [user,setUser]=useState([])
  const [textarea,setTextarea]=useState([])
  const [calender,setCalender]=useState([])
  const [radio,setradio]=useState([])
  const [dropdownFields,setDropdownFields]=useState([])
  const [radiooptions,setRadioOptions]=useState([])
  const [trigger,setTrigger]=useState(true)
  const [number,setNumber]=useState([])
  const [isDisabled, setIsDisabled] = useState(false);
  const maxDigits=32;
  //const project=cookie.get("project")
  const locations=useLocation()
  const [files,setFile]=useState([])
  const queryParams = new URLSearchParams(locations.search);
  const parameter1 = queryParams.get('email')
  const parameter2 = queryParams.get('token')
  const parameter3= queryParams.get('tokenauth')
  const parameter4=queryParams.get('channel')
  const [count1,setCount1] = useState(10000)
  if(parameter3==="true")
    {
      //cookie.set("tokenauth",parameter3)
      localStorage.setItem("tokenauth","true")
      userAuth(true)
  
      //cookie.set("token", parameter2)
      localStorage.setItem("token",parameter2)
      //cookie.set("project", parameter4)
  
    }
    if(parameter3==="false")
    {
      //cookie.set("tokenauth",parameter3)
      localStorage.setItem("tokenauth","false")
      //cookie.set("token", parameter2)//
      localStorage.setItem("token",parameter2)
      //cookie.set("project", parameter4)
  
    }
    const token=localStorage.getItem("token")
    console.log(token)
    useEffect(() => {
      try{
    const decodedToken = jwtDecode(token);
    //console.log(decodedToken)
    setProject(decodedToken.project)
    // console.log(queryParams.get('location'))
    // if(capitalCities.includes(queryParams.get('location')) && queryParams.get('location'))
    //   {
    // setLocation(queryParams.get('location') || "Mumbai")
    //   }
    //   else if(queryParams.get('location')){
    // setShowInput(true)
    // setCity(queryParams.get('location') || "")
    // setLocation("Other")
    //   }
    //   else{
    //     setLocation("Mumbai")
    //   }
      }
      catch(error)
      {
        console.log(error)
        navigate("/v1/jim_user_login")
      }
    },[])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/getOptions/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
        console.log(data.data)
        setOptions(data.data)
      })
      .catch(err=>console.log(err))
  }, [])

  // Get the values of parameter1 and parameter2
  // const iv = '0000000000000000'; //16 zeros
  // const key = "fbbaee133bcc12d9467682343a613598"
  // console.log(decodedToken,"decoded value")

  // //let encryptedEmail = cipher.update(decodedToken.email, 'utf8', 'base64');
  // //encryptedEmail += cipher.final('base64');


  // const decipher = crypto.createDecipheriv('aes-256-cbc', key, iv);
  // let decryptedEmail = decipher.update(queryParams.get('email').substring(32), 'base64', 'utf8');
  // decryptedEmail += decipher.final('utf8');
  // console.log(decryptedEmail,"email of decrypt")


  // let decryptedToken = decipher.update(queryParams.get('token').substring(32), 'base64', 'utf8');
  // decryptedEmail += decipher.final('utf8');
  // console.log(decryptedEmail,"email of decrypt")

  // // let decryptedUsername = decipher.update(payloadUsename, 'base64', 'utf8');
  // // decryptedUsername += decipher.final('utf8');
  // // console.log("++++++++++",decryptedUsername)

  // let decryptedTokenAuth = decipher.update(queryParams.get('tokenauth').substring(32), 'base64', 'utf8');
  // decryptedTokenAuth += decipher.final('utf8');
  // console.log("******************",decryptedTokenAuth)

  // let decryptedChannel = decipher.update(queryParams.get('channel').substring(32), 'base64', 'utf8');
  // decryptedChannel += decipher.final('utf8');
  // console.log("))))))))))))))))))))))",decryptedChannel)
  
  // // Get the values of parameter1 and parameter2
  // const parameter1 = decryptedEmail
  // const parameter2 = decryptedToken
  // const parameter3= decryptedTokenAuth
  // const parameter4=decryptedChannel

  const [project,setProject]=useState("")


  
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/getPlaceholder/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
        console.log(data.data[0].fieldname)
          const newPlaceholder = data.data[0].fieldname; // Your dynamic placeholder
          const editor = document.querySelector('.ql-editor');
          //editor.setAttribute('data-placeholder', newPlaceholder);
          setDescript(newPlaceholder)
      })
      .catch(err=>console.log(err))
    }, [project])
    useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/getOptions/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
        console.log(data.data)
        setOptions(data.data)
      })
      .catch(err=>console.log(err))
  }, [project])

  useDispatch(mai(parameter1))
  
  const handleSummaryChange = (e) => {
    //setShowInput(false)
    const inputSummary = e.target.value;

    // Limit the summary to 50 characters
    if (inputSummary.length <= 150) {
      setSummary(inputSummary);
    }
  };
  
  //let datas = sessionStorage.getItem("mail");
  const datas=useSelector((state)=>state.userMail)
  console.log(datas)
  

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/calender/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    }
)
    .then(res=>res.json())
    .then(data=>
      {
      setCalender(data.data)
      if(data.statusCode===400)
      {
       
        showAlert({
          title:  'Logged Out',
          message: 'You are logged out due to session timeout.',
          okText: 'OK',
          callback: () => {
              console.log('Alert closed');
              setAlertProps(null)
          }
      });
        navigate('/v1/jim_user_login')
      }
  })
      .catch(err=>console.log(err))
  }, [project])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/getAllFields/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      { console.log(data.data)
        setAllFieldsData(data.data)
  })
  },[project])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/textareas/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
      setTextarea(data.data)
      if(data.statusCode===400)
      {
       
      //alert("User Logged Out")
        navigate('/v1/jim_user_login')
      }
  })
      .catch(err=>console.log(err))
  }, [project])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/radio/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
      setradio(data.data)
      if(data.statusCode===400)
      {
       
      //alert("User Logged Out")
        navigate('/v1/jim_user_login')
      }
    })
      .catch(err=>console.log(err))
  }, [project])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/dropdown/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
      setDropdownFields(data.data)
      if(data.statusCode===400)
      {
       
      //alert("User Logged Out")
        navigate('/v1/jim_user_login')
      }
    })
      .catch(err=>console.log(err))
  }, [project])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/options/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
      setRadioOptions(data.data)
      if(data.statusCode===400)
      {
       
      //alert("User Logged Out")
        navigate('/v1/jim_user_login')
      }
  })
      .catch(err=>console.log(err))
  }, [project])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/number/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
        if(data.statusCode===400)
      {
       
      //alert("User Logged Out")
        navigate('/v1/jim_user_login')
      }
      setNumber(data.data)
    }
      )
      .catch(err=>console.log(err))
  }, [project])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/user/${project}`,
    {
      method: 'GET',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>
      {
      if(data.statusCode===400)
      {
       
      //alert("User Logged Out")
        navigate('/v1/jim_user_login')
      }
  })
      .catch(err=>console.log(err))
  }, [project])
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/user/${project}/${datas}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })
      .then(res=>res.json())
      .then(data=>
        {
          
          console.log(data.data)
        setNam(data.data[0].name);setMail(data.data[0].email);setMobile(data.data[0].mobile)
        if(data.statusCode===400)
        {
         
        //alert("User Logged Out")
          navigate('/v1/jim_user_login')
        }}).then(console.log(nam))
        .catch(err=>console.log(err))
    }, [project])
    useEffect(() => {

      console.log(token)
     if(localStorage.getItem("tokenauth")==="false")
     //if(cookie.get("tokenauth")==="false")
     {
      window.location.href="/v1/jim_user_login"
     }
    }, [])
    
    console.log(nam)

    //sessionStorage.setItem("key", nam);
    const dispatch=useDispatch()
    dispatch(userName(nam))

    
    const [initialState,setInitialState]=useState({});
    const [disabled,setDisabled]=useState({})

    console.log(textarea)
    useEffect(()=>
      {
  textarea.forEach((item)=>
  {
    console.log(queryParams.get(item.fieldname.split(" ").join("")))
    initialState[item.fieldname.split(" ").join("")]=!queryParams.get(item.fieldname.split(" ").join(""))?'':queryParams.get(item.fieldname.split(" ").join(""))
    setDisabled((prevState)=>
        ({
          ...prevState,
          [item.fieldname.split(" ").join("")]:!queryParams.get(item.fieldname.split(" ").join(""))?"":"true"
        }))    
  })
  calender.forEach((item)=>
  {
    console.log(item.fieldname.split(" ").join(""))
    initialState[item.fieldname.split(" ").join("")]=!queryParams.get(item.fieldname.split(" ").join(""))?'':
    Date.parse(queryParams.get(item.fieldname.split(" ").join(""))) && /^\d{4}-\d{2}-\d{2}$/.test(queryParams.get(item.fieldname.split(" ").join("")))?queryParams.get(item.fieldname.split(" ").join("")):" ";
    setDisabled((prevState)=>
      ({
        ...prevState,
        [item.fieldname.split(" ").join("")]:!queryParams.get(item.fieldname.split(" ").join(""))?"":
        Date.parse(queryParams.get(item.fieldname.split(" ").join(""))) && /^\d{4}-\d{2}-\d{2}$/.test(queryParams.get(item.fieldname.split(" ").join("")))?"true":""
      })) 
  })
  number.forEach((item)=>
    {
      initialState[item.fieldname.split(" ").join("")]=!queryParams.get(item.fieldname.split(" ").join(""))?'': 
      (/^\d+$/.test(queryParams.get(item.fieldname.split(" ").join(""))) && queryParams.get(item.fieldname.split(" ").join("")).length<=32)?queryParams.get(item.fieldname.split(" ").join("")):" ";
      setDisabled((prevState)=>
        ({
          ...prevState,
          [item.fieldname.split(" ").join("")]:!queryParams.get(item.fieldname.split(" ").join(""))?'': 
          (/^\d+$/.test(queryParams.get(item.fieldname.split(" ").join(""))) && queryParams.get(item.fieldname.split(" ").join("")).length<=32)?"true":""
        })) 
    })
    radio.forEach((item)=>
      {
        console.log(item.fieldname.split(" ").join(""))
        console.log(radiooptions)
        console.log(radiooptions.filter(j=>j.field===item.fieldname).map((element=>element.FieldName)))
        console.log(radiooptions.filter(j=>j.field===item.fieldname).map((element=>element.FieldName)).includes(queryParams.get(item.fieldname.split(" ").join(""))))
        initialState[item.fieldname.split(" ").join("")]=!queryParams.get(item.fieldname.split(" ").join(""))?" ":
        radiooptions.filter(j=>j.field===item.fieldname).map((element=>element.FieldName)).includes(queryParams.get(item.fieldname.split(" ").join("")))?
        queryParams.get(item.fieldname.split(" ").join("")):" ";
        setDisabled((prevState)=>
          ({
            ...prevState,
            [item.fieldname.split(" ").join("")]:
            !queryParams.get(item.fieldname.split(" ").join(""))?"":
        radiooptions.filter(j=>j.field===item.fieldname).map((element=>element.FieldName)).includes(queryParams.get(item.fieldname.split(" ").join("")))?
        "true":""
          }))
      })
      dropdownFields.forEach((item)=>
        {
          console.log(item.fieldname.split(" ").join(""))
          console.log(queryParams.get(item.fieldname.split(" ").join("")))
          initialState[item.fieldname.split(" ").join("")]=!queryParams.get(item.fieldname.split(" ").join(""))?" ":
          radiooptions.filter(j=>j.field===item.fieldname).map((element=>element.FieldName)).includes(queryParams.get(item.fieldname.split(" ").join("")))?
          queryParams.get(item.fieldname.split(" ").join("")):" "
          setDisabled((prevState)=>
            ({
              ...prevState,
              [item.fieldname.split(" ").join("")]:
              !queryParams.get(item.fieldname.split(" ").join(""))?"":
          radiooptions.filter(j=>j.field===item.fieldname).map((element=>element.FieldName)).includes(queryParams.get(item.fieldname.split(" ").join("")))?
          "true":""
            }))
        })
      allFieldsData.filter(e=>e.fieldtype==="HeadGroup").forEach((item)=>
          {
            console.log(item.fieldname.split(" ").join(""))
            console.log(queryParams.get(item.fieldname.split(" ").join("")))
            initialState[item.fieldname.split(" ").join("")]=!queryParams.get(item.fieldname.split(" ").join(""))?" ":
            radiooptions.filter(j=>j.field===item.fieldname).map((element=>element.FieldName)).includes(queryParams.get(item.fieldname.split(" ").join("")))?
            queryParams.get(item.fieldname.split(" ").join("")):" "
            setDisabled((prevState)=>
              ({
                ...prevState,
                [item.fieldname.split(" ").join("")]:
                !queryParams.get(item.fieldname.split(" ").join(""))?"":
            radiooptions.filter(j=>j.field===item.fieldname).map((element=>element.FieldName)).includes(queryParams.get(item.fieldname.split(" ").join("")))?
            "true":""
              }))
          })
        
      },[textarea,calender,number])

console.log(disabled)
        const getElement=(name)=>
          {
            var htmlElement=""
            if(name.fieldtype==="statictext")
              {
                htmlElement=<><div data-test-id="request-field-summary">
      <div className="sc-gGCbJM foiuyx">
      <label htmlFor="summary" className="css-1qzu3fk">{name.fieldname}<span className="sc-lcpuFF kHmppP" aria-hidden="true">*</span></label></div>
                <div data-ds--text-field--container="true" className="css-1o07z2d">
      <input style={{marginBottom:"auto"}} id="nameuser" disabled  required defaultValue={mail} 
      onChange={(e)=>setMail(e.target.value)}
     name="nameuser" type="text" data-ds--text-field--input="true" className="css-mfjdp3" />
      </div>
      </div> <br/></>
              }
       else if(name.fieldtype==="staticnumber")
        {
              htmlElement=<><div data-test-id="request-field-summary">
              <div className="sc-gGCbJM foiuyx">
              <label htmlFor="mobnumber" className="css-1qzu3fk">{name.fieldname}<span className="sc-lcpuFF kHmppP" aria-hidden="true">*</span></label></div>
              <div data-ds--text-field--container="true" className="css-1o07z2d">
              <input style={{marginBottom:"auto"}} disabled type="number" id="phoneNumber" name="phoneNumber" pattern="[0-9]{10}" placeholder="Number" required  defaultValue={mobile}
                className="css-mfjdp3"  onChange={(e)=> {const inputValue = e.target.value;
                  // Use a regular expression to remove non-numeric characters
                  const numericValue = inputValue.replace(/[^0-9]/g, '');
                  const limitedNumericValue = numericValue.slice(0, maxDigits);
                  setMobile(limitedNumericValue);
                  }}/>
              </div></div>
              <br/></>
              }
              else if(name.fieldtype==="staticselect")
                {
                  htmlElement=<><div data-test-id="request-field-summary">
      <div>
      <label htmlFor="option" className="css-1qzu3fk">{name.fieldname}</label><span className="sc-lcpuFF kHmppP" aria-hidden="true">*</span>
      
      <div className=" css-5a7vsu-container">
      <div className=" css-bzqzs4-control" ref={dropdownRef} onClick={()=>dropdown("")}>
      <div className=" css-s88hxx">
  
      <div className=" css-8atqhb" onclick={()=>dropdown("")} style={{opacity: "1", transition: "opacity 1ms ease 0s",marginTop:"5px"}}>
      {opt===""?<div className="sc-jrIrqw gRnkOb">Select</div>:<div className="sc-jrIrqw gRnkOb">{opt} </div>}
      </div>
      <input style={{marginBottom:"auto"}} id="request-group-select"   readOnly="" tabIndex="0" aria-autocomplete="list" className="css-62g3xt-dummyInput" defaultValue=""/>
      </div>
      <div className=" css-t5ibhw">
      <div aria-hidden="true" className=" css-gg6ksl-indicatorContainer">
      <span role="img" aria-label="open" className="css-snhnyn" ><svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z" fill="currentColor" fillRule="evenodd"></path></svg></span></div></div></div></div></div>
      {optionFlag===1?
      <div className="scroll-menu" style={{width:"200px"}}>
         {options.map(ele=>{return <div className="menu-item"   value={ele.reasons}  onClick={()=>{dropdown(ele.reasons);
    }}>{ele.reasons}</div>
    })}
    {/* <div className="menu-item" value="Billing"  onClick={()=>dropdown("Billing")}>Billing</div>
    <div className="menu-item" value="Playback"  onClick={()=>dropdown("Playback")}>Playback</div>
    <div className="menu-item" value="Report"  onClick={()=>dropdown("Report")}>Report</div>
    <div className="menu-item" value="Onboarding"  onClick={()=>dropdown("Onboarding")}>Onboarding</div>
    <div className="menu-item" value="Others"  onClick={()=>dropdown("Others")}>Others</div> */}
    </div>
  
    :""}
   
      </div><br/></>
                }
                if(name.fieldtype==="staticlocation")
                  {
                htmlElement=<><div data-test-id="request-field-summary">
      <label htmlFor="capitalCity" className="css-1qzu3fk">{name.fieldname}</label>
      <span className="sc-lcpuFF kHmppP" aria-hidden="true">*</span>
      <br/>
      <div data-ds--text-field--container="true" className="css-1o07z2d">
      <select class="form-select"
        id="capitalCity"
        name="capitalCity"
        value={location}
        onChange={handleCityChange}
      >
        {capitalCities.sort().map((city, index) => (
          <option key={index} value={city}>
            {city}
          </option>
        ))}
        
         <option value={"Other"} onClick={handleCity} onBlur={handleOnBlur}>
           Other
         </option>
        
      </select>
      </div>
      {
        showInput ? <input type ="text" required value={city} placeholder="enter city" onChange={handleInputCity}/> :null
      }
      
      </div>
      <br/></>
                  }
                  else if(name.fieldtype==="statictitle")
                    {
                  htmlElement=<><div data-test-id="request-field-summary">
      <div className="sc-gGCbJM foiuyx">
      <label htmlFor="summary" className="css-1qzu3fk">Title<span className="sc-lcpuFF kHmppP" aria-hidden="true">*  
        Characters left: {150 - summary.length}
        
      </span>
      </label></div>
      <div className="sc-cgHJcJ fLqQEk" ><div data-ds--text-field--container="true" className="css-1o07z2d">
      <input style={{marginBottom:"auto"}} id="summary"   required  onChange={handleSummaryChange} name="summary" type="text" data-ds--text-field--input="true" className="css-mfjdp3" value={summary} />
      
      </div></div></div><br/></>
          }
            else if(name.fieldtype==="textarea")
              { 
                htmlElement=<><div data-test-id="request-field-summary">
                <div className="sc-gGCbJM foiuyx"><label htmlFor="mobnumber" className="css-1qzu3fk">{name.fieldname}</label>
                {name.required==="true"?<span className="sc-lcpuFF kHmppP" aria-hidden="true">*</span>:""}</div>
                <div data-ds--text-field--container="true" className="css-1o07z2d">
                {name.required==="true"?<input style={{marginBottom:"auto"}} disabled={disabled[name.fieldname.split(" ").join("")]} id="summary" required={true} 
                      value={state[name.fieldname.split(" ").join("")]}
                      onChange={(e) => handleChange(name.fieldname.split(" ").join(""), e.target.value)} name="mobnumber" type="text" data-ds--text-field--input="true" className="css-mfjdp3" />
                :<input id="summary" style={{marginBottom:"auto"}} disabled={disabled[name.fieldname.split(" ").join("")]} required={false} 
                      value={state[name.fieldname.split(" ").join("")]}
                      onChange={(e) => handleChange(name.fieldname.split(" ").join(""), e.target.value)} name="mobnumber" type="text" data-ds--text-field--input="true" className="css-mfjdp3" />}
                      </div></div>
      <br/></>
              }
              else if(name.fieldtype==="number"){
                htmlElement=<><div data-test-id="request-field-summary">
                <div className="sc-gGCbJM foiuyx">
                <label htmlFor="mobinumber" className="css-1qzu3fk">{name.fieldname}</label>
                {name.required==="true"?<span className="sc-lcpuFF kHmppP" aria-hidden="true">*</span>:""}</div>
                <div data-ds--text-field--container="true" className="css-1o07z2d">
                {name.required==="true"?
                <input type="number" id="phoneNumber" style={{marginBottom:"auto"}}  disabled={disabled[name.fieldname.split(" ").join("")]} autoComplete="off" required={true} name="phoneNumber"  placeholder="Number"  value={state[name.fieldname.split(" ").join("")]}
                  className="css-mfjdp3"  onChange={(e)=> {const inputValue = e.target.value;
                    // Use a regular expression to remove non-numeric characters
                    const numericValue = inputValue.replace(/[^0-9]/g, '');
                    const limitedNumericValue = numericValue.slice(0, maxDigits);
                    setValue(limitedNumericValue);
                    handleChange(name.fieldname.split(" ").join(""),limitedNumericValue)}}/>:
                    <input  style={{marginBottom:"auto"}}  disabled={disabled[name.fieldname.split(" ").join("")]} type="number" autoComplete="off" id="phoneNumber" name="phoneNumber"  placeholder="Number"  value={state[name.fieldname.split(" ").join("")]}
                    className="css-mfjdp3"  onChange={(e)=> {const inputValue = e.target.value;
                      // Use a regular expression to remove non-numeric characters
                      const numericValue = inputValue.replace(/[^0-9]/g, '');
                      const limitedNumericValue = numericValue.slice(0, maxDigits);
                      setValue(limitedNumericValue);
                      handleChange(name.fieldname.split(" ").join(""),limitedNumericValue)}}/>}
                </div></div>
                <br/></>
              }
              else if(name.fieldtype==="calender")
                {
                  htmlElement=<><div data-test-id="request-field-summary">
                  <div className="sc-gGCbJM foiuyx">
                  <label htmlFor="mobnumber" className="css-1qzu3fk">{name.fieldname}</label>
                  {name.required==="true"?<span className="sc-lcpuFF kHmppP" aria-hidden="true">*</span>:""}</div>
                  <div data-ds--text-field--container="true" className="css-1o07z2d">
                  {name.required==="true"?<input type="date"  disabled={disabled[name.fieldname.split(" ").join("")]}  id="calender" required={true} 
                        value={state[name.fieldname.split(" ").join("")]} className="css-mfjdp3"
                        onChange={(e) => handleChange(name.fieldname.split(" ").join(""), e.target.value)}  name="calender"/>:
                        <input type="date"  disabled={disabled[name.fieldname.split(" ").join("")]}  id="calender" required={false} 
                        value={state[name.fieldname.split(" ").join("")]} className="css-mfjdp3"
                        onChange={(e) => handleChange(name.fieldname.split(" ").join(""), e.target.value)}  name="calender"/>}
              &nbsp;&nbsp;
                  </div></div>
            
                  <br/></>
                }
                else if(name.fieldtype==="dropdown"){
                  htmlElement=<><div data-test-id="request-field-summary">
                  <div className="sc-gGCbJM foiuyx">
                  <label htmlFor="mobnumber" className="css-1qzu3fk">{name.fieldname}</label>
                  {name.required==="true"?<span className="sc-lcpuFF kHmppP" aria-hidden="true">*</span>:""}
                  </div>
                  <div data-ds--text-field--container="true" className="css-1o07z2d">
                  {name.required==="true"?<select name="select" id="select" disabled={disabled[name.fieldname.split(" ").join("")]} className="css-mfjdp3" value={state[name.fieldname.split(" ").join("")]} onChange={(e) => handleChange(name.fieldname.split(" ").join(""), e.target.value)} required={true}>
                  <option value="">Select</option>
                  {radiooptions.filter(j=>j.field===name.fieldname).map((element)=>
                  <><option value={element.FieldName}>{element.FieldName}</option></>)}
                  </select>:
                  <select name="select" id="select" disabled={disabled[name.fieldname.split(" ").join("")]} value={state[name.fieldname.split(" ").join("")]} className="css-mfjdp3" onChange={(e) => handleChange(name.fieldname.split(" ").join(""), e.target.value)} required={false}>
                  <option value="">Select</option>
                  {radiooptions.filter(j=>j.field===name.fieldname).map((element)=>
                  <><option value={element.FieldName}>{element.FieldName}</option></>)}
                  </select>}
                  </div>
                  </div>
                  <br/></>
                }
                else if(name.fieldtype==="radio")
                  {
                    htmlElement=<><div data-test-id="request-field-summary">
                    <div className="sc-gGCbJM foiuyx">
                    <label htmlFor={name.fieldname} className="css-1qzu3fk">{name.fieldname}</label>
                    {name.required==="true"?<span className="sc-lcpuFF kHmppP" aria-hidden="true">*</span>:""}
                    </div>
                    {radiooptions.filter(j=>j.field===name.fieldname).map((element)=>name.required==="true"?<><input  type="radio" disabled={disabled[name.fieldname.split(" ").join("")]}  id={element.FieldName} name={name.fieldname} checked={state[name.fieldname.split(" ").join("")]===element.FieldName}
                    value={element.FieldName} required={true} onChange={(e) => handleChange(name.fieldname.split(" ").join(""), element.FieldName)}/>
                <label for={`${element.FieldName}`}>{element.FieldName}</label>&nbsp;</>:
              <><input  type="radio" disabled={disabled[name.fieldname.split(" ").join("")]}  id={element.FieldName} name={name.fieldname} checked={state[name.fieldname.split(" ").join("")]===element.FieldName}
                    value={element.FieldName}   onChange={(e) => handleChange(name.fieldname.split(" ").join(""), element.FieldName)}/>
                <label for={`${element.FieldName}`}>{element.FieldName}</label>&nbsp;</>)}
                    </div>
                    <br/></>
                  }
                 
              return htmlElement
          }
  const [state,setState]=useState(initialState);
  const handleChange=(item,value)=>
  {
    console.log(item)
    console.log(value)
    setState((prevState)=>
    ({
      ...prevState,
      [item]:value,

    }))
   
  }
  console.log(initialState)
  // const checkAutoAssignSupport = async () =>{
  //   const response = await fetch(`${process.env.REACT_APP_API_URL}/getAutoAssignSupport/${project}`, {
  //     method: "POST",
  //     withCredentials: "true",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //         console.log("+++++++++>>>>",data)
  //         // setInitialData(data.data);
  //         // supportData = data.data
  //         console.log("************",data.data)
  //         //return data.data
  //         //setSelected(initialData[0].isAutoAssign)
  //       if (data.statusCode === 400) {
  //         //alert("User Logged Out")
  //         navigate("/v1/jim_admin_user_login");
  //       }
  //     })
  //     .catch((err) => console.log(err));


  // }

  const checkAutoAssignSupport = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getAutoAssignSupport/${project}`, {
        method: "POST",
        withCredentials: true, // Convert to boolean
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log("+++++++++>>>>", data);
  
      if (data.statusCode === 400) {
        // Redirect to login page if needed
        navigate("/v1/jim_admin_user_login");
        return null; // or handle the error as needed
      }
  
      // Return the data received from the API
      return data.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null; // or handle the error as needed
    }
  };

  useEffect(()=>{
    const result = checkAutoAssignSupport().then(data=>{
      //const payload = JSON.parse(data.L1)
      //console.log(payload)
    } )
  },[project])
 
  
  
  const click=async (y)=>
  {
    let i=0
    const token=localStorage.getItem("token")
    const plainString = descript.replace(/<[^>]+>/g, '');
    let supportData= []
    //const noSpace=/^[^-\s][a-zA-Z0-9_\s-]+$/
    const noSpace = plainString.split('')
    console.log(plainString,"descript")
    y.preventDefault()
    setIsLoading(false)
    const result1 = await checkAutoAssignSupport()
    const ResultL1 = result1.L1 ? JSON.parse(result1.L1 || "[]") :[]  
    const ResultL2 =result1.L2 ? JSON.parse(result1.L2 || "[]") : []
    const ResultL3 =result1.L2 ? JSON.parse(result1.L3 || "[]") : []
    console.log("****************",result1)
    if(noSpace[0]==" " && noSpace[0]!="")
    {
      showAlert({
        title:  'Invalid Input',
        message: 'Please enter text first.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    });
      setIsLoading(true)
    }
    else if(opt!=="" && summary!=="" && plainString!==""  && trigger==false)
    {
    const user=data
    const status="opened"
    const file=attach
    const description=descript
    const response = await fetch(`${process.env.REACT_APP_API_URL}/ticketCount/${project}`,
      {
        method: 'GET', 
        withCredentials: 'true',
        headers: { 'Authorization':`Bearer ${token}` },
      }
    );
    if (!response.ok) {
      throw new Error("API is failed");
    }
    const countData = await response.json();
    let formattedTicketId;
    console.log("=============",countData.data)
    if(countData.data.length==1){
    const lastTicketId = countData?.data[0]?.id;
    const digits = lastTicketId.replace(/\D+/g, '').trim();
    formattedTicketId = `JIM-${parseInt(digits)+1}`;
    }
    
    console.log("*******************",result1.L1)
 
    const obj={admin:result1.L1?"L1":"" || result1.L2?"L2":"" || result1.L3?"L3":"" || null,id:formattedTicketId || "JIM-1001",user,opt,summary,description,status,mail,mobile,location:location == "Other" ?city:location}
    //result1.L1?"L1":"" || result1.L2?"L2":"" || result1.L3?"L3":"" || null
  //}


    console.log(obj)
    const res={
      method: 'POST', 
      withCredentials: 'true',
      headers: { 'Authorization':`Bearer ${token}`,'Content-Type': 'application/json' },
      body: JSON.stringify(obj)
  };
   await fetch(`${process.env.REACT_APP_API_URL}/comment/${project}`, res)
        .then((data)=>
        {
          if(data.status===200)
            {
              i++;
            console.log("added")
            }
        })
        console.log(state)
        const dynamicres={
          method: 'POST',
          withCredentials: 'true',
          headers: { 'Authorization':`Bearer ${token}`,'Content-Type': 'application/json' },
          body: JSON.stringify(state)
      };
       await fetch(`${process.env.REACT_APP_API_URL}/insert/dynamicdata/${project}/${formattedTicketId || "JIM-1001"}`, dynamicres)
      .then(async (data)=>
      {
       console.log(data)
       console.log(i)
        if(data.status===400)
        {
          navigate('/v1/jim_user_login')
        }
        else if(i===1 && data.status===201){         
          setOption('');
        setSummary('');
        setDescript('');
        
          setIsLoading(true)
          navigate("/v1/jim/user_homepage");
          //window.location.reload(false); // Omit optional second argument
          
          if(ResultL1[0]?.email || ResultL2[0]?.email || ResultL3[0]?.email)
          {
            console.log("l1 email",ResultL1[0]?.email)
            console.log("l2 email",ResultL2[0]?.email)
            console.log("l3 email",ResultL3[0]?.email)
            fetch(
              `${process.env.REACT_APP_API_URL}/assigned`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                  mail: ResultL1[0]?.email || ResultL2[0]?.email || ResultL3[0]?.email || null,
                  name: ResultL1[0]?.name || ResultL2[0]?.name || ResultL3[0]?.name || null,
                  id: formattedTicketId || 'JIM-1001'
                })})
              .then(console.log("email send"))
              .catch((err) => console.log(err));
          }
          showAlert({
            title:  'Ticket Raised',
            message: 'We are pleased to inform you that your ticket has been successfully raised.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        });
          console.log("added")
        }
        else{
          setIsLoading(true)
          showAlert({
            title:  'Failed To Raise',
            message: 'We encountered an issue while trying to raise your ticket. Please try again.',
            okText: 'OK',
            callback: () => {
                console.log('Alert closed');
                setAlertProps(null)
            }
        });
        }
      })
     
       fetch(`${process.env.REACT_APP_API_URL}/open`,
      {
        method: 'POST',
          withCredentials: 'true',
          headers: { 'Authorization':`Bearer ${token}`,'Content-Type': 'application/json' },
          body: JSON.stringify( {mail:mail,name:data,project:project,id:formattedTicketId || "JIM-1001"})
       
      })
      .then(console.log("email send"))
      .catch(err=>console.log(err))
      fetch(`${process.env.REACT_APP_API_URL}/admin/${project}`,
      {
        method: 'GET',
        withCredentials: 'true',
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })
  
    .then(res=>res.json())
    .then(data=>
      { 
        let emailString = "";
        let nameString = ""
        data.data.forEach((item, index) => {
          emailString += item.email;
          if (index < data.data.length - 1) {
            emailString += ";";
          }
          nameString += item.name;
          if (index < data.data.length - 1) {
            nameString += ",";
          }
        });
       //data.data.map(element=>{
     fetch(`${process.env.REACT_APP_API_URL}/adminopen`,
       {
        method: 'POST',
          withCredentials: 'true',
          headers: { 'Authorization':`Bearer ${token}`,'Content-Type': 'application/json' },
          body: JSON.stringify( {mail:emailString,name:nameString,project:project,userName:user,userMail:datas,id:formattedTicketId || "JIM-1001"})
       
      })
      .then(console.log("email send"))
      .catch(err=>console.log(err))
      })
   
      console.log(hiddenFileInput.current.files[0])
      console.log(y)
      const formData = new FormData();
      formData.append("file",files);
      console.log(formData)
      for (var key of formData.entries()) {
        console.log(key[0] + ', ' + key[1])
      }
    const result =await fetch(`${process.env.REACT_APP_API_URL}/upload/${project}/${formattedTicketId || "JIM-1001"}`,{
      method: 'Post',
      withCredentials: 'true',
      headers:
      {
        'Authorization':`Bearer ${token}`,
      },
      //headers: { 'Content-Type': 'multipart/form-data' },
      body: formData,
  })
    //})
 
    }
    else{
      showAlert({
        title:  'Required',
        message: 'Please fill out all required field.',
        okText: 'OK',
        callback: () => {
            console.log('Alert closed');
            setAlertProps(null)
        }
    });
      setIsLoading(true)
    }
        try {
          //navigate("/v1/jim/create_ticket"); // Omit optional second argument
        } catch (error) {
          navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
        }

  }
    
        const hiddenFileInput = useRef(null);
        const handleClick = event => {
          hiddenFileInput.current.click();
        };
        console.log(descript);
        let [optionFlag,setOptionFlag]=useState(0)
        
        const dropdown=(e)=>
        {
          if(optionFlag===0)
          {
            setOptionFlag(1)
          }
          else{
            setOptionFlag(0)
          }
          setOption(e)
        }
      
     
      
 
 
     console.log(mail)
         const capitalCities =["Mumbai", "Delhi", "Bangalore", "Kolkata", "Chennai",
         "Hyderabad", "Pune", "Ahmedabad", "Jaipur", "Lucknow",
         "Chandigarh", "Bhopal", "Indore", "Patna", "Kanpur",
         "Nagpur", "Coimbatore", "Vadodara", "Ludhiana", "Agra",
         "Nashik", "Kochi", "Varanasi", "Madurai", "Visakhapatnam"
         // Add more cities or create additional arrays as needed
       ];

  // Define initial state for the selected capital city

 

  // Function to handle changes in the selected city
  const handleCityChange = (event) => {
    console.log("######",event.target.value)
    setLocation(event.target.value)
    if(event.target.value=="Other"){
      setShowInput(true)
    }
    else{
      setShowInput(false)
    }
  };

  const dropdownRef = useRef(null);
  
  useEffect(() => {
    // Add a click event listener to the document to detect clicks outside the dropdown
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOptionFlag(0)
      }
    }
  
    // Attach the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);
  
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  console.log(">>>>>>>>>>>>>>>>>>>>>>>>>",hiddenFileInput)
  console.log("++++++++++++++++++++++++++++++++++",attach)
  const handleDragOver=(event)=>
  {
    event.preventDefault()
    console.log(event.dataTransfer.files)

  }
  const handleDrop=(event)=>
  {
    event.preventDefault()
    console.log(event.dataTransfer.files[0].name)
    setFile(event.dataTransfer.files[0])
    let files=event.dataTransfer.files[0]
    console.log(files)
    setAttach(files.name)
  }

  const handleCity = () =>{
    alert("hello world")
    setShowInput(true)
  }
const  handleInputCity = (e) =>{
console.log(e.target.value)
const regex = /^[A-Za-z]+$/;
if(regex.test(e.target.value)){
  //const lowerCaseCitiesArray = capitalCities.map(city => city.toLowerCase());
  // if(lowerCaseCitiesArray.includes(e.target.value.toLowerCase())){
  //   alert("city is available in list")
  // }else{
setCity(e.target.value)
// }
}
else{
  setCity(e.target.value.slice(0,-1))
}
}

const handleOnBlur = () =>{
  setShowInput(false)
}
  console.log(">>>>>>>123&&&&&&&&&&&",descript)
  return (
    <>
    {allFieldsData.length!==0?
    <>
    <Headerhotel requests={1} backButton={0} raiseTicket={0} registrationRequest={0} adminRequests={0} showAlert={showAlert} setAlertProps={setAlertProps}/>
    <form onSubmit={click}>
    <div>
    <div className="sc-hORach dWMRNy">
    <div className="sc-eTuwsz jmFfsW"></div>
    <main className="sc-hXRMBi bJStMD sc-ibxdXY fpdUcT">
      <div className="sc-hrWEMg cesPGE">
      <header>
      <span className="sc-rBLzX fayCzS">
      {/*<p className="sc-CtfFt ewysoW sc-Rmtcm DgkTO" href="/servicedesk/customer/portals" target="_self">Jio Services Portal Home</p>
      <span className="sc-hGoxap dNSoSr">/</span>
      <a className="sc-CtfFt ewysoW sc-Rmtcm DgkTO" href="/servicedesk/customer/portal/10" target="_self">Jio Services</a>
      <span className="sc-hGoxap dNSoSr">/</span>
  <a className="sc-CtfFt ewysoW sc-Rmtcm DgkTO" href="/servicedesk/customer/portal/10/group/-1" target="_self">Raise a request</a>*/}
      </span>
      <div className="sc-TFwJa dUxdnV"><div className="sc-fjmCvl OGFXn">
      <img className="sc-feJyhm fDacxp" src={img}/>
      </div>
      <div>
      <h1 className="sc-bHwgHz bGtLRv">Jio Services</h1>
      </div></div>
     
      </header>
      <br/>
      {/* <div data-test-id="request-field-summary">
      <div className="sc-gGCbJM foiuyx">
      <label htmlFor="mobnumber" className="css-1qzu3fk">Mobile Number<span className="sc-lcpuFF kHmppP" aria-hidden="true">*</span></label></div>
      <div data-ds--text-field--container="true" className="css-1o07z2d">
      <input style={{marginBottom:"auto"}} disabled type="number" id="phoneNumber" name="phoneNumber" pattern="[0-9]{10}" placeholder="Number" required  defaultValue={mobile}
        className="css-mfjdp3"  onChange={(e)=> {const inputValue = e.target.value;
          // Use a regular expression to remove non-numeric characters
          const numericValue = inputValue.replace(/[^0-9]/g, '');
          const limitedNumericValue = numericValue.slice(0, maxDigits);
          setMobile(limitedNumericValue);
          }}/>
      </div></div>
      <br/> */}
      {allFieldsData.length===0?"":allFieldsData.filter(j=>j.groupname===null).map((name)=>
        {return name.fieldtype==="HeadGroup"?
      <><div data-test-id="request-field-summary">
                    <div className="sc-gGCbJM foiuyx">
                    <label htmlFor="mobnumber" className="css-1qzu3fk">{name.fieldname}</label>
                    {name.required==="true"?<span className="sc-lcpuFF kHmppP" aria-hidden="true">*</span>:""}
                    </div>
                    <div data-ds--text-field--container="true" className="css-1o07z2d">
                    {name.required==="true"?<select name="select" id="select" disabled={disabled[name.fieldname.split(" ").join("")]} className="css-mfjdp3" value={state[name.fieldname.split(" ").join("")]} onChange={(e) => handleChange(name.fieldname.split(" ").join(""), e.target.value)} required={true}>
                    <option value="">Select</option>
                    {radiooptions.filter(j=>j.field===name.fieldname).map((element)=>
                    <><option value={element.FieldName}>{element.FieldName}</option></>)}
                    </select>:
                    <select name="select" id="select" disabled={disabled[name.fieldname.split(" ").join("")]} value={state[name.fieldname.split(" ").join("")]} className="css-mfjdp3" onChange={(e) => handleChange(name.fieldname.split(" ").join(""), e.target.value)} required={false}>
                    <option value="">Select</option>
                    {radiooptions.filter(j=>j.field===name.fieldname).map((element)=>
                    <><option value={element.FieldName}>{element.FieldName}</option></>)}
                    </select>}
                    </div>
                    </div>
                    <br/>
      {allFieldsData.filter(j=>j.groupname===state[name.fieldname.split(" ").join("")]).map(ele=>getElement(ele))}</>
        :getElement(name)}
      )}     
      
      
      <div className="sc-krvtoX gLHWRW">
      <div className="" style={{height: "auto", overflow: "visible", transition: "height 0.25s ease 0"}}>
      <div style={{overflow: "visible"}}>
      
      <div data-test-id="request-field-description">
      <div className="sc-gGCbJM foiuyx">
      <label htmlFor="description" className="css-1qzu3fk">Description</label>
      {
        !trigger ?  <span className="sc-lcpuFF kHmppP" aria-hidden="true" style={{fontSize:"13px"}} >*  
        Characters left: {count1} 
      </span> : <span className="sc-lcpuFF kHmppP" aria-hidden="true" style={{fontSize:"13px"}} >*  
        Characters left: {10000} 
      </span>
      }
      {/* <span className="sc-lcpuFF kHmppP" aria-hidden="true" style={{fontSize:"13px"}} >*  
        Characters left: {10011 - descript.length} 
      </span> */}
      </div>
      <div className="sc-chAAoq hcyXVm"  id="description" data-test-id="rich-text-area-field">
      <div className="sc-hAXbOi eotBHb">
      <div className="css-1dr2qy6">
      <div style={{display: "block", width: "100%", position: "absolute"}}>
      </div>
      <div className=" css-18w41as">
      <div className="akEditor css-1yk94i7" onClick={()=>{
         setIsDisabled(false)
        if(trigger==true){
          //setCount1(10000)
          setDescript('')    
          setTrigger(false)
         
        }      
      }
      } required>
    <ReactQuill   theme="snow" value={descript} style={{height:"105px"}}
    readOnly={isDisabled}
    onChange={(value)=>{
      console.log("$$$$$$$$$",value.replace(/(<([^>]+)>)/gi, '').length)  
      if(value.replace(/(<([^>]+)>)/gi, '').length <= 10000){
        setDescript(value)
        setCount1(10000-value.replace(/(<([^>]+)>)/gi, '').length)
        setIsDisabled(false);
      }
      else{
        setIsDisabled(true);
      }
      
    }} />
    {/* <div dangerouslySetInnerHTML={{ __html: descript }} /> */}
    {/* <div dangerouslySetInnerHTML={{ __html: descript }} /> */}
      </div></div></div></div></div></div><br/>
      <div data-test-id="request-field-attachment">
      <div className="sc-gGCbJM foiuyx"><label htmlFor="attachment" className="css-1qzu3fk">Attachment</label>
      <p dangerouslySetInnerHTML={{__html:data.description}}/>

      </div>
      <div><div className="sc-hizQCF fkZtIa"><div className="sc-eNPDpu gJJhIj">
    <div className="sc-hARARD evXuov" >
    <p style={{align:"center"}}>Drag & Drop file here</p>
    {/* <span>Select .jpg or .mp4 or .zip</span> */}
    <div className="sc-TFwJa dUxdnV"  style = {{justifyContent: 'center', marginBottom: '00px',marginTop:"00px", marginLeft: '20px',paddingTop:"0px",paddingBottom:"0px"}}>
    <div className="sc-fjmCvl OGFXn" style={{width:"100px",height:"100px"}} ref={hiddenFileInput} onDragOver={handleDragOver} onDrop={handleDrop}>
      <img className="sc-feJyhm fDacxp" src={uploadImage}/></div></div>
    </div>
    <p style={{align:"center"}}>OR</p>
    <br/>
    <button type="button" onClick={handleClick} style={{backgroundColor:"#3399FF"}} className="request-create-form-submit css-68ar9j">
    <span className="css-j8fq0c">
    <span className="css-t5emrf">
    {attach===''?<span>Browse File</span>:<span>{attach.replace("C:\\fakepath\\", "")}</span>}</span></span></button>
    <input
        type="file"
        name="file"
        /*onChange={handleChange}*/
        onChange={async (e)=>
          {
            setUploadErr(false)
            if(hiddenFileInput.current.files[0])
            {
            if(hiddenFileInput.current.files[0].size<=10485760/2)
            {
              const mimetypes= [('.jpg','image/jpeg'), ('.jpeg','image/jpeg'),
              ('.png','image/png'), 
              ('.pdf','application/pdf'), 
              ('.zip','application/x-zip-compressed'),
              ('.zip','application/zip'),  
              ('.mp4','video/mp4')];
            console.log(e.target.files[0].type)
            const buffer = e.target.files[0].arrayBuffer()
            const type=await fileTypeFromBuffer(await buffer)
            console.log(type?.mime)
              if(mimetypes.includes(e.target.files[0].type) && mimetypes.includes(type?.mime) && (e.target.files[0].type===type?.mime || e.target.files[0].type==="application/x-zip-compressed"))
                {
                  setFile(e.target.files[0])
              setAttach(e.target.value)
                }
                else{
                  setAttach("")
                  setFile([])
                  setUploadErr(true)
                  setFileUploadErrMessage("* Invalid File")
                }
            }
            else{
              setAttach("")
              setFile([])
              setUploadErr(true)
              setFileUploadErrMessage("* File size should less than 5MB")
              //alert("file size should less than 5MB")
            }
          }
          else{
            setAttach("")
          }
          }}
        accept='.jpg,.jpeg,.pdf,.zip,.mp4,.png'
        ref={hiddenFileInput}
        style={{display: 'none'}} 
      />
      {
        uploadErr==true ? <p style={{color:"red",fontSize:"12px"}}>{fileUploadErrMessage}</p> : null
      }
      <p style={{color:"blue",fontSize:"12px", marginTop:"4px"}}>Max file size: 5 MB</p>
      <p style={{fontSize:"12px", marginTop:"0px"}}>Supported File Formats: zip,jpg,jpeg,png,pdf,mp4.</p>
      </div></div></div>
    <input data-testid="media-picker-file-input" type="file" multiple="" style={{display: "none"}}/>
    </div>
    
    <div className="sc-cbkKFq icDrbe" style = {{justifyContent: 'center', marginBottom: '16px', marginLeft: '8px'}}>
    <button  type="submit" className="request-create-form-submit css-68ar9j" >
   
    <span className="css-j8fq0c">
    <span className="css-t5emrf">
      {
        !isLoading ? <div class="spinner-border spinner-border-sm">
        <span class="sr-only">Loading...</span>
        </div>: <span>
        Submit
      </span>
      }
      </span>
      </span>
      </button>
    {/*<button type="button" className="css-q68uj">
    <span className="css-j8fq0c">
    <span className="css-t5emrf">
<span>Cancel</span></span></span></button>*/}</div></div></div></div>
    <div className="sc-jzgbtB gUQSWG"></div><div className="sc-jqIZGH bokdCL"></div></div>
    </main>
    </div>
    </div>
    </form>
    <Footer/></>:<div style={{display:"flex",height:"458px",width:"100%",justifyContent:"center",alignItems:"center"}}>  <div class="spinner-border" style={{width: "3rem", height: "3rem", role:"status"}}>
        <span class="sr-only">Loading...</span>
      </div> </div>}
    </>
  )
}

export default Commenthotel;
