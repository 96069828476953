import React, { useEffect } from 'react'
import '../css/login.css';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import emailjs from '@emailjs/browser';
import { Ref } from 'react';
import { useRef } from 'react';
import Headerhotel from './headerhotel';
import { useDispatch } from 'react-redux';
import { mail } from '../feature/slice';
import cookie from 'js-cookie';




function SignInHotel({userAuth}){
  var [list,setList]=useState("")
    var [name,setName]=useState('')
    const [to,setTo]=useState('')
    const navigate=useNavigate()
    const dispatch=useDispatch()
   const [data,setdata]=useState(4)
   console.log(data)
   console.log(name)
   const feed=useRef()
   useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/findAll`)
    .then(res=>res.json())
    .then(data=>
      setList(data))
      .catch(err=>console.log(err))
  }, [])
  console.log(list)
  const button= () => {
    try {
      navigate(-1); // Omit optional second argument
    } catch (error) {
      navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
    }
  }
  const clicks= (async (y) => {
    y.preventDefault()
   
    
    //sessionStorage.setItem("list", JSON.stringify(list));
      const obj={name}
      const res={
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj)
    };
    const response=await fetch(`${process.env.REACT_APP_API_URL}/details`, res)
   // const result=await response
    const result=await response.json()
    console.log(result)
    if(result.token)
    {
      const find=await fetch(`${process.env.REACT_APP_API_URL}/find/${name}`);
      const data = await find.json();
      setdata(data);
      console.log(data)
      if(data===1)
      {
    //localStorage.setItem("token",result.token) 
    cookie.set("token",result.token) 
    //sessionStorage.setItem("mail",name)
   
    dispatch(mail(name))
    //const token = localStorage.getItem("token");
    const token=cookie.get("token")
    if (token) {
      // Make an asynchronous request to validate the token
      
      const responses=await fetch('/protected-route',
      {
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })
    if (responses.status === 200) {
      // Token is valid
      userAuth(true);
      //localStorage.setItem("tokenauth","true")
      cookie.set("tokenauth","true")
    } else {
      // Token is not valid
      //userAuth(false);
    }
  } else {
    // No token found
   // userAuth(false);
  }
    try {
        
        navigate("/v1/jim/create_ticket");
        
      } catch (error) {
        navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
      }
    
      }
      else{
        const email=result.email
        const mobile=result.mobile
        const name=result.username
        const pass=""
        const user="support"
        const obj={email,pass,mobile,name,user}
        const res={
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(obj)
      };
      fetch(`${process.env.REACT_APP_API_URL}/adminuseradd`, res)
            .then(()=>
            {
                console.log("added")
            })
        //localStorage.setItem("token",result.token)
        cookie.set("token",result.token)  
        sessionStorage.setItem("mail",email)
    //const token = localStorage.getItem("token");
    const token=cookie.get("token")
    if (token) {
      // Make an asynchronous request to validate the token
      
      const responses=await fetch('/protected-route',
      {
        headers:
        {
          'Authorization':`Bearer ${token}`,
        },
      })
    if (responses.status === 200) {
      // Token is valid
      //userAuth(true);
      //localStorage.setItem("tokenauth","true")
      cookie.set("tokenauth","true")
    } else {
      // Token is not valid
      //userAuth(false);
    }
  } else if(response.status===401) {
    // No token found
   // userAuth(false);
  }
    try {
        
        navigate("/v1/jim/support");
        
      } catch (error) {
        navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
      }
    
      }
    }
      else
      {
    try {
        navigate("/v1/jim/signnot");
         // Omit optional second argument
       

      } catch (error) {
        navigate("/error", { state: { message: "Failed to submit form" } }); // Pass optional second argument
      }
  
    
  }
})

  return (
  <>
  {/*<div className="body" style={{height: "630px"}}>
  <Header disp="none"/>
    
    <h2 style={{color: "#ffffff",textAlign: "center"}}>Forgot Password</h2><br/>
<div align="center">
<h6 className="login" >

<form>
<h5>Enter User Mail:</h5>
<br/><input  id="uname" name="uname" onChange={(e)=>setName(e.target.value)}/>
<br/><br/>
<input type="submit" name="log" id="log" value="Submit" onClick={clicks}/>
</form>
</h6>
</div>
  </div>*/}
  <div /*className="body" style={{height: "630px"}}*/>
      
      <Headerhotel disp="none"/>
      
     <h1 style={{color: "#ffffff",textAlign: "center",fontFamily:"auto"}}>Sign In</h1><br/>
  <div className="login">
  <span class="sc-AnqlK jNMwMZ">
    <button type="button" class="css-1undbr0" onClick={button}>
      <span class="css-j8fq0c">
        <span class="css-8xpfx5">
          <span role="img" aria-label="Back" class="css-snhnyn" /*style="--icon-primary-color: currentColor; --icon-secondary-color: var(--ds-surface, #FFFFFF);"*/>
            <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
              <path fill="#fff" fill-opacity=".01" d="M0 0h24v24H0z"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.207 5.793a1 1 0 010 1.414L8.414 11H18a1 1 0 110 2H8.414l3.793 3.793a1 1 0 11-1.415 1.414l-5.499-5.5A.997.997 0 015 12.028v-.057a.996.996 0 01.293-.678l5.499-5.5a1 1 0 011.415 0z" fill="currentColor"></path></svg></span></span>
              <span class="css-mu6jxl">Back</span></span></button></span>
              <br/>
              <br/>
      <form onSubmit={clicks} ref={feed}>
      <label><b>User Email
      </b>
      </label>
      <input type="email" name="username"  id="Uname" placeholder="Usermail" onChange={(e)=>setName(e.target.value)}/>
      <br/><br/>

      <input type="text" name="name"  value={to} style={{display:"none"}}/>
      <input type="text" name="email" value={name} style={{display:"none"}}/>

      <input type="submit" name="log" id="log" value="Submit" /*onClick={clicks}*//>
      </form>
      <br/><br/>
      {/*<a style={{float: "left"}} href="/register">Register for new User</a>*/}
      
      <br/>
      <br/>
    
</div>
  </div>
</>
  )
}

export default SignInHotel
